import Index from "../../../Index";
import { styled } from "@mui/material/styles";
import PagesIndex from "../../../PagesIndex";
import Sidebar from "../../../../components/user/defaultLayout/Sidebar";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import DataService, { imagePath } from "../../../../config/DataService";
import { Api } from "../../../../config/Api";

import { useEffect, useState } from "react";
import {
  userLoginDetails,
  userLoginProfileDetails,
} from "../../../../redux/features/adminslice/AdminSlice";
import { getUserProfileData } from "../../../../redux/features/AddService";
import { Pages } from "@mui/icons-material";

const Item = styled(Index.Paper)(({ theme }) => ({
  //   backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  //   ...theme.typography.body2,
  //   padding: theme.spacing(1),
  //   textAlign: "center",
  //   color: theme.palette.text.secondary,
}));

function UserProfile() {
  const navigate = PagesIndex.useNavigate();
  const dispatch = PagesIndex.useDispatch();

  const { getUserLoginDetails, getUserLoginProfileDetails, userProfileGet } =
    useSelector((state) => state.admin);

  const userToken = getUserLoginProfileDetails?.token;
  const [buttonDisable, setButtonDisable] = useState(false);
  const [image, setImage] = useState("");

  // const { firstName, middleName, lastName, email, mobile } = userProfileGet[0];

  useEffect(() => {
    let data = {
      vastipatrak: getUserLoginDetails?.vastipatrak,
    };
    dispatch(getUserProfileData(data));
  }, []);

  let initialValues = {
    firstName: getUserLoginProfileDetails
      ? getUserLoginProfileDetails?.mem_fname
      : "",
    middleName: getUserLoginProfileDetails
      ? getUserLoginProfileDetails?.mem_mname
      : "",
    lastName: getUserLoginProfileDetails
      ? getUserLoginProfileDetails?.mem_lname
      : "",
    mobile: getUserLoginProfileDetails
      ? getUserLoginProfileDetails?.mem_mobile
      : "",
    email: getUserLoginProfileDetails
      ? getUserLoginProfileDetails?.mem_emailid
      : "",
    image: getUserLoginProfileDetails
      ? getUserLoginProfileDetails?.mem_pic
      : "",
  };

  const handleFormSubmit = (values) => {
    setButtonDisable(true);
    const formData = new FormData();
    formData.append("firstName", values.firstName);
    formData.append("middleName", values.middleName);
    formData.append("lastName", values.lastName);
    formData.append("mobile", values.mobile);
    formData.append("email", values.email);
    formData.append("image", image ? image : values?.image);
    formData.append("vastipatrak", getUserLoginProfileDetails.vastipatrak);
    updateProfile(formData, values);
  };

  const updateProfile = async (data, values) => {
    try {
      const res = await DataService.post(Api.USER_UPDATE_PROFILE, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success(res?.data?.message);
      if (res?.data?.status == 200) {
        let data = {
          vastipatrak: getUserLoginDetails?.vastipatrak,
        };
        dispatch(getUserProfileData(data));
        let userData = {
          mem_fname: values?.firstName,
          mem_mname: values?.middleName,
          mem_lname: values?.lastName,
          mem_mobile: values?.mobile,
          mem_pic: userProfileGet?.image,
          mem_emailid: values?.email,
          vastipatrak: getUserLoginProfileDetails?.vastipatrak,
        };
        dispatch(userLoginProfileDetails(userData));
        setTimeout(() => {
          navigate("/user/user-listing");
        }, 0.5);
        setButtonDisable(false);
      } else {
        setButtonDisable(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <Index.Box className="apply-sahay-main">
      <Index.Box
        className="head-content"
        sx={{ display: "flex", flexDirection: "row" }}
      >
        {/* <Header /> */}
        {/* <Index.Box className="user-right"> */}
        <Index.Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <Index.Box className="p-15 background-ed profile-content flex-center res-profile-mobile ">
            <Index.Box className=" h-100">
              <Index.Box className="card-center">
                <PagesIndex.Formik
                  enableReinitialize
                  onSubmit={handleFormSubmit}
                  initialValues={initialValues}
                  validationSchema={
                    PagesIndex.validationSchemaUpdateUserProfile
                  }
                >
                  {({
                    values,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    errors,
                    touched,
                    handleBlur,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Index.Box className="card-main profile-card-main">
                        {/* <Index.Box className="title-main mb-15 text-center">
                          <Index.Typography
                            variant="p"
                            component="p"
                            className="page-title"
                          >
                            Update Profile
                          </Index.Typography>
                        </Index.Box> */}
                        <Index.Box className="profile-image text-center mb-20 user-profile-details">
                          {/* <img
                            src={
                              image
                                ? URL.createObjectURL(image)
                                : userProfileGet?.image != ""
                                ? `${imagePath}${userProfileGet?.image}`
                                : PagesIndex.Png.usericon
                            }
                            className="profile-img mb-15"
                          />
                           */}
                          {image ? (
                            <>
                              <img
                                src={URL.createObjectURL(image)}
                                alt="Image Preview"
                                className="profile-img mb-15"
                              />
                            </>
                          ) : (
                            <>
                              {userProfileGet?.image ? (
                                <img
                                  src={`${imagePath}${userProfileGet?.image}`}
                                  alt="Image Preview"
                                  className="profile-img mb-15"
                                />
                              ) : (
                                <img
                                  src={PagesIndex.Png.usericon}
                                  alt="Image Preview"
                                  className="profile-img mb-15"
                                />
                              )}
                            </>
                          )}
                          <Index.Box className="flex-center">
                            <Index.Box className="common-button grey-button change-profile user-edit-btn-details">
                              <Index.Button
                                variant="contained"
                                type="button"
                                className=""
                              >
                                <img
                                  src={PagesIndex.Svg.pencil}
                                  className="edit-profile-user"
                                />
                              </Index.Button>
                              <input
                                type="file"
                                className="change-profile-input"
                                accept="image/*"
                                name="image"
                                onChange={(event) => {
                                  setImage(event.currentTarget.files[0]);
                                }}
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="profile-inner">
                          <Index.Box className="">
                            <Index.Grid
                              container
                              columnSpacing={{ xs: 3.75, sm: 3.75, md: 3.75 }}
                            >
                              <Index.Grid item xs={12} sm={6} md={6} lg={6}>
                                <Item className="dashboard-item">
                                  <Index.Box>
                                    <Index.Typography
                                      variant="label"
                                      component="label"
                                      className="change-input-label"
                                    >
                                      First Name
                                    </Index.Typography>
                                    <Index.Box className="input-design-div with-border mb-15">
                                      <Index.TextField
                                        hiddenLabel
                                        id="filled-hidden-label-normal"
                                        placeholder="First Name"
                                        variant="filled"
                                        className="admin-input-design input-placeholder"
                                        name="firstName"
                                        value={values.firstName}
                                        onChange={(e) => {
                                          const inputValue = e.target.value;
                                          if (inputValue.length <= 21) {
                                            setFieldValue(
                                              "firstName",
                                              inputValue
                                            );
                                          }
                                        }}
                                        onBlur={handleBlur}
                                        helperText={
                                          touched.firstName && errors.firstName
                                        }
                                        error={Boolean(
                                          errors.firstName && touched.firstName
                                        )}
                                      />
                                    </Index.Box>
                                  </Index.Box>
                                </Item>
                              </Index.Grid>
                              <Index.Grid item xs={12} sm={6} md={6} lg={6}>
                                <Item className="dashboard-item">
                                  <Index.Box>
                                    <Index.Typography
                                      variant="label"
                                      component="label"
                                      className="input-label"
                                    >
                                      Middle Name
                                    </Index.Typography>
                                    <Index.Box className="input-design-div with-border mb-15">
                                      <Index.TextField
                                        hiddenLabel
                                        id="filled-hidden-label-normal"
                                        placeholder="Middle Name"
                                        variant="filled"
                                        className="admin-input-design input-placeholder"
                                        name="middleName"
                                        value={values.middleName}
                                        onChange={(e) => {
                                          const inputValue = e.target.value;
                                          if (inputValue.length <= 21) {
                                            setFieldValue(
                                              "middleName",
                                              inputValue
                                            );
                                          }
                                        }}
                                        onBlur={handleBlur}
                                        helperText={
                                          touched.middleName &&
                                          errors.middleName
                                        }
                                        error={Boolean(
                                          errors.middleName &&
                                            touched.middleName
                                        )}
                                      />
                                    </Index.Box>
                                  </Index.Box>
                                </Item>
                              </Index.Grid>
                              <Index.Grid item xs={12} sm={6} md={6} lg={6}>
                                <Item className="dashboard-item">
                                  <Index.Box>
                                    <Index.Typography
                                      variant="label"
                                      component="label"
                                      className="input-label"
                                    >
                                      Last Name
                                    </Index.Typography>
                                    <Index.Box className="input-design-div with-border mb-15">
                                      <Index.TextField
                                        hiddenLabel
                                        id="filled-hidden-label-normal"
                                        placeholder="Last Name"
                                        variant="filled"
                                        className="admin-input-design input-placeholder"
                                        name="lastName"
                                        value={values.lastName}
                                        onChange={(e) => {
                                          const inputValue = e.target.value;
                                          if (inputValue.length <= 21) {
                                            setFieldValue(
                                              "lastName",
                                              inputValue
                                            );
                                          }
                                        }}
                                        onBlur={handleBlur}
                                        helperText={
                                          touched.lastName && errors.lastName
                                        }
                                        error={Boolean(
                                          errors.lastName && touched.lastName
                                        )}
                                      />
                                    </Index.Box>
                                  </Index.Box>
                                </Item>
                              </Index.Grid>
                              <Index.Grid item xs={12} sm={6} md={6} lg={6}>
                                <Item className="dashboard-item">
                                  <Index.Box>
                                    <Index.Typography
                                      variant="label"
                                      component="label"
                                      className="input-label"
                                    >
                                      Email
                                    </Index.Typography>
                                    <Index.Box className="input-design-div with-border mb-15">
                                      <Index.TextField
                                        hiddenLabel
                                        id="filled-hidden-label-normal"
                                        placeholder="Email"
                                        variant="filled"
                                        className="admin-input-design input-placeholder"
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={
                                          touched.email && errors.email
                                        }
                                        error={Boolean(
                                          errors.email && touched.email
                                        )}
                                      />
                                    </Index.Box>
                                  </Index.Box>
                                </Item>
                              </Index.Grid>
                              <Index.Grid item xs={12} sm={6} md={6} lg={6}>
                                <Item className="dashboard-item">
                                  <Index.Box>
                                    <Index.Typography
                                      variant="label"
                                      component="label"
                                      className="input-label"
                                    >
                                      Mobile Number
                                    </Index.Typography>
                                    <Index.Box className="input-design-div with-border mb-15">
                                      <Index.TextField
                                        hiddenLabel
                                        id="filled-hidden-label-normal"
                                        placeholder="Mobile Number"
                                        variant="filled"
                                        type="number"
                                        className="admin-input-design input-placeholder"
                                        name="mobile"
                                        value={values.mobile}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "mobile",
                                            e.target.value.slice(0, 10)
                                          );
                                        }}
                                        onBlur={handleBlur}
                                        helperText={
                                          touched.mobile && errors.mobile
                                        }
                                        error={Boolean(
                                          errors.mobile && touched.mobile
                                        )}
                                      />
                                    </Index.Box>
                                  </Index.Box>
                                </Item>
                              </Index.Grid>
                            </Index.Grid>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="line" mt={3} mb={3}></Index.Box>

                        <Index.Box className="user-profile-btn-main">
                          <Index.Box
                            mr={2}
                            className="common-button blue-button text-end-details change-password-btn update_priofile_btn"
                          >
                            <Index.Button
                              variant="contained"
                              disabled={buttonDisable}
                              type="submit"
                            >
                              Update Profile
                            </Index.Button>
                          </Index.Box>
                          <Index.Box className="common-button blue-button text-end-details change-password-btn update_priofile_btn">
                            <Index.Button
                              variant="contained"
                              onClick={() => navigate("/user/user-listing")}
                            >
                              Back
                            </Index.Button>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </form>
                  )}
                </PagesIndex.Formik>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}

export default UserProfile;
