import Index from "../../../Index";
import React, { useState } from "react";
import PagesIndex from "../../../PagesIndex";
import { useEffect } from "react";
import {
  getSubSahaylist,
  multipleDeleteSubSahay,
  subSahaylistDelete,
} from "../../../../redux/features/AddService";
import { STATUSES } from "../../../../redux/features/adminslice/AdminSlice";
import moment from "moment";
import { useSelector } from "react-redux";
import PageLoading from "../../../../components/common/pageLoading/PageLoading";
import RowPerPageDropdown from "../../../../components/common/paginationRowPerpage/RowPerPageDropdown";

const SubsahaytypeList = () => {
  //useselector
  const { getSubSahaydata, status } = PagesIndex.useSelector(
    (state) => state.admin
  );

  //navigate and dispatch
  const navigate = PagesIndex.useNavigate();
  const dispatch = PagesIndex.useDispatch();

  // all state
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [count, setCount] = React.useState(0);
  const [currentRows, setCurrentRows] = React.useState([]);
  const [actionIndex, setActionIndex] = useState();
  const [rowId, setRowId] = useState("");
  const [pageData, setPageData] = useState([]);
  const [rowPerPage, setRowPerPage] = useState("25");
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loader, setLoader] = useState(true);
  const [subSahayatypeList, setSubSahayatypeList] = useState([]);
  const [searchedData, setSearchedData] = useState([]);
  const rollData = JSON.parse(localStorage?.getItem("RollName"))?.toLowerCase();
  let deleteText = "sub sahay type";
  useEffect(() => {
    dispatch(getSubSahaylist()).then((res) => {
      if (res?.payload) {
        setLoader(false);
        setSubSahayatypeList(res?.payload);
      }
      setLoader(false);
    });
  }, []);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickMenu = (e, index) => {
    setAnchorEl(e.currentTarget);
    setActionIndex(index);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const { rollPermissionData } = useSelector((state) => state.admin);
  const rollPermissionNameData =
    rollPermissionData?.findData?.roleName?.toLowerCase();
  //update
  const handleEdit = (row) => {
    navigate(`/${rollData}/subtype-master-add`, {
      state: { row, edit: "Edit" },
    });
  };

  //open delete model
  const handleDeleteOpen = () => {
    setAnchorEl(null);
    setDeleteOpen(true);
  };

  //close delete model
  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  //handle delete
  const handleDeleteRecord = () => {
    dispatch(subSahaylistDelete(rowId.Id)).then((res) => {
      if (res?.payload == 201) {
        dispatch(getSubSahaylist()).then((res) => {
          if (res?.payload) {
            setLoader(false);
            setSubSahayatypeList(res?.payload);
          }
          setLoader(false);
        });
      }
    });

    setDeleteOpen(false);
  };

  const handleCheckboxChange = (itemId) => {
    if (selectedRows.includes(itemId)) {
      setSelectedRows(selectedRows.filter((id) => id !== itemId));
    } else {
      setSelectedRows([...selectedRows, itemId]);
    }
  };

  const handleAllDelete = async () => {
    const urlEncodedData = new URLSearchParams();

    selectedRows?.forEach((id, i) => {
      urlEncodedData.append(`ids[0][id${i + 1}]`, id);
    });

    dispatch(multipleDeleteSubSahay(urlEncodedData)).then((res) => {
      if (res.payload == 200) {
        dispatch(getSubSahaylist()).then((res) => {
          if (res?.payload) {
            setLoader(false);
            setSubSahayatypeList(res?.payload);
          }
          setLoader(false);
        });
      }
    });
  };

  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      const allItemIds = pageData.map((item) => item.Id);
      setSelectedRows(allItemIds);
    }
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    setSearchedData(subSahayatypeList);
  }, [subSahayatypeList]);

  const requestSearch = (searched) => {
    if (!searched) return setSearchedData(subSahayatypeList);
    setSearchedData(
      subSahayatypeList.filter(
        (item) =>
          item?.sahayCategory.toLowerCase().includes(searched.toLowerCase()) ||
          item?.sahayName.toLowerCase().includes(searched.toLowerCase())
      )
    );
  };

  

  if (rollPermissionData?.permissionArray?.includes("Sub Sahay Type Master_view")|| rollPermissionNameData === "admin") {
  } else {
    navigate(`/${rollData}/sahay-application`);
  }

  return (
    <>
      {loader ? (
        <PageLoading />
      ) : (
        <>
          <Index.Box className="page-content-box">
            <Index.Box className="barge-common-box">
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex res-title-header-flex">
                  <Index.Box className="plus-hidden-show-mobile">
                    <Index.Box className="title-main">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="page-title"
                      >
                        Sub Sahay Type Master
                      </Index.Typography>
                    </Index.Box>
                    {rollPermissionData?.permissionArray?.includes(
                      "Sub Sahay Type Master_add"
                    ) || rollPermissionNameData === "admin" ? (
                      <>
                        <Index.Box className="common-button blue-button res-blue-button res-mobile-show">
                          <Index.Box className="mobile-menu-show-hover">
                            <PagesIndex.Link
                              to="/admin/subtype-master-add"
                              className="no-text-decoration"
                            >
                              {" "}
                              <Index.Button
                                variant="contained"
                                disableRipple
                                className="apply-sahay-btn"
                              >
                                <Index.ControlPointIcon />
                              </Index.Button>
                            </PagesIndex.Link>
                            <Index.Box className="hover-tooltip-custom">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="title-tooltip"
                              >
                                {" "}
                                Add Sub Sahay
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </>
                    ) : (
                      ""
                    )}
                  </Index.Box>
                  <Index.Box className="d-flex align-items-center res-set-search">
                    <PagesIndex.Search
                      className="search"
                      onChange={(e) => requestSearch(e.target.value)}
                    >
                      <PagesIndex.StyledInputBase
                        placeholder="Search"
                        inputProps={{ "aria-label": "search" }}
                      />
                    </PagesIndex.Search>
                    {rollPermissionData?.permissionArray?.includes(
                      "Sub Sahay Type Master_add"
                    ) || rollPermissionNameData === "admin" ? (
                      <>
                        <Index.Box className="common-button blue-button res-blue-button desk-hidden-show">
                          <Index.Link
                            to={`/${rollData}/subtype-master-add`}
                            className="no-text-decoration"
                          >
                            {" "}
                            <Index.Button variant="contained" disableRipple>
                              <Index.ControlPointIcon className="plus-add" />
                              Add Sub Sahay
                            </Index.Button>
                          </Index.Link>
                        </Index.Box>
                      </>
                    ) : (
                      ""
                    )}
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="sahay-table">
                <Index.TableContainer
                  sx={{ maxHeight: 382 }}
                  // component={Index.Paper}
                  className="table-container  sub-type-list-container scroll-table common-table-container-custom"
                >
                  <Index.Table
                   stickyHeader aria-label="sticky table"
                    className="table-design-main barge-table sub-type-list-table"
                  >
                    <Index.TableHead className="sub-type-list-head">
                      <Index.TableRow className="sub-type-list-tr">
                        <Index.TableCell className="sub-type-list-th">
                          Sahay Type
                        </Index.TableCell>
                        <Index.TableCell
                          className="sub-type-list-th"
                          align="left"
                        >
                          Sub Sahay Type
                        </Index.TableCell>
                        <Index.TableCell
                          className="sub-type-list-th"
                          align="left"
                        >
                          Created Date{" "}
                        </Index.TableCell>
                        <Index.TableCell
                          className="sub-type-list-th"
                          align="center"
                        >
                          {rollPermissionData?.permissionArray?.includes(
                            "Sub Sahay Type Master_edit"
                          ) ||
                          rollPermissionData?.permissionArray?.includes(
                            "Sub Sahay Type Master_delete"
                          ) ||
                          rollPermissionNameData === "admin"
                            ? "Action"
                            : ""}
                        </Index.TableCell>
                      </Index.TableRow>
                    </Index.TableHead>
                    <Index.TableBody
                      className={
                        status !== STATUSES.LOADING
                          ? "sub-type-list-tbody"
                          : "loading-table"
                      }
                    >
                      {pageData?.length ? (
                        pageData?.map((data, index) => (
                          <Index.TableRow className="sub-type-list-tr">
                            <Index.TableCell
                              className="sub-type-list-td"
                              align="left"
                            >
                              {" "}
                              {data?.sahayCategory}
                            </Index.TableCell>
                            <Index.TableCell
                              className="sub-type-list-td"
                              key={data.Id}
                            >
                              {data?.sahayName}
                            </Index.TableCell>
                            <Index.TableCell
                              className="sub-type-list-td"
                              align="left"
                            >
                              {moment
                                .utc(
                                  data?.Createdatetime
                                    ? data?.Createdatetime
                                    : null
                                )
                                .format("YYYY-MM-DD, h:mm:ss A")}
                            </Index.TableCell>

                            <Index.TableCell
                              className="sub-type-list-td"
                              align="center"
                              sx={{
                                display: "flex",
                                justifyContent: "space-around",
                                alignContent: "space-around",
                              }}
                            >
                              {rollPermissionData?.permissionArray?.includes(
                                "Sub Sahay Type Master_edit"
                              ) || rollPermissionNameData === "admin" ? (
                                <>
                                  <Index.IconButton
                                    onClick={() => handleEdit(data)}
                                  >
                                    <Index.EditIcon className="muiico-edit" />
                                  </Index.IconButton>
                                </>
                              ) : (
                                ""
                              )}
                              {rollPermissionData?.permissionArray?.includes(
                                "Sub Sahay Type Master_delete"
                              ) || rollPermissionNameData === "admin" ? (
                                <>
                                  <Index.IconButton
                                    onClick={() => {
                                      setRowId(data);
                                      handleDeleteOpen();
                                    }}
                                  >
                                    <Index.DeleteIcon className="muiico-delete" />
                                  </Index.IconButton>
                                </>
                              ) : (
                                ""
                              )}
                            </Index.TableCell>
                          </Index.TableRow>
                        ))
                      ) : (
                        <PagesIndex.NoRecordFound colSpan={12} />
                      )}
                    </Index.TableBody>
                  </Index.Table>
                </Index.TableContainer>
              </Index.Box>
              <Index.Box className="pagination-design flex-end">
                <RowPerPageDropdown
                  rowPerPage={rowPerPage}
                  setRowPerPage={setRowPerPage}
                />
                <Index.Stack spacing={2}>
                  <PagesIndex.Pagination
                    fetchData={searchedData}
                    setPageData={setPageData}
                    pageData={pageData}
                    rowPerPage={rowPerPage}
                  />
                </Index.Stack>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <PagesIndex.DeleteModal
            deleteText={deleteText}
            deleteOpen={deleteOpen}
            handleDeleteRecord={handleDeleteRecord}
            handleDeleteClose={handleDeleteClose}
          />
        </>
      )}
    </>
  );
};

export default SubsahaytypeList;
