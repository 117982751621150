import React, { useState } from "react";
import Index from "../../Index";

const ReasonModal = ({
  open,
  handleClose,
  handleComplete,
  setReason,
  reason,
  isDisabled
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
  };

  const [error, setError] = useState("");
  return (
    <Index.Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal-delete modal"
    >
      <Index.Box sx={style} className="delete-modal-inner-main modal-inner">
        <Index.Box className="remark-textarea-details">
          <Index.Box className="set-text-area ">
            <Index.Typography
              variant="label"
              component="label"
              className="input-label"
            >
              Remark (ટિપ્પણી)
            </Index.Typography>
            <Index.Box className="set-textarea-box-top">
              <Index.TextField
                className="set-textarea-box custom-set-area"
                aria-label="empty textarea"
                placeholder="Remark"
                multiline
                name="Remark"
                onChange={(e) => {
                  const newvalue = e.target.value
                    .replace(/^\s+/, "")
                    .replace(/\s\s+/g, " ");
                  setReason(newvalue);
                }}
              />
            </Index.Box>

            <p className="error-text" style={{ marginTop: "10px" }}>
              {error}
            </p>
          </Index.Box>
          <Index.Box className="btn-submit-area">
            <Index.Box className="common-button blue-button change-password-btn update_priofile_btn">
              <Index.Button
                variant="contained"
                disableRipple
                type="submit"
                disabled={isDisabled}
                onClick={() => {
                  if (reason) {
                    handleComplete();
                  } else {
                    setError("Please enter remark");
                  }
                }}
              >
                Submit
              </Index.Button>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Modal>
  );
};

export default ReasonModal;
