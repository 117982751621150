import React, { useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import Pagination from "../../../../components/common/Pagination";
import PagesIndex from "../../../PagesIndex";
import {
  getNewReportData,
  getRepaymentData,
  getSahaySubTypeList,
  getSahayTypeList,
} from "../../../../redux/features/AddService";
import { useSelector } from "react-redux";
import PageLoading from "../../../../components/common/pageLoading/PageLoading";
import moment from "moment";
import FileExcel from "../../../../components/common/excelSheet/FileExcel";
import { Autocomplete } from "@mui/material";
import RowPerPageDropdown from "../../../../components/common/paginationRowPerpage/RowPerPageDropdown";
import dataService from "../../../../config/DataService";
import { ApiOutlined } from "@mui/icons-material";
import { Api } from "../../../../config/Api";
import PrintReport from "../../../../components/common/printReport/PrintReport";
import { useReactToPrint } from "react-to-print";
import dayjs from "dayjs";

export default function NewReport() {
  const { getNewReportDataList } = PagesIndex.useSelector(
    (state) => state?.admin
  );
  const location = PagesIndex.useLocation()
  const fileName = "Sahay Application Procedure Report CSV";
  const [excel, setExcel] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [rowPerPage, setRowPerPage] = useState("25");
  const dispatch = PagesIndex.useDispatch();
  const [filter, setFilter] = useState("");
  const [filterSubSahay, setFilterSubSahay] = useState("");
  const [filterYear, setFilterYear] = useState("");
  const [searchedData, setSearchedData] = useState([]);
  const [filterReportData, setFilterReportData] = useState([]);
  const [sahaySubTypeList, setSahaySubTypeList] = useState([]);
  const [sahayTypeList, setSahayTypeList] = useState([]);
  const [sahayFilter, setSahayFilter] = useState("");
  const [selectDateData, setSelectDateData] = useState("");

  // Print Reports
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    dispatch(getNewReportData()).then((res) => {
      if (res?.payload?.status == 200) {
        setLoader(false);
      }
      setLoader(false);
    });
  }, [filter, filterSubSahay, sahayFilter, filterYear]);



  // useEffect(() => {
  //   if (filter || filterSubSahay || sahayFilter || selectDateData) {
  //     const filterData = getNewReportDataList?.data?.filter((ele) => {

  //       if (filter && sahayFilter && filterSubSahay && selectDateData) {
  //         return (
  //           ele.FID == filter &&
  //           // ele.SahayTypeName == sahayFilter &&
  //           ele.subSahayTypeName == filterSubSahay &&
  //           ele.applicationReceiveDate == selectDateData
  //         );
  //       } else if (filter && sahayFilter) {
  //         return ele.FID == filter && ele.SahayTypeName == sahayFilter;
  //       } else if (filter && filterSubSahay) {
  //         return ele.FID == filter && ele.subSahayTypeName == filterSubSahay;
  //       } else if (sahayFilter && filterSubSahay) {
  //         return (
  //           // ele.SahayTypeName == sahayFilter &&
  //           ele.subSahayTypeName == filterSubSahay
  //         );
  //       } else if (filter && sahayFilter && filterSubSahay) {
  //         return ele.FID == filter && ele.subSahayTypeName == filterSubSahay &&
  //         ele.SahayTypeName == sahayFilter
  //       } else if (filter || sahayFilter || filterSubSahay || selectDateData) {
  //         return (
  //           ele.FID == filter ||
  //           ele.SahayTypeName == sahayFilter ||
  //           ele.subSahayTypeName == filterSubSahay ||
  //           ele.applicationReceiveDate == selectDateData
  //         );
  //       } else {
  //         return true; // If no filters are provided, return true for all elements
  //       }
  //     });
  //     setFilterReportData(filterData);
  //   } else {
  //     setFilterReportData();
  //   }
  // }, [filter, filterSubSahay, sahayFilter, selectDateData]);



  // useEffect(() => {
  //   if (
  //     filter ||
  //     filterSubSahay ||
  //     sahayFilter ||
  //     selectDateData ||
  //     filterYear
  //   ) {
  //     const filterData = getNewReportDataList?.data?.filter((ele) => {
  //       if (
  //         filter &&
  //         sahayFilter &&
  //         filterSubSahay &&
  //         filterYear &&
  //         selectDateData
  //       ) {
  //         return (
  //           ele.FID == filter &&
  //           ele.SahayTypeName == sahayFilter &&
  //           ele.subSahayTypeName == filterSubSahay &&
  //           ele.Year == filterYear &&
  //           ele.applicationReceiveDate == selectDateData
  //         );
  //       } else if (filter && sahayFilter && filterSubSahay) {
  //         return (
  //           ele.FID == filter &&
  //           ele.SahayTypeName == sahayFilter &&
  //           ele.subSahayTypeName == filterSubSahay
  //         );
  //       } else if (filter && sahayFilter) {
  //         return ele.FID == filter && ele.SahayTypeName == sahayFilter;
  //       } else if (filter && filterSubSahay) {
  //         return ele.FID == filter && ele.subSahayTypeName == filterSubSahay;
  //       } else if (sahayFilter && filterSubSahay) {
  //         return (
  //           ele.SahayTypeName == sahayFilter &&
  //           ele.subSahayTypeName == filterSubSahay
  //         );
  //       } else if (sahayFilter && selectDateData) {
  //         return (
  //           ele.SahayTypeName == sahayFilter &&
  //           ele.applicationReceiveDate == selectDateData
  //         );
  //       } else if (sahayFilter && filterSubSahay && selectDateData) {
  //         return (
  //           ele.SahayTypeName == sahayFilter &&
  //           ele.applicationReceiveDate == selectDateData && ele.subSahayTypeName
  //         );
  //       }
  //       else if (
  //         filter ||
  //         sahayFilter ||
  //         filterSubSahay ||
  //         filterYear ||
  //         selectDateData
  //       ) {
  //         return (
  //           ele.FID == filter ||
  //           ele.SahayTypeName == sahayFilter ||
  //           ele.subSahayTypeName == filterSubSahay ||
  //           ele.Year == filterYear ||
  //           ele.applicationReceiveDate == selectDateData
  //         );
  //       } else if (filter) {
  //         return ele.FID == filter;
  //       } else if (sahayFilter) {
  //         return ele.SahayTypeName == sahayFilter;
  //       } else if (filterSubSahay) {
  //         return ele.subSahayTypeName == filterSubSahay;
  //       } else if (selectDateData) {
  //         return ele.applicationReceiveDate == selectDateData;
  //       } else {
  //         return true; // If no filters are provided, return true for all elements
  //       }
  //     });
  //     setFilterReportData(filterData);
  //   } else {
  //     setFilterReportData();
  //   }
  // }, [filter, filterSubSahay, sahayFilter, selectDateData, filterYear]);

  useEffect(() => {
    const filters = {
      FID: filter,
      Year: filterYear,
      SahayTypeName: sahayFilter,
      subSahayTypeName: filterSubSahay,
      applicationReceiveDate: selectDateData,
    };

    const filterData = getNewReportDataList?.data?.filter((ele) => {
      if (Object.values(filters).some(Boolean)) {
        return Object.entries(filters).every(([key, value]) => {
          if (!value) return true;
          return ele[key] == value;
        });
      } else {
        return true;
      }
    });

    setFilterReportData(filterData);
  }, [filter, filterYear, sahayFilter, filterSubSahay, selectDateData]);

  useEffect(() => {
    setSearchedData(filterReportData);
  }, [filterReportData]);

  useEffect(() => {
    if (filterReportData?.length > 0) {
      const customHeadings = filterReportData?.map((item) => ({
        Fid: item?.FID,
        "Applicant name": item?.applicantName,
        "Beneficiary Name": item?.beneficiaryName,
        "Sahay name": item?.SahayTypeName,
        "Sub-Sahay name": item?.subSahayTypeName,
        "Application Received Date": item?.applicationReceiveDate,
        "Application Entered Date": item?.ApplicationEnteredDate,
        "Committee Approval Date": item?.committeeApprovalDate,
        "Disbursement Date": item?.disbursedDate,
        "Demand Amount": item?.demandAmount,
        "Disbursement Amount": item?.approveAmount,
      }));

      setExcel(customHeadings);
    }
  }, [filterReportData]);

  // Search on table
  const requestSearch = (searched) => {
    if (!searched) return setSearchedData(filterReportData);
    setSearchedData(
      filterReportData?.filter((item) =>
        item?.applicantName
          ?.toString()
          ?.toLowerCase()
          .includes(searched?.toLowerCase())
      )
    );
  };
  //get Sub sahay list
  // const getSahaySubTypeData = async () => {
  //   try {
  //     const res = await dataService.get(Api.GET_ALL_SUB_SAHAY_LIST);
  //     if (res?.data?.status) {
  //       setSahaySubTypeList(res?.data?.Data);
  //     } else {
  //       setSahaySubTypeList([]);
  //     }
  //   } catch (error) {}
  // };
  const getSahaySubTypeData = async (id) => {
    dispatch(getSahaySubTypeList(id)).then((res) => {
      if (res?.payload) {
        setSahaySubTypeList(res?.payload);
      } else {
        setSahaySubTypeList([]);
      }
    });
  };

  const fetchSahayTypeList = () => {
    dispatch(getSahayTypeList()).then((res) => {
      setSahayTypeList(res?.payload);
    });
  };

  useEffect(() => {
    fetchSahayTypeList();
    getSahaySubTypeData();
  }, []);

    //#region start view sahay application

    const handleSahayApplicationView = (viewdata) => {
      const updatedViewData = {
        ...viewdata,
        familyId: viewdata?.FID,   
        prevRoute: location?.pathname,
      };
      navigate(`/${rollData}/sahay-report-view`, { state: updatedViewData });
    };
  
  
    //#region end view sahay application

  const { rollPermissionData } = useSelector((state) => state.admin);

  const rollData = JSON.parse(localStorage?.getItem("RollName"))?.toLowerCase();

  const navigate = PagesIndex.useNavigate();
  const rollPermissionNameData =
    rollPermissionData?.findData?.parentRoleName?.toLowerCase();

  if (
    rollPermissionData?.permissionArray?.includes("Sahay Application Procedure Report_view") ||
    rollPermissionNameData === "admin"
  ) {
  } else {
    navigate(`/${rollData}/sahay-application`);
  }

  return (
    <>
      {loader ? (
        <PageLoading />
      ) : (
        <>
          <Index.Box className="page-content-box">
            <Index.Box className="barge-common-box">
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex res-title-header-flex">
                  <Index.Box className="title-main">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="page-title"
                    >
                      Sahay Application Procedure Report
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="grid-row  sahay-multiple-main">
                <Index.Box sx={{ width: 1 }} className="grid-main">
                  <Index.Box
                    display="grid"
                    className="app-report-row"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 3",
                        lg: "span 3",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="sahay-multiple-input-main">
                        <PagesIndex.Search
                          className="search sahay-multiple-search"
                          onChange={(e) => requestSearch(e.target.value)}
                        >
                          <PagesIndex.StyledInputBase placeholder="Search" />
                        </PagesIndex.Search>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 3",
                        lg: "span 3",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="sahay-multiple-input-main">
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          className="multiple-autocomplet-control"
                          options={getNewReportDataList?.data || []}
                          getOptionLabel={(option) => option.FID?.toString()}
                          onChange={(value, newvalue) => {
                            setFilter(newvalue?.FID);
                          }}
                          filterOptions={(options, { inputValue }) => {
                            const inputLowerCase = inputValue?.toLowerCase();
                            const uniqueFamilyId = new Set();
                            return options?.filter((option) => {
                              const familyId =
                                option?.FID?.toString()?.toLowerCase();
                              // Check unique
                              if (uniqueFamilyId?.has(familyId)) {
                                return false;
                              }
                              // Check search
                              if (familyId?.includes(inputLowerCase)) {
                                uniqueFamilyId?.add(familyId);
                                return true;
                              }
                              return false;
                            });
                          }}
                          renderInput={(params) => (
                            <Index.TextField {...params} label="Fid" />
                          )}
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 3",
                        lg: "span 3",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="sahay-multiple-input-main">
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          className="multiple-autocomplet-control"
                          options={sahayTypeList || []}
                          getOptionLabel={(option) => option?.Sahay_category}
                          onChange={(value, newvalue) => {
                            setSahaySubTypeList([]);
                            setFilterSubSahay("");
                            setSahayFilter(newvalue?.Sahay_category);
                            getSahaySubTypeData(newvalue?.Id);
                          }}
                          renderInput={(params) => (
                            <Index.TextField {...params} label="Sahay Name" />
                          )}
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 3",
                        lg: "span 3",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="sahay-multiple-input-main">
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          className="multiple-autocomplet-control"
                          options={sahaySubTypeList}
                          getOptionLabel={(option) => option?.SahayName}
                          onChange={(value, newvalue) => {
                            setFilterSubSahay(newvalue?.SahayName);
                          }}
                          value={
                            sahaySubTypeList &&
                            sahaySubTypeList.length > 0 &&
                            filterSubSahay
                              ? sahaySubTypeList.find(
                                  (item) => item.SahayName === filterSubSahay
                                )
                              : null
                          }
                          renderInput={(params) => (
                            <Index.TextField
                              {...params}
                              label="Sub Sahay Name"
                            />
                          )}
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 4",
                        lg: "span 3",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="sahay-multiple-input-main">
                        <Autocomplete
                          disablePortal
                          className="multiple-autocomplet-control"
                          id="combo-box-demo"
                          options={getNewReportDataList?.data || []}
                          getOptionLabel={(option) => option?.Year}
                          onChange={(value, newvalue) => {
                            setFilterYear(newvalue?.Year);
                          }}
                          renderInput={(params) => (
                            <Index.TextField {...params} label="Year" />
                          )}
                          filterOptions={(options, { inputValue }) => {
                            const inputLowerCase = inputValue
                              ?.toLowerCase()
                              ?.replace(/\s/g, "");
                            const uniqueYear = new Set();
                            return options?.filter((option) => {
                              const year = option?.Year?.toLowerCase()?.replace(
                                /\s/g,
                                ""
                              );
                              // Check unique
                              if (uniqueYear?.has(year)) {
                                return false;
                              }
                              // Check search
                              if (year?.includes(inputLowerCase)) {
                                uniqueYear?.add(year);
                                return true;
                              }
                              return false;
                            });
                          }}
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 3",
                        lg: "span 3",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="sahay-multiple-input-main common-date-picker-custom">
                        <Index.LocalizationProvider
                          dateAdapter={Index.AdapterDayjs}
                          sx={{ position: "absolute" }}
                        >
                          <Index.DemoContainer components={["DatePicker"]}>
                            <Index.DatePicker
                              label="Application Receive Date"
                              name="applicationReceiveDate"
                              onChange={(value) => {
                                setSelectDateData(
                                  moment(value.$d).format("YYYY-MM-DD")
                                );
                              }}
                              // renderInput={(params) => (
                              //   <Index.TextField
                              //     {...params}
                              //     label="Date"
                              //   />
                              // )}
                              format="YYYY-MM-DD"
                              sx={{
                                // overflowY: "hidden",
                                // position: "relative",
                                // bottom: "8px",
                                width: "100%",
                              }}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  error: false,
                                },
                              }}
                            />
                          </Index.DemoContainer>
                        </Index.LocalizationProvider>
                      </Index.Box>
                    </Index.Box>
                    {filterReportData?.length > 0 && (
                      <>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 4",
                            lg: "span 4",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="sahay-multiple-input-main">
                            <Index.Box
                              Box
                              className="res-auto-left common-button blue-button  multiple-download-btn  res-blue-button"
                            >
                              <FileExcel apiData={excel} fileName={fileName} />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 4",
                            lg: "span 3",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="sahay-multiple-input-main">
                            <Index.Box
                              Box
                              className="res-auto-left common-button blue-button multiple-download-btn res-blue-button"
                            >
                              <Index.Button onClick={handlePrint}>
                                Print
                              </Index.Button>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </>
                    )}
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="table-list">
                <Index.TableContainer
                  sx={{ maxHeight: 220 }}
                  // component={Index.Paper}
                  className="table-container user-master-container scroll-table common-table-container-custom"
                >
                  <Index.Table
                          stickyHeader aria-label="sticky table"
                    className="table-design-main barge-table re-payment-table"
                  >
                    <Index.TableHead className="user-master-head">
                      <Index.TableRow className="user-master-tr">
                        <Index.TableCell className="user-master-th">
                          Fid
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Applicant Name
                        </Index.TableCell>

                        <Index.TableCell className="user-master-th">
                          Benificiary name
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Sahay name
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Sub-Sahay name
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Application Received Date
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Application Entered Date
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Application Approval Date
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Disbursement Date
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Demand Amount
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Disbursement Amount
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Year
                        </Index.TableCell>
                        {/* <Index.TableCell className="user-master-th">
                          Remark
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Status
                        </Index.TableCell> */}
                      </Index.TableRow>
                    </Index.TableHead>
           
                    <Index.TableBody className="user-master-tbody">
                      {pageData?.length &&
                      (filter ||
                        filterYear ||
                        filterSubSahay ||
                        sahayFilter ||
                        selectDateData) ? (
                        pageData?.map((data, index) => (
                          <Index.TableRow className="user-master-tr"     onClick={() => handleSahayApplicationView(data)}
                          sx={{ cursor: "pointer" }}>
                         
                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-role-name">
                                {data?.FID || "-"}
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-details-name">
                                {data?.applicantName || "-"}
                              </Index.Box>
                            </Index.TableCell>

                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-email-name">
                                {data?.beneficiaryName || "-"}
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-email-name">
                                {data?.SahayTypeName || "-"}
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-email-name">
                                {data?.subSahayTypeName || "-"}
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-number-name">
                                {data?.applicationReceiveDate || "-"}
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-number-name">
                                {data?.ApplicationEnteredDate || "-"}
                                {/* {moment(data?.StartDate || "-").format(
                                    "DD-MM-YYYY"
                                  ) == "Invalid date"
                                    ? "-"
                                    : moment(data?.StartDate || "-").format(
                                        "DD-MM-YYYY"
                                      )} */}
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-number-name">
                                {data?.committeeApprovalDate || "-"}
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-number-name">
                                {data?.disbursedDate || "-"}
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-number-name">
                                {data?.demandAmount || "-"}
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-number-name">
                                {data?.approveAmount || "-"}
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-number-name">
                                {data?.Year || "-"}
                              </Index.Box>
                            </Index.TableCell>
                          </Index.TableRow>
                        ))
                      ) : (
                        <PagesIndex.NoRecordFound colSpan={12} />
                      )}
                    </Index.TableBody>
                  </Index.Table>
                </Index.TableContainer>
              </Index.Box>
              <Index.Box className="pagination-design flex-end">
                <RowPerPageDropdown
                  rowPerPage={rowPerPage}
                  setRowPerPage={setRowPerPage}
                />
                <Index.Stack spacing={2}>
                  <Pagination
                    fetchData={searchedData}
                    setPageData={setPageData}
                    pageData={pageData}
                    rowPerPage={rowPerPage}
                  />
                </Index.Stack>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box sx={{ display: "none" }}>
            <PrintReport
              componentRef={componentRef}
              tableData={excel}
              reportName={[{ type: "RepaymentReport" }]}
            />
          </Index.Box>
        </>
      )}
    </>
  );
}
