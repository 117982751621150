import React, { useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import {
  getSahaylistAdd,
  getSahaylistEdit,
} from "../../../../redux/features/AddService";
import { useSelector } from "react-redux";

const SahaytypeAdd = () => {
  const navigate = PagesIndex.useNavigate();
  const { state } = PagesIndex.useLocation();
  const dispatch = PagesIndex.useDispatch();

  //all state
  const [sahayExist, setSahayExist] = useState("");
  const rollData = JSON.parse(localStorage?.getItem("RollName"))?.toLowerCase();

  //initial values
  let initialValues = {
    sahayCategory: state ? state?.editData?.Sahay_category : "",
    isSubmit: false,
  };

  //handle submit
  const handleFormSubmit = (values, { setFieldValue }) => {
    setFieldValue("isSubmit", true);
    if (state?.editData?.Id) {
      let updatedata = {
        sahayCategory: values?.sahayCategory,
        sahayId: state?.editData?.Id,
      };
      dispatch(getSahaylistEdit(updatedata)).then((res) => {
        if (res?.payload == 200) {
          navigate(`/${rollData}/type-master`);
          setTimeout(() => {
            setFieldValue("isSubmit", false);
          }, 1000);
        } else {
          setTimeout(() => {
            setFieldValue("isSubmit", false);
          }, 1000);
        }
        if (res?.payload?.status == 409) {
          setSahayExist(res?.payload?.message);
        } else {
          setSahayExist("");
        }
      });
    } else {
      dispatch(getSahaylistAdd(values)).then((res) => {
        if (res?.payload == 201) {
          navigate("/admin/type-master");
          setTimeout(() => {
            setFieldValue("isSubmit", false);
          }, 1000);
        } else {
          setTimeout(() => {
            setFieldValue("isSubmit", false);
          }, 1000);
        }
        if (res?.payload?.status == 409) {
          setSahayExist(res?.payload?.message);
        } else {
          setSahayExist("");
        }
      });
    }
  };

  const { rollPermissionData } = useSelector((state) => state.admin);
  const rollPermissionNameData =
  rollPermissionData?.findData?.parentRoleName?.toLowerCase();

  if (
    rollPermissionData?.permissionArray?.includes("Sahay Type Master_add") ||
    rollPermissionData?.permissionArray?.includes("Sahay Type Master_edit")|| rollPermissionNameData === "admin"
  ) {
  } else {
    navigate(`/${rollData}/sahay-application`);
  }
  return (
    <>
      {" "}
      <Index.Box className="page-content-box">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex">
              <Index.Box className="title-main mb-10">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  {state ? state?.edit : "Add"} Sahay Type
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <PagesIndex.Formik
            enableReinitialize
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={PagesIndex.validationSchemaSahayTypeMaster}
          >
            {({
              values,
              handleChange,
              handleSubmit,
              errors,
              handleBlur,
              touched,
              // isSubmitting,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Index.Box className="">
                  <Index.Grid container spacing={2}>
                    <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Sahay Title(સહાય શીર્ષક)*
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          name="sahayCategory"
                          onBlur={handleBlur}
                          value={values.sahayCategory}
                          hiddenLabel
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            if (inputValue == "") {
                              setSahayExist("");
                            }
                            setFieldValue("sahayCategory", inputValue);
                          }}
                          id="filled-hidden-label-normal"
                          placeholder="Enter sahay"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          autoComplete="off"
                        />
                      </Index.Box>
                      {errors?.sahayCategory && touched.sahayCategory && (
                        <p className="error-text">{errors?.sahayCategory}</p>
                      )}
                      {sahayExist !== "" && values?.sahayCategory !== "" && (
                        <p className="error-text">{sahayExist}</p>
                      )}
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={3}>
                      <Index.Box className="common-button blue-button flex-start save-btn custom-space-details res-set-search">
                        <Index.Button
                          variant="contained"
                          type="submit"
                          disabled={values.isSubmit}
                        >
                          Submit
                        </Index.Button>
                      </Index.Box>
                    </Index.Grid>
                  </Index.Grid>
                </Index.Box>
              </form>
            )}
          </PagesIndex.Formik>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default SahaytypeAdd;
