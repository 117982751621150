import React, { useEffect, useRef, useState } from "react";
import Index from "../../../../container/Index";
import PropTypes from "prop-types";
import PagesIndex from "../../../PagesIndex";
import BackHandIcon from "@mui/icons-material/BackHand";
import { saveAs } from "file-saver";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import {
  addDisbursementSchedule,
  generateOtpCommitte,
  getCommitteeCurrentSahayApplicationListStaff,
  getCommitteePreviousSahayApplicationListStaff,
  getCurrentSahayApplicationListAdmin,
  getCurrentSahayApplicationListStaff,
  getDisbursedSchedulelist,
  getFamilyMemberById,
  getPreviousSahayApplicationListAdmin,
  getPreviousSahayApplicationListAdminNew,
  getSahayApplicationListAdmin,
  getSahaySubTypeList,
  getSahayTypeList,
  getSingleSahayApplication,
  uploadCSVData,
  uploadCurrentCSVData,
  uploadFamilyMemberData,
  verifyOtpCommitte,
} from "../../../../redux/features/AddService";
import moment from "moment";
import {
  STATUSES,
  editPreviousSahay,
} from "../../../../redux/features/adminslice/AdminSlice";
import DataService, { imagePath } from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import { toast } from "react-toastify";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import * as xlsx from "xlsx";
import { useSelector } from "react-redux";
import PageLoading from "../../../../components/common/pageLoading/PageLoading";
import RowPerPageDropdown from "../../../../components/common/paginationRowPerpage/RowPerPageDropdown";
import { OutlinedInput } from "@mui/material";
import {
  uploadLoanDocSchema,
  validationScheduleSchema,
  validationSchemaApproveAmoumt,
  validationSchemaDisbursement,
  validationSchemaEMIAmount,
  validationSchemaVerifyCommitteOTP,
} from "../../../../validation/Validation";
import ExportSampleSheet from "../../../../components/common/excelSheet/ExportSampleSheet";
import ReasonModal from "../../../../components/common/reasonModal/ReasonModal";
import { FieldArray } from "formik";
import { MobileDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import OTPInput from "react-otp-input";
import { useReactToPrint } from "react-to-print";
import PrintSahayApplication from "./PrintSahayApplication";

import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: "#eaac35",
    color: "#ffffff",
    fontSize: "11px",
    fontFamily: "Poppins-Regular",
    padding: "5px 9px",
    lineHeight: "19px",
    borderRadius: "10px",
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 100,
    },
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const SahayApplications = () => {
  const {
    sahayApplicationAdminGet,
    getPriviousListData,
    status,
    getAdminLoginDetails,
  } = PagesIndex.useSelector((state) => state.admin);

  const location = PagesIndex.useLocation();
  const navigate = PagesIndex.useNavigate();
  const dispatch = PagesIndex.useDispatch();
  const currentTabValue = location?.state?.activeTab;

  //all state
  const [excel, setExcel] = useState([
    {
      memberfamilyid: "",
      sahaycategory: "",
      subsahaycategory: "",
      sahayamount: "",
      addharcardno: "",
      nameofapplicant: "",
      bankname: "",
      accountholdername: "",
      accountnumber: "",
      ifsccode: "",
      remark: "",
      doesapplicanttooksahayfromanytrust: "",
      branch: "",
      vastipatrak: "",
      beneficial: "",
      workingtype: "",
      familyincome: "",
      electricbillamount: "",
      applicationreceivedate: "",
      formfillingdate: "",
    },
  ]);
  const familyMemberExcel = [
    {
      sahayid: "",
      name: "",
      relation: "",
      age: "",
      workingtype: "",
    },
  ];
  const previousYearExcelSample = [
    {
      "Type Of Sahay": "",
      typeofsubsahay: "",
      "Sahay Amount": "",
      "Year of Sahay": "",
      "Repay Detail": "",
      startDate: "",
      "NO OF MONTH": "",
      "Family ID": "",
      "Applicant Name": "",
      vastipatrak: "",
    },
  ];
  const [searchedData, setSearchedData] = React.useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [actionIndex, setActionIndex] = useState();
  const [pageData, setPageData] = useState([]);
  const [rowPerPage, setRowPerPage] = useState("25");
  const [statusFilter, setStatusFilter] = useState("Pending");
  const [approvedAmountIdData, setapprovedAmountIdData] = useState();
  const [showClaimedAmount, setShowClaimedAmount] = useState();
  const [currentData, setCurrentData] = useState();
  const [loader, setLoader] = useState(true);
  const [csvFile, setCsvFile] = useState(null);
  const [csvLoader, setCsvLoader] = useState(false);
  const [applicationListData, setApplicationListData] = useState([]);
  const [applicationListDataCommittee, setApplicationListDataCommittee] =
    useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  //////////////////// Previous List Data //////////////////
  const [previousSearchedData, setPreviousSearchedData] = React.useState([]);
  const [previousPageData, setPreviousPageData] = useState([]);
  const [previousListData, setPreviousListData] = React.useState([]);
  const [remarkIdData, setRemarkIdData] = useState();
  const [remarkData, setRemarkData] = useState("");
  const [familySahayId, setFamilySahayId] = useState("");
  const [tabValue, setTabValue] = React.useState(
    currentTabValue ? currentTabValue : 1
  );
  const [uploadSahayId, setUploadSahayId] = useState("");

  const [sortColumn, setSortColumn] = useState("userName");
  const [sortDirection, setSortDirection] = useState("asc");
  const [sahayTypeList, setSahayTypeList] = useState([]);
  const [sahaySubTypeList, setSahaySubTypeList] = useState([]);
  const [sahayType, setSahayType] = useState("");
  const [subSahay, setSubSahay] = useState("");
  const [dataForFilter, setDataForFilter] = useState([]);
  // useEffect(()=>{
  //   setTabValue(0)
  // },[])
  // const [csvData, setCSVData] = useState([]);
  const rollData = JSON.parse(localStorage?.getItem("RollName"))?.toLowerCase();

  const { state } = PagesIndex.useLocation();
  const RowIdHighlightData = state?.rowId;
  // const RowPreviousIdData = state?.rowPreviousId;
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setRowPerPage("25");
  };

  const getSahayTypeData = () => {
    dispatch(getSahayTypeList()).then((res) => {
      setSahayTypeList(res?.payload);
    });
  };

  const getSahaySubTypeData = (sahayTypeId) => {
    dispatch(getSahaySubTypeList(sahayTypeId)).then((res) => {
      setSahaySubTypeList(res?.payload);
    });
  };

  useEffect(() => {
    getSahayTypeData();
  }, []);

  //// Function to convert the csv data into lowewercase and spcae less

  // const modifiedData = modifyData(csvData);

  /////// For member current CSV ////////////

  function modifyMemberCurrentData(data) {
    const modifiedData = data.map((item) => {
      const modifiedItem = {};

      for (const key in item) {
        if (item.hasOwnProperty(key)) {
          const newKey = key.replace(/\s+/g, "").toLowerCase();
          modifiedItem[newKey] = item[key];
        }
      }

      return modifiedItem;
    });

    dispatch(
      uploadFamilyMemberData({
        applicantFamilyMembers: modifiedData,
      })
    ).then((res) => {
      setCsvFile(null);
      if (res?.payload?.status === 201 || res?.payload?.status === 200) {
        // if (tabValue === 1 && rollData === "committee") {
        if (res?.payload?.data?.length > 0) {
          setOpenErrorModal(true);
          setSaveErrorData(
            res?.payload?.data?.length > 0 &&
              res?.payload?.data?.map((item) => item)
          );
        }
        if (res?.payload?.data?.errors?.length > 0) {
          setOpenErrorModal(true);
          setSaveErrorCountData(
            res?.payload?.data?.errors?.length > 0 &&
              res?.payload?.data?.errors?.map((item) => item?.documentIndex)
            // res?.payload?.data?.errorDocuments?.map((item) => item?.error)
          );
        }
        if (
          tabValue === 1 &&
          rollData?.toLowerCase()?.match("committee")?.length
        ) {
          dispatch(getCommitteePreviousSahayApplicationListStaff()).then(
            (data) => {
              if (data?.payload) {
                setLoader(false);
                setPreviousListData(data?.payload);
                // const filterData = data?.payload
                //   ?.filter((row) => {
                //     if (rollData === "committee") {
                //       return (
                //         getAdminLoginDetails?.sahayPermission &&
                //         getAdminLoginDetails?.sahayPermission.includes(
                //           row?.subSahayTypeName
                //         )
                //       );
                //     }
                //     return true;
                //   })
                //   ?.filter((ele) => {
                //     return (
                //       ele?.committeeStatus?.toLowerCase() ==
                //       statusFilter?.toLowerCase()
                //     );
                //   });
                // setPreviousListData(filterData);
              }
              setLoader(false);
            }
          );
        }
        // if (tabValue === 1 && rollData === "admin") {
        if (tabValue === 1 && rollData?.toLowerCase()?.match("admin")?.length) {
          dispatch(getPreviousSahayApplicationListAdminNew()).then((data) => {
            if (data?.payload) {
              setLoader(false);
              setPreviousListData(data?.payload);
            }
            setLoader(false);
          });
        }
        // if (tabValue === 1 && rollData === "staff") {
        if (tabValue === 1 && rollData?.toLowerCase()?.match("staff")?.length) {
          dispatch(getPreviousSahayApplicationListAdmin()).then((data) => {
            if (data?.payload) {
              setLoader(false);
              setPreviousListData(data?.payload);
              // const filterData = data?.payload?.filter((ele) => {
              //   if (rollData === "staff") {
              //     return (
              //       ele?.committeeStatus?.toLowerCase() ==
              //       statusFilter?.toLowerCase()
              //     );
              //   }
              //   return true;
              // });

              // setPreviousListData(filterData);
            }
            setLoader(false);
          });
        }
      }
    });

    // return modifiedData;
  }

  const handleCSVProfileMember = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCsvFile(file);
    }
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const csvData = e.target.result;
        const workbook = xlsx.read(csvData, { type: "binary" });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const json = xlsx.utils.sheet_to_json(worksheet);
        modifyMemberCurrentData(json);
      };

      reader.readAsBinaryString(file);
    }
  };

  //// To Change the CSV ///////////

  const handleCSVChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCsvFile(file);
    }
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const csvData = e.target.result;
        const workbook = xlsx.read(csvData, { type: "binary" });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const json = xlsx.utils.sheet_to_json(worksheet);
        modifyData(json);
      };

      reader.readAsBinaryString(file);
    }
  };
  function modifyData(data) {
    const modifiedData = data.map((item) => {
      const modifiedItem = {};
      for (const key in item) {
        if (item.hasOwnProperty(key)) {
          const newKey = key.replace(/\s+/g, "").toLowerCase();
          modifiedItem[newKey] = item[key];
        }
      }

      return modifiedItem;
    });
    dispatch(uploadCSVData({ bulkApplications: modifiedData })).then((res) => {
      setCsvLoader(true);
      setCsvFile(null);
      if (res?.payload?.status === 201 || res?.payload?.status === 200) {
        setCsvLoader(false);
        if (res?.payload?.data?.length > 0) {
          setOpenErrorModal(true);
          setSaveErrorData(
            res?.payload?.data?.length > 0 &&
              res?.payload?.data?.map((item) => item)
          );
        }
        if (res?.payload?.data?.errorDocuments?.length > 0) {
          setOpenErrorModal(true);
          setSaveErrorCountData(
            res?.payload?.data?.errorDocuments?.length > 0 &&
              res?.payload?.data?.errorDocuments?.map(
                (item) => item?.documentIndex
              )
            // res?.payload?.data?.errorDocuments?.map((item) => item?.error)
          );
        }
        if (
          tabValue === 0 &&
          rollData?.toLowerCase()?.match("committee")?.length
        ) {
          dispatch(getCommitteePreviousSahayApplicationListStaff()).then(
            (data) => {
              if (data?.payload) {
                setLoader(false);
                setPreviousListData(data?.payload);
                // const filterData = data?.payload
                //     ?.filter((row) => {
                //       if (rollData === "committee") {
                //         return (
                //           getAdminLoginDetails?.sahayPermission &&
                //           getAdminLoginDetails?.sahayPermission.includes(
                //             row?.subSahayTypeName
                //           )
                //         );
                //       }
                //       return true;
                //     })
                //     ?.filter((ele) => {
                //       return (
                //         ele?.committeeStatus?.toLowerCase() ==
                //         statusFilter?.toLowerCase()
                //       );
                //     });
                //     setPreviousListData(filterData);
              }
              setLoader(false);
            }
          );
        }
        // if (tabValue === 0 && rollData === "admin") {
        if (tabValue === 0 && rollData?.toLowerCase()?.match("admin")?.length) {
          dispatch(getPreviousSahayApplicationListAdminNew()).then((data) => {
            if (data?.payload) {
              setLoader(false);
              setPreviousListData(data?.payload);
            }
            setLoader(false);
          });
        }
        // if (tabValue === 0 && rollData === "staff") {
        if (tabValue === 0 && rollData?.toLowerCase()?.match("staff")?.length) {
          dispatch(getPreviousSahayApplicationListAdmin()).then((data) => {
            if (data?.payload) {
              setLoader(false);
              setPreviousListData(data?.payload);

              // const filterData = data?.payload?.filter((ele) => {
              //   if (rollData === "staff") {
              //     return (
              //       ele?.committeeStatus?.toLowerCase() ==
              //       statusFilter?.toLowerCase()
              //     );
              //   }
              //   return true;
              // });

              // setPreviousListData(filterData);
            }
            setLoader(false);
          });
        }
      }
    });

    // return modifiedData;
  }

  const handleApproveClaimSubmit = async () => {
    // handleSahayApplicationStatus(approvedAmountIdData, "Approved");
    handleCloseApproved();
    let data = {
      approvedAmount: approveAmountModalData?.approvedAmount,
      applicationId: approvedAmountIdData,
      status: "Approved",
      remark: committeeStatusData,
    };

    if (subSahayType !== "") {
      data = {
        ...data,
        loanInstallment: approveAmountModalData?.loanInstallment?.filter(
          (row) => row?.EMIAmount !== ""
        ),
      };
    }

    try {
      const response = await DataService.post(
        `${Api.CHECK_SAHAY_APPLICATION_STATUS}`,
        data
      );

      if (response?.data?.status == 200) {
        setApprovedAmonut();
        setCommitteeStatusData("");
        // handleSahayApplicationStatus(approvedAmountIdData, "Approved");
        handleCloseApproved();
        // setCheckStatus(value);
        // dispatch(getSahayApplicationListAdmin());
        dispatch(
          getCommitteeCurrentSahayApplicationListStaff(statusFilter)
        ).then((data) => {
          if (data?.payload) {
            setLoader(false);
            // setApplicationListData(data?.payload);
            if (rollData == "committee") {
              // const filterDataNewStatusColl = data?.payload.filter((ele) => {
              //   return ele?.newStatusColumn?.toLowerCase() !== "pending";
              // });
              // const filterData = data?.payload
              //   ?.filter((row) => {
              //     if (rollData === "committee") {
              //       return (
              //         getAdminLoginDetails?.sahayPermission &&
              //         getAdminLoginDetails?.sahayPermission.includes(
              //           row?.subSahayTypeName
              //         )
              //       );
              //     }
              //     return true;
              //   })
              //   ?.filter((row) => {
              //     if (rollData === "committee") {
              //       return (
              //         row.newStatusColumn?.toLowerCase() === "approved" ||
              //         row.newStatusColumn?.toLowerCase() === "rejected"
              //       );
              //     }
              //     return true;
              //   })
              //   ?.filter((ele) => {

              //         ele?.committeeStatus?.toLowerCase() ==
              //         statusFilter?.toLowerCase()

              //   });

              setApplicationListData(data?.payload);
              setDataForFilter(data?.payload);
            } else {
              setApplicationListData(data?.payload);
              setDataForFilter(data?.payload);
            }
          }
          setLoader(false);
        });
        toast.success(response?.data?.message);
        setBtnLoading(false);
      } else {
        toast.error(response?.data?.message);
        setBtnLoading(false);
      }
    } catch (error) {
      // PagesIndex.toast.error(error?.response?.data?.message);
      if (error?.response?.data?.status === 409) {
        handleCloseApproved();
        rollData?.toLowerCase()?.match("committee")?.length &&
          dispatch(
            getCommitteeCurrentSahayApplicationListStaff(statusFilter)
          ).then((data) => {
            if (data?.payload) {
              // setLoader(true);
              // setApplicationListData(data?.payload);
              if (rollData == "committee") {
                // const filterData = data?.payload
                //   ?.filter((row) => {
                //     if (rollData === "committee") {
                //       return (
                //         getAdminLoginDetails?.sahayPermission &&
                //         getAdminLoginDetails?.sahayPermission.includes(
                //           row?.subSahayTypeName
                //         )
                //       );
                //     }
                //     return true;
                //   })
                //   ?.filter((row) => {
                //     if (rollData === "committee") {
                //       return (
                //         row.newStatusColumn?.toLowerCase() === "approved" ||
                //         row.newStatusColumn?.toLowerCase() === "rejected"
                //       );
                //     }
                //     return true;
                //   })
                //   ?.filter((ele) => {
                //     if (statusFilter == "Loan") {
                //       return (
                //         ele?.subSahayTypeName == "Educational Loan" ||
                //         ele?.subSahayTypeName == "Swawlamban"
                //       );
                //     } else {
                //       return (
                //         ele?.committeeStatus?.toLowerCase() ==
                //         statusFilter?.toLowerCase()
                //       );
                //     }
                //   });
                setApplicationListData(data?.payload);
                setDataForFilter(data?.payload);
              } else {
                setApplicationListData(data?.payload);
                setDataForFilter(data?.payload);
              }
            }
          });
        toast.error(error?.response?.data?.message);
        setTimeout(() => {
          setLoader(false);
        }, 1000);
      } else if (error?.response?.data?.status === 400) {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const handleUploadSubmit = async (values) => {
    // handleSahayApplicationStatus(approvedAmountIdData, "Approved");
    handleCloseUploadModal();
    const data = new FormData();

    data?.append("loanDoc", values?.loanDoc);
    data?.append("id", uploadSahayId);

    try {
      const response = await DataService.post(
        `${Api.UPLOAD_LOAN_DOCUMENT}`,
        data
      );

      if (response?.data?.status == 200) {
        setUploadSahayId();

        // handleSahayApplicationStatus(approvedAmountIdData, "Approved");
        handleCloseUploadModal();
        // setCheckStatus(value);
        // dispatch(getSahayApplicationListAdmin());
        dispatch(getCurrentSahayApplicationListStaff(statusFilter)).then(
          (data) => {
            if (data?.payload) {
              setLoader(false);
              // setApplicationListData(data?.payload);
              if (rollData == "committee") {
                // const filterDataNewStatusColl = data?.payload.filter((ele) => {
                //   return ele?.newStatusColumn?.toLowerCase() !== "pending";
                // });
                // const filterData = data?.payload
                //   ?.filter((row) => {
                //     if (rollData === "committee") {
                //       return (
                //         getAdminLoginDetails?.sahayPermission &&
                //         getAdminLoginDetails?.sahayPermission.includes(
                //           row?.subSahayTypeName
                //         )
                //       );
                //     }
                //     return true;
                //   })
                //   ?.filter((row) => {
                //     if (rollData === "committee") {
                //       return (
                //         row.newStatusColumn?.toLowerCase() === "approved" ||
                //         row.newStatusColumn?.toLowerCase() === "rejected"
                //       );
                //     }
                //     return true;
                //   })
                //   ?.filter((ele) => {
                //     if (statusFilter == "Loan") {
                //       return (
                //         ele?.subSahayTypeName == "Educational Loan" ||
                //         ele?.subSahayTypeName == "Swawlamban"
                //       );
                //     } else {
                //       return (
                //         ele?.committeeStatus?.toLowerCase() ==
                //         statusFilter?.toLowerCase()
                //       );
                //     }
                //   });
                setApplicationListData(data?.payload);
                setDataForFilter(data?.payload);
              } else {
                setApplicationListData(data?.payload);
                setDataForFilter(data?.payload);
              }
            }
            setLoader(false);
          }
        );
        toast.success(response?.data?.message);
        setBtnLoading(false);
      } else {
        toast.error(response?.data?.message);
        setBtnLoading(false);
      }
    } catch (error) {
      // PagesIndex.toast.error(error?.response?.data?.message);
      if (error?.response?.data?.status === 409) {
        handleCloseApproved();
        rollData?.toLowerCase()?.match("committee")?.length &&
          dispatch(
            getCommitteeCurrentSahayApplicationListStaff(statusFilter)
          ).then((data) => {
            if (data?.payload) {
              // setLoader(true);
              // setApplicationListData(data?.payload);
              if (rollData == "committee") {
                // const filterData = data?.payload
                //   ?.filter((row) => {
                //     if (rollData === "committee") {
                //       return (
                //         getAdminLoginDetails?.sahayPermission &&
                //         getAdminLoginDetails?.sahayPermission.includes(
                //           row?.subSahayTypeName
                //         )
                //       );
                //     }
                //     return true;
                //   })
                //   ?.filter((row) => {
                //     if (rollData === "committee") {
                //       return (
                //         row.newStatusColumn?.toLowerCase() === "approved" ||
                //         row.newStatusColumn?.toLowerCase() === "rejected"
                //       );
                //     }
                //     return true;
                //   })
                //   ?.filter((ele) => {
                //     if (statusFilter == "Loan") {
                //       return (
                //         ele?.subSahayTypeName == "Educational Loan" ||
                //         ele?.subSahayTypeName == "Swawlamban"
                //       );
                //     } else {
                //       return (
                //         ele?.committeeStatus?.toLowerCase() ==
                //         statusFilter?.toLowerCase()
                //       );
                //     }
                //   });
                setApplicationListData(data?.payload);
                setDataForFilter(data?.payload);
              } else {
                setApplicationListData(data?.payload);
                setDataForFilter(data?.payload);
              }
            }
          });
        toast.error(error?.response?.data?.message);
        setTimeout(() => {
          setLoader(false);
        }, 1000);
      }
    }
  };

  /////// For current CSV ////////////

  function modifyCurrentData(data) {
    const modifiedData = data.map((item) => {
      const modifiedItem = {};
      for (const key in item) {
        if (item.hasOwnProperty(key)) {
          const newKey = key.replace(/\s+/g, "").toLowerCase();
          modifiedItem[newKey] = item[key];
        }
      }

      return modifiedItem;
    });

    dispatch(uploadCurrentCSVData({ bulkApplications: modifiedData })).then(
      (res) => {
        setCsvFile(null);
        if (res?.payload?.status === 201 || res?.payload?.status === 200) {
          if (res?.payload?.data?.length > 0) {
            setOpenErrorModal(true);
            setSaveErrorData(
              res?.payload?.data?.length > 0 &&
                res?.payload?.data?.map((item) => item)
            );
          }
          if (res?.payload?.data?.errors?.length > 0) {
            setOpenErrorModal(true);
            setSaveErrorCountData(
              res?.payload?.data?.errors?.length > 0 &&
                res?.payload?.data?.errors?.map((item) => item?.documentIndex)
              // res?.payload?.data?.errorDocuments?.map((item) => item?.error)
            );
          }
          // if (tabValue === 1 && rollData === "committee") {
          if (
            tabValue === 1 &&
            rollData?.toLowerCase()?.match("committee")?.length
          ) {
            dispatch(getCurrentSahayApplicationListStaff()).then((data) => {
              if (data?.payload) {
                setLoader(false);
                // setApplicationListData(data?.payload);
                // const filterData = data?.payload?.filter((ele) => {
                //   if (rollData === "staff") {
                //     return (
                //       ele?.newStatusColumn?.toLowerCase() ==
                //       statusFilter?.toLowerCase()
                //     );
                //   }
                //   return true;
                // });

                setApplicationListData(data?.payload);
                setDataForFilter(data?.payload);
              }
              setLoader(false);
            });
          }

          // if (tabValue === 1 && rollData === "admin") {
          if (
            tabValue === 1 &&
            rollData?.toLowerCase()?.match("admin")?.length
          ) {
            dispatch(getCurrentSahayApplicationListAdmin()).then((data) => {
              if (data?.payload) {
                setLoader(false);
                setApplicationListData(data?.payload);
              }
              setLoader(false);
            });
          }
          // if (tabValue === 1 && rollData === "staff") {
          if (
            tabValue === 1 &&
            rollData?.toLowerCase()?.match("staff")?.length
          ) {
            dispatch(getCurrentSahayApplicationListStaff()).then((data) => {
              if (data?.payload) {
                setLoader(false);
                // setApplicationListData(data?.payload);
                // const filterData = data?.payload?.filter((ele) => {
                //   if (rollData === "staff") {
                //     if (statusFilter == "Loan") {
                //       return (
                //         ele?.subSahayTypeName == "Educational Loan" ||
                //         ele?.subSahayTypeName == "Swawlamban"
                //       );
                //     } else {
                //       return (
                //         ele?.newStatusColumn?.toLowerCase() ==
                //         statusFilter?.toLowerCase()
                //       );
                //     }
                //   }
                //   return true;
                // });

                setApplicationListData(data?.payload);
                setDataForFilter(data?.payload);
              }
              setLoader(false);
            });
          }
        }
      }
    );

    // return modifiedData;
  }

  const handleCSVChangeCurrent = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCsvFile(file);
    }
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const csvData = e.target.result;
        const workbook = xlsx.read(csvData, { type: "binary" });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const json = xlsx.utils.sheet_to_json(worksheet);
        modifyCurrentData(json);
      };

      reader.readAsBinaryString(file);
    }
  };

  // const handleExportCSVChange = () => {
  //   if (previousListData.length === 0) {
  //     alert("No data to export.");
  //     return;
  //   }

  //   // Convert data to a CSV string
  //   const csvContent = xlsx.utils.arrayToCsv(previousListData);

  //   // Create a Blob with the CSV content
  //   const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

  //   // Create a download link
  //   const link = document.createElement("a");
  //   link.href = window.URL.createObjectURL(blob);
  //   link.download = "exported-data.csv";

  //   // Trigger a click event to start the download
  //   link.click();
  // };

  //////////////////////// This is for export data in csv ////////////////////
  function handleExportPreviousCSVChange(data) {
    if (data.length === 0) {
      // Return early if there is no data to export
      return;
    }
    // Define a mapping between original keys and display names
    const fieldMappings = {
      repayDetails: "Repay Details",
      // newStatusColumn: "User Name",
      mem_mname: "User Middle Name",
      mem_lname: "User Last Name",
      mem_fname: "User First Name",
      // committeeRemark: "Committee Remark",
      // committeeStatus: "Committee Status",
      SahayTypeName: "Type Of Sahay",
      SahayAmmount: "Sahay Amount",
      AadharNo: "Aadhar No",
      yearType: "Year Type",
      yearFrom: "Year From",
      totalMonth: "No. Of Month",
      toYear: "Year To",
      subSahayTypeName: "Sub Type Of Sahay",
      startDate: "Start Date",
      familyId: "Family Id",
    };

    // Create the CSV header with the selected fields and display names
    const fieldsToExport = Object.keys(fieldMappings);
    const csvHeader = fieldsToExport
      .map((field) => fieldMappings[field])
      .join(",");

    // Create an array to store data rows
    const csvRows = [];

    // Iterate over the data and extract values for the selected fields
    data.forEach((item) => {
      const row = fieldsToExport.map((field) => item[field]);
      csvRows.push(row.join(","));
    });

    // Combine the header and data rows
    const csvContent = csvHeader + "\n" + csvRows.join("\n");

    // Create a Blob object from the CSV content
    const blob = new Blob([csvContent], { type: "text/csv" });

    // Use file-saver library to save the Blob as a CSV file
    saveAs(blob, "exported_data.csv");
  }

  function handleExportCurrentCSVChange(data) {
    if (data.length === 0) {
      // Return early if there is no data to export
      return;
    }

    // Define a mapping between original keys and display names
    const fieldMappings = {
      familyId: "Family Id",
      mem_fname: "User Name",
      SahayTypeName: "Sahay Category",
      subSahayTypeName: "Sub Sahay Category",
      SahayAmmount: "Sahay Amount",
      AadharNo: "Addharcard No.",
      newStatusColumn: "Staff Status",
      committeeStatus: "Committee Status",
      committeeRemark: "Committee Remark",
    };

    // Create the CSV header with the selected fields and display names
    const fieldsToExport = Object.keys(fieldMappings);
    const csvHeader = fieldsToExport
      .map((field) => fieldMappings[field])
      .join(",");

    // Create an array to store data rows
    const csvRows = [];

    // Iterate over the data and extract values for the selected fields
    data.forEach((item) => {
      const row = fieldsToExport.map((field) => item[field]);
      csvRows.push(row.join(","));
    });

    // Combine the header and data rows
    const csvContent = csvHeader + "\n" + csvRows.join("\n");

    // Create a Blob object from the CSV content
    const blob = new Blob([csvContent], { type: "text/csv" });

    // Use file-saver library to save the Blob as a CSV file
    saveAs(blob, "exported_data.csv");
  }

  const handleClickMenu = (e, item, index) => {
    setActionIndex(index);
    setCurrentData(item);
    setAnchorEl(e.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  //View
  const handleSahayApplicationView = (viewdata) => {
    viewdata["lastStatus"] = statusFilter;
    navigate(`/${rollData}/sahay-application-view`, { state: viewdata });
  };
  const handlePreviousSahayApplicationView = (previousviewdata) => {
    navigate(`/${rollData}/previous-sahay-application-view`, {
      state: previousviewdata,
    });
  };
  const [filterData, setFilterData] = useState([]);
  const [filterCurrentData, setFilterCurrentData] = useState([]);

  // useEffect(() => {
  //   dispatch(getSahayApplicationListAdmin()).then((data) => {
  //     if (data?.payload) {
  //       setApplicationListData(data?.payload);
  //     }
  //   });
  // }, [tabValue === 1,rollData === "admin"]);
  useEffect(() => {
    // if (tabValue === 1 && rollData === "staff") {
    setLoader(true);
    if (tabValue === 1 && rollData?.toLowerCase()?.match("staff")?.length) {
      dispatch(getCurrentSahayApplicationListStaff()).then((data) => {
        if (data?.payload) {
          // setApplicationListData(data?.payload);
          // const filterData = data?.payload?.filter((ele) => {
          //   if (statusFilter == "Loan") {
          //     return (
          //       ele?.subSahayTypeName == "Educational Loan" ||
          //       ele?.subSahayTypeName == "Swawlamban"
          //     );
          //   } else {
          //     return (
          //       ele?.newStatusColumn?.toLowerCase() ==
          //       statusFilter?.toLowerCase()
          //     );
          //   }
          // });

          setApplicationListData(data?.payload);
          setDataForFilter(data?.payload);
          setTimeout(() => {
            setLoader(false);
          }, 1000);
        } else {
          setLoader(false);
        }
      });
    }
  }, [tabValue === 1, rollData?.toLowerCase()?.match("staff")?.length]);

  useEffect(() => {
    // if (tabValue === 1 && rollData === "admin") {
    if (tabValue === 1 && rollData?.toLowerCase()?.match("admin")?.length) {
      dispatch(getCurrentSahayApplicationListAdmin()).then((data) => {
        if (data?.payload) {
          setLoader(false);
          setApplicationListData(data?.payload);
        }
        setLoader(false);
      });
    }
  }, [tabValue === 1, rollData?.toLowerCase()?.match("admin")?.length]);

  useEffect(() => {
    // if (rollData === "committee") {
    if ((tabValue === 1, rollData?.toLowerCase()?.match("committee")?.length)) {
      dispatch(getCommitteeCurrentSahayApplicationListStaff(statusFilter)).then(
        (data) => {
          if (data?.payload) {
            setLoader(false);
            // setApplicationListDataCommittee(data?.payload);
            // setApplicationListData(data?.payload);
            if (rollData == "committee") {
              // const filterData = data?.payload
              //   ?.filter((row) => {
              //     if (rollData === "committee") {
              //       return (
              //         getAdminLoginDetails?.sahayPermission &&
              //         getAdminLoginDetails?.sahayPermission.includes(
              //           row?.subSahayTypeName
              //         )
              //       );
              //     }
              //     return true;
              //   })
              //   ?.filter((row) => {
              //     if (rollData === "committee") {
              //       return (
              //         row.newStatusColumn?.toLowerCase() === "approved" ||
              //         row.newStatusColumn?.toLowerCase() === "rejected"
              //       );
              //     }
              //     return true;
              //   });

              setApplicationListData(data?.payload);

              setDataForFilter(data?.payload);
            } else {
              setApplicationListData(data?.payload);
              setDataForFilter(data?.payload);
            }
          }
          setLoader(false);
        }
      );
    }
  }, [tabValue === 1, rollData?.toLowerCase()?.match("committee")?.length]);

  useEffect(() => {
    // if (tabValue === 1 && rollData === "admin") {
    if (tabValue === 1 && rollData?.toLowerCase()?.match("admin")?.length) {
      dispatch(getCurrentSahayApplicationListAdmin()).then((data) => {
        if (data?.payload) {
          setLoader(false);
          setApplicationListData(data?.payload);
          // setFilterCurrentData(data?.payload);
        }
        setLoader(false);
      });
    }
  }, [tabValue === 1, rollData?.toLowerCase()?.match("admin")?.length]);

  useEffect(() => {
    // if (tabValue === 0 && rollData === "staff") {
    if (tabValue === 0 && rollData?.toLowerCase()?.match("staff")?.length) {
      setLoader(true);
      dispatch(getPreviousSahayApplicationListAdmin()).then((data) => {
        if (data?.payload) {
          setPreviousListData(data?.payload);
          // const filterData = data?.payload?.filter((ele) => {
          //   if (rollData === "staff") {
          //     return (
          //       ele?.committeeStatus?.toLowerCase() ==
          //       statusFilter?.toLowerCase()
          //     );
          //   }
          //   return true;
          // });

          // setPreviousListData(filterData);
          setTimeout(() => {
            setLoader(false);
          }, 1000);
        } else {
          setLoader(false);
        }
      });
    }
  }, [
    tabValue === 0,
    rollData?.toLowerCase()?.match("staff")?.length,
    statusFilter,
  ]);
  useEffect(() => {
    // if (tabValue === 0 && rollData === "admin") {
    if (tabValue === 0 && rollData?.toLowerCase()?.match("admin")?.length) {
      dispatch(getPreviousSahayApplicationListAdminNew()).then((data) => {
        if (data?.payload) {
          setLoader(false);
          setPreviousListData(data?.payload);
          // setFilterData(data?.payload);
        }
        setLoader(false);
      });
    }
  }, [tabValue === 0, rollData?.toLowerCase()?.match("admin")?.length]);

  useEffect(() => {
    // if (tabValue === 0 && rollData === "committee") {
    if (tabValue === 0 && rollData?.toLowerCase()?.match("committee")?.length) {
      dispatch(getCommitteePreviousSahayApplicationListStaff()).then((data) => {
        if (data?.payload) {
          setLoader(false);
          setPreviousListData(data?.payload);
          // const filterData = data?.payload
          //           ?.filter((row) => {
          //             if (rollData === "committee") {
          //               return (
          //                 getAdminLoginDetails?.sahayPermission &&
          //                 getAdminLoginDetails?.sahayPermission.includes(
          //                   row?.subSahayTypeName
          //                 )
          //               );
          //             }
          //             return true;
          //           })
          //           ?.filter((ele) => {
          //             return (
          //               ele?.committeeStatus?.toLowerCase() ==
          //               statusFilter?.toLowerCase()
          //             );
          //           });
          //           setPreviousListData(filterData);
        }
        setLoader(false);
      });
    }
  }, [
    tabValue === 0,
    rollData?.toLowerCase()?.match("committee")?.length,
    statusFilter,
  ]);
  //#region filter sahay
  useEffect(() => {
    if (rollData === "staff" || rollData === "committee") {
      const filters = {
        SahayType: sahayType,
        subSahayType: subSahay,
        statusFilter: statusFilter,
      };

      const filterData = dataForFilter?.filter((ele) => {
        if (
          Object.values(filters)?.some(
            (value) => value !== undefined && value !== null
          )
        ) {
          const result = Object.entries(filters).every(([key, value]) => {
            if (value === "") return true;

            if (key === "statusFilter") {
              // Use different logic for "committee" and "staff"
              const statusKey =
                rollData === "committee"
                  ? "committeeStatus"
                  : "newStatusColumn";

              if (value === "Pending") {
                return ele?.[statusKey] === "Pending";
              } else if (value === "Approved") {
                return ele?.[statusKey] === "Approved";
              } else if (value === "Hold") {
                return ele?.[statusKey] === "Hold";
              } else if (value === "Rejected") {
                return ele?.[statusKey] === "Rejected";
              } else if (value === "Loan") {
                return (
                  ele?.subSahayTypeName === "Educational Loan" ||
                  ele?.subSahayTypeName === "Swawlamban"
                );
              }
            }
            return ele[key] === value;
          });

          // Additional checks when rollData is "committee"
          if (rollData === "committee") {
            const sahayPermissionCheck =
              getAdminLoginDetails?.sahayPermission &&
              getAdminLoginDetails?.sahayPermission.includes(
                ele?.subSahayTypeName
              );

            const statusCheck =
              ele?.newStatusColumn?.toLowerCase() === "approved";

            return result && sahayPermissionCheck && statusCheck;
          }

          return result;
        } else {
          return true;
        }
      });

      setSearchedData(filterData || []);
    }
  }, [statusFilter, subSahay, sahayType, applicationListData]);

  // Copy original data to searched data
  useEffect(() => {
    if (rollData?.toLowerCase()?.match("admin")?.length) {
      setSearchedData(applicationListData);
    }
  }, [applicationListData]);

  // useEffect(() => {
  //   setSearchedData(applicationListData);
  // }, [applicationListData]);
  useEffect(() => {
    setPreviousSearchedData(previousListData);
  }, [previousListData]);

  const requestPreviousSearch = (previousSearched) => {
    if (!previousSearched) return setPreviousSearchedData(previousListData);
    setPreviousSearchedData(
      previousListData.filter(
        (item) =>
          item?.familyId?.toString().includes(previousSearched.toLowerCase()) ||
          item?.mem_fname
            ?.toString()
            .toLowerCase()
            .includes(previousSearched.toLowerCase()) ||
          item?.mem_lname
            ?.toString()
            .toLowerCase()
            .includes(previousSearched.toLowerCase()) ||
          item?.SahayTypeName?.toString()
            .toLowerCase()
            .includes(previousSearched.toLowerCase()) ||
          item?.subSahayTypeName
            ?.toString()
            .toLowerCase()
            .includes(previousSearched.toLowerCase()) ||
          item?.SahayAmmount?.toString()
            .toLowerCase()
            .includes(previousSearched.toLowerCase())
      )
    );
  };
  const requestSearch = (searched) => {
    if (!searched) return setSearchedData(applicationListData);
    setSearchedData(
      applicationListData.filter(
        (item) =>
          item?.familyId?.toString().includes(searched.toLowerCase()) ||
          item?.mem_fname
            ?.toString()
            .toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.mem_lname
            ?.toString()
            .toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.SahayTypeName?.toString()
            .toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.subSahayTypeName
            ?.toString()
            .toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.sahay_amount?.toString()?.includes(searched?.toLowerCase()) ||
          item?.AadharNo?.toString()?.includes(searched?.toLowerCase()) ||
          item?.newStatusColumn
            ?.toString()
            ?.toLowerCase()
            ?.includes(searched?.toLowerCase())
      )
    );
  };

  const handleSort = (column) => {
    const isAsc = sortColumn === column && sortDirection === "asc";
    setSortDirection(isAsc ? "desc" : "asc");
    setSortColumn(column);

    const sortedData = [...searchedData].sort((a, b) => {
      if (column === "userName") {
        const nameA = `${a.mem_fname} ${a.mem_lname}`.toLowerCase();
        const nameB = `${b.mem_fname} ${b.mem_lname}`.toLowerCase();
        return isAsc ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
      }
      return 0;
    });

    setSearchedData(sortedData);
  };

  const handleSahayApplicationStatus = async (row, value) => {
    setBtnLoading(true);
    let data = {
      status: openStaffRemark ? staffStatus : value,
      applicationId: openStaffRemark
        ? staffRemarkId?.applicationId
        : row?.applicationId,
      remark: openStaffRemark
        ? staffRemarkId?.committeeRemark
        : row?.committeeRemark,
      staffRemark: openStaffRemark ? staffRemark : "",
    };

    try {
      const response = await DataService.post(
        `${Api.CHECK_SAHAY_APPLICATION_STATUS}`,
        data
      );
      setLoader(true);

      if (response?.data?.status == 200 || 201) {
        setBtnLoading(false);
        setTimeout(() => {
          setLoader(false);
        }, 6000);

        rollData?.toLowerCase()?.match("committee")?.length
          ? dispatch(
              getCommitteeCurrentSahayApplicationListStaff(statusFilter)
            ).then((data) => {
              if (data?.payload) {
                setLoader(true);
                // setApplicationListData(data?.payload);
                // if (rollData == "committee") {
                //   const filterData = data?.payload
                //     ?.filter((row) => {
                //       if (rollData === "committee") {
                //         return (
                //           getAdminLoginDetails?.sahayPermission &&
                //           getAdminLoginDetails?.sahayPermission.includes(
                //             row?.subSahayTypeName
                //           )
                //         );
                //       }
                //       return true;
                //     })
                //     ?.filter((row) => {
                //       if (rollData === "committee") {
                //         return (
                //           row.newStatusColumn?.toLowerCase() === "approved" ||
                //           row.newStatusColumn?.toLowerCase() === "rejected"
                //         );
                //       }
                //       return true;
                //     })
                //     ?.filter((ele) => {
                //       if (statusFilter == "Loan") {
                //         return (
                //           ele?.subSahayTypeName == "Educational Loan" ||
                //           ele?.subSahayTypeName == "Swawlamban"
                //         );
                //       } else {
                //         return (
                //           ele?.committeeStatus?.toLowerCase() ==
                //           statusFilter?.toLowerCase()
                //         );
                //       }
                //     });
                //   setApplicationListData(filterData);
                // } else {
                //   setApplicationListData(data?.payload);
                // }

                setApplicationListData(data?.payload);
                setDataForFilter(data?.payload);
              }
            })
          : dispatch(getCurrentSahayApplicationListStaff()).then((data) => {
              if (data?.payload) {
                setLoader(false);
                // setApplicationListData(data?.payload);
                // const filterData = data?.payload?.filter((ele) => {
                //   if (rollData === "staff") {
                //     if (statusFilter == "Loan") {
                //       return (
                //         ele?.subSahayTypeName == "Educational Loan" ||
                //         ele?.subSahayTypeName == "Swawlamban"
                //       );
                //     } else {
                //       return (
                //         ele?.newStatusColumn?.toLowerCase() ==
                //         statusFilter?.toLowerCase()
                //       );
                //     }
                //   }
                //   return true;
                // });
                if (openStaffRemark) {
                  handleCloseStaffRemark();
                }
                setApplicationListData(data?.payload);
                setDataForFilter(data?.payload);
              }
            });
        toast.success(response?.data?.message);
        setBtnLoading(false);
        setLoader(false);
      } else {
        setBtnLoading(false);
        setLoader(false);
      }
    } catch (error) {
      setBtnLoading(false);
      // PagesIndex.toast.error(error?.response?.data?.message);
      if (error?.response?.data?.status == 409) {
        //Staff
        rollData?.toLowerCase()?.match("staff")?.length &&
          dispatch(getCurrentSahayApplicationListStaff()).then((data) => {
            if (data?.payload) {
              setLoader(false);
              // setApplicationListData(data?.payload);
              // const filterData = data?.payload?.filter((ele) => {
              //   if (rollData === "staff") {
              //     if (statusFilter == "Loan") {
              //       return (
              //         ele?.subSahayTypeName == "Educational Loan" ||
              //         ele?.subSahayTypeName == "Swawlamban"
              //       );
              //     } else {
              //       return (
              //         ele?.newStatusColumn?.toLowerCase() ==
              //         statusFilter?.toLowerCase()
              //       );
              //     }
              //   }
              //   return true;
              // });

              if (openStaffRemark) {
                handleCloseStaffRemark();
              }
              setApplicationListData(data?.payload);
              setDataForFilter(data?.payload);
            }
          });

        //Committee
        rollData?.toLowerCase()?.match("committee")?.length &&
          dispatch(
            getCommitteeCurrentSahayApplicationListStaff(statusFilter)
          ).then((data) => {
            if (data?.payload) {
              setLoader(true);
              // setApplicationListData(data?.payload);
              if (rollData == "committee") {
                // const filterData = data?.payload
                //   ?.filter((row) => {
                //     if (rollData === "committee") {
                //       return (
                //         getAdminLoginDetails?.sahayPermission &&
                //         getAdminLoginDetails?.sahayPermission.includes(
                //           row?.subSahayTypeName
                //         )
                //       );
                //     }
                //     return true;
                //   })
                //   ?.filter((row) => {
                //     if (rollData === "committee") {
                //       return (
                //         row.newStatusColumn?.toLowerCase() === "approved" ||
                //         row.newStatusColumn?.toLowerCase() === "rejected"
                //       );
                //     }
                //     return true;
                //   })
                //   ?.filter((ele) => {
                //     if (statusFilter == "Loan") {
                //       return (
                //         ele?.subSahayTypeName == "Educational Loan" ||
                //         ele?.subSahayTypeName == "Swawlamban"
                //       );
                //     } else {
                //       return (
                //         ele?.committeeStatus?.toLowerCase() ==
                //         statusFilter?.toLowerCase()
                //       );
                //     }
                //   });
                if (openStaffRemark) {
                  handleCloseStaffRemark();
                }
                setApplicationListData(data?.payload);
                setDataForFilter(data?.payload);
              } else {
                if (openStaffRemark) {
                  handleCloseStaffRemark();
                }
                setApplicationListData(data?.payload);
                setDataForFilter(data?.payload);
              }
            }
          });
        toast.error(error?.response?.data?.message);
        setTimeout(() => {
          setLoader(false);
        }, 1000);
      }
    }
  };
  const handlePreviousSahayApplicationStatus = async (row, value) => {
    setBtnLoading(true);

    let data = {
      status: value,
      applicationId: row,
    };
    try {
      const response = await DataService.post(
        `${Api.CHECK_PREVIOUS_SAHAY_APPLICATION_STATUS}`,
        data
      );

      if (response?.data?.status == 200) {
        // setCheckStatus(value);
        setBtnLoading(true);
        dispatch(getPreviousSahayApplicationListAdmin()).then((data) => {
          if (data?.payload) {
            setLoader(false);
            setPreviousListData(data?.payload);
          }
          setLoader(false);
        });
        toast.success(response?.data?.message);
        // setBtnLoading(false);
      } else {
        // setBtnLoading(false);
      }
    } catch (error) {
      // PagesIndex.toast.error(error?.response?.data?.message);
    }
  };

  //#region print pdf
  //Print crew members
  const componentRef = useRef();
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [familyMembers, setFamilyMembers] = useState([]);
  const [editSingleApplicantData, setEditSingleApplicantData] = useState();
  const [printLoading, setPrintLoading] = useState(false);

  const printForm = useReactToPrint({
    content: () => componentRef.current,
  });

  //Single applicant
  const fetchSingleSelectedApplicant = (memberData, row) => {
    setFamilyMembers(memberData);

    const member =
      memberData &&
      memberData?.filter((member) => member?.id == row?.applicantId);

    if (member?.[0]) {
      setEditSingleApplicantData({
        name:
          member?.[0]?.firstName +
          " " +
          member?.[0]?.middleName +
          " " +
          member?.[0]?.lastName,

        age: member?.[0]?.age ? member?.[0]?.age : 0,
        workingType: member?.[0]?.workingType,
        relation: member?.[0]?.relation,
        relation_id: member?.[0]?.relation_id,
      });
    }

    // Delay calling printForm until states are updated
    setTimeout(() => {
      setPrintLoading(false);
      setSelectedRowData(null);
      printForm();
    }, 0);
  };

  const fetchSingleApplicant = (familydata, row) => {
    let data;
    if (familydata?.vastipatrak == "0") {
      data = {
        familyId: row?.familyId,
        VastiPatra: false,
      };
    } else {
      data = {
        familyId: row?.familyId,
        VastiPatra: true,
      };
    }

    dispatch(getFamilyMemberById(data)).then((res) => {
      if (res?.payload) {
        setFamilyMembers(res?.payload);
        fetchSingleSelectedApplicant(res?.payload, row);
      }
    });
  };

  const handlePrint = async (row) => {
    setPrintLoading(true);
    setSelectedRow(row);
    try {
      // Fetch the data for the selected row
      const res = await DataService.post(
        `${Api.GET_SINGLE_SAHAY_APPLICATION}/${row?.applicationId}`
      );

      if (res?.data?.status === 200) {
        setSelectedRowData(res?.data?.data);
        fetchSingleApplicant(res?.data?.data?.application[0], row);
      } else {
        console.error("Error fetching data: Invalid status code");
      }
    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response?.data?.message || error.message
      );
    }
  };

  const [open, setOpen] = React.useState(false);
  const [amountOpen, setAmountOpen] = React.useState(false);
  const [otpOpen, setOtpOpen] = useState(false);
  const [scheduleOpen, setScheduleOpen] = useState(false);
  const [uploadOpen, setUploadOpen] = React.useState(false);
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [saveErrorData, setSaveErrorData] = React.useState([]);
  const [saveErrorCountData, setSaveErrorCountData] = React.useState([]);
  const [rejectedIdData, setRejectedIdData] = React.useState();
  const [rejectedValues, setRejectedValues] = React.useState("");
  const [committeeStatusData, setCommitteeStatusData] = React.useState("");
  const [remarkStatus, setRemarkStatus] = React.useState("");
  const [remarkError, setRemarkError] = React.useState("");
  const [approvedAmonut, setApprovedAmonut] = React.useState();
  const [staffRemark, setStaffRemark] = React.useState("");
  const [openStaffRemark, setOpenStaffRemark] = React.useState("");
  const [staffRemarkId, setStaffRemarkId] = useState("");
  const [staffStatus, setStaffStatus] = useState("");
  const [subSahayType, setSubSahayType] = useState("");
  const [loanInstallment, setLoanInstallment] = useState([]);
  const [approveAmountModalData, setApproveAmountModalData] = useState("");
  const [disbursedScheduleData, setDisbursedScheduleData] = useState([]);
  const [oldDisbursementSchedule, setOldDisbursementSchedule] = useState([]);

  const handleOpenSchedule = (values) => {
    dispatch(getDisbursedSchedulelist(approvedAmountIdData)).then((res) => {
      setOldDisbursementSchedule(
        res?.payload?.map((row) =>
          moment(row?.DisbursedDate).format("YYYY-MM-DD")
        )
      );
    });
    setApproveAmountModalData(values);
    setScheduleOpen(true);
    setAmountOpen(false);
  };
  const handleCloseSchedule = () => {
    setScheduleOpen(false);
  };

  const handleClose = () => setOpen(false);
  const handleCloseApproved = () => {
    setAmountOpen(false);

    setSubSahayType("");
  };

  const handleOpenOtp = () => {
    setOtpOpen(true);
  };
  const handleCloseOtp = () => {
    setOtpOpen(false);
  };
  const handleCloseErrorModal = () => setOpenErrorModal(false);

  //#region start function for otp modal

  const handleOpenApproved = (
    amountkId,
    claimedAmount,
    sahayAmount,
    committeeStatusDat,
    approvedAmountData,
    subSahayTypeData,
    loanInstallment
  ) => {
    const totalAmount = claimedAmount + sahayAmount;
    // setAmountOpen(true);

    setapprovedAmountIdData(amountkId);
    setShowClaimedAmount(totalAmount);
    setCommitteeStatusData(committeeStatusDat);
    setApprovedAmonut(approvedAmountData);
    setSubSahayType(subSahayTypeData);
    setLoanInstallment(
      loanInstallment && loanInstallment !== "undefined"
        ? JSON.parse(loanInstallment)
        : []
    );
    setAmountOpen(true);
  };

  const handleGenerateOtp = (values) => {
    setDisbursedScheduleData(values?.DisbursedDate);

    dispatch(generateOtpCommitte()).then((res) => {
      if (res?.payload?.status == 200) {
        toast.success(res?.payload?.message);
        setScheduleOpen(false);
        setOldDisbursementSchedule([]);
        handleOpenOtp();
      }
    });
  };

  console.log(disbursedScheduleData, 1852);
  
const handleDisbursedSchedule = () => {
  const newArray = disbursedScheduleData?.map((data) => {
    const isDateString = typeof data === "string"; // Check if data is a string
  const isDateFormatValid = isDateString && /^\d{4}-\d{2}-\d{2}$/.test(data); // Validate format only if it's a string

    
    
    return {
      DisbursedDate: isDateFormatValid
        ? data // If already in "YYYY-MM-DD", use as is
        : data.format("YYYY-MM-DD"), // Otherwise, format it
      sahayId: approvedAmountIdData,
    };
  });
  
  dispatch(addDisbursementSchedule(newArray)).then((res) => {
    if (res?.payload?.status == 200) {
      handleApproveClaimSubmit();
    }
  });
};


  const handleVerifyOtp = (values) => {
    dispatch(verifyOtpCommitte(values)).then((res) => {
      if (res?.payload?.status == 200) {
        if (disbursedScheduleData?.length > 0) {
          handleDisbursedSchedule();
        } else {
          handleApproveClaimSubmit();
        }
        handleCloseOtp();
      }
    });
  };

  const handleOpenUpload = (id) => {
    setUploadSahayId(id);
    setUploadOpen(true);
  };

  const handleCloseUploadModal = () => setUploadOpen(false);
  const handleOPen = (remarkId, values) => {
    setOpen(true);
    setRemarkIdData(remarkId);
    if (values === "Rejected" || values === "Hold") {
      setRejectedIdData(remarkId);
      setRejectedValues(values);
    }
  };

  const handleOpenStaffRemark = (row, staffStatus) => {
    setStaffRemarkId(row);
    setStaffStatus(staffStatus);
    setOpenStaffRemark(true);
  };

  const handleCloseStaffRemark = (row) => {
    setStaffRemarkId("");
    setStaffStatus("");

    setOpenStaffRemark(false);
  };

  const { rollPermissionData } = useSelector((state) => state.admin);
  const rollPermissionNameData =
    rollPermissionData?.findData?.roleName?.toLowerCase();
  const onSumitRemarkCommittee = async (e) => {
    if (remarkData === "") {
      setRemarkError("Please enter remark");
    }
    if (remarkData !== "") {
      let data;
      if (rejectedValues === "Hold" || rejectedValues === "Rejected") {
        data = {
          status: rejectedValues,
          applicationId: rejectedIdData,
          remark: remarkData,
        };
      } else {
        data = {
          status: remarkStatus,
          remark: remarkData,
          applicationId: remarkIdData,
        };
      }

      try {
        const response = await DataService.post(
          `${Api.CHECK_SAHAY_APPLICATION_STATUS}`,
          data
        );

        if (response?.data?.status == 200) {
          setRejectedValues("");
          setRemarkStatus("");
          handleClose();
          // setCheckStatus(value);
          // dispatch(getSahayApplicationListAdmin());
          dispatch(
            getCommitteeCurrentSahayApplicationListStaff(statusFilter)
          ).then((data) => {
            if (data?.payload) {
              setLoader(false);
              // setApplicationListData(data?.payload);
              if (rollData == "committee") {
                // const filterData = data?.payload
                //   ?.filter((row) => {
                //     if (rollData === "committee") {
                //       return (
                //         getAdminLoginDetails?.sahayPermission &&
                //         getAdminLoginDetails?.sahayPermission.includes(
                //           row?.subSahayTypeName
                //         )
                //       );
                //     }
                //     return true;
                //   })
                //   ?.filter((row) => {
                //     if (rollData === "committee") {
                //       return (
                //         row.newStatusColumn?.toLowerCase() === "approved" ||
                //         row.newStatusColumn?.toLowerCase() === "rejected"
                //       );
                //     }
                //     return true;
                //   })
                //   ?.filter((ele) => {
                //     if (statusFilter == "Loan") {
                //       return (
                //         ele?.subSahayTypeName == "Educational Loan" ||
                //         ele?.subSahayTypeName == "Swawlamban"
                //       );
                //     } else {
                //       return (
                //         ele?.committeeStatus?.toLowerCase() ==
                //         statusFilter?.toLowerCase()
                //       );
                //     }
                //   });
                setApplicationListData(data?.payload);
                setDataForFilter(data?.payload);
              } else {
                setApplicationListData(data?.payload);
                setDataForFilter(data?.payload);
              }
            }
            setLoader(false);
          });
          toast.success(response?.data?.message);
          setBtnLoading(false);
        } else {
          setBtnLoading(false);
        }
      } catch (error) {
        // PagesIndex.toast.error(error?.response?.data?.message);
        if (error?.response?.data?.status === 409) {
          handleClose();
          rollData?.toLowerCase()?.match("committee")?.length &&
            dispatch(
              getCommitteeCurrentSahayApplicationListStaff(statusFilter)
            ).then((data) => {
              if (data?.payload) {
                // setLoader(true);
                // setApplicationListData(data?.payload);
                if (rollData == "committee") {
                  // const filterData = data?.payload
                  //   ?.filter((row) => {
                  //     if (rollData === "committee") {
                  //       return (
                  //         getAdminLoginDetails?.sahayPermission &&
                  //         getAdminLoginDetails?.sahayPermission.includes(
                  //           row?.subSahayTypeName
                  //         )
                  //       );
                  //     }
                  //     return true;
                  //   })
                  //   ?.filter((row) => {
                  //     if (rollData === "committee") {
                  //       return (
                  //         row.newStatusColumn?.toLowerCase() === "approved" ||
                  //         row.newStatusColumn?.toLowerCase() === "rejected"
                  //       );
                  //     }
                  //     return true;
                  //   })
                  //   ?.filter((ele) => {
                  //     if (statusFilter == "Loan") {
                  //       return (
                  //         ele?.subSahayTypeName == "Educational Loan" ||
                  //         ele?.subSahayTypeName == "Swawlamban"
                  //       );
                  //     } else {
                  //       return (
                  //         ele?.committeeStatus?.toLowerCase() ==
                  //         statusFilter?.toLowerCase()
                  //       );
                  //     }
                  //   });
                  setApplicationListData(data?.payload);
                  setDataForFilter(data?.payload);
                } else {
                  setApplicationListData(data?.payload);
                  setDataForFilter(data?.payload);
                }
              }
            });
          toast.error(error?.response?.data?.message);
          setTimeout(() => {
            setLoader(false);
          }, 1000);
        }
      }
    }
  };

  //Edit Sahay application
  const handleEdit = (data) => {
    data["lastStatus"] = statusFilter;
    navigate(`/${rollData}/edit-apply-for-sahay-admin`, {
      state: { editSahay: data },
    });
    // navigate(`/${rollData}/edit-apply-for-current-sahay`, { state: {row : data} })
  };

  //Back from Previous add/view
  useEffect(() => {
    if (state?.tabType == "previous") {
      setTabValue(0);
    }
  }, [state]);

  //Active Status
  useState(() => {
    if (location?.state?.prevState) {
      setStatusFilter(location?.state?.prevState);
    }
  }, [location?.state?.prevState]);

  return (
    <>
      {loader ? (
        <PageLoading />
      ) : (
        <>
          <Index.Box className="page-content-box">
            {/* <Index.Box sx={{ width: "100%" }}> */}
            {/* <Index.Box className="sahay-application-tab-main">
           <Index.Tabs
             value={tabValue}
             onChange={handleTabChange}
             textColor="primary"
             indicatorColor="primary"
             aria-label="secondary tabs example"
           >
             <Index.Tab
               value="one"
               label="Previous Year"
               className="sahay-tab"
             />
             <Index.Tab value="two" label="Current Year" className="sahay-tab" />
           </Index.Tabs>
         </Index.Box> */}

            <Index.Box className="barge-common-box">
              <Index.Box className="title-header"></Index.Box>
              <Index.Box className="title-header-flex res-title-header-flex">
                <Index.Box className="plus-hidden-show-mobile">
                  <Index.Box className="title-main">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="page-title"
                    >
                      Sahay Application
                    </Index.Typography>
                  </Index.Box>
                  {/* {rolePermissions == 1 ? ( */}

                  {rollData?.toLowerCase()?.match("staff")?.length ? (
                    rollPermissionData?.permissionArray?.includes(
                      "Current Sahay Application_add"
                    ) &&
                    rollPermissionData?.permissionArray?.includes(
                      "Previous Sahay Application_add"
                    ) ? (
                      <Index.Box className="common-button blue-button res-blue-button res-mobile-show">
                        <Index.Box className="mobile-menu-show-hover">
                          <PagesIndex.Link
                            to={
                              tabValue === 0
                                ? `/${rollData}/apply-for-previous-sahay`
                                : `/${rollData}/apply-for-sahay-admin`
                            }
                            className="no-text-decoration"
                          >
                            {" "}
                            <Index.Button
                              variant="contained"
                              disableRipple
                              className="apply-sahay-btn"
                            >
                              <Index.ControlPointIcon />
                            </Index.Button>
                          </PagesIndex.Link>
                          <Index.Box className="hover-tooltip-custom">
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="title-tooltip"
                            >
                              Apply for sahay (સહાય માટે અરજી કરો)
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    ) : rollPermissionData?.permissionArray?.includes(
                        "Previous Sahay Application_add"
                      ) ? (
                      <Index.Box className="common-button blue-button res-blue-button res-mobile-show">
                        <Index.Box className="mobile-menu-show-hover">
                          <PagesIndex.Link
                            to={`/${rollData}/apply-for-previous-sahay`}
                            className="no-text-decoration"
                          >
                            {" "}
                            <Index.Button
                              variant="contained"
                              disableRipple
                              className="apply-sahay-btn"
                            >
                              <Index.ControlPointIcon />
                            </Index.Button>
                          </PagesIndex.Link>
                          <Index.Box className="hover-tooltip-custom">
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="title-tooltip"
                            >
                              Apply for sahay (સહાય માટે અરજી કરો)
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    ) : rollPermissionData?.permissionArray?.includes(
                        "Current Sahay Application_add"
                      ) ? (
                      <Index.Box className="common-button blue-button res-blue-button res-mobile-show">
                        <Index.Box className="mobile-menu-show-hover">
                          <PagesIndex.Link
                            to={`/${rollData}/apply-for-sahay-admin`}
                            className="no-text-decoration"
                          >
                            {" "}
                            <Index.Button
                              variant="contained"
                              disableRipple
                              className="apply-sahay-btn"
                            >
                              <Index.ControlPointIcon />
                            </Index.Button>
                          </PagesIndex.Link>
                          <Index.Box className="hover-tooltip-custom">
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="title-tooltip"
                            >
                              Apply for sahay (સહાય માટે અરજી કરો)
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </Index.Box>
              </Index.Box>
              <Index.Box className="custom-res-set-search-box custom-res-set-search-mb ">
                <Index.Box className="d-flex align-items-center res-set-search">
                  <PagesIndex.Search
                    className="search"
                    // onChange={(e) => {
                    //   tabValue === 0
                    //     ? requestPreviousSearch(e.target.value)
                    //     : requestSearch(e.target.value);
                    // }}
                    // onChange={(e) => { requestPreviousSearch(e.target.value) }}
                    onChange={(e) => {
                      tabValue === 0
                        ? requestPreviousSearch(e.target.value)
                        : requestSearch(e.target.value);
                    }}
                  >
                    <PagesIndex.StyledInputBase
                      placeholder="Search"
                      inputProps={{ "aria-label": "search" }}
                    />
                  </PagesIndex.Search>

                  {rollData?.toLowerCase()?.match("staff")?.length ||
                  rollData?.toLowerCase()?.match("committee")?.length ? (
                    <Index.Box className="lable-input-grp pending-button-sahay">
                      <Index.FormControl
                        sx={{ width: "100%" }}
                        className="main-select"
                      >
                        <Index.Select
                          className="select-drop"
                          displayEmpty
                          value={sahayType}
                          onChange={(e) => {
                            setSahayType(e.target.value);
                            setSubSahay("");
                            getSahaySubTypeData(e.target.value);
                          }}
                          input={<OutlinedInput />}
                          MenuProps={MenuProps}
                          inputProps={{
                            "aria-label": "Without label",
                          }}
                        >
                          <Index.MenuItem value="">Sahay</Index.MenuItem>
                          {sahayTypeList?.map((ele) => (
                            <Index.MenuItem key={ele?.Id} value={ele?.Id}>
                              {ele?.Sahay_category}
                            </Index.MenuItem>
                          ))}
                        </Index.Select>
                      </Index.FormControl>
                    </Index.Box>
                  ) : null}
                  {rollData?.toLowerCase()?.match("staff")?.length ||
                  rollData?.toLowerCase()?.match("committee")?.length ? (
                    <Index.Box
                      className="lable-input-grp pending-button-sahay"
                      sx={{ marginRight: "10px" }}
                    >
                      <Index.FormControl
                        sx={{ width: "100%" }}
                        className="main-select"
                      >
                        <Index.Select
                          className="select-drop"
                          displayEmpty
                          value={subSahay}
                          onChange={(e) => {
                            setSubSahay(e.target.value);
                          }}
                          input={<OutlinedInput />}
                          MenuProps={MenuProps}
                          inputProps={{
                            "aria-label": "Without label",
                          }}
                        >
                          <Index.MenuItem value="">Sub Sahay</Index.MenuItem>
                          {sahaySubTypeList?.map((ele) => (
                            <Index.MenuItem key={ele?.Id} value={ele?.Id}>
                              {ele?.SahayName}
                            </Index.MenuItem>
                          ))}
                        </Index.Select>
                      </Index.FormControl>
                    </Index.Box>
                  ) : null}

                  {tabValue === 1 &&
                  ["committee", "staff"]?.includes(rollData?.toLowerCase()) ? (
                    <Index.Box className="lable-input-grp pending-button-sahay">
                      <Index.FormControl
                        sx={{ width: "100%", marginLeft: "7px" }}
                        className="main-select"
                      >
                        <Index.Select
                          className="select-drop"
                          displayEmpty
                          value={statusFilter}
                          onChange={(e) => {
                            setStatusFilter(e.target.value);
                          }}
                          input={<OutlinedInput />}
                          MenuProps={MenuProps}
                          inputProps={{
                            "aria-label": "Without label",
                          }}
                        >
                          <Index.MenuItem value={"Pending"}>
                            Pending
                          </Index.MenuItem>
                          <Index.MenuItem value={"Approved"}>
                            Approved
                          </Index.MenuItem>
                          <Index.MenuItem value={"Rejected"}>
                            Rejected
                          </Index.MenuItem>
                          <Index.MenuItem value={"Loan"}>Loan</Index.MenuItem>

                          <Index.MenuItem value={"Hold"}>Hold</Index.MenuItem>
                        </Index.Select>
                      </Index.FormControl>
                    </Index.Box>
                  ) : null}

                  {rollData?.toLowerCase()?.match("staff")?.length ||
                  rollData?.toLowerCase()?.match("admin")?.length ? (
                    (rollPermissionData?.permissionArray?.includes(
                      "Current Sahay Application_add"
                    ) &&
                      rollPermissionData?.permissionArray?.includes(
                        "Previous Sahay Application_add"
                      )) ||
                    rollData?.toLowerCase()?.match("admin")?.length ? (
                      <>
                        {rollData == "staff" && (
                          <Index.Box className="common-button blue-button res-blue-button desk-hidden-show ">
                            <PagesIndex.Link
                              to={
                                tabValue === 0
                                  ? `/${rollData}/apply-for-previous-sahay`
                                  : `/${rollData}/apply-for-sahay-admin`
                              }
                              className="no-text-decoration"
                            >
                              {" "}
                              {/* <Index.Button
                         variant="contained"
                         disableRipple
                         className="apply-sahay-btn"
                       > */}{" "}
                              <Index.Button
                                variant="contained"
                                disableRipple
                                className="apply-sahay-btn"
                              >
                                <Index.ControlPointIcon />{" "}
                                <span>Apply for sahay</span>
                              </Index.Button>
                              {/* </Index.Button> */}
                            </PagesIndex.Link>
                          </Index.Box>
                        )}
                        {rollData == "admin" && (
                          <Index.Box className="common-button blue-button res-blue-button desk-hidden-show ">
                            {/* <LightTooltip title="Export Previous Year CSV">
                           <Index.Button
                             className="csv-button"
                             onClick={() =>
                               handleExportPreviousCSVChange(previousListData)
                             }
                           >
                             Export CSV
                           </Index.Button>
                             </LightTooltip> */}

                            {/* <LightTooltip
                              title={
                                tabValue === 0
                                  ? "Import Previous Year CSV"
                                  : "Import Current Year CSV"
                              }
                            > */}
                            <LightTooltip title="">
                              <Index.Button className="csv-button">
                                <label
                                  htmlFor="actual-btn-csv-sahay-app"
                                  style={{ cursor: "pointer" }}
                                >
                                  {tabValue === 1
                                    ? "Import CSV Current Year"
                                    : "Import CSV Previous Year"}
                                </label>
                                <input
                                  type="file"
                                  id="actual-btn-csv-sahay-app"
                                  onChange={(e) => {
                                    tabValue === 0
                                      ? handleCSVChange(e)
                                      : handleCSVChangeCurrent(e);
                                  }}
                                  hidden
                                  accept=".csv"
                                />
                              </Index.Button>
                            </LightTooltip>
                          </Index.Box>
                        )}
                      </>
                    ) : rollPermissionData?.permissionArray?.includes(
                        "Previous Sahay Application_add"
                      ) || rollData === "admin" ? (
                      <>
                        {rollData === "staff" && (
                          <Index.Box className="common-button blue-button res-blue-button desk-hidden-show ">
                            <PagesIndex.Link
                              to={`/${rollData}/apply-for-previous-sahay`}
                              className="no-text-decoration"
                            >
                              {" "}
                              <Index.Button
                                variant="contained"
                                disableRipple
                                className="apply-sahay-btn"
                              >
                                {" "}
                                <Index.Button
                                  variant="contained"
                                  disableRipple
                                  className="apply-sahay-btn"
                                >
                                  <Index.ControlPointIcon />{" "}
                                  <span>Apply for previous sahay</span>
                                </Index.Button>
                              </Index.Button>
                            </PagesIndex.Link>
                          </Index.Box>
                        )}
                        {rollData === "admin" && (
                          <Index.Box className="common-button blue-button res-blue-button desk-hidden-show ">
                            {/* <LightTooltip title="Export Previous Year CSV">
                          <Index.Button
                            className="csv-button"
                            onClick={() =>
                              handleExportPreviousCSVChange(previousListData)
                            }
                          >
                            Export CSV
                          </Index.Button>
                        </LightTooltip> */}
                            {/* <LightTooltip title="Import Previous Year CSV"> */}
                            <LightTooltip title="">
                              <Index.Button className="csv-button">
                                <label
                                  htmlFor="actual-btn-1"
                                  style={{ cursor: "pointer" }}
                                >
                                  Import CSV
                                </label>
                                <input
                                  type="file"
                                  id="actual-btn-1"
                                  value=""
                                  onChange={(e) => handleCSVChange(e)}
                                  hidden
                                  accept=".csv"
                                />
                              </Index.Button>
                            </LightTooltip>
                          </Index.Box>
                        )}
                      </>
                    ) : rollPermissionData?.permissionArray?.includes(
                        "Current Sahay Application_add"
                      ) || rollData === "admin" ? (
                      <>
                        {rollData === "staff" && (
                          <Index.Box className="common-button blue-button res-blue-button desk-hidden-show ">
                            <PagesIndex.Link
                              to={`/${rollData}/apply-for-sahay-admin`}
                              // to={
                              //   tabValue === 0
                              //     ? "/admin/apply-for-previous-sahay"
                              //     : "/admin/apply-for-sahay-admin"
                              // }
                              className="no-text-decoration"
                            >
                              {" "}
                              <Index.Button
                                variant="contained"
                                disableRipple
                                className="apply-sahay-btn"
                              >
                                {" "}
                                <Index.Button
                                  variant="contained"
                                  disableRipple
                                  className="apply-sahay-btn"
                                >
                                  <Index.ControlPointIcon />{" "}
                                  <span>Apply for current sahay</span>
                                </Index.Button>
                              </Index.Button>
                            </PagesIndex.Link>
                          </Index.Box>
                        )}
                        {rollData === "admin" && (
                          <Index.Box className="common-button blue-button res-blue-button desk-hidden-show ">
                            {/* <LightTooltip title="Import Current Year CSV"> */}
                            <LightTooltip title="">
                              <Index.Button className="csv-button">
                                <label
                                  htmlFor="actual-btn-2"
                                  style={{ cursor: "pointer" }}
                                >
                                  Import CSV
                                </label>
                                <input
                                  type="file"
                                  id="actual-btn-2"
                                  value=""
                                  onChange={(e) => handleCSVChangeCurrent(e)}
                                  hidden
                                  accept=".csv"
                                />
                              </Index.Button>
                            </LightTooltip>
                          </Index.Box>
                        )}
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </Index.Box>
                {/* {rollData == "staff" && ( */}
                {rollData == "admin" && (
                  <LightTooltip
                    title={
                      tabValue === 1
                        ? "Export Current CSV Sample"
                        : "Export Previous Year CSV Sample"
                    }
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 4",
                        lg: "span 4",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="sahay-multiple-input-main">
                        <Index.Box
                          Box
                          className="res-auto-left common-button blue-button multiple-download-btn res-blue-button"
                        >
                          {tabValue === 1 ? (
                            <ExportSampleSheet
                              apiData={excel}
                              fileName={"Current Year CSV Sample"}
                            />
                          ) : (
                            <ExportSampleSheet
                              apiData={previousYearExcelSample}
                              fileName={"Previous Year CSV Sample"}
                            />
                          )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </LightTooltip>
                )}
                {rollData == "admin" && tabValue == 1 && (
                  // {rollData == "staff" && tabValue == 1 && (
                  <LightTooltip title="Import Family Member">
                    <Index.Box className="reject-btn">
                      <Index.Button className="approve-btn-list">
                        {/* <label htmlFor={`actual-btn12-${row?.id}`}> */}
                        <label htmlFor={`actual-btn12-11`}>
                          <img
                            alt="plus"
                            src={PagesIndex.Svg.ImportButton}
                            // className="approve-plus"
                            // onClick={() => setFamilySahayId(row?.id)}
                            className="cursor-pointer"
                          />
                        </label>

                        <input
                          type="file"
                          id={`actual-btn12-11`}
                          // id={`actual-btn12-${row?.id}`}
                          onChange={(e) => handleCSVProfileMember(e)}
                          hidden
                          accept=".csv"
                        />
                      </Index.Button>
                    </Index.Box>
                  </LightTooltip>
                )}
                {/* {rollData == "staff" && tabValue == 1 && ( */}
                {rollData == "admin" && tabValue == 1 && (
                  <LightTooltip title="Export family member CSV Sample">
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 4",
                        lg: "span 4",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="sahay-multiple-input-main">
                        <Index.Box
                          Box
                          className="res-auto-left common-button blue-button multiple-download-btn res-blue-button"
                        >
                          <ExportSampleSheet
                            apiData={familyMemberExcel}
                            fileName={"Family Member CSV Sample"}
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </LightTooltip>
                )}
              </Index.Box>

              <Index.Box className="tabs-main-box">
                <Index.Box className="flex-space-csv">
                  <Index.Box sx={{ width: "100%" }}>
                    <Index.Box>
                      {/* <Index.Box className="common-button blue-button res-blue-button desk-hidden-show "> */}
                      {rollData === "committee" ? (
                        <Index.Tabs
                          value={tabValue}
                          // onChange={handleTabChange}
                          aria-label="basic tabs example"
                          className="admin-tabs-main"
                        >
                          {(rollPermissionData?.permissionArray?.includes(
                            "Current Sahay Application_view"
                          ) ||
                            rollPermissionNameData === "admin") && (
                            <Index.Tab
                              label="Current Year"
                              {...a11yProps(1)}
                              className="admin-tab"
                            />
                          )}
                        </Index.Tabs>
                      ) : (
                        <Index.Tabs
                          value={tabValue}
                          onChange={handleTabChange}
                          aria-label="basic tabs example"
                          className="admin-tabs-main"
                        >
                          {(rollPermissionData?.permissionArray?.includes(
                            "Previous Sahay Application_view"
                          ) ||
                            rollPermissionNameData === "admin") && (
                            <Index.Tab
                              label="Previous Year"
                              {...a11yProps(0)}
                              className="admin-tab"
                            />
                          )}
                          {(rollPermissionData?.permissionArray?.includes(
                            "Current Sahay Application_view"
                          ) ||
                            rollPermissionNameData === "admin") && (
                            <Index.Tab
                              label="Current Year"
                              {...a11yProps(1)}
                              className="admin-tab"
                            />
                          )}
                        </Index.Tabs>
                      )}
                      {/* </Index.Box> */}
                    </Index.Box>
                  </Index.Box>
                  {/* {tabValue === 0 && rollPermissionNameData === "staff" ? (
                    <>
                      <Index.Box className="import-csv-btn">
                        <LightTooltip title="Export Previous Year CSV">
                          <Index.Button
                            className="csv-button"
                            onClick={() =>
                              handleExportPreviousCSVChange(previousListData)
                            }
                          >
                            Export SCV
                          </Index.Button>
                        </LightTooltip> 
                        <LightTooltip title="Import Previous Year CSV">
                          <Index.Button className="csv-button">
                            <label htmlFor="actual-btn">Import CSV</label>
                            <input
                              type="file"
                              id="actual-btn"
                              onChange={handleCSVChange}
                              hidden
                              accept=".csv"
                            />
                          </Index.Button>
                        </LightTooltip>
                      </Index.Box>
                    </>
                  ) : tabValue === 1 && rollPermissionNameData === "staff" ? (
                    <Index.Box className="import-csv-btn">
                      <LightTooltip title="Export Current Year CSV">
                        <Index.Button
                          className="csv-button"
                          onClick={() =>
                            handleExportCurrentCSVChange(applicationListData)
                          }
                        >
                          Export SCV
                        </Index.Button>
                      </LightTooltip> 
                      <LightTooltip title="Import Current Year CSV">
                        <Index.Button className="csv-button">
                          <label htmlFor="actual-btn">Import CSV</label>
                          <input
                            type="file"
                            id="actual-btn"
                            // onChange={handleCSVChangeCurrent}
                            hidden
                            accept=".csv"
                          />
                        </Index.Button>
                      </LightTooltip>
                    </Index.Box>
                  ) : (
                    ""
                  )} */}
                </Index.Box>
              </Index.Box>

              <TabPanel value={tabValue} index={0} className="admin-tabpanel">
                <Index.Box className="">
                  <Index.Box>
                    {/* // <input type="file" id="actual-btn" hidden />
 
                 // <label for="actual-btn">Choose File</label>  */}
                  </Index.Box>
                  <Index.TableContainer
                    component={Index.Paper}
                    className="table-container sahay-application-container"
                  >
                    <Index.Table
                      aria-label="simple table"
                      className="table-design-main barge-table sahay-appli-table"
                    >
                      <Index.TableHead className="sahay-appli-head">
                        <Index.TableRow className="sahay-appli-tr">
                          <Index.TableCell className="sahay-appli-tr">
                            Type
                          </Index.TableCell>
                          <Index.TableCell className="sahay-appli-tr">
                            Family Id
                          </Index.TableCell>
                          <Index.TableCell
                            className="user-sahay-td"
                            onClick={() => handleSort("userName")}
                            style={{ cursor: "pointer" }}
                          >
                            User Name
                            {sortColumn === "userName" &&
                              (sortDirection === "asc" ? (
                                <SwapVertIcon
                                  className="swap-icon user-sahay-td-svg"
                                  fontSize="small"
                                />
                              ) : (
                                <SwapVertIcon
                                  className="swap-icon user-sahay-td-svg"
                                  fontSize="small"
                                />
                              ))}
                          </Index.TableCell>
                          <Index.TableCell className="sahay-appli-tr">
                            Type Of Sahay
                          </Index.TableCell>
                          <Index.TableCell className="sahay-appli-tr">
                            Sub Type Of Sahay
                          </Index.TableCell>
                          <Index.TableCell
                            className="sahay-appli-tr"
                            align="left"
                          >
                            Requested Sahay
                          </Index.TableCell>
                          <Index.TableCell
                            className="sahay-appli-tr"
                            align="left"
                          >
                            Approved Amount
                          </Index.TableCell>

                          <Index.TableCell
                            className="sahay-appli-tr"
                            align="left"
                          >
                            Year Of Sahay
                          </Index.TableCell>
                          <Index.TableCell
                            className="sahay-appli-tr"
                            align="left"
                          >
                            Start Date
                          </Index.TableCell>
                          <Index.TableCell
                            className="sahay-appli-tr"
                            align="left"
                          >
                            No. Of Month
                          </Index.TableCell>
                          <Index.TableCell
                            className="sahay-appli-tr"
                            align="left"
                          >
                            Repay Details
                          </Index.TableCell>
                          {/* <Index.TableCell className="sahay-appli-tr" align="left">
                         Status
                       </Index.TableCell> */}
                          {rollPermissionData?.permissionArray?.includes(
                            "Previous Sahay Application_view"
                          ) ? (
                            <Index.TableCell
                              className="sahay-appli-tr"
                              align="left"
                            >
                              Action
                            </Index.TableCell>
                          ) : (
                            <Index.TableCell
                              className="sahay-appli-tr"
                              align="left"
                            ></Index.TableCell>
                          )}
                        </Index.TableRow>
                      </Index.TableHead>
                      <Index.TableBody
                      // className={
                      //   status == STATUSES.LOADING
                      //     ? "sahay-appli-tbody"
                      //     : "loading-table"
                      // }
                      >
                        {/* {status !== STATUSES.LOADING ? ( */}

                        {previousPageData?.length ? (
                          previousPageData?.map((row, index) => (
                            <Index.TableRow
                              key={index}
                              className={`sahay-appli-tr ${
                                row?.applicationId === RowIdHighlightData
                                  ? "highlighted-row"
                                  : ""
                              }`}
                            >
                              <Index.TableCell
                                align="left"
                                className="sahay-appli-td"
                              >
                                {row?.vastipatrak == 1
                                  ? "Vastipatrak"
                                  : "NonVastipatrak"}
                              </Index.TableCell>
                              <Index.TableCell
                                align="left"
                                className="sahay-appli-td"
                              >
                                {row?.familyId}
                              </Index.TableCell>
                              <Index.TableCell
                                align="left"
                                className="sahay-appli-td"
                              >
                                {row?.mem_fname} {row?.mem_mname}{" "}
                                {row?.mem_lname}
                                {/* {row?.Name} */}
                              </Index.TableCell>

                              <Index.TableCell
                                align="left"
                                className="sahay-appli-td"
                              >
                                {row?.SahayTypeName}
                              </Index.TableCell>
                              <Index.TableCell
                                align="left"
                                className="sahay-appli-td"
                              >
                                {row?.subSahayTypeName}
                              </Index.TableCell>

                              <Index.TableCell
                                align="left"
                                className="sahay-appli-td"
                              >
                                {/* {(row?.SahayAmmount == null ||
                                row?.SahayAmmount == 0)
                                  ? row?.sahay_amount
                                  :( row?.sahay_amount == null ||
                                    row?.sahay_amount == 0)
                                  ? row?.SahayAmmount
                                  : "0"} */}
                                {/* {row?.SahayAmmount == null &&
                                row?.SahayAmmount == 0 && row?.sahay_amount !== null 
                                  ? row?.sahay_amount
                                  :row?.sahay_amount == null &&
                                    row?.sahay_amount == 0 && row?.SahayAmmount !== null  ? row?.SahayAmmount :"0" } */}
                                {row?.SahayAmmount === null
                                  ? row?.sahay_amount
                                  : row?.sahay_amount === null
                                  ? row?.SahayAmmount
                                  : "0"}
                              </Index.TableCell>
                              <Index.TableCell
                                align="left"
                                className="sahay-appli-td"
                              >
                                {row?.SahayAmmount === null
                                  ? row?.sahay_amount
                                  : row?.sahay_amount === null
                                  ? row?.SahayAmmount
                                  : "0"}
                              </Index.TableCell>

                              <Index.TableCell
                                align="left"
                                className="sahay-appli-td"
                              >
                                {row?.toYear
                                  ? `${row?.yearFrom}- ${row?.toYear}`
                                  : "---"}
                              </Index.TableCell>
                              <Index.TableCell
                                align="left"
                                className="sahay-appli-td"
                              >
                                {row?.startDate
                                  ? new Date(
                                      row?.startDate
                                    )?.toLocaleDateString("en-GB")
                                  : "-"}
                              </Index.TableCell>
                              <Index.TableCell
                                align="left"
                                className="sahay-appli-td"
                              >
                                {row?.totalMonth ? row?.totalMonth : "-"}
                              </Index.TableCell>

                              <LightTooltip title={row?.repayDetails}>
                                <Index.TableCell
                                  align="left"
                                  className="sahay-appli-td"
                                >
                                  {
                                    row.repayDetails
                                      ? row.repayDetails?.slice(0, 15) + "..."
                                      : "-"
                                    // ?.join(" ")+ "..."
                                  }
                                </Index.TableCell>
                              </LightTooltip>

                              {/* <Index.TableCell
                             align="left"
                             className="sahay-appli-td"
                           >
                             <Index.Box
                               className={`status-details ${
                                 row?.newStatusColumn == "pending"
                                   ? "pending - status"
                                   : row?.newStatusColumn == "approved"
                                   ? "approved-status"
                                   : row?.newStatusColumn == "rejected"
                                   ? "reject-status"
                                   : ""
                               }`}
                             >
                               
                               {rollData === "committee"
                                 ? "Pending "
                                 : row?.newStatusColumn}
                             </Index.Box>
                           </Index.TableCell> */}
                              <Index.TableCell
                                sx={{ display: "flex" }}
                                className="sahay-appli-td"
                              >
                                <Index.Box className="flex-content-sahay">
                                  {rollPermissionData?.permissionArray?.includes(
                                    "Previous Sahay Application_view"
                                  ) || rollPermissionNameData === "admin" ? (
                                    <Index.IconButton
                                      onClick={() =>
                                        handlePreviousSahayApplicationView(row)
                                      }
                                    >
                                      <Index.Visibility className="muiico-view" />
                                    </Index.IconButton>
                                  ) : (
                                    ""
                                  )}

                                  <Index.Box className="reject-btn">
                                    {rollData?.toLowerCase()?.match("staff")
                                      ?.length && (
                                      <Index.IconButton
                                        className="approve-btn-list"
                                        color="primary"
                                        aria-label="upload picture"
                                        component="label"
                                        onClick={() => {
                                          dispatch(editPreviousSahay(row));
                                          navigate(
                                            `/${rollData}/edit-apply-for-previous-sahay`,
                                            {
                                              state: row,
                                            }
                                          );
                                        }}
                                      >
                                        <Index.EditIcon className="muiico-view" />
                                      </Index.IconButton>
                                    )}
                                  </Index.Box>
                                  {/* <Index.Box className="approved-btn">
                                 <Index.Button
                                   disabled={btnLoading}
                                   className="approve-btn-list"
                                   onClick={() =>
                                     handlePreviousSahayApplicationStatus(
                                       row?.applicationId,
                                       "approved"
                                     )
                                   }
                                 >
                                   <img
                                     src={PagesIndex.Svg.approve}
                                     className="approve"
                                   ></img>
                                 </Index.Button>
                               </Index.Box> */}
                                  {/* <Index.Box className="approved-btn">
                                 <LightTooltip title="Remarks">
                                   <Index.Button
                                     className="approve-btn-list"
                                     onClick={handleOpen}
                                   >
                                     <img
                                       src={PagesIndex.Svg.plusyellow}
                                       className="approve-plus"
                                     ></img>
                                   </Index.Button>
                                 </LightTooltip>
                               </Index.Box> */}
                                  {/* <Index.Box className="reject-btn">
                                 <Index.Button
                                   disabled={btnLoading}
                                   className="reject-btn-list"
                                   onClick={() =>
                                     handlePreviousSahayApplicationStatus(
                                       row?.applicationId,
                                       "rejected"
                                     )
                                   }
                                 >
                                   <img
                                     src={PagesIndex.Svg.incorrect}
                                     className="reject"
                                   ></img>
                                 </Index.Button>
                               </Index.Box> */}
                                </Index.Box>
                              </Index.TableCell>
                            </Index.TableRow>
                          ))
                        ) : (
                          <PagesIndex.NoRecordFound colSpan={14} />
                        )}
                        {/* ) : (
                     <PagesIndex.PageLoading colSpan={12} />
                   )} */}
                      </Index.TableBody>
                    </Index.Table>
                  </Index.TableContainer>
                </Index.Box>

                <Index.Box className="pagination-design flex-end">
                  <RowPerPageDropdown
                    rowPerPage={rowPerPage}
                    setRowPerPage={setRowPerPage}
                  />
                  <Index.Stack spacing={2}>
                    <PagesIndex.Pagination
                      fetchData={previousSearchedData}
                      setPageData={setPreviousPageData}
                      pageData={previousPageData}
                      rowPerPage={rowPerPage}
                    />
                  </Index.Stack>
                </Index.Box>
              </TabPanel>
              <TabPanel value={tabValue} index={1} className="admin-tabpanel">
                <Index.Box className="">
                  <Index.TableContainer
                    sx={{ maxHeight: 325 }}
                    // component={Index.Paper}
                    className="table-container sahay-application-container scroll-table common-table-container-custom"
                  >
                    <Index.Table
                      stickyHeader
                      aria-label="sticky table"
                      className="table-design-main barge-table sahay-appli-table"
                    >
                      <Index.TableHead className="sahay-appli-head">
                        <Index.TableRow className="sahay-appli-tr">
                          <Index.TableCell className="sahay-appli-tr">
                            Type
                          </Index.TableCell>
                          {rollData?.toLowerCase()?.match("staff")?.length && (
                            <Index.TableCell className="sahay-appli-tr">
                              Application Id
                            </Index.TableCell>
                          )}
                          <Index.TableCell className="sahay-appli-tr">
                            Family Id
                          </Index.TableCell>
                          <Index.TableCell
                            className="user-sahay-td"
                            onClick={() => handleSort("userName")}
                            style={{ cursor: "pointer" }}
                          >
                            User Name
                            {sortColumn === "userName" &&
                              (sortDirection === "asc" ? (
                                <SwapVertIcon
                                  className="swap-icon user-sahay-td-svg"
                                  fontSize="small"
                                />
                              ) : (
                                <SwapVertIcon
                                  className="swap-icon user-sahay-td-svg"
                                  fontSize="small"
                                />
                              ))}
                          </Index.TableCell>
                          <Index.TableCell
                            className="sahay-appli-tr"
                            align="left"
                          >
                            Sahay Category
                          </Index.TableCell>
                          <Index.TableCell
                            className="sahay-appli-tr"
                            align="left"
                          >
                            Sub Sahay Category
                          </Index.TableCell>
                          <Index.TableCell
                            className="sahay-appli-tr"
                            align="left"
                          >
                            Requested Sahay
                          </Index.TableCell>

                          <Index.TableCell
                            className="sahay-appli-tr"
                            align="left"
                          >
                            Approved Amount
                          </Index.TableCell>

                          <Index.TableCell
                            className="sahay-appli-tr"
                            align="left"
                          >
                            Beneficiary Name
                          </Index.TableCell>

                          <Index.TableCell
                            className="sahay-appli-tr"
                            align="left"
                          >
                            Staff Status
                          </Index.TableCell>
                          <Index.TableCell
                            className="sahay-appli-tr"
                            align="left"
                          >
                            Staff Remark
                          </Index.TableCell>
                          <Index.TableCell
                            className="sahay-appli-tr"
                            align="left"
                          >
                            Committee Status
                          </Index.TableCell>
                          <Index.TableCell
                            className="sahay-appli-tr"
                            align="left"
                          >
                            Committee Remark
                          </Index.TableCell>
                          {rollData?.toLowerCase()?.match("committee")
                            ?.length && (
                            <Index.TableCell
                              className="sahay-appli-tr"
                              align="left"
                            >
                              Approved By
                            </Index.TableCell>
                          )}

                          <Index.TableCell
                            className="sahay-appli-tr"
                            align="left"
                          >
                            Action
                          </Index.TableCell>
                        </Index.TableRow>
                      </Index.TableHead>
                      <Index.TableBody
                      // className={
                      //   status == STATUSES.LOADING
                      //     ? "sahay-appli-tbody"
                      //     : "loading-table"
                      // }
                      >
                        {/* {status !== STATUSES.LOADING ? ( */}
                        {pageData?.length ? (
                          pageData
                            // ?.filter((row) => {
                            //   if (rollData === "committee") {
                            //     // If rollData is "committee," filter for "approved" and "rejected" status
                            //     return (
                            //       row.newStatusColumn.toLowerCase() ===
                            //         "approved" ||
                            //       row.newStatusColumn.toLowerCase() ===
                            //         "rejected"
                            //     ) || getAdminLoginDetails?.sahayPermission?.includes(row.subSahayName.toLowerCase());
                            //   }

                            //   return true;
                            // })

                            // ?.filter((row) => {
                            //   if (rollData === "committee") {
                            //     return (
                            //       row.newStatusColumn?.toLowerCase() ===
                            //         "approved" ||
                            //       row.newStatusColumn?.toLowerCase() ===
                            //         "rejected"
                            //     );
                            //   }
                            //   return true;
                            // })
                            ?.map((row, index) => (
                              <Index.TableRow
                                key={row?.id}
                                className={`sahay-appli-tr ${
                                  row?.applicationId === RowIdHighlightData
                                    ? "highlighted-row"
                                    : ""
                                }`}
                              >
                                <Index.TableCell
                                  align="left"
                                  className="sahay-appli-td"
                                >
                                  {row?.vastipatrak == 1
                                    ? "Vastipatrak"
                                    : "Non Vastipatrak"}
                                </Index.TableCell>
                                {rollData?.toLowerCase()?.match("staff")
                                  ?.length && (
                                  <Index.TableCell
                                    align="left"
                                    className="sahay-appli-td"
                                  >
                                    {row?.applicationId}
                                  </Index.TableCell>
                                )}
                                <Index.TableCell
                                  align="left"
                                  className="sahay-appli-td"
                                >
                                  {row?.familyId}
                                </Index.TableCell>
                                <Index.TableCell
                                  align="left"
                                  className="sahay-appli-td"
                                >
                                  {/* {row?.fname} {row?.mname} {row?.lname} */}
                                  {row?.mem_fname} {row?.mem_mname}{" "}
                                  {row?.mem_lname}
                                </Index.TableCell>

                                <Index.TableCell
                                  align="left"
                                  className="sahay-appli-td"
                                >
                                  {row?.SahayTypeName}
                                </Index.TableCell>
                                <Index.TableCell
                                  align="left"
                                  className="sahay-appli-td"
                                >
                                  {row?.subSahayTypeName}
                                </Index.TableCell>
                                <Index.TableCell
                                  align="left"
                                  className="sahay-appli-td"
                                >
                                  {row?.SahayAmmount == null ||
                                  row?.SahayAmmount == 0
                                    ? row?.sahay_amount
                                    : row?.sahay_amount == null ||
                                      row?.sahay_amount == 0
                                    ? row?.SahayAmmount
                                    : "0"}
                                </Index.TableCell>

                                <Index.TableCell
                                  align="left"
                                  className="sahay-appli-td"
                                >
                                  {row?.approveAmount || "-"}
                                </Index.TableCell>

                                <Index.TableCell
                                  align="left"
                                  className="sahay-appli-td"
                                >
                                  {row?.beneficiaryName}
                                </Index.TableCell>

                                {/* <Index.TableCell
                                align="left"
                                className="sahay-appli-td"
                              >
                                {row?.AadharNo}
                              </Index.TableCell> */}
                                <Index.TableCell
                                  align="left"
                                  className="sahay-appli-td"
                                >
                                  <Index.Box
                                    className={`status-details ${
                                      row?.newStatusColumn == "Pending"
                                        ? "pending - status"
                                        : row?.newStatusColumn == "Approved"
                                        ? "approved-status"
                                        : row?.newStatusColumn == "Rejected"
                                        ? "reject-status"
                                        : ""
                                    }`}
                                  >
                                    {/* {checkStatus.toUpperCase()} */}
                                    {/* Pending */}
                                    {row?.newStatusColumn}
                                  </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell
                                  align="left"
                                  className="sahay-appli-td"
                                  sx={{ textAlign: "center" }}
                                >
                                  {row?.staffRemark === "null" ? (
                                    "-"
                                  ) : row?.staffRemark === "undefined" ? (
                                    "-"
                                  ) : row?.staffRemark === null ? (
                                    "-"
                                  ) : (
                                    <LightTooltip title={row?.staffRemark}>
                                      {
                                        row?.staffRemark
                                          ? row.staffRemark
                                              // ?.split(" ")
                                              ?.slice(0, 15) + "......"
                                          : "-"
                                        // ?.join(" ")
                                      }
                                    </LightTooltip>
                                  )}
                                </Index.TableCell>
                                <Index.TableCell
                                  align="left"
                                  className="sahay-appli-td"
                                  sx={{ textAlign: "center" }}
                                >
                                  <Index.Box
                                    className={`status-details ${
                                      row?.committeeStatus == "Pending"
                                        ? "pending - status"
                                        : row?.committeeStatus == "Approved"
                                        ? "approved-status"
                                        : row?.committeeStatus == "Rejected"
                                        ? "reject-status"
                                        : ""
                                    }`}
                                  >
                                    {/* {checkStatus.toUpperCase()} */}
                                    {/* {row?.committeeStatus} */}
                                    {row?.committeeStatus === null
                                      ? "-"
                                      : row?.committeeStatus}
                                    {/* {row?.newStatusColumn} */}
                                  </Index.Box>
                                </Index.TableCell>

                                <Index.TableCell
                                  align="left"
                                  className="sahay-appli-td"
                                  sx={{ textAlign: "center" }}
                                >
                                  {row?.committeeRemark === "null" ? (
                                    "-"
                                  ) : row?.committeeRemark === "undefined" ? (
                                    "-"
                                  ) : row?.committeeRemark === null ? (
                                    "-"
                                  ) : (
                                    <LightTooltip title={row?.committeeRemark}>
                                      {
                                        row.committeeRemark
                                          // ?.split(" ")
                                          ?.slice(0, 15) + "......"
                                        // ?.join(" ")
                                      }
                                      {/* {row.committeeRemark
                                     ?.split(" ")
                                     ?.length > 20
                                     ? " ..."
                                     : ""} */}
                                    </LightTooltip>
                                  )}
                                </Index.TableCell>
                                {rollData?.toLowerCase()?.match("committee")
                                  ?.length && (
                                  <Index.TableCell
                                    align="left"
                                    className="sahay-appli-td"
                                  >
                                    {row?.actionBy || "-"}
                                  </Index.TableCell>
                                )}

                                <Index.TableCell
                                  sx={{ display: "flex" }}
                                  className="sahay-appli-td"
                                >
                                  <Index.Box className="flex-content-sahay">
                                    {row?.newStatusColumn?.toLowerCase() ==
                                      "approved" && rollData != "admin" ? (
                                      <>
                                        {selectedRowData && (
                                          <Index.Box
                                            className="pdfDownload"
                                            sx={{ display: "none" }}
                                          >
                                            <PrintSahayApplication
                                              ref={componentRef}
                                              formData={selectedRowData}
                                              state={selectedRow}
                                              familyMembers={familyMembers}
                                              editSingleApplicantData={
                                                editSingleApplicantData
                                              }
                                            />
                                          </Index.Box>
                                        )}
                                        <LightTooltip title="Print">
                                          <Index.IconButton
                                            onClick={() => handlePrint(row)}
                                            disabled={printLoading}
                                          >
                                            <Index.PrintIcon className="muiico-edit" />
                                          </Index.IconButton>
                                        </LightTooltip>
                                      </>
                                    ) : null}
                                    {(row?.newStatusColumn?.toLowerCase() ==
                                      "pending" ||
                                      (row?.committeeStatus?.toLowerCase() ==
                                        "pending" &&
                                        rollData == "staff")) &&
                                    rollData != "admin" ? (
                                      <>
                                        <LightTooltip title="Edit">
                                          <Index.IconButton
                                            onClick={() => handleEdit(row)}
                                          >
                                            <Index.EditIcon className="muiico-edit" />
                                          </Index.IconButton>
                                        </LightTooltip>
                                      </>
                                    ) : null}
                                    <LightTooltip title="View">
                                      <Index.IconButton
                                        onClick={() =>
                                          handleSahayApplicationView(row)
                                        }
                                      >
                                        <Index.Visibility className="muiico-view" />
                                      </Index.IconButton>
                                    </LightTooltip>
                                    {/* <Index.Box className="reject-btn">
                                 <Index.IconButton
                                   className="approve-btn-list"
                                   color="primary"
                                   aria-label="upload picture"
                                   component="label"
                                   // onClick={() => {
                                   //   // dispatch(editPreviousSahay(row));
                                   //   // navigate(
                                   //   //   "/admin/edit-apply-for-previous-sahay",
                                   //   //   {
                                   //   //     state: row,
                                   //   //   }
                                   //   // );
                                   // }}
                                 >
                                   <Index.EditIcon className="muiico-view" />
                                 </Index.IconButton>
                               </Index.Box> */}

                                    {rollData != "admin" && (
                                      <LightTooltip
                                        title={
                                          row?.committeeStatus?.toLowerCase() ==
                                            "approved" ||
                                          row?.newStatusColumn?.toLowerCase() ==
                                            "approved"
                                            ? "Approved"
                                            : "Approve"
                                        }
                                      >
                                        <Index.Box className="approved-btn">
                                          <Index.Button
                                            disabled={
                                              // Condition 1: 'staff' in rollData and committeeStatus is not 'pending'
                                              (rollData
                                                ?.toLowerCase()
                                                .includes("staff") &&
                                                row?.committeeStatus?.toLowerCase() !==
                                                  "pending") ||
                                              // Condition 2: row is already disbursed
                                              row?.isDisbursed === 1 ||
                                              // Condition 3: 'committee' in rollData and matching committeeApprovalName for the admin
                                              (rollData
                                                ?.toLowerCase()
                                                .includes("committee") &&
                                                row?.committeeApprovalName &&
                                                JSON.parse(
                                                  row?.committeeApprovalName
                                                )?.some(
                                                  (committee) =>
                                                    committee.committeeName ===
                                                      getAdminLoginDetails?.name &&
                                                    committee.committeeNumber ===
                                                      getAdminLoginDetails?.mobileno
                                                ))
                                            }
                                            className="approve-btn-list"
                                            onClick={() => {
                                              if (
                                                rollData
                                                  ?.toLowerCase()
                                                  ?.match("committee")
                                              ) {
                                                handleOpenApproved(
                                                  row?.applicationId,
                                                  row?.SahayAmmount,
                                                  row?.sahay_amount,
                                                  row?.committeeRemark,
                                                  row?.approveAmount,
                                                  row?.subSahayTypeName,
                                                  row?.loanInstallment
                                                );
                                              } else {
                                                handleSahayApplicationStatus(
                                                  row,
                                                  "Approved"
                                                );
                                              }
                                            }}
                                          >
                                            <img
                                              src={PagesIndex.Svg.approve}
                                              className="approve"
                                            ></img>
                                          </Index.Button>
                                        </Index.Box>
                                      </LightTooltip>
                                    )}

                                    {rollData != "admin" &&
                                      rollData?.toLowerCase()?.match("staff") &&
                                      (row?.subSahayTypeName ==
                                        "Educational Loan" ||
                                        row?.subSahayTypeName ==
                                          "Swawlamban") &&
                                      row?.loanAgreementDoc == null &&
                                      row?.committeeStatus == "Approved" && (
                                        <LightTooltip title="upload">
                                          <Index.Box className="approved-btn">
                                            <Index.Button
                                              className="approve-btn-list"
                                              onClick={() => {
                                                handleOpenUpload(
                                                  row?.applicationId
                                                );
                                              }}
                                            >
                                              <img
                                                src={PagesIndex.Svg.uploadImg}
                                                className="approve"
                                              ></img>
                                            </Index.Button>
                                          </Index.Box>
                                        </LightTooltip>
                                      )}
                                    {rollData != "admin" && (
                                      <LightTooltip
                                        title={
                                          row?.committeeStatus?.toLowerCase() ==
                                            "rejected" ||
                                          row?.newStatusColumn?.toLowerCase() ==
                                            "rejected"
                                            ? "Rejected"
                                            : "Reject"
                                        }
                                      >
                                        <Index.Box className="reject-btn">
                                          <Index.Button
                                            // disabled={
                                            //   rollData
                                            //     ?.toLowerCase()
                                            //     ?.match("staff")?.length
                                            //     ? row?.newStatusColumn ===
                                            //         "Rejected" ||
                                            //       row?.newStatusColumn ===
                                            //         "Approved" ||
                                            //       (row?.committeeStatus ===
                                            //         "Approved" &&
                                            //         row?.newStatusColumn ===
                                            //           "Approved")
                                            //       ? true
                                            //       : false
                                            //     : row?.committeeStatus ===
                                            //         "Rejected" ||
                                            //       row?.committeeStatus ===
                                            //         "Approved"
                                            //     ? true
                                            //     : false
                                            // }
                                            disabled={
                                              (rollData
                                                ?.toLowerCase()
                                                ?.match("staff")?.length &&
                                                row?.committeeStatus?.toLowerCase() !=
                                                  "pending") ||
                                              row?.isDisbursed == 1
                                            }
                                            className="reject-btn-list"
                                            // onClick={() =>
                                            //   handleSahayApplicationStatus(
                                            //     row?.applicationId,
                                            //     "Rejected"
                                            //   )
                                            // }
                                            onClick={() =>
                                              rollData
                                                ?.toLowerCase()
                                                ?.match("committee")
                                                ? handleOPen(
                                                    row?.applicationId,
                                                    "Rejected"
                                                  )
                                                : handleOpenStaffRemark(
                                                    row,
                                                    "Rejected"
                                                  )
                                            }
                                          >
                                            <img
                                              src={PagesIndex.Svg.incorrect}
                                              className="reject"
                                            ></img>
                                          </Index.Button>
                                        </Index.Box>
                                      </LightTooltip>
                                    )}
                                    {rollData?.toLowerCase()?.match("committee")
                                      ?.length ? (
                                      <LightTooltip title="Remarks">
                                        <Index.Box className="approved-btn">
                                          <Index.Button
                                            disabled={
                                              row?.committeeStatus ===
                                              "Approved"
                                            }
                                            className="approve-btn-list"
                                            onClick={() => {
                                              handleOPen(row?.applicationId);
                                              setRemarkStatus(
                                                row?.committeeStatus
                                              );
                                            }}
                                          >
                                            <img
                                              src={PagesIndex.Svg.plusyellow}
                                              className="approve-plus"
                                            ></img>
                                          </Index.Button>
                                        </Index.Box>
                                      </LightTooltip>
                                    ) : (
                                      ""
                                    )}
                                    {rollData?.toLowerCase()?.match("committee")
                                      ?.length ||
                                    rollData?.toLowerCase()?.match("staff")
                                      ?.length ? (
                                      <LightTooltip title="hold">
                                        <Index.Box className="reject-btn">
                                          <Index.Button
                                            disabled={
                                              rollData
                                                ?.toLowerCase()
                                                ?.match("staff")?.length
                                                ? row?.committeeStatus?.toLowerCase() ==
                                                    "approved" ||
                                                  row?.committeeStatus?.toLowerCase() ==
                                                    "rejected" ||
                                                  row?.committeeStatus?.toLowerCase() ==
                                                    "hold" ||
                                                  row?.isDisbursed == 1
                                                : (rollData
                                                    ?.toLowerCase()
                                                    ?.match("committee")
                                                    ?.length &&
                                                    row?.committeeStatus?.toLowerCase() ==
                                                      "hold") ||
                                                  row?.isDisbursed == 1
                                            }
                                            className="reject-btn-list"
                                            onClick={() =>
                                              rollData
                                                ?.toLowerCase()
                                                ?.match("committee")
                                                ? handleOPen(
                                                    row?.applicationId,
                                                    "Hold"
                                                  )
                                                : // : handleSahayApplicationStatus(
                                                  //   row,
                                                  //   "Hold"
                                                  // )
                                                  handleOpenStaffRemark(
                                                    row,
                                                    "Hold"
                                                  )
                                            }
                                          >
                                            <BackHandIcon />
                                          </Index.Button>
                                        </Index.Box>
                                      </LightTooltip>
                                    ) : (
                                      ""
                                    )}

                                    {rollData != "admin" && (
                                      <LightTooltip title="Pending">
                                        <Index.Box className="reject-btn">
                                          <Index.Button
                                            // disabled={
                                            //   rollData
                                            //     ?.toLowerCase()
                                            //     ?.match("staff")?.length
                                            //     ? row?.newStatusColumn ===
                                            //         "Rejected" ||
                                            //       row?.newStatusColumn ===
                                            //         "Approved" ||
                                            //       (row?.committeeStatus ===
                                            //         "Approved" &&
                                            //         row?.newStatusColumn ===
                                            //           "Approved")
                                            //       ? true
                                            //       : false
                                            //     : row?.committeeStatus ===
                                            //         "Rejected" ||
                                            //       row?.committeeStatus ===
                                            //         "Approved"
                                            //     ? true
                                            //     : false
                                            // }
                                            disabled={
                                              rollData
                                                ?.toLowerCase()
                                                ?.match("staff")?.length
                                                ? (rollData
                                                    ?.toLowerCase()
                                                    ?.match("staff")?.length &&
                                                    row?.newStatusColumn?.toLowerCase() ==
                                                      "pending") ||
                                                  row?.committeeStatus?.toLowerCase() ==
                                                    "approved" ||
                                                  row?.committeeStatus?.toLowerCase() ==
                                                    "rejected" ||
                                                  row?.committeeStatus?.toLowerCase() ==
                                                    "hold" ||
                                                  row?.isDisbursed == 1
                                                : (rollData
                                                    ?.toLowerCase()
                                                    ?.match("committee")
                                                    ?.length &&
                                                    row?.committeeStatus?.toLowerCase() ==
                                                      "pending") ||
                                                  row?.isDisbursed == 1
                                            }
                                            className="reject-btn-list"
                                            onClick={() =>
                                              handleSahayApplicationStatus(
                                                row,
                                                "Pending"
                                              )
                                            }
                                          >
                                            {/* <img
                                              src={PagesIndex.Svg.incorrect}
                                              className="reject"
                                            ></img> */}
                                            <Index.PendingIcon className="muiico-edit " />
                                          </Index.Button>
                                        </Index.Box>
                                      </LightTooltip>
                                    )}
                                    {/* {rollData != "admin" && ( */}
                                    {/* {rollData == "staff" && (
                                      <LightTooltip title="Add Family Member">
                                        <Index.Box className="reject-btn">
                                          <Index.Button className="approve-btn-list">
                                            <label
                                              htmlFor={`actual-btn12-${row?.id}`}
                                            >
                                              <img
                                                alt="plus"
                                                src={
                                                  PagesIndex.Svg.ImportButton
                                                }
                                                // className="approve-plus"
                                                onClick={() =>
                                                  setFamilySahayId(row?.id)
                                                }
                                                className="cursor-pointer"
                                              />
                                            </label>

                                            <input
                                              type="file"
                                              id={`actual-btn12-${row?.id}`}
                                              onChange={(e) =>
                                                handleCSVProfileMember(e)
                                              }
                                              hidden
                                              accept=".csv"
                                            />
                                          </Index.Button>
                                        </Index.Box>
                                      </LightTooltip>
                                    )} */}
                                  </Index.Box>
                                </Index.TableCell>
                              </Index.TableRow>
                            ))
                        ) : (
                          <PagesIndex.NoRecordFound colSpan={13} />
                        )}
                        {/* ) : (
                     <PagesIndex.PageLoading colSpan={12} />
                   )} */}
                      </Index.TableBody>
                    </Index.Table>
                  </Index.TableContainer>
                </Index.Box>

                <Index.Box className="pagination-design flex-end">
                  <RowPerPageDropdown
                    rowPerPage={rowPerPage}
                    setRowPerPage={setRowPerPage}
                  />
                  <Index.Stack spacing={2}>
                    <PagesIndex.Pagination
                      fetchData={searchedData}
                      setPageData={setPageData}
                      pageData={pageData}
                      rowPerPage={rowPerPage}
                    />
                  </Index.Stack>
                </Index.Box>
              </TabPanel>
            </Index.Box>
          </Index.Box>

          <Index.Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="modal-delete modal"
          >
            <Index.Box
              sx={style}
              className="delete-modal-inner-main modal-inner"
            >
              <Index.Box className="remark-textarea-details">
                <Index.Box className="set-text-area ">
                  <Index.Typography
                    variant="label"
                    component="label"
                    className="input-label"
                  >
                    Remark (ટિપ્પણી)
                  </Index.Typography>
                  <Index.Box className="set-textarea-box-top">
                    <Index.TextField
                      className="set-textarea-box custom-set-area"
                      aria-label="empty textarea"
                      placeholder="Remark"
                      multiline
                      name="Remark"
                      onChange={(e) => setRemarkData(e.target.value)}
                    />
                  </Index.Box>

                  <p className="error-text" style={{ marginTop: "10px" }}>
                    {remarkError}
                  </p>
                </Index.Box>
                <Index.Box className="btn-submit-area">
                  <Index.Box className="common-button blue-button change-password-btn update_priofile_btn">
                    <Index.Button
                      variant="contained"
                      disableRipple
                      type="submit"
                      onClick={onSumitRemarkCommittee}
                    >
                      Submit
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Modal>

          {/* ////////////////////// This is for claimed and approved amount ///////////////// */}
          <Index.Modal
            open={amountOpen}
            onClose={handleCloseApproved}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="modal-delete modal"
          >
            <Index.Box
              sx={style}
              className="delete-modal-inner-main modal-inner approve-amount-model"
            >
              <PagesIndex.Formik
                enableReinitialize
                onSubmit={handleOpenSchedule}
                validationSchema={
                  subSahayType === "Educational Loan" ||
                  subSahayType === "Swawlamban"
                    ? validationSchemaEMIAmount
                    : validationSchemaApproveAmoumt
                }
                initialValues={{
                  claimedAmount: showClaimedAmount,
                  approvedAmount: approvedAmonut ? approvedAmonut : "",
                  loanInstallment:
                    loanInstallment?.length > 0
                      ? loanInstallment
                      : [
                          {
                            EMIDate: "",
                            EMIAmount: "",
                          },
                        ],
                }}
              >
                {({
                  values,
                  handleChange,
                  handleSubmit,
                  touched,
                  setFieldValue,
                  handleBlur,
                  errors,
                  isValid,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Index.Box
                      className={`${
                        subSahayType == "" ? "remark-textarea-details" : ""
                      }`}
                    >
                      <Index.Box className="set-text-area ">
                        <Index.Box
                          sx={{ flexGrow: 1 }}
                          className="al-form-groups"
                        >
                          <Index.Grid container spacing={2}>
                            <Index.Grid item xs={12} sm={12} md={12} lg={12}>
                              <Index.Box
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "end",
                                }}
                              >
                                <Index.IconButton onClick={handleCloseApproved}>
                                  <Index.CloseIcon />
                                </Index.IconButton>
                              </Index.Box>
                            </Index.Grid>
                            <Index.Grid item xs={12} sm={12} md={12} lg={12}>
                              <Index.Box className="lable-input-grp">
                                <Index.Typography
                                  component="p"
                                  className="in-label"
                                >
                                  Claimed Amount (દાવો કરેલ રકમ)
                                </Index.Typography>
                                <Index.TextField
                                  type="number"
                                  disabled
                                  placeholder="Enter claimed amount"
                                  className="basic-input input-width"
                                  name="claimedAmount"
                                  value={values?.claimedAmount}
                                  onChange={(event) => {
                                    const inputValue = event.target.value;
                                    if (inputValue.length <= 10) {
                                      // Only update the value if it's not more than 30 characters
                                      handleChange(event);
                                    }
                                  }}
                                  onBlur={handleBlur}
                                />
                                {/* {errors?.claimedAmount &&
                                    touched?.claimedAmount && (
                                      <p className="error-text">
                                        {errors?.claimedAmount}
                                      </p>
                                    )} */}
                              </Index.Box>
                            </Index.Grid>
                            <Index.Grid item xs={12} sm={12} md={12} lg={12}>
                              <Index.Box className="lable-input-grp">
                                <Index.Typography
                                  component="p"
                                  className="in-label"
                                >
                                  Approved Amount(મંજૂર રકમ)
                                </Index.Typography>
                                <Index.TextField
                                  type="number"
                                  placeholder="Enter approved amount"
                                  // inputProps={ariaLabel}
                                  className="basic-input input-width"
                                  name="approvedAmount"
                                  value={values?.approvedAmount}
                                  onChange={(event) => {
                                    const inputValue = event.target.value;
                                    if (
                                      Number(inputValue) <=
                                      Number(values?.claimedAmount)
                                    ) {
                                      handleChange(event);
                                    }
                                  }}
                                  onBlur={handleBlur}
                                />
                              </Index.Box>
                              {errors?.approvedAmount &&
                                touched?.approvedAmount && (
                                  <p
                                    className="error-text"
                                    style={{ marginTop: "10px" }}
                                  >
                                    {errors?.approvedAmount}
                                  </p>
                                )}
                            </Index.Grid>
                            {(subSahayType === "Educational Loan" ||
                              subSahayType === "Swawlamban") &&
                            values?.approvedAmount ? (
                              <FieldArray name="loanInstallment">
                                {({ push, remove }) => (
                                  <>
                                    {values?.loanInstallment?.map(
                                      (_, index) => (
                                        <React.Fragment key={index}>
                                          <Index.Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={6}
                                            lg={6}
                                          >
                                            <Index.Typography
                                              component="p"
                                              className="in-label"
                                            >
                                              EMI Date(EMI તારીખો)
                                            </Index.Typography>
                                            <Index.Box className="input-design-div with-border">
                                              <Index.LocalizationProvider
                                                dateAdapter={Index.AdapterDayjs}
                                                sx={{ position: "absolute" }}
                                              >
                                                <Index.DemoContainer
                                                  components={["DatePicker"]}
                                                >
                                                  <MobileDatePicker
                                                    name={`loanInstallment[${index}].EMIDate`}
                                                    onChange={(value) => {
                                                      const formattedDate =
                                                        moment(
                                                          value?.$d
                                                        ).format("YYYY-MM-DD");

                                                      setFieldValue(
                                                        `loanInstallment[${index}].EMIDate`,
                                                        formattedDate
                                                      );
                                                    }}
                                                    disablePast
                                                    value={dayjs(
                                                      values?.loanInstallment?.[
                                                        index
                                                      ]?.EMIDate
                                                    )}
                                                    format="YYYY-MM-DD"
                                                    sx={{
                                                      overflowY: "hidden",
                                                      position: "relative",
                                                      bottom: "8px",
                                                      width: "100%",
                                                    }}
                                                    slotProps={{
                                                      textField: {
                                                        size: "small",
                                                        error: false,
                                                      },
                                                    }}
                                                  />
                                                </Index.DemoContainer>
                                              </Index.LocalizationProvider>
                                            </Index.Box>
                                            {errors?.loanInstallment?.[index]
                                              ?.EMIDate &&
                                              touched.loanInstallment?.[index]
                                                ?.EMIDate && (
                                                <p
                                                  className="error-text"
                                                  style={{ marginTop: "10px" }}
                                                >
                                                  {
                                                    errors?.loanInstallment[
                                                      index
                                                    ]?.EMIDate
                                                  }
                                                </p>
                                              )}
                                          </Index.Grid>

                                          <Index.Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={4}
                                            lg={4}
                                          >
                                            <Index.Box className="lable-input-grp">
                                              <Index.Typography
                                                component="p"
                                                className="in-label"
                                              >
                                                Amount(રકમ)
                                              </Index.Typography>
                                              <Index.TextField
                                                type="number"
                                                placeholder="Enter amount"
                                                className="basic-input input-width"
                                                name={`loanInstallment[${index}].EMIAmount`}
                                                value={
                                                  values.loanInstallment[index]
                                                    ?.EMIAmount
                                                }
                                                onChange={(event) => {
                                                  const inputValue =
                                                    event.target.value;
                                                  const totalEMIAmount =
                                                    values.loanInstallment.reduce(
                                                      (acc, curr, i) => {
                                                        return (
                                                          acc +
                                                          (i === index
                                                            ? parseFloat(
                                                                inputValue
                                                              ) || 0
                                                            : parseFloat(
                                                                curr.EMIAmount
                                                              ) || 0)
                                                        );
                                                      },
                                                      0
                                                    );

                                                  // Allow input only if totalEMIAmount is less than or equal to approvedAmount
                                                  if (
                                                    totalEMIAmount <=
                                                    values.approvedAmount
                                                  ) {
                                                    handleChange(event);
                                                  }
                                                }}
                                                onBlur={handleBlur}
                                              />
                                            </Index.Box>
                                            {errors?.loanInstallment?.[index]
                                              ?.EMIAmount &&
                                              touched.loanInstallment?.[index]
                                                ?.EMIAmount && (
                                                <p
                                                  className="error-text"
                                                  style={{ marginTop: "10px" }}
                                                >
                                                  {
                                                    errors.loanInstallment[
                                                      index
                                                    ]?.EMIAmount
                                                  }
                                                </p>
                                              )}
                                          </Index.Grid>

                                          {index ===
                                            values?.loanInstallment?.length -
                                              1 &&
                                            values?.loanInstallment?.length >
                                              1 && (
                                              <Index.Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={1}
                                                lg={1}
                                                mt={3}
                                              >
                                                <Index.Box className="approved-btn">
                                                  <LightTooltip title="Minus">
                                                    <Index.Button
                                                      className="approve-btn-list"
                                                      onClick={() =>
                                                        remove(index)
                                                      }
                                                    >
                                                      <img
                                                        src={
                                                          PagesIndex.Png
                                                            .minusYello
                                                        }
                                                        className="approve-plus"
                                                      />
                                                    </Index.Button>
                                                  </LightTooltip>
                                                </Index.Box>
                                              </Index.Grid>
                                            )}
                                        </React.Fragment>
                                      )
                                    )}
                                    {values?.approvedAmount !== "" &&
                                      values?.loanInstallment?.reduce(
                                        (acc, curr) =>
                                          acc +
                                          (parseFloat(curr.EMIAmount) || 0),
                                        0
                                      ) < values.approvedAmount && (
                                        <Index.Grid
                                          item
                                          xs={12}
                                          sm={6}
                                          md={1}
                                          lg={1}
                                          mt={3}
                                        >
                                          <Index.Box className="approved-btn">
                                            <LightTooltip title="Add">
                                              <Index.Button
                                                className="approve-btn-list"
                                                onClick={() =>
                                                  push({
                                                    EMIDate: "",
                                                    EMIAmount: "",
                                                  })
                                                }
                                              >
                                                <img
                                                  src={
                                                    PagesIndex.Svg.plusyellow
                                                  }
                                                  className="approve-plus"
                                                />
                                              </Index.Button>
                                            </LightTooltip>
                                          </Index.Box>
                                        </Index.Grid>
                                      )}
                                  </>
                                )}
                              </FieldArray>
                            ) : null}
                            {typeof errors?.loanInstallment == "string" &&
                              touched?.loanInstallment && (
                                <p className="error-text">
                                  {errors?.loanInstallment}
                                </p>
                              )}
                          </Index.Grid>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="btn-submit-area">
                        <Index.Box className="common-button blue-button change-password-btn update_priofile_btn">
                          <Index.Button
                            variant="contained"
                            disableRipple
                            type="submit"
                          >
                            Submit
                          </Index.Button>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </form>
                )}
              </PagesIndex.Formik>
            </Index.Box>
          </Index.Modal>

          {/* ///////////////////////// This if for disbursement schedule /////////////////////// */}

          <Index.Modal
            open={scheduleOpen}
            onClose={handleCloseSchedule}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="modal schedule-modal-par"
          >
            <Index.Box
              sx={style}
              className="delete-modal-inner-main modal-inner approve-amount-model schedule-modal"
            >
              <PagesIndex.Formik
                enableReinitialize
                onSubmit={handleGenerateOtp}
                validationSchema={validationScheduleSchema}
                initialValues={{
                  DisbursedDate:
                    oldDisbursementSchedule?.length > 0
                      ? oldDisbursementSchedule
                      : [],
                }}
              >
                {({
                  values,
                  handleChange,
                  handleSubmit,
                  touched,
                  setFieldValue,
                  handleBlur,
                  errors,
                  isValid,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Index.Box className="remark-textarea-details">
                      <Index.Box className="set-text-area ">
                        <Index.Box
                          sx={{ flexGrow: 1 }}
                          className="al-form-groups"
                        >
                          <Index.Grid container spacing={2}>
                            <Index.Grid item xs={12} sm={12} md={12} lg={12}>
                              <Index.Box
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "end",
                                }}
                              >
                                <Index.IconButton onClick={handleCloseSchedule}>
                                  <Index.CloseIcon />
                                </Index.IconButton>
                              </Index.Box>
                            </Index.Grid>

                            <Index.Grid item xs={12} sm={12} md={12} lg={12}>
                              <Index.Typography
                                component="p"
                                className="in-label"
                              >
                                Disbursement Schedule Date(EMI તારીખો)
                              </Index.Typography>
                              <Index.Box className="input-design-div with-border">
                                <DatePicker
                                  format="YYYY-MM-DD"
                                  multiple
                                  plugins={[<DatePanel />]}
                                  editable={false}
                                  placeholder="Select date"
                                  value={values?.DisbursedDate}
                                  onChange={(e) => {
                                    setFieldValue("DisbursedDate", e);
                                  }}
                                />
                                <Index.FormHelperText error>
                                  {touched?.DisbursedDate && errors?.DisbursedDate 
                                    }
                                </Index.FormHelperText>
                              </Index.Box>
                            </Index.Grid>
                          </Index.Grid>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="btn-submit-area">
                        <Index.Box className="d-flex">
                          <Index.Box className="common-button blue-button change-password-btn update_priofile_btn">
                            <Index.Button
                              variant="contained"
                              disableRipple
                              onClick={() => {
                                handleGenerateOtp();
                              }}
                            >
                              Skip
                            </Index.Button>
                          </Index.Box>

                          <Index.Box className="common-button blue-button change-password-btn update_priofile_btn">
                            <Index.Button
                              variant="contained"
                              disableRipple
                              type="submit"
                            >
                              Submit
                            </Index.Button>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </form>
                )}
              </PagesIndex.Formik>
            </Index.Box>
          </Index.Modal>

          {/* //#region for otp apporve modal /////////////// */}

          <Index.Modal
            open={otpOpen}
            onClose={handleCloseOtp}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="modal-delete modal"
          >
            <Index.Box
              sx={style}
              className="delete-modal-inner-main modal-inner approve-amount-model"
            >
              <PagesIndex.Formik
                enableReinitialize
                onSubmit={handleVerifyOtp}
                validationSchema={validationSchemaVerifyCommitteOTP}
                initialValues={{
                  OTP: "",
                }}
              >
                {({
                  values,
                  handleChange,
                  handleSubmit,
                  touched,
                  setFieldValue,
                  handleBlur,
                  errors,
                  isValid,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Index.Box
                      className={`${
                        subSahayType == "" ? "remark-textarea-details" : ""
                      }`}
                    >
                      <Index.Box className="set-text-area ">
                        <Index.Box
                          sx={{ flexGrow: 1 }}
                          className="al-form-groups"
                        >
                          <Index.Grid container spacing={2}>
                            <Index.Grid item xs={12} sm={12} md={12} lg={12}>
                              <Index.Box
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "end",
                                }}
                              >
                                <Index.IconButton onClick={handleCloseOtp}>
                                  <Index.CloseIcon />
                                </Index.IconButton>
                              </Index.Box>
                            </Index.Grid>

                            <Index.Grid item xs={12} sm={12} md={12} lg={12}>
                              <Index.Box className="lable-input-grp">
                                <Index.Box className="box-login-text bluebox-text text-center">
                                  <Index.Typography
                                    variant="body1"
                                    component="p"
                                    className=""
                                  >
                                    Please Enter Otp.
                                  </Index.Typography>
                                </Index.Box>
                                <OTPInput
                                  name="otp"
                                  numInputs={4}
                                  inputStyle="otp-verification-input"
                                  containerStyle="otp-verification-input-wrap"
                                  inputType="number"
                                  value={values.OTP}
                                  onChange={(file) =>
                                    setFieldValue("OTP", +file)
                                  }
                                  // error={Boolean(errors.otp)}
                                  renderInput={(props) => <input {...props} />}
                                />
                                {errors?.OTP && touched?.OTP && (
                                  <p
                                    className="error-text"
                                    style={{ marginTop: "10px" }}
                                  >
                                    {errors?.OTP}
                                  </p>
                                )}
                              </Index.Box>
                            </Index.Grid>
                          </Index.Grid>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="btn-submit-area">
                        <Index.Box className="common-button blue-button change-password-btn update_priofile_btn">
                          <Index.Button
                            variant="contained"
                            disableRipple
                            type="submit"
                          >
                            Submit
                          </Index.Button>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </form>
                )}
              </PagesIndex.Formik>
            </Index.Box>
          </Index.Modal>

          {/* /////////////////////// THis is for the Error message ///////////////// */}

          <Index.Modal
            fullWidth
            open={openErrorModal}
            onClose={handleCloseErrorModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="modal-delete modal"
          >
            <Index.Box
              sx={style}
              className="delete-modal-inner-main modal-inner"
            >
              <Index.Box className="remark-textarea-details">
                <Index.Box className="set-text-area ">
                  <Index.Typography
                    variant="label"
                    component="label"
                    className="input-label"
                    sx={{ color: "red" }}
                  >
                    {saveErrorData.length > 0
                      ? "This Required fields are not found in CSV sheet"
                      : ""}
                  </Index.Typography>
                  <Index.Typography
                    variant="label"
                    component="label"
                    className="input-label"
                  >
                    {saveErrorCountData.length > 0 ? (
                      <ul>
                        {saveErrorCountData &&
                          saveErrorCountData.map((error, index) => (
                            <li key={index}>
                              {error + 1} Row Application Have Wrong Data
                            </li>
                          ))}
                      </ul>
                    ) : (
                      <ul>
                        {saveErrorData &&
                          saveErrorData.map((error, index) => (
                            <li key={index} style={{ marginBottom: "10px" }}>
                              {error}.
                            </li>
                          ))}
                      </ul>
                    )}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Modal>

          {/* ////////////////////////////////////////////////////////////////////////////// */}

          {/* staff remark modal */}
          <ReasonModal
            open={openStaffRemark}
            handleClose={handleCloseStaffRemark}
            handleComplete={handleSahayApplicationStatus}
            reason={staffRemark}
            setReason={setStaffRemark}
            isDisabled={btnLoading}
          />

          {/* upload document  */}

          <Index.Modal
            open={uploadOpen}
            onClose={handleCloseUploadModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="modal-delete modal"
          >
            <Index.Box
              sx={style}
              className="delete-modal-inner-main modal-inner approve-amount-model"
            >
              <PagesIndex.Formik
                enableReinitialize
                onSubmit={handleUploadSubmit}
                validationSchema={uploadLoanDocSchema}
                initialValues={{
                  loanDoc: null,
                }}
              >
                {({
                  values,
                  handleChange,
                  handleSubmit,
                  touched,
                  setFieldValue,
                  handleBlur,
                  errors,
                  isValid,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Index.Box
                      className={`${
                        subSahayType == "" ? "remark-textarea-details" : ""
                      }`}
                    >
                      <Index.Box className="set-text-area ">
                        <Index.Box
                          sx={{ flexGrow: 1 }}
                          className="al-form-groups"
                        >
                          <Index.Grid container spacing={2}>
                            <Index.Grid item xs={12} sm={12} md={12} lg={12}>
                              <Index.Box
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "end",
                                }}
                              >
                                <Index.IconButton
                                  onClick={handleCloseUploadModal}
                                >
                                  <Index.CloseIcon />
                                </Index.IconButton>
                              </Index.Box>
                            </Index.Grid>
                            <Index.Grid item xs={12} sm={12} md={12} lg={12}>
                              <Index.Box className="lable-input-grp">
                                <Index.Typography
                                  component="p"
                                  className="in-label"
                                >
                                  Upload Document
                                </Index.Typography>
                                <Index.Box className="select-position">
                                  <input
                                    type="file"
                                    name="loanDoc"
                                    className="ori-input"
                                    onChange={(event) => {
                                      const file = event.target.files[0];
                                      setFieldValue("loanDoc", file);
                                    }}
                                  />

                                  <Index.Box className="select-file">
                                    Upload Your Document
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                              {errors?.loanDoc && touched?.loanDoc && (
                                <p
                                  className="error-text"
                                  style={{ marginTop: "10px" }}
                                >
                                  {errors?.loanDoc}
                                </p>
                              )}
                            </Index.Grid>

                            <Index.Grid item xs={8}>
                              <Index.Box className="select-view-document-name remove-margin-upload">
                                {values?.loanDoc && (
                                  <Index.Box className="document-name">
                                    <Index.Typography
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        window.open(
                                          values.document instanceof Blob
                                            ? URL.createObjectURL(
                                                values.loanDoc
                                              )
                                            : `${imagePath}${values.loanDoc.name}`
                                        )
                                      }
                                    >
                                      {values.loanDoc.name}
                                    </Index.Typography>
                                    <Index.IconButton
                                      onClick={() =>
                                        setFieldValue("loanDoc", null)
                                      }
                                    >
                                      <Index.ClearIcon className="muiico-cancel" />
                                    </Index.IconButton>
                                  </Index.Box>
                                )}
                              </Index.Box>
                            </Index.Grid>
                          </Index.Grid>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="btn-submit-area">
                        <Index.Box className="common-button blue-button change-password-btn update_priofile_btn">
                          <Index.Button
                            variant="contained"
                            disableRipple
                            type="submit"
                          >
                            Submit
                          </Index.Button>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </form>
                )}
              </PagesIndex.Formik>
            </Index.Box>
          </Index.Modal>
        </>
      )}
    </>
  );
};

export default SahayApplications;
