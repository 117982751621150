import React, { useEffect, useState } from "react";
import Index from "../../../../container/Index";
import PagesIndex from "../../../PagesIndex";
import { Tooltip, tooltipClasses } from "@mui/material";
import moment from "moment";
import dayjs from "dayjs";
import {
  editSingleDisbursementData,
  addBillDisbursement,
  getSingleDisbursedBill,
  editBillDisbursement,
} from "../../../../redux/features/AddService";
import { Api } from "../../../../config/Api";
import dataService, { imagePath } from "../../../../config/DataService";
import { toast } from "react-toastify";
import PageLoading from "../../../../components/common/pageLoading/PageLoading";
import { useSelector } from "react-redux";
import { MobileDatePicker } from "@mui/x-date-pickers";
import styled from "styled-components";
import { FieldArray } from "formik";
import { validationSchemaDisbursementBill } from "../../../../validation/Validation";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: "#eaac35",
    color: "#ffffff",
    fontSize: "11px",
    fontFamily: "Poppins-Regular",
    padding: "5px 9px",
    lineHeight: "19px",
    borderRadius: "10px",
  },
}));

const ariaLabel = { "aria-label": "description" };

const AddEditBillDisbursement = () => {
  const dispatch = PagesIndex.useDispatch();
  const navigate = PagesIndex.useNavigate();
  const location = PagesIndex.useLocation();

  const isEdit = location?.state?.isEdit;
  const editDisbursement = location?.state?.billForDisbursement;
  const rollData = JSON.parse(localStorage?.getItem("RollName"))?.toLowerCase();
  const [pageLoader, setPageLoader] = useState(false);
  const [isCompleted, setIsCompleted] = useState(0);
  const [singleBillDisburseData, setSingleBillDisburseData] = useState("");

  ///// Previous Sahay Data

  const initialValues = {
    chequeNumber: isEdit
      ? singleBillDisburseData?.BillDisbursedDetails?.chequeNumber
      : "",
    chequeDate: isEdit
      ? singleBillDisburseData?.BillDisbursedDetails?.chequeDate
      : "",
    documents: isEdit ? singleBillDisburseData?.BillDisbursedDocuments : [],
    isSubmit: false,
    payAmount: singleBillDisburseData?.ProgramBill?.totalAmount || "",
    partialAmount:
      isEdit && singleBillDisburseData?.BillDisbursedDetails
        ? JSON.parse(
            singleBillDisburseData?.BillDisbursedDetails?.disbursedInstallments
          )?.map((row) => {
            return {
              ...row,
              isNewRow: false,
            };
          })
        : [
            {
              disbursedAmount: "",
              pendingAmount:
                singleBillDisburseData?.ProgramBill?.totalAmount || "",
              disbursedDate: new Date(),
              isNewRow: true,
            },
          ],
  };

  const handleBack = () => {
    navigate(`/${rollData}/disbursed-bill-list`, {
      state: {
        rowID: location?.state?.billForDisbursement?.Id,
        prevState: location?.state?.billForDisbursement?.lastStatus,
      },
    });
  };

  const handleFormSubmit = (values, { setFieldValue }) => {
    setFieldValue("isSubmit", true);
    const data = new FormData();

    if (isEdit) {
      data.append("id", +singleBillDisburseData?.BillDisbursedDetails?.id);
    }

    data.append("programBillId", +editDisbursement?.Id);
    data.append("chequeNumber", values?.chequeNumber);
    data.append("chequeDate", values?.chequeDate);

    data.append("payAmount", values?.payAmount);

    values?.partialAmount?.forEach((amount, index) => {
      data.append(
        `disbursedInstallments[${index}][pendingAmount]`,
        amount?.pendingAmount
      );
      data.append(
        `disbursedInstallments[${index}][disbursedAmount]`,
        amount?.disbursedAmount
      );
      data.append(
        `disbursedInstallments[${index}][disbursedDate]`,
        moment(amount?.disbursedDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
      );
    });

    values?.documents?.length > 0 &&
      values?.documents?.forEach((ele) => {
        if (ele instanceof Blob) {
          data.append("documents", ele);
        }
      });

    values?.documents?.length > 0 &&
      values?.documents?.forEach((ele) => {
        if (ele instanceof Blob) {
        } else {
          data.append("oldDocuments", ele?.Documents);
        }
      });
    if (isEdit) {
      dispatch(editBillDisbursement(data)).then((res) => {
        if (
          (res?.payload !== undefined && res?.payload?.status == 200) ||
          201
        ) {
          navigate(`/${rollData}/disbursed-bill-list`);
        } else {
          setTimeout(() => {
            setFieldValue("isSubmit", false);
          }, 1000);
        }
      });
    } else {
      dispatch(addBillDisbursement(data)).then((res) => {
        if (res?.payload?.status == 201) {
          navigate(`/${rollData}/disbursed-bill-list`);
        } else {
          setTimeout(() => {
            setFieldValue("isSubmit", false);
          }, 1000);
        }
      });
    }
  };

  const getSingleDisburseData = () => {
    setPageLoader(true);
    dispatch(getSingleDisbursedBill(editDisbursement?.Id)).then((res) => {
      if (res?.payload?.status == 200) {
        setSingleBillDisburseData(res?.payload?.data);
        setIsCompleted(res?.payload?.data?.ProgramBill?.isBillDisbursed);
        setPageLoader(false);
      }
    });
  };

  useEffect(() => {
    getSingleDisburseData();
  }, []);

  const { rollPermissionData } = useSelector((state) => state.admin);
  const rollPermissionNameData =
    rollPermissionData?.findData?.parentRoleName?.toLowerCase();

  if (
    rollPermissionData?.permissionArray?.includes("Disbursement_add") ||
    rollPermissionData?.permissionArray?.includes("Disbursement_edit")
  ) {
  } else {
    navigate(`/${rollData}/sahay-application`);
  }

  return (
    <>
      <div>
        {pageLoader ? (
          <PageLoading />
        ) : (
          <>
            <Index.Box className="apply-sahay-main">
              <Index.Box className="head-content">
                <Index.Box
                  className={`user-right ${
                    location.pathname == "/admin/add-edit-bill-disbursement"
                      ? "admin-border-radius"
                      : ""
                  }`}
                >
                  <Index.Box
                    className={`bottom-user-right ${
                      location.pathname == "/admin/add-edit-bill-disbursement"
                        ? "admin-height-remove"
                        : ""
                    }`}
                  >
                    <Index.Box className="user-apply-content">
                      <PagesIndex.Formik
                        enableReinitialize
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={validationSchemaDisbursementBill}
                      >
                        {({
                          values,
                          handleChange,
                          handleSubmit,
                          touched,
                          setFieldValue,
                          handleBlur,
                          errors,
                          isValid,
                          isSubmitting,
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <Index.Box className="main-in-row">
                              <Index.Box className="user-basic user-heading">
                                <Index.Typography
                                  component="h1"
                                  className="head-apply"
                                >
                                  {isEdit
                                    ? "Edit Bill disbursement"
                                    : "Apply for Bill disbursement"}
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className="main-in-row">
                              <Index.Box
                                className="user-basic"
                                sx={{ display: "flex" }}
                              ></Index.Box>
                              <Index.Box
                                sx={{ flexGrow: 1 }}
                                className="al-form-groups"
                              >
                                <Index.Grid container spacing={2}>
                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        Bill Number (બિલ નંબર)
                                      </Index.Typography>
                                      <Index.TextField
                                        disabled
                                        inputProps={ariaLabel}
                                        className="basic-input input-width"
                                        name="familyId"
                                        value={
                                          singleBillDisburseData?.ProgramBill
                                            ?.billNumber
                                        }
                                      />
                                    </Index.Box>
                                  </Index.Grid>
                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        Vendor Name (વિક્રેતાનું નામ)
                                      </Index.Typography>
                                      <Index.TextField
                                        disabled
                                        type="text"
                                        inputProps={ariaLabel}
                                        className="basic-input input-width"
                                        name="userName"
                                        value={
                                          singleBillDisburseData?.ProgramBill
                                            ?.vendorName
                                        }
                                      />
                                    </Index.Box>
                                  </Index.Grid>

                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        Cheque No. (ચેક નં.)
                                      </Index.Typography>
                                      <Index.TextField
                                        inputProps={ariaLabel}
                                        className="basic-input input-width"
                                        name="chequeNumber"
                                        value={values?.chequeNumber}
                                        onChange={(e) => {
                                          if (e.target.value.length <= 10) {
                                            handleChange(e);
                                          }
                                        }}
                                        onBlur={handleBlur}
                                      />
                                    </Index.Box>
                                    {errors?.chequeNumber &&
                                      touched?.chequeNumber && (
                                        <p className="error-text">
                                          {errors?.chequeNumber}
                                        </p>
                                      )}
                                  </Index.Grid>

                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Typography
                                      component="p"
                                      className="in-label"
                                    >
                                      Cheque Date (તારીખ તપાસો)
                                    </Index.Typography>
                                    <Index.Box className="input-design-div with-border ">
                                      <Index.LocalizationProvider
                                        dateAdapter={Index.AdapterDayjs}
                                        sx={{ position: "absolute" }}
                                      >
                                        <Index.DemoContainer
                                          components={["DatePicker"]}
                                        >
                                          <MobileDatePicker
                                            name="chequeDate"
                                            onChange={(value) => {
                                              setFieldValue(
                                                "chequeDate",
                                                moment(value.$d).format(
                                                  "YYYY-MM-DD"
                                                )
                                              );
                                            }}
                                            value={dayjs(values?.chequeDate)}
                                            format="YYYY-MM-DD"
                                            sx={{
                                              overflowY: "hidden",
                                              position: "relative",
                                              bottom: "8px",
                                              width: "100%",
                                            }}
                                            slotProps={{
                                              textField: {
                                                size: "small",
                                                error: false,
                                              },
                                            }}
                                          />
                                        </Index.DemoContainer>
                                      </Index.LocalizationProvider>
                                    </Index.Box>
                                    {errors?.chequeDate &&
                                      touched.chequeDate && (
                                        <p className="error-text">
                                          {errors?.chequeDate}
                                        </p>
                                      )}
                                  </Index.Grid>

                                  <>
                                    <Index.Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      md={4}
                                      lg={3}
                                      mb={2}
                                    >
                                      <Index.Box className="lable-input-grp">
                                        <Index.Typography
                                          component="p"
                                          className="in-label"
                                        >
                                          Bill Amount (બિલની રકમ)
                                        </Index.Typography>
                                        <Index.TextField
                                          disabled
                                          type="number"
                                          inputProps={ariaLabel}
                                          className="basic-input input-width"
                                          name="payAmount"
                                          value={values?.payAmount}
                                        />
                                      </Index.Box>
                                    </Index.Grid>

                                    <FieldArray
                                      name="partialAmount"
                                      render={({ push, remove }) => {
                                        return (
                                          <>
                                            {values?.partialAmount?.map(
                                              (amount, index) => (
                                                <Index.Grid
                                                  item
                                                  xs={12}
                                                  sm={6}
                                                  md={4}
                                                  lg={4}
                                                  mb={2}
                                                  key={index}
                                                >
                                                  <Index.Grid
                                                    container
                                                    spacing={2}
                                                  >
                                                    {/* Disbursed Amount */}
                                                    <Index.Grid
                                                      item
                                                      xs={12}
                                                      sm={6}
                                                      md={4}
                                                      lg={5}
                                                      mb={2}
                                                    >
                                                      <Index.Box className="label-input-grp">
                                                        <Index.Typography
                                                          component="p"
                                                          className="in-label"
                                                        >
                                                          Disbursed Amount
                                                          (વિતરિત રકમ)
                                                        </Index.Typography>

                                                        <Index.TextField
                                                          type="number"
                                                          inputProps={{
                                                            ...ariaLabel,
                                                            disabled:
                                                              amount?.isNewRow ==
                                                              false,
                                                          }}
                                                          className="basic-input input-width"
                                                          name={`partialAmount[${index}].disbursedAmount`}
                                                          value={
                                                            amount?.disbursedAmount
                                                          }
                                                          onChange={(e) => {
                                                            // Calculate the new value from the input
                                                            const newValue =
                                                              parseFloat(
                                                                e.target.value
                                                              ) || 0;

                                                            // Calculate the pending amount dynamically
                                                            const previousPendingAmount =
                                                              index > 0
                                                                ? parseFloat(
                                                                    values
                                                                      .partialAmount[
                                                                      index - 1
                                                                    ]
                                                                      .pendingAmount
                                                                  ) || 0
                                                                : parseFloat(
                                                                    values?.payAmount ||
                                                                      0
                                                                  );

                                                            const newPendingAmount =
                                                              previousPendingAmount -
                                                              newValue;

                                                            // If pendingAmount becomes less than or equal to 0, stop updating the value
                                                            if (
                                                              newPendingAmount <
                                                              0
                                                            ) {
                                                              return; // Stop updating if pendingAmount reaches 0 or less
                                                            }

                                                            // Otherwise, update the disbursedAmount and pendingAmount
                                                            setFieldValue(
                                                              `partialAmount[${index}].disbursedAmount`,
                                                              newValue
                                                            );
                                                            setFieldValue(
                                                              `partialAmount[${index}].pendingAmount`,
                                                              newPendingAmount
                                                            );
                                                          }}
                                                        />
                                                      </Index.Box>
                                                    </Index.Grid>

                                                    {/* Pending Amount */}
                                                    <Index.Grid
                                                      item
                                                      xs={12}
                                                      sm={6}
                                                      md={4}
                                                      lg={5}
                                                      mb={2}
                                                    >
                                                      <Index.Box className="label-input-grp">
                                                        <Index.Typography
                                                          component="p"
                                                          className="in-label"
                                                        >
                                                          Pending Amount (બાકી
                                                          રકમ)
                                                        </Index.Typography>
                                                        <Index.TextField
                                                          disabled
                                                          type="number"
                                                          inputProps={ariaLabel}
                                                          className="basic-input input-width"
                                                          name={`partialAmount[${index}].pendingAmount`}
                                                          value={
                                                            amount?.pendingAmount
                                                          }
                                                        />
                                                      </Index.Box>
                                                    </Index.Grid>

                                                    {/* Add Button */}
                                                    {+amount?.pendingAmount !==
                                                      0 &&
                                                      index ===
                                                        values.partialAmount
                                                          .length -
                                                          1 &&
                                                      isCompleted == 0 &&
                                                      isEdit &&
                                                      !amount?.isNewRow && (
                                                        <Index.Grid
                                                          item
                                                          xs={12}
                                                          sm={6}
                                                          md={4}
                                                          lg={1}
                                                          mb={2}
                                                          mt={3}
                                                        >
                                                          <Index.Box className="approved-btn">
                                                            <LightTooltip title="Add">
                                                              <Index.Button
                                                                className="approve-btn-list"
                                                                onClick={() => {
                                                                  const previousPendingAmount =
                                                                    parseFloat(
                                                                      values
                                                                        .partialAmount[
                                                                        index
                                                                      ]
                                                                        .pendingAmount
                                                                    ) || 0;

                                                                  push({
                                                                    disbursedAmount:
                                                                      "",
                                                                    pendingAmount:
                                                                      previousPendingAmount,
                                                                    isNewRow: true,
                                                                    disbursedDate:
                                                                      new Date(),
                                                                  });
                                                                }}
                                                              >
                                                                <img
                                                                  src={
                                                                    PagesIndex
                                                                      .Svg
                                                                      .plusyellow
                                                                  }
                                                                  className="approve-plus"
                                                                />
                                                              </Index.Button>
                                                            </LightTooltip>
                                                          </Index.Box>
                                                        </Index.Grid>
                                                      )}

                                                    {/* Remove Button */}
                                                    {/* Remove Button */}
                                                    {index ===
                                                      values.partialAmount
                                                        .length -
                                                        1 && // Check if it's the last index
                                                      values.partialAmount
                                                        .length > 1 && // Ensure there's more than 1 entry
                                                      isEdit && // Edit mode must be active
                                                      amount?.isNewRow && // It must be a new row
                                                      parseFloat(
                                                        amount?.pendingAmount
                                                      ) !== 0 && ( // Pending amount should not be zero
                                                        <Index.Grid
                                                          item
                                                          xs={12}
                                                          sm={6}
                                                          md={4}
                                                          lg={1}
                                                          mb={2}
                                                          mt={3}
                                                        >
                                                          <Index.Box className="approved-btn">
                                                            <LightTooltip title="Minus">
                                                              <Index.Button
                                                                className="approve-btn-list"
                                                                onClick={() => {
                                                                  remove(index);
                                                                }}
                                                              >
                                                                <img
                                                                  src={
                                                                    PagesIndex
                                                                      .Png
                                                                      .minusYello
                                                                  }
                                                                  className="approve-plus"
                                                                />
                                                              </Index.Button>
                                                            </LightTooltip>
                                                          </Index.Box>
                                                        </Index.Grid>
                                                      )}
                                                  </Index.Grid>
                                                </Index.Grid>
                                              )
                                            )}
                                          </>
                                        );
                                      }}
                                    />
                                  </>
                                </Index.Grid>
                              </Index.Box>
                            </Index.Box>

                            <Index.Box
                              sx={{ flexGrow: 1, marginTop: "15px" }}
                              className="al-form-groups fam-in-grp multiple-doc mt-0px-sahay{"
                            >
                              <Index.Grid container spacing={2}>
                                <Index.Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  md={4}
                                  lg={4}
                                  mb={2}
                                >
                                  <Index.Box className="lable-input-grp">
                                    <Index.Typography
                                      component="p"
                                      className="in-label"
                                    >
                                      Add Multiple Document (બહુવિધ દસ્તાવેજ
                                      ઉમેરો)
                                    </Index.Typography>
                                    <Index.Box className="select-position">
                                      <input
                                        type="file"
                                        name="documents"
                                        multiple
                                        className="ori-input"
                                        onChange={(event) => {
                                          const files = event.target.files;
                                          setFieldValue("documents", [
                                            ...values?.documents,
                                            ...files,
                                          ]);
                                        }}
                                      />

                                      <Index.Box className="select-file">
                                        Upload Your Document
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Grid>

                                <Index.Grid item xs={8}>
                                  <Index.Box className="select-view-document-name">
                                    {values?.documents?.map((file, index) => (
                                      <Index.Box
                                        className="document-name"
                                        key={index}
                                      >
                                        <Index.Typography
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            // Implement the logic to view the document here
                                            // You can use a modal or a new browser tab/window to show the document
                                            window.open(
                                              file instanceof Blob
                                                ? URL.createObjectURL(file)
                                                : `${imagePath}${file?.Documents}`
                                            );
                                          }}
                                        >
                                          {file?.name
                                            ? file?.name
                                            : file?.Filename}
                                        </Index.Typography>
                                        <Index.IconButton
                                          onClick={() => {
                                            const updatedDocuments =
                                              values.documents.filter(
                                                (_, i) => i !== index
                                              );
                                            setFieldValue(
                                              "documents",
                                              updatedDocuments
                                            );
                                          }}
                                        >
                                          <Index.ClearIcon className="muiico-cancel" />
                                        </Index.IconButton>
                                      </Index.Box>
                                    ))}
                                  </Index.Box>
                                </Index.Grid>
                              </Index.Grid>
                            </Index.Box>

                            <Index.Box
                              sx={{ flexGrow: 1 }}
                              className="al-form-groups fam-in-grp"
                            >
                              <Index.Grid item xs={6}>
                                <Index.Box className="common-button blue-button flex-end save-btn back-btn">
                                  <Index.Button
                                    variant="contained"
                                    onClick={() => handleBack()}
                                  >
                                    Back
                                  </Index.Button>
                                </Index.Box>
                              </Index.Grid>
                              <Index.Grid container spacing={2}>
                                <Index.Grid item xs={6}>
                                  <Index.Box className="lable-input-grp ">
                                    <Index.Box className="select-position">
                                      <Index.Box
                                        className={
                                          values.isSubmit === false
                                            ? "apply-now"
                                            : "disable-now"
                                        }
                                      >
                                        <button
                                          type="submit"
                                          disabled={values.isSubmit}
                                        >
                                          {isEdit !== true
                                            ? "Submit"
                                            : "Update"}
                                        </button>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Grid>
                              </Index.Grid>
                            </Index.Box>
                          </form>
                        )}
                      </PagesIndex.Formik>

                      <Index.Box className="title-header-flex "></Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </>
        )}
      </div>
    </>
  );
};

export default AddEditBillDisbursement;
