import React, { useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import Pagination from "../../../../components/common/Pagination";
import PagesIndex from "../../../PagesIndex";
import { getEscReportData } from "../../../../redux/features/AddService";
import { useSelector } from "react-redux";
import PageLoading from "../../../../components/common/pageLoading/PageLoading";
import FileExcel from "../../../../components/common/excelSheet/FileExcel";
import { Autocomplete } from "@mui/material";
import RowPerPageDropdown from "../../../../components/common/paginationRowPerpage/RowPerPageDropdown";
import moment from "moment";
import dayjs from "dayjs";
import dataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import PrintReport from "../../../../components/common/printReport/PrintReport";
import { useReactToPrint } from "react-to-print";

export default function EscReport() {
  const location = PagesIndex.useLocation()
  const { getEscReportDataList } = PagesIndex.useSelector(
    (state) => state?.admin
  );
  const fileName = "ECS report CSV";
  const [excel, setExcel] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [rowPerPage, setRowPerPage] = useState("25");
  const dispatch = PagesIndex.useDispatch();
  const [filter, setFilter] = useState("");
  const [checkDateFilter, setCheckDateFilter] = useState("");
  const [bankNameFilter, setBankNameFilter] = useState("");
  const [subSahayFilter, setSubSahayFilter] = useState("");
  const [searchedData, setSearchedData] = useState([]);
  const [sahaySubTypeList, setSahaySubTypeList] = useState([]);
  const [filterReportData, setFilterReportData] = useState([]);

  // Print Reports
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    dispatch(getEscReportData()).then((res) => {
      if (res?.payload?.status == 200) {
        setLoader(false);
      }
      setLoader(false);
    });
  }, [checkDateFilter, bankNameFilter, subSahayFilter]);

  //Filter Data
  // useEffect(() => {
  //   if (checkDateFilter || bankNameFilter || subSahayFilter) {
  //     const filterData = getEscReportDataList?.data?.data?.filter((ele) => {
  //       if (checkDateFilter && bankNameFilter && subSahayFilter) {
  //         return (
  //           moment(ele?.Date).format("YYYY-MM-DD") === checkDateFilter &&
  //           ele?.BankName === bankNameFilter &&
  //           ele?.SubSahayName === subSahayFilter
  //         );
  //       } else if (checkDateFilter && bankNameFilter) {
  //         return (
  //           moment(ele?.Date).format("YYYY-MM-DD") === checkDateFilter &&
  //           ele?.BankName === bankNameFilter
  //         );
  //       } else if (checkDateFilter && subSahayFilter) {
  //         return (
  //           moment(ele?.Date).format("YYYY-MM-DD") === checkDateFilter &&
  //           ele?.SubSahayName === subSahayFilter
  //         );
  //       } else if (bankNameFilter && subSahayFilter) {
  //         return (
  //           ele?.BankName === bankNameFilter &&
  //           ele?.SubSahayName === subSahayFilter
  //         );
  //       } else if (checkDateFilter || bankNameFilter || subSahayFilter) {
  //         return (
  //           moment(ele?.Date).format("YYYY-MM-DD") === checkDateFilter ||
  //           ele?.BankName === bankNameFilter ||
  //           ele?.SubSahayName === subSahayFilter
  //         );
  //       } else {
  //         return [];
  //       }
  //     });
  //     setFilterReportData(filterData);
  //   } else {
  //     setFilterReportData([]);
  //   }
  // }, [checkDateFilter, bankNameFilter, subSahayFilter]);

  useEffect(() => {
    const filters = {
      SubSahayName: subSahayFilter,
      BankName: bankNameFilter,
      // Assuming checkDateFilter is a Date object
      Date: checkDateFilter ? moment(checkDateFilter).format("YYYY-MM-DD") : null,
    };

    const filterData = getEscReportDataList?.data?.data?.filter((ele) => {
      if (Object.values(filters).some(Boolean)) {
        return Object.entries(filters).every(([key, value]) => {
          if (!value) return true;
          if (key === 'Date') {
            // Assuming ele.Date is a Date object
            return moment(ele.Date).format("YYYY-MM-DD") === value;
          }
          return ele[key] === value;
        });
      } else {
        return true;
      }
    });

    setFilterReportData(filterData);
  }, [checkDateFilter, bankNameFilter, subSahayFilter]);

  // Copy original data to searched data
  useEffect(() => {
    setSearchedData(filterReportData);
  }, [filterReportData]);

  // Search on table
  const requestSearch = (searched) => {
    if (!searched) return setSearchedData(filterReportData);
    setSearchedData(
      filterReportData?.filter((item) =>
        item?.BankName?.toString()
          ?.toLowerCase()
          .includes(searched?.toLowerCase())
      )
    );
  };

  useEffect(() => {
    if (filterReportData?.length > 0) {
      const customHeadings = filterReportData?.map((item) => ({
        FID: item?.FID,
        "Applicant Name": item?.ApplicantName,
        "SubSahay Name": item?.SubSahayName,
        "Area Name": item?.Area,
        "Sanction Amount": item?.SanctionAmt,
        "Disbursement Bank Name": item?.BankName,
        "Date of Disbursement": moment(item?.CreatedDate).format("YYYY-MM-DD"),
        "Applicant Bank Name": item?.ApplicantBankName,
        "Branch Name": item?.BranchName,
        "Account No": item?.AccountNo,
        "IFSC Code": item?.IFSCCode,
      }));

      setExcel(customHeadings);
    }
  }, [filterReportData]);

  //get Sub sahay list
  const getSahaySubTypeData = async () => {
    try {
      const res = await dataService.get(Api.GET_ALL_SUB_SAHAY_LIST);
      if (res?.data?.status) {
        setSahaySubTypeList(res?.data?.Data);
      } else {
        setSahaySubTypeList([]);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getSahaySubTypeData();
  }, []);

    //#region start view sahay application

    const handleSahayApplicationView = (viewdata) => {
      const updatedViewData = {
        ...viewdata,
        familyId: viewdata?.FID,   
        prevRoute: location?.pathname,
      };
      navigate(`/${rollData}/sahay-report-view`, { state: updatedViewData });
    };
  
  
    //#region end view sahay application

  const { rollPermissionData } = useSelector((state) => state.admin);

  const rollData = JSON.parse(localStorage?.getItem("RollName"))?.toLowerCase();

  const navigate = PagesIndex.useNavigate();
  const rollPermissionNameData =
    rollPermissionData?.findData?.parentRoleName?.toLowerCase();

  if (
    rollPermissionData?.permissionArray?.includes("Ecs Report_view") ||
    rollPermissionNameData === "admin"
  ) {
  } else {
    navigate(`/${rollData}/sahay-application`);
  }

  return (
    <>
      {loader ? (
        <PageLoading />
      ) : (
        <>
          <Index.Box className="page-content-box">
            <Index.Box className="barge-common-box">
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex res-title-header-flex">
                  <Index.Box className="title-main">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="page-title"
                    >
                      ECS Report
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="grid-row  sahay-multiple-main">
                <Index.Box sx={{ width: 1 }} className="grid-main">
                  <Index.Box
                    display="grid"
                    className="app-report-row"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 4",
                        lg: "span 4",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="sahay-multiple-input-main">
                        <PagesIndex.Search
                          className="search sahay-multiple-search"
                          onChange={(e) => requestSearch(e.target.value)}
                        >
                          <PagesIndex.StyledInputBase placeholder="Search" />
                        </PagesIndex.Search>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 4",
                        lg: "span 4",
                      }}
                      className="grid-column"
                    >
                      {/* <Index.LocalizationProvider
                        dateAdapter={Index.AdapterDayjs}
                        sx={{ position: "absolute" }}
                      >
                        <Index.DemoContainer components={["DatePicker"]}>
                          <Index.DatePicker
                            onChange={(value) => {
                              setCheckDateFilter(value?.format("YYYY-MM-DD"));
                            }}
                            value={dayjs(checkDateFilter)}
                            format="DD-MM-YYYY"
                            disableFuture={true}
                            sx={{
                              overflowY: "hidden",

                              position: "relative",
                              bottom: "8px",
                            }}
                            slotProps={{
                              textField: {
                                size: "small",
                                error: false,
                              },
                            }}
                          />
                        </Index.DemoContainer>
                      </Index.LocalizationProvider> */}
                      {/* <Index.Box
                        className="input-design-div with-border "
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      > */}
                      <Index.Box className="input-design-div with-border ">
                        <Index.LocalizationProvider
                          dateAdapter={Index.AdapterDayjs}
                          sx={{ position: "absolute" }}
                        >
                          <Index.DemoContainer components={["DatePicker"]}>
                            <Index.DatePicker
                              onChange={(value) => {
                             
                                setCheckDateFilter(value?.format("YYYY-MM-DD"));
                              }}
                              value={dayjs(checkDateFilter)}
                              format="DD-MM-YYYY"
                              disableFuture={true}
                              sx={{
                                overflowY: "hidden",

                                position: "relative",
                                bottom: "8px",
                              }}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  error: false,
                                },
                              }}
                            />
                          </Index.DemoContainer>
                        </Index.LocalizationProvider>
                      </Index.Box>
                      {/* <Index.IconButton
                          onClick={() => setCheckDateFilter("")}
                        >
                          <img
                            src={PagesIndex.Svg.close}
                            width={20}
                            height={20}
                          />
                        </Index.IconButton>
                      </Index.Box> */}
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 4",
                        lg: "span 4",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="sahay-multiple-input-main">
                        <Autocomplete
                          disablePortal
                          className="multiple-autocomplet-control"
                          id="combo-box-demo"
                          options={getEscReportDataList?.data?.data || []}
                          getOptionLabel={(option) => option.BankName}
                          onChange={(value, newvalue) => {
                            setBankNameFilter(newvalue?.BankName);
                          }}
                          renderInput={(params) => (
                            <Index.TextField
                              {...params}
                              label="Disbursement Bank Name"
                            />
                          )}
                          filterOptions={(options, { inputValue }) => {
                            const inputLowerCase = inputValue?.toLowerCase();
                            const uniquebankName = new Set();
                            return options?.filter((option) => {
                              const bankName = option?.BankName?.toLowerCase();
                              // Check unique
                              if (uniquebankName?.has(bankName)) {
                                return false;
                              }
                              // Check search
                              if (bankName?.includes(inputLowerCase)) {
                                uniquebankName?.add(bankName);
                                return true;
                              }
                              return false;
                            });
                          }}
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 4",
                        lg: "span 4",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="sahay-multiple-input-main">
                        <Autocomplete
                          disablePortal
                          className="multiple-autocomplet-control"
                          id="combo-box-demo"
                          options={sahaySubTypeList || []}
                          getOptionLabel={(option) => option.SahayName}
                          onChange={(value, newvalue) => {
                            setSubSahayFilter(newvalue?.SahayName);
                          }}
                          renderInput={(params) => (
                            <Index.TextField
                              {...params}
                              label="Sub Sahay Name"
                            />
                          )}
                        />
                      </Index.Box>
                    </Index.Box>
                    {filterReportData?.length > 0 && (
                      <>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 4",
                            lg: "span 4",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="sahay-multiple-input-main">
                            <Index.Box
                              Box
                              className="res-auto-left common-button blue-button multiple-download-btn res-blue-button"
                            >
                              <FileExcel apiData={excel} fileName={fileName} />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 4",
                            lg: "span 3",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="sahay-multiple-input-main">
                            <Index.Box
                              Box
                              className="res-auto-left common-button blue-button multiple-download-btn res-blue-button"
                            >
                              <Index.Button onClick={handlePrint}>
                                Print
                              </Index.Button>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </>
                    )}
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="table-list">
                <Index.TableContainer
                 sx={{ maxHeight: 275 }}
                  // component={Index.Paper}
                  className="table-container user-master-container scroll-table common-table-container-custom"
                >
                  <Index.Table
                    stickyHeader aria-label="sticky table"
                    className="table-design-main barge-table escreport-table"
                  >
                    <Index.TableHead className="user-master-head">
                      <Index.TableRow className="user-master-tr">
                        <Index.TableCell className="user-master-th">
                          Fid
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Applicant Name
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          SubSahay Name
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Area
                        </Index.TableCell>

                        <Index.TableCell className="user-master-th">
                          Sanction amount
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Disbursement Bank Name
                        </Index.TableCell>

                        <Index.TableCell className="user-master-th">
                          Date of Disbursement
                        </Index.TableCell>

                        <Index.TableCell className="user-master-th">
                          Applicant Bank Name
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Branch
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          A/c Number
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          IFSC Code
                        </Index.TableCell>
                      </Index.TableRow>
                    </Index.TableHead>
                    <Index.TableBody className="user-master-tbody">
                      {pageData?.length &&
                      (checkDateFilter || bankNameFilter || subSahayFilter) ? (
                        pageData?.map((data, index) => (
                          <Index.TableRow className="user-master-tr"     onClick={() => handleSahayApplicationView(data)}
                          sx={{ cursor: "pointer" }}>
                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-role-name">
                                {data?.FID || "-"}
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-details-name">
                                {data?.ApplicantName || "-"}
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-details-name">
                                {data?.SubSahayName || "-"}
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-details-name">
                                {data?.Area || "-"}
                              </Index.Box>
                            </Index.TableCell>

                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-email-name">
                                {data?.SanctionAmt || data?.claimedsanctionAmt
                                  ? data?.SanctionAmt
                                  : "-"}
                                {/* {data?.SanctionAmt || data?.claimedsanctionAmt
                                  ? data?.SanctionAmt + data?.claimedsanctionAmt
                                  : "-"} */}
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-number-name">
                                {data?.BankName || "-"}
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-number-name">
                                {data?.CreatedDate
                                  ? moment(data?.Date).format(
                                      "YYYY-MM-DD"
                                    )
                                  : "-"}
                                {/* {data?.CreatedDate
                                  ? moment(data?.CreatedDate).format(
                                      "YYYY-MM-DD"
                                    )
                                  : "-"} */}
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-number-name">
                                {data?.ApplicantBankName || "-"}
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-number-name">
                                {data?.BranchName || "-"}
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-number-name">
                                {data?.AccountNo || "-"}
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-number-name">
                                {data?.IFSCCode || "-"}
                              </Index.Box>
                            </Index.TableCell>
                          </Index.TableRow>
                        ))
                      ) : (
                        <PagesIndex.NoRecordFound colSpan={12} />
                      )}
                    </Index.TableBody>
                  </Index.Table>
                </Index.TableContainer>
              </Index.Box>
              <Index.Box className="pagination-design flex-end">
                <RowPerPageDropdown
                  rowPerPage={rowPerPage}
                  setRowPerPage={setRowPerPage}
                />
                <Index.Stack spacing={2}>
                  <Pagination
                    fetchData={searchedData}
                    setPageData={setPageData}
                    pageData={pageData}
                    rowPerPage={rowPerPage}
                  />
                </Index.Stack>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box sx={{ display: "none" }}>
            <PrintReport
              componentRef={componentRef}
              tableData={excel}
              reportName={[{ type: "EscReport" }]}
            />
          </Index.Box>
        </>
      )}
    </>
  );
}
