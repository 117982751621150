import React, { useEffect, useState } from "react";

import Index from "../../../Index";
import "./NewMember.css";
import {
  addFamilyHead,
  addFamilyMember,
  getAllFamilyMemberByIdView,
  getAllFamilyWithMohollaNameAndAreaList,
  getBloodmasterList,
  getCityMasterList,
  getCountryMasterList,
  getGnyatiMasterList,
  getMaritalstatusList,
  getMohallalist,
  getRelationMasterList,
  getStateMasterList,
  updateFamilyMember,
} from "../../../../redux/features/AddService";
import PagesIndex from "../../../PagesIndex";
import moment from "moment";
import dayjs from "dayjs";

import { validationSchemaNewMember } from "../../../../validation/Validation";
import DataService from "../../../../config/DataService";
import { toast } from "react-toastify";
import PageLoading from "../../../../components/common/pageLoading/PageLoading";
import { useSelector } from "react-redux";
import { Autocomplete } from "@mui/material";

const NewMemberAdd = () => {
  const {
    getMohalla,
    getBloodList,
    getMaritalList,
    getGnyatiList,
    getRelationList,
    getCountryList,
    getUserProfile,
  } = PagesIndex.useSelector((state) => state.admin);
  const navigate = PagesIndex.useNavigate();
  let familyId = generateFamilyID();

  const { state } = PagesIndex.useLocation();
  const editData = state?.row;
  const dispatch = PagesIndex.useDispatch();
  //all state
  const [countryId, setCountryId] = useState(editData ? "India" : "");
  const [getStateId, setGetStateId] = useState(
    editData ? editData?.mem_altState : ""
  );
  const [statesData, setStatesData] = useState([]);
  const [citiesData, setCitiesData] = useState([]);
  const [isSubmitHead, setIsSubmitHead] = useState(false);
  const [isSubmitFamily, setIsSubmitFamily] = useState(false);
  const [getFamilyMemberEdit, setGetFamilyMemberEdit] = useState([]);
  const [getFamilyMemberEditData, setGetFamilyMemberEditData] = useState([]);
  const [familyHead, setFamilyHead] = useState();
  const [getFamilyid, setGetFamilyid] = useState();
  const [head, setHead] = useState();
  const [loader, setLoader] = useState(true);
  const [loaderRelation, setLoaderRelation] = useState(true);
  const [familyListWithArea, setFamilyListWithArea] = useState([]);

  const rollData = JSON.parse(localStorage?.getItem("RollName"))?.toLowerCase();
  // variable for get head of family and family member field data
  const headOfFamilyMember =
    getUserProfile?.HeadOfFamily && getUserProfile?.HeadOfFamily;
  const getFamilyMember =
    getUserProfile?.FamilyMembers && getUserProfile?.FamilyMembers;
  useEffect(() => {
    dispatch(getMohallalist());
    dispatch(getBloodmasterList());
    dispatch(getMaritalstatusList());
    dispatch(getGnyatiMasterList());
    dispatch(getRelationMasterList());
    dispatch(getStateMasterList());
    dispatch(getCountryMasterList());
    dispatch(getCityMasterList());
  }, []);

  const { rollPermissionData } = useSelector((state) => state.admin);

  const handleMenuRelationOpen = () => {
    setLoaderRelation(true);

    familyListWithArea?.length > 0 &&
      setTimeout(() => {
        setLoaderRelation(false);
      }, 2000);
  };
  const handleMenuRelationClose = () => {
    setLoaderRelation(true);
    setTimeout(() => {
      setLoaderRelation(false);
    }, 2000);
  };

  useEffect(() => {
    dispatch(getStateMasterList(countryId)).then((data) => {
      if (data) {
        setLoader(false);
        setStatesData(data.payload);
      }
    });
  }, [countryId, editData]);

  useEffect(() => {
    dispatch(getStateMasterList("India")).then((data) => {
      if (data) {
        setLoader(false);
        setStatesData(data.payload);
      }
    });
  }, []);

  useEffect(() => {
    dispatch(getCityMasterList(getStateId)).then((data) => {
      if (data) {
        setLoader(false);
        setCitiesData(data.payload);
      }
    });
  }, [getStateId]);

  useEffect(() => {
    if (editData) {
      dispatch(
        getAllFamilyMemberByIdView({ familyId: editData?.mem_family_id })
      ).then((data) => {
        if (data?.payload) {
          setLoader(false);
          let filterdData2 = data?.payload?.FamilyMembers?.filter((dataOF) => {
            if (dataOF?.firstName == data?.payload?.HeadOfFamily?.firstName) {
              return data;
            }
          });
          setLoader(false);
          setHead(data?.payload?.HeadOfFamily);

          setGetFamilyMemberEdit(filterdData2);

          let filterdData = data?.payload?.FamilyMembers?.filter((dataOF) => {
            if (dataOF?.firstName != data?.payload?.HeadOfFamily?.firstName) {
              return data;
            }
          });

          setFamilyHead(filterdData2[0]);
          setGetFamilyMemberEditData(data?.payload?.FamilyMembers);
        }
      });
    }
  }, []);

  useEffect(() => {
    // const getFamilyId = DataService.get(`/${rollData}/getFamilyCount`)
    const getFamilyId = DataService.get(`/common/getFamilyCount`)
      .then((res) => {
        setLoader(false);
        setGetFamilyid(res?.data?.data);
      })
      .catch((err) => {});
  }, []);

  const getFamilyListWithArea = () => {
    dispatch(getAllFamilyWithMohollaNameAndAreaList()).then((res) => {
      if (res?.payload) {
        setFamilyListWithArea(res?.payload?.data);
      } else {
        setFamilyListWithArea([]);
      }
    });
  };

  useEffect(() => {
    getFamilyListWithArea();
  }, []);

  //intial values for update and edit
  let initialValues;
  if (editData) {
    initialValues = {
      oldFamilyId : editData?.oldFamilyId || "-",
      patanHeadOfTheFamily: head?.patanHeadOfTheFamily
        ? head?.patanHeadOfTheFamily
        : "",
      familyId: editData ? editData?.mem_family_id : getFamilyid,
      firstName: head ? head?.firstName : "",
      middleName: head ? head?.middleName : "",
      lastName: head ? head?.lastName : "",
      MohollaId: head ? head?.MohollaId : "",
      DOB: head ? head?.dob : "",
      age: head ? head?.mem_age : "",
      mobileNumber: head ? head?.mem_mobile : "",
      email: head ? head?.email : "",
      res_add1: head ? head?.res_add1 : "",
      res_add2: head ? head?.res_add2 : "",

      gender: head ? head?.mem_gender : "",
      bloodGroupId: head ? head?.mem_bloodgroup_id : "",
      maritialStatusId: head ? head?.mem_maritalstatus_id : "",
      medicalPolicy: head ? head?.mem_medical_policy : "",
      gnyatiId: head ? head?.gnyatiId : "",
      area: head ? head?.area : "",
      cityId: head ? head?.cityId : "",
      pinCode: head ? head?.pinCode : "",
      country: head ? head?.country : "",
      grandFather: head ? head?.grandFather : "",
      isBusiness: head ? head?.mem_isBusiness : "",
      stateId: head ? head?.stateId : "",

      dataArray: getFamilyMemberEditData?.map((item) => {
        return {
          memId: item ? item?.memId : "",
          mem_fname: item ? item?.firstName : "",
          // mem_family_id: editData?.mem_family_id,
          mem_mname: item ? item?.middleName : "",
          mem_lname: item ? item?.lastName : "",
          mem_age: item ? item?.mem_age : "",
          mem_dob: item ? item?.mem_dob : "",
          mem_isBusiness: item ? item?.mem_isBusiness : "",
          mem_hof_relation: item ? item?.mem_hof_relation : "",
          maritialStatus: item ? item?.maritialStatusId : "",
          mem_mobile: item ? item?.mem_mobile : "",
          monthly_income: editData ? editData?.monthly_income : 0,
        };
      }),
    };
  } else {
    initialValues = {
      patanHeadOfTheFamily: "",
      familyId: getFamilyid ? getFamilyid : "",
      firstName: "",
      lastName: "",
      middleName: "",
      MohollaId: "",
      DOB: "",
      age: "",
      mobileNumber: "",
      email: "",
      res_add1: "",
      res_add2: "",
      gender: "",
      bloodGroupId: "",
      maritialStatusId: "",
      medicalPolicy: "",
      gnyatiId: "",
      area: "",
      cityId: "",
      pinCode: "",
      country: headOfFamilyMember ? headOfFamilyMember?.country : "",
      grandFather: "",
      isBusiness: "",
      stateId: "",
      isSubmit: false,
      dataArray: [
        {
          mem_fname: "",
          mem_family_id: getFamilyid ? getFamilyid : "",
          mem_mname: "",
          mem_lname: "",
          mem_age: "",
          mem_dob: "",
          mem_isBusiness: "",
          mem_hof_relation: "",
          maritialStatus: "",
          mem_mobile: "",
          monthly_income: 0,
        },
      ],
    };
  }

  // handle submit for add and edit
  const handleFormSubmit = (values, { setFieldValue }) => {
    setFieldValue("isSubmit", true);
    setGetFamilyid(editData ? editData?.mem_family_id : familyId);
    const data = new URLSearchParams();
    data.append("familyId", getFamilyid);

    data.append("MohollaId", values.MohollaId);
    data.append("DOB", values.DOB);
    data.append("mobileNumber", values.mobileNumber);
    data.append("email", values.email);
    data.append("res_add1", values.res_add1);
    data.append("res_add2", values.res_add2);
    data.append("gender", values.gender);
    data.append("bloodGroupId", values.bloodGroupId);
    data.append("maritialStatusId", values.maritialStatusId);
    data.append("medicalPolicy", values.medicalPolicy);
    data.append("gnyatiId", values.gnyatiId);
    data.append("area", values.area);
    data.append("cityId", values.cityId);
    data.append("pinCode", values.pinCode);
    data.append("country", values.country);
    data.append("grandFather", values.grandFather);
    data.append("isBusiness", values.isBusiness);
    data.append("stateId", values.stateId);
    data.append("firstName", values.firstName);
    data.append("lastName", values.lastName);
    data.append("middleName", values.middleName);
    data.append("age", values.age);
    data.append("patanHeadOfTheFamily", values?.patanHeadOfTheFamily)
    data.append("oldFamilyId", values?.oldFamilyId)

    const arrayData = new URLSearchParams();
    values?.dataArray?.forEach((ele, values) => {
      if (!editData) {
        arrayData.append("memId", ele.mem_id);
      }

      arrayData.append("mem_fname", ele.mem_fname);
      arrayData.append("mem_family_id", +getFamilyid);
      arrayData.append("mem_mname", ele.mem_mname);
      arrayData.append("mem_lname", ele.mem_lname);
      arrayData.append("mem_age", +ele.mem_age);
      arrayData.append("mem_dob", ele.mem_dob);
      arrayData.append("mem_isBusiness", ele.mem_isBusiness);
      arrayData.append("mem_hof_relation", +ele.mem_hof_relation);
      arrayData.append("maritialStatus", +ele.maritialStatus);
      arrayData.append("mem_mobile", +ele.mem_mobile);
      arrayData.append("monthly_income", 0);
    });
    if (editData) {
      let headFamily = {
        firstName: values?.firstName,
        lastName: values?.lastName,
        middleName: values?.middleName,
        MohollaId: values?.MohollaId,
        DOB: values?.DOB,
        age: values?.age,
        mobileNumber: values?.mobileNumber,
        email: values?.email,
        res_add1: values?.res_add1,
        res_add2: values?.res_add2,
        gender: values?.gender,
        bloodGroupId: values?.bloodGroupId,
        maritialStatusId: values?.maritialStatusId,
        medicalPolicy: values?.medicalPolicy,
        gnyatiId: values?.gnyatiId,
        area: values?.area,
        cityId: values?.cityId,
        pinCode: values?.pinCode,
        grandFather: values?.grandFather,
        isBusiness: values?.isBusiness,
        stateId: values?.stateId,
        country: values?.country,
        patanHeadOfTheFamily : values?.patanHeadOfTheFamily,
      };

      let updateData = {
        familyId: editData?.mem_family_id,
        membersData: values.dataArray,
        headOfFamily: headFamily,
      };

      dispatch(updateFamilyMember(updateData)).then((res) => {
        if (res?.payload == 200) {
          navigate(`/${rollData}/new-member/list`);
          setTimeout(() => {
            setFieldValue("isSubmit", false);
          }, 1000);
        } else {
          setTimeout(() => {
            setFieldValue("isSubmit", false);
          }, 1000);
        }
      });
    } else {
      dispatch(addFamilyHead(data, navigate)).then((data) => {
        if (data == 200 || 201) {
          setIsSubmitHead(true);
        } else {
          setTimeout(() => {
            setFieldValue("isSubmit", false);
          }, 1000);
        }
      });
      dispatch(addFamilyMember(values.dataArray, navigate)).then((data) => {
        if (data == 200 || 201) {
          setIsSubmitFamily(true);
        } else {
          setTimeout(() => {
            setFieldValue("isSubmit", false);
          }, 1000);
        }
      });
    }
  };
  useEffect(() => {
    if (isSubmitFamily && isSubmitHead) {
      // toast.success("Member added successfully");
      navigate(`/${rollData}/new-member/list`);
    }
  }, [isSubmitFamily, isSubmitHead]);

  const rollPermissionNameData =
    rollPermissionData?.findData?.parentRoleName?.toLowerCase();

  if (
    rollPermissionData?.permissionArray?.includes("New Member Master_add") ||
    rollPermissionData?.permissionArray?.includes("New Member Master_edit") ||
    rollPermissionNameData === "admin"
  ) {
  } else {
    navigate(`/${rollData}/sahay-application`);
  }

  //end handle submit
  return (
    <>
      {loader ? (
        <PageLoading />
      ) : (
        <>
          <Index.Box className="page-content-box">
            <Index.Box className="barge-common-box">
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex">
                  <Index.Box className="title-main mb-10">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="page-title"
                    >
                      {state ? state?.edit : "Add"} Head Of Family
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <PagesIndex.Formik
                enableReinitialize={editData ? true : false}
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={validationSchemaNewMember}
              >
                {({
                  values,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  errors,
                  handleBlur,
                  touched,
                  dirty,
                  isValid,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Index.Box className="">
                      <Index.Grid container spacing={3}>
                        <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Family ID (કુટુંબ ID)
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              placeholder="Family ID"
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              autoComplete="off"
                              name="familyId"
                              type="number"
                              onChange={handleChange}
                              value={values.familyId}
                              disabled
                            />
                          </Index.Box>
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                          <Index.Box className="lable-input-grp">
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label"
                            >
                              Parent FID (પિતૃ FID)
                            </Index.Typography>
                            <Index.Box className="sahay-multiple-input-main">
                              <Autocomplete
                                disablePortal
                                className="multiple-autocomplet-control"
                                id="combo-box-demo"
                                options={familyListWithArea}
                                onOpen={handleMenuRelationOpen}
                                onClose={handleMenuRelationClose}
                                loading={loaderRelation}
                                value={
                                  (familyListWithArea?.length > 0 &&
                                    familyListWithArea?.find(
                                      (row) =>
                                        row?.mem_id == values?.patanHeadOfTheFamily
                                    )) ||
                                  ""
                                }
                                getOptionLabel={(option) =>
                                  `${
                                    option?.mem_family_id?.toString() || ""
                                  }  ${option?.mem_fname || ""} ${
                                    option?.mem_mname || ""
                                  } ${option?.mem_lname || ""}`
                                }
                                onChange={(value, newvalue) => {
                                  setFieldValue(
                                    "patanHeadOfTheFamily",
                                    newvalue?.mem_id
                                  );
                                }}
                                renderInput={(params) => (
                                  <Index.TextField
                                    {...params}
                                    placeholder="Select Relation"
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <React.Fragment>
                                          {/* {loader ? <CircularProgress color="inherit" size={20} /> : null} */}
                                          {params.InputProps.endAdornment}
                                        </React.Fragment>
                                      ),
                                    }}
                                  />
                                )}
                              />
                            </Index.Box>

                            {errors?.patanHeadOfTheFamily &&
                              touched?.patanHeadOfTheFamily && (
                                <p className="error-text">
                                  {errors?.patanHeadOfTheFamily}
                                </p>
                              )}
                          </Index.Box>
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Old Family Id (જૂની ફેમિલી આઈડી)
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              placeholder="Old Family Id"
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              autoComplete="off"
                              name="oldFamilyId"
                              onChange={handleChange}
                              value={values.oldFamilyId}
                              onBlur={handleBlur}
                            />
                          </Index.Box>
                          {errors?.oldFamilyId && touched.oldFamilyId && (
                            <p className="error-text">{errors?.oldFamilyId}</p>
                          )}
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Email Id* (ઈમેલ આઈડી)
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              placeholder="Email Id  "
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              autoComplete="off"
                              name="email"
                              onChange={handleChange}
                              value={values.email}
                              onBlur={handleBlur}
                            />
                          </Index.Box>
                          {errors?.email && touched.email && (
                            <p className="error-text">{errors?.email}</p>
                          )}
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Mobile No* (મોબાઈલ નં)
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.TextField
                              type="number"
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              placeholder="Mobile No"
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              autoComplete="off"
                              name="mobileNumber"
                              onChange={(e) => {
                                setFieldValue(
                                  "mobileNumber",
                                  e.target.value?.slice(0, 10)
                                );
                              }}
                              value={values.mobileNumber}
                              onBlur={handleBlur}
                            />
                          </Index.Box>
                          {errors?.mobileNumber && touched.mobileNumber && (
                            <p className="error-text">{errors?.mobileNumber}</p>
                          )}
                        </Index.Grid>

                        <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            First Name* (પ્રથમ નામ)
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              placeholder="First Name"
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              autoComplete="off"
                              name="firstName"
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                if (inputValue.length <= 21) {
                                  setFieldValue("firstName", inputValue);
                                }
                              }}
                              value={values.firstName}
                              onBlur={handleBlur}
                            />
                          </Index.Box>
                          {errors?.firstName && touched.firstName && (
                            <p className="error-text">{errors?.firstName}</p>
                          )}
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Middle Name* (મધ્ય નામ)
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              placeholder="Middle Name  "
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              autoComplete="off"
                              name="middleName"
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                if (inputValue.length <= 21) {
                                  setFieldValue("middleName", inputValue);
                                }
                              }}
                              value={values.middleName}
                              onBlur={handleBlur}
                            />
                          </Index.Box>
                          {errors?.middleName && touched.middleName && (
                            <p className="error-text">{errors?.middleName}</p>
                          )}
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Last Name* (છેલ્લું નામ)
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              placeholder="Last Name  "
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              autoComplete="off"
                              name="lastName"
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                if (inputValue.length <= 21) {
                                  setFieldValue("lastName", inputValue);
                                }
                              }}
                              value={values.lastName}
                              onBlur={handleBlur}
                            />
                          </Index.Box>
                          {errors?.lastName && touched.lastName && (
                            <p className="error-text">{errors?.lastName}</p>
                          )}
                        </Index.Grid>

                        <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Grand Father* (દાદા)
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              placeholder="Grand Father Name"
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              autoComplete="off"
                              name="grandFather"
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                if (inputValue.length <= 21) {
                                  setFieldValue("grandFather", inputValue);
                                }
                              }}
                              value={values.grandFather}
                              onBlur={handleBlur}
                            />
                          </Index.Box>
                          {/* {errors?.grandFather && touched.grandFather && (
                            <p className="error-text">{errors?.grandFather}</p>
                          )} */}
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Gender* (જાતિ)
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.Select
                              hiddenLabel
                              fullWidth
                              // label="gender"
                              id="hgh"
                              name="gender"
                              defaultValue={values ? values?.gender : ""}
                              value={values.gender}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <Index.MenuItem value={1}>Male</Index.MenuItem>
                              <Index.MenuItem value={0}>Female</Index.MenuItem>
                            </Index.Select>
                          </Index.Box>

                          {errors?.gender && touched.gender && (
                            <p className="error-text">{errors?.gender}</p>
                          )}
                        </Index.Grid>

                        <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Date Of Birth* (જન્મ તારીખ)
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border ">
                            <Index.LocalizationProvider
                              dateAdapter={Index.AdapterDayjs}
                              sx={{ position: "absolute" }}
                            >
                              <Index.DemoContainer components={["DatePicker"]}>
                                <Index.DatePicker
                                  name="DOB"
                                  onChange={(value) => {
                                    setFieldValue(
                                      "DOB",
                                      moment(value.$d).format("YYYY-MM-DD")
                                    );
                                    const currentDate = new Date();
                                    const currentYear =
                                      moment(currentDate).format("YYYY");
                                    const birthYear = moment(value.$d).format(
                                      "YYYY"
                                    );
                                    const age = currentYear - birthYear;
                                    setFieldValue("age", age);
                                  }}
                                  value={dayjs(values?.DOB)}
                                  format="YYYY-MM-DD"
                                  disableFuture={true}
                                  sx={{
                                    overflowY: "hidden",

                                    position: "relative",
                                    bottom: "8px",
                                  }}
                                  slotProps={{
                                    textField: {
                                      size: "small",
                                      error: false,
                                    },
                                  }}
                                />
                              </Index.DemoContainer>
                            </Index.LocalizationProvider>
                          </Index.Box>
                          {errors?.DOB && touched.DOB && (
                            <p className="error-text">{errors?.DOB}</p>
                          )}
                        </Index.Grid>

                        <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Age (ઉંમર)
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              placeholder="Age"
                              variant="filled"
                              type="number"
                              className="admin-input-design input-placeholder"
                              autoComplete="off"
                              name="age"
                              onChange={handleChange}
                              value={values.age}
                              disabled
                            />
                          </Index.Box>
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Blood Group (બ્લડ ગ્રુપ)
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.Select
                              fullWidth
                              name="bloodGroupId"
                              // label="hgh"
                              id="hgh"
                              value={values.bloodGroupId}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              {getBloodList &&
                                getBloodList?.map((option) => (
                                  <Index.MenuItem
                                    key={option.bloodgroup_id}
                                    value={option.bloodgroup_id}
                                    name={option.bloodgroup_name}
                                  >
                                    {option.bloodgroup_name}
                                  </Index.MenuItem>
                                ))}
                            </Index.Select>
                          </Index.Box>
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Marital Status* (વૈવાહિક સ્થિતિ)
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.Select
                              fullWidth
                              name="maritialStatusId"
                              // label="hgh"
                              id="hgh"
                              defaultValue={
                                values ? values?.maritialStatusId : ""
                              }
                              value={values.maritialStatusId}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              {getMaritalList &&
                                getMaritalList?.map((option) => (
                                  <Index.MenuItem
                                    key={option.maritalstatus_id}
                                    value={option.maritalstatus_id}
                                  >
                                    {option.maritalstatus_name}
                                  </Index.MenuItem>
                                ))}
                            </Index.Select>
                          </Index.Box>

                          {errors?.maritialStatusId &&
                            touched.maritialStatusId && (
                              <p className="error-text">
                                {errors?.maritialStatusId}
                              </p>
                            )}
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Business* (વ્યાપાર)
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.Select
                              fullWidth
                              name="isBusiness"
                              // label="hgh"
                              id="hgh"
                              value={values.isBusiness}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <Index.MenuItem value={"Business"}>
                                Business
                              </Index.MenuItem>
                              <Index.MenuItem value={"Retired"}>
                                Retired
                              </Index.MenuItem>
                              <Index.MenuItem value={"House Wife"}>
                                House Wife
                              </Index.MenuItem>
                              <Index.MenuItem value={"Student"}>
                                Student
                              </Index.MenuItem>
                              <Index.MenuItem value={"Job"}>Job</Index.MenuItem>
                            </Index.Select>
                          </Index.Box>
                          {errors?.isBusiness && touched.isBusiness && (
                            <p className="error-text">{errors?.isBusiness}</p>
                          )}
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Medical Policy* (તબીબી નીતિ)
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.Select
                              fullWidth
                              name="medicalPolicy"
                              // label="hgh"
                              id="hgh"
                              defaultValue={values ? values?.medicalPolicy : ""}
                              value={values.medicalPolicy}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <Index.MenuItem value={"yes"}>Yes</Index.MenuItem>
                              <Index.MenuItem value={"no"}>No</Index.MenuItem>
                            </Index.Select>
                          </Index.Box>

                          {errors?.medicalPolicy && touched.medicalPolicy && (
                            <p className="error-text">
                              {errors?.medicalPolicy}
                            </p>
                          )}
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Gnyati* (જ્ઞાતિ)
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.Select
                              fullWidth
                              name="gnyatiId"
                              // label="hgh"
                              id="hgh"
                              defaultValue={values ? values?.gnyatiId : ""}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.gnyatiId}
                            >
                              {getGnyatiList &&
                                getGnyatiList?.map((option) => (
                                  <Index.MenuItem
                                    key={option.gnyati_id}
                                    value={option.gnyati_id}
                                    name={option.gnyati_name}
                                  >
                                    {option.gnyati_name}
                                  </Index.MenuItem>
                                ))}
                            </Index.Select>
                          </Index.Box>

                          {errors?.gnyatiId && touched.gnyatiId && (
                            <p className="error-text">{errors?.gnyatiId}</p>
                          )}
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Moholla Name* (વિસ્તારનું નામ)
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.Select
                              fullWidth
                              name="MohollaId"
                              // label="hgh"
                              id="hgh"
                              defaultValue={values ? values?.MohollaId : ""}
                              value={values.MohollaId}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              {getMohalla &&
                                getMohalla?.map((option) => (
                                  <Index.MenuItem
                                    key={option.mohollo_id}
                                    value={option.mohollo_id}
                                  >
                                    {option.mohollo_name}
                                  </Index.MenuItem>
                                ))}
                            </Index.Select>
                          </Index.Box>

                          {errors?.MohollaId && touched.MohollaId && (
                            <p className="error-text">{errors?.MohollaId}</p>
                          )}
                        </Index.Grid>

                        <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Residential Address1* (રહેઠાણનું સરનામું1)
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.TextField
                              hiddenLabel
                              type="text"
                              id="filled-hidden-label-normal"
                              placeholder="Res. address1  "
                              variant="filled"
                              InputProps={{
                                inputProps: { min: 0, max: 10 },
                              }}
                              className="admin-input-design input-placeholder"
                              autoComplete="off"
                              name="res_add1"
                              value={values.res_add1}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Index.Box>
                          {errors?.res_add1 && touched.res_add1 && (
                            <p className="error-text">{errors?.res_add1}</p>
                          )}
                        </Index.Grid>

                        <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Residential Address2 (રહેઠાણનું સરનામું2)
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.TextField
                              hiddenLabel
                              type="text"
                              id="filled-hidden-label-normal"
                              placeholder="Res. address2"
                              variant="filled"
                              InputProps={{
                                inputProps: { min: 0, max: 10 },
                              }}
                              className="admin-input-design input-placeholder"
                              autoComplete="off"
                              name="res_add2"
                              value={values.res_add2}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Index.Box>
                          {errors?.res_add2 && touched.res_add2 && (
                            <p className="error-text">{errors?.res_add2}</p>
                          )}
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Area* (વિસ્તાર)
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              placeholder="Area "
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              autoComplete="off"
                              name="area"
                              value={values.area}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Index.Box>
                          {errors?.area && touched.area && (
                            <p className="error-text">{errors?.area}</p>
                          )}
                        </Index.Grid>

                        <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Country* (દેશ)
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.Select
                              fullWidth
                              name="country"
                              id="hgh"
                              defaultValue={values ? values?.country : ""}
                              value={values.country}
                              onChange={(e) => {
                                setFieldValue("country", e.target.value);
                                setCountryId(
                                  e.target.value == 101 ? "India" : ""
                                );
                              }}
                              onBlur={handleBlur}
                            >
                              {getCountryList &&
                                getCountryList?.map((option) => (
                                  <Index.MenuItem
                                    key={option.country_id}
                                    value={option.country_id}
                                    // name={option.country_name}
                                  >
                                    {option.country_name}
                                  </Index.MenuItem>
                                ))}
                            </Index.Select>
                          </Index.Box>
                          {errors?.country && touched.country && (
                            <p className="error-text">{errors?.country}</p>
                          )}
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            State* (રાજ્ય)
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.Select
                              fullWidth
                              name="stateId"
                              // label="hgh"
                              id="hgh"
                              defaultValue={values ? values?.stateId : ""}
                              value={values.stateId}
                              onChange={(e) => {
                                setFieldValue("stateId", e.target.value);
                                setGetStateId(e.target.value);
                              }}
                              onBlur={handleBlur}
                            >
                              {statesData && statesData.length > 0 ? (
                                statesData?.map((option) => (
                                  <Index.MenuItem
                                    key={option.state_id}
                                    value={option.state_id}
                                    name={option.state_name}
                                  >
                                    {option.state_name}
                                  </Index.MenuItem>
                                ))
                              ) : (
                                <Index.MenuItem disabled>
                                  No state found
                                </Index.MenuItem>
                              )}
                            </Index.Select>
                          </Index.Box>
                          {errors?.stateId && touched.stateId && (
                            <p className="error-text">{errors?.stateId}</p>
                          )}
                        </Index.Grid>

                        <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            City* (શહેર)
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.Select
                              fullWidth
                              name="cityId"
                              id="hgh"
                              defaultValue={values ? values?.cityId : ""}
                              value={values.cityId}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              {citiesData?.length > 0 ? (
                                citiesData?.map((option) => (
                                  <Index.MenuItem
                                    key={option.city_id}
                                    value={option.city_id}
                                    name={option.city_name}
                                  >
                                    {option.city_name}
                                  </Index.MenuItem>
                                ))
                              ) : (
                                <Index.MenuItem disabled>
                                  No city found
                                </Index.MenuItem>
                              )}
                            </Index.Select>
                          </Index.Box>
                          {errors?.cityId && touched.cityId && (
                            <p className="error-text">{errors?.cityId}</p>
                          )}
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Pin code (પિન કોડ)
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.TextField
                              type="number"
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              placeholder="Pin code"
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              autoComplete="off"
                              name="pinCode"
                              value={values.pinCode}
                              onChange={(e) => {
                                if (e.target.value.length <= 6) {
                                  // Only update the value if it's not more than 40 characters
                                  handleChange(e);
                                }
                              }}
                              onBlur={handleBlur}
                            />
                          </Index.Box>
                        </Index.Grid>
            
                      </Index.Grid>

                      <Index.Box className="new-member-top-spacing">
                        <Index.Box className="title-header-flex">
                          <Index.Box className="title-main mb-10">
                            <Index.Typography
                              variant="p"
                              component="p"
                              className="page-title"
                            >
                              {state ? state?.edit : "Add"} Family Member
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>

                        <PagesIndex.FieldArray name="dataArray">
                          {({ push, remove }) => (
                            <div>
                              {!editData && (
                                <Index.Box className="common-button blue-button flex-end save-btn">
                                  <Index.Button
                                    variant="contained"
                                    onClick={() =>
                                      push({
                                        mem_fname: "",
                                        mem_family_id: getFamilyid,
                                        mem_mname: "",
                                        mem_lname: "",
                                        mem_age: "",
                                        mem_dob: "",
                                        mem_isBusiness: "",
                                        mem_hof_relation: "",
                                        maritialStatus: "",
                                        mem_mobile: "",
                                        monthly_income: 0,
                                      })
                                    }
                                    disabled={!isValid}
                                  >
                                    Add Data
                                  </Index.Button>
                                </Index.Box>
                              )}
                              {values?.dataArray?.map((data, index) => (
                                <div key={index}>
                                  <Index.Grid
                                    container
                                    spacing={3}
                                    mt={2}
                                    key={index}
                                  >
                                    {!editData && (
                                      <Index.Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={4}
                                        lg={3}
                                      >
                                        <Index.Typography
                                          variant="label"
                                          component="label"
                                          className="input-label"
                                        >
                                          Family id (કૌટુંબિક આઈડી)
                                        </Index.Typography>
                                        <Index.Box className="input-design-div with-border">
                                          <Index.TextField
                                            hiddenLabel
                                            id="filled-hidden-label-normal"
                                            placeholder="Family id"
                                            variant="filled"
                                            className="admin-input-design input-placeholder"
                                            autoComplete="off"
                                            name={`dataArray.${index}.mem_family_id`}
                                            onChange={handleChange}
                                            value={
                                              values.dataArray[index]
                                                .mem_family_id
                                            }
                                            disabled
                                          />
                                        </Index.Box>
                                      </Index.Grid>
                                    )}
                                    <Index.Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      md={4}
                                      lg={3}
                                    >
                                      <Index.Typography
                                        variant="label"
                                        component="label"
                                        className="input-label"
                                      >
                                        First Name* (પ્રથમ નામ)
                                      </Index.Typography>
                                      <Index.Box className="input-design-div with-border">
                                        <Index.TextField
                                          hiddenLabel
                                          id="filled-hidden-label-normal"
                                          placeholder="First Name"
                                          variant="filled"
                                          className="admin-input-design input-placeholder"
                                          autoComplete="off"
                                          // name={data.}
                                          name={`dataArray.${index}.mem_fname`}
                                          onChange={(e) => {
                                            const inputValue = e.target.value;
                                            if (inputValue.length <= 21) {
                                              setFieldValue(
                                                `dataArray.${index}.mem_fname`,
                                                inputValue
                                              );
                                            }
                                          }}
                                          onBlur={handleBlur}
                                          value={
                                            values.dataArray[index].mem_fname
                                          }
                                        />
                                      </Index.Box>
                                      {errors.dataArray &&
                                        errors.dataArray[index] &&
                                        errors.dataArray[index].mem_fname &&
                                        touched &&
                                        touched.dataArray &&
                                        touched.dataArray[index] &&
                                        touched.dataArray[index].mem_fname && (
                                          <p className="error-text">
                                            {errors.dataArray[index].mem_fname}
                                          </p>
                                        )}
                                    </Index.Grid>

                                    <Index.Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      md={4}
                                      lg={3}
                                    >
                                      <Index.Typography
                                        variant="label"
                                        component="label"
                                        className="input-label"
                                      >
                                        Middle Name* (મધ્ય નામ)
                                      </Index.Typography>
                                      <Index.Box className="input-design-div with-border">
                                        <Index.TextField
                                          hiddenLabel
                                          id="filled-hidden-label-normal"
                                          placeholder="Middle Name  "
                                          variant="filled"
                                          className="admin-input-design input-placeholder"
                                          autoComplete="off"
                                          name={`dataArray.${index}.mem_mname`}
                                          onChange={(e) => {
                                            const inputValue = e.target.value;
                                            if (inputValue.length <= 21) {
                                              setFieldValue(
                                                `dataArray.${index}.mem_mname`,
                                                inputValue
                                              );
                                            }
                                          }}
                                          onBlur={handleBlur}
                                          value={
                                            values.dataArray[index].mem_mname
                                          }
                                        />
                                      </Index.Box>
                                      {errors.dataArray &&
                                        errors.dataArray[index] &&
                                        errors.dataArray[index].mem_mname &&
                                        touched &&
                                        touched.dataArray &&
                                        touched.dataArray[index] &&
                                        touched.dataArray[index].mem_mname && (
                                          <p className="error-text">
                                            {errors.dataArray[index].mem_mname}
                                          </p>
                                        )}
                                    </Index.Grid>
                                    <Index.Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      md={4}
                                      lg={3}
                                    >
                                      <Index.Typography
                                        variant="label"
                                        component="label"
                                        className="input-label"
                                      >
                                        Last Name* (છેલ્લું નામ)
                                      </Index.Typography>
                                      <Index.Box className="input-design-div with-border">
                                        <Index.TextField
                                          hiddenLabel
                                          id="filled-hidden-label-normal"
                                          placeholder="Last Name  "
                                          variant="filled"
                                          className="admin-input-design input-placeholder"
                                          autoComplete="off"
                                          name={`dataArray.${index}.mem_lname`}
                                          onChange={(e) => {
                                            const inputValue = e.target.value;
                                            if (inputValue.length <= 21) {
                                              setFieldValue(
                                                `dataArray.${index}.mem_lname`,
                                                inputValue
                                              );
                                            }
                                          }}
                                          onBlur={handleBlur}
                                          value={
                                            values.dataArray[index].mem_lname
                                          }
                                        />
                                      </Index.Box>
                                      {errors.dataArray &&
                                        errors.dataArray[index] &&
                                        errors.dataArray[index].mem_lname &&
                                        touched &&
                                        touched.dataArray &&
                                        touched.dataArray[index] &&
                                        touched.dataArray[index].mem_lname && (
                                          <p className="error-text">
                                            {errors.dataArray[index].mem_lname}
                                          </p>
                                        )}
                                    </Index.Grid>

                                    <Index.Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      md={4}
                                      lg={3}
                                    >
                                      <Index.Typography
                                        variant="label"
                                        component="label"
                                        className="input-label"
                                      >
                                        Date Of Birth* (જન્મ તારીખ)
                                      </Index.Typography>
                                      <Index.Box className="input-design-div with-border">
                                        <Index.LocalizationProvider
                                          dateAdapter={Index.AdapterDayjs}
                                          sx={{ position: "absolute" }}
                                        >
                                          <Index.DemoContainer
                                            components={["DatePicker"]}
                                          >
                                            <Index.DatePicker
                                              name={`dataArray.${index}.mem_dob`}
                                              onBlur={handleBlur}
                                              onChange={(value) => {
                                                setFieldValue(
                                                  `dataArray.${index}.mem_dob`,
                                                  moment(value.$d).format(
                                                    "YYYY-MM-DD"
                                                  )
                                                );
                                                const currentDate = new Date();
                                                const currentYear =
                                                  moment(currentDate).format(
                                                    "YYYY"
                                                  );
                                                const birthYear = moment(
                                                  value.$d
                                                ).format("YYYY");
                                                const age =
                                                  currentYear - birthYear;
                                                setFieldValue(
                                                  `dataArray.${index}.mem_age`,
                                                  age
                                                );
                                              }}
                                              value={
                                                values?.dataArray[index]
                                                  ? dayjs(
                                                      values.dataArray[index]
                                                        ?.mem_dob
                                                    )
                                                  : ""
                                              }
                                              format="YYYY-MM-DD"
                                              sx={{
                                                overflowY: "hidden",
                                                // border: "2px solid pink",
                                                position: "relative",
                                                bottom: "8px",
                                              }}
                                              slotProps={{
                                                textField: {
                                                  size: "small",
                                                  error: false,
                                                },
                                              }}
                                            />
                                          </Index.DemoContainer>
                                        </Index.LocalizationProvider>
                                      </Index.Box>
                                      {errors.dataArray &&
                                        errors.dataArray[index] &&
                                        errors.dataArray[index].mem_dob &&
                                        touched &&
                                        touched.dataArray &&
                                        touched.dataArray[index] &&
                                        touched.dataArray[index].mem_dob && (
                                          <p className="error-text">
                                            {errors.dataArray[index].mem_dob}
                                          </p>
                                        )}
                                    </Index.Grid>
                                    <Index.Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      md={4}
                                      lg={3}
                                    >
                                      <Index.Typography
                                        variant="label"
                                        component="label"
                                        className="input-label"
                                      >
                                        Age (ઉંમર)
                                      </Index.Typography>
                                      <Index.Box className="input-design-div with-border">
                                        <Index.TextField
                                          hiddenLabel
                                          id="filled-hidden-label-normal"
                                          placeholder="Age"
                                          variant="filled"
                                          type="number"
                                          className="admin-input-design input-placeholder"
                                          autoComplete="off"
                                          name={`dataArray.${index}.mem_age`}
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          value={
                                            values.dataArray[index].mem_age
                                          }
                                          disabled
                                        />
                                      </Index.Box>
                                    </Index.Grid>
                                    <Index.Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      md={4}
                                      lg={3}
                                    >
                                      <Index.Typography
                                        variant="label"
                                        component="label"
                                        className="input-label"
                                      >
                                        Is Business* (બિઝનેસ છે)
                                      </Index.Typography>
                                      <Index.Box className="input-design-div with-border">
                                        <Index.Select
                                          hiddenLabel
                                          fullWidth
                                          id="hgh"
                                          defaultValue={
                                            values
                                              ? values.dataArray[index]
                                                  .mem_isBusiness
                                              : ""
                                          }
                                          name={`dataArray.${index}.mem_isBusiness`}
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          value={
                                            values.dataArray[index]
                                              .mem_isBusiness
                                          }
                                        >
                                          <Index.MenuItem value={"Business"}>
                                            Business
                                          </Index.MenuItem>
                                          <Index.MenuItem value={"Retired"}>
                                            Retired
                                          </Index.MenuItem>
                                          <Index.MenuItem value={"House Wife"}>
                                            House Wife
                                          </Index.MenuItem>
                                          <Index.MenuItem value={"Student"}>
                                            Student
                                          </Index.MenuItem>
                                          <Index.MenuItem value={"Job"}>
                                            Job
                                          </Index.MenuItem>
                                        </Index.Select>
                                      </Index.Box>
                                      {errors.dataArray &&
                                        errors.dataArray[index] &&
                                        errors.dataArray[index]
                                          .mem_isBusiness &&
                                        touched &&
                                        touched.dataArray &&
                                        touched.dataArray[index] &&
                                        touched.dataArray[index]
                                          .mem_isBusiness && (
                                          <p className="error-text">
                                            {
                                              errors.dataArray[index]
                                                .mem_isBusiness
                                            }
                                          </p>
                                        )}
                                    </Index.Grid>
                                    <Index.Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      md={4}
                                      lg={3}
                                    >
                                      <Index.Typography
                                        variant="label"
                                        component="label"
                                        className="input-label"
                                      >
                                        Relation* (સંબંધ)
                                      </Index.Typography>
                                      <Index.Box className="input-design-div with-border">
                                        <Index.Select
                                          hiddenLabel
                                          fullWidth
                                          id="hgh"
                                          name={`dataArray.${index}.mem_hof_relation`}
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          value={
                                            values.dataArray[index]
                                              .mem_hof_relation
                                          }
                                        >
                                          {getRelationList &&
                                            getRelationList?.map((option) => (
                                              <Index.MenuItem
                                                key={option.relation_id}
                                                value={option.relation_id}
                                                name={option.relation_name}
                                              >
                                                {option.relation_name}
                                              </Index.MenuItem>
                                            ))}
                                        </Index.Select>
                                      </Index.Box>
                                      {errors.dataArray &&
                                        errors.dataArray[index] &&
                                        errors.dataArray[index]
                                          .mem_hof_relation &&
                                        touched &&
                                        touched.dataArray &&
                                        touched.dataArray[index] &&
                                        touched.dataArray[index]
                                          .mem_hof_relation && (
                                          <p className="error-text">
                                            {
                                              errors.dataArray[index]
                                                .mem_hof_relation
                                            }
                                          </p>
                                        )}
                                    </Index.Grid>
                                    <Index.Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      md={4}
                                      lg={3}
                                    >
                                      <Index.Typography
                                        variant="label"
                                        component="label"
                                        className="input-label"
                                      >
                                        Maritial Status* (વૈવાહિક સ્થિતિ)
                                      </Index.Typography>
                                      <Index.Box className="input-design-div with-border">
                                        <Index.Select
                                          hiddenLabel
                                          fullWidth
                                          id="hgh"
                                          name={`dataArray.${index}.maritialStatus`}
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          value={
                                            values.dataArray[index]
                                              .maritialStatus
                                          }
                                        >
                                          {getMaritalList &&
                                            getMaritalList?.map((option) => (
                                              <Index.MenuItem
                                                key={option.maritalstatus_id}
                                                value={option.maritalstatus_id}
                                                name={option.maritalstatus_name}
                                              >
                                                {option.maritalstatus_name}
                                              </Index.MenuItem>
                                            ))}
                                        </Index.Select>
                                      </Index.Box>
                                      {errors.dataArray &&
                                        errors.dataArray[index] &&
                                        errors.dataArray[index]
                                          .maritialStatus &&
                                        touched &&
                                        touched.dataArray &&
                                        touched.dataArray[index] &&
                                        touched.dataArray[index]
                                          .maritialStatus && (
                                          <p className="error-text">
                                            {
                                              errors.dataArray[index]
                                                .maritialStatus
                                            }
                                          </p>
                                        )}
                                    </Index.Grid>
                                    <Index.Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      md={4}
                                      lg={3}
                                    >
                                      <Index.Typography
                                        variant="label"
                                        component="label"
                                        className="input-label"
                                      >
                                        Mobile No* (મોબાઈલ નં)
                                      </Index.Typography>
                                      <Index.Box className="input-design-div with-border">
                                        <Index.TextField
                                          inputProps={{ maxlength: 10 }}
                                          type="number"
                                          hiddenLabel
                                          id="filled-hidden-label-normal"
                                          placeholder="Mobile No"
                                          variant="filled"
                                          className="admin-input-design input-placeholder"
                                          autoComplete="off"
                                          name={`dataArray.${index}.mem_mobile`}
                                          onBlur={handleBlur}
                                          // onChange={handleChange}
                                          onChange={(e) => {
                                            setFieldValue(
                                              `dataArray.${index}.mem_mobile`,
                                              e.target.value.slice(0, 10)
                                            );
                                          }}
                                          value={
                                            values.dataArray[index].mem_mobile
                                          }
                                        />
                                      </Index.Box>
                                      {errors.dataArray &&
                                        errors.dataArray[index] &&
                                        errors.dataArray[index].mem_mobile &&
                                        touched &&
                                        touched.dataArray &&
                                        touched.dataArray[index] &&
                                        touched.dataArray[index].mem_mobile && (
                                          <p className="error-text">
                                            {errors.dataArray[index].mem_mobile}
                                          </p>
                                        )}
                                    </Index.Grid>

                                    {index > 0 && !editData && (
                                      <Index.Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={4}
                                        lg={3}
                                      >
                                        <Index.Box className="common-button blue-button save-btn remove-btn">
                                          <Index.Button
                                            variant="contained"
                                            onClick={() => remove(index)}
                                          >
                                            Remove
                                          </Index.Button>
                                        </Index.Box>{" "}
                                      </Index.Grid>
                                    )}
                                  </Index.Grid>
                                </div>
                              ))}
                            </div>
                          )}
                        </PagesIndex.FieldArray>
                      </Index.Box>

                      <Index.Box className="line" mt={3} mb={3}></Index.Box>
                      <Index.Box className="common-button blue-button flex-start save-btn text-end-details">
                        <Index.Button
                          variant="contained"
                          type="submit"
                          disabled={values.isSubmit}
                        >
                          Submit
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </form>
                )}
              </PagesIndex.Formik>
            </Index.Box>
          </Index.Box>
        </>
      )}
    </>
  );
};
export const generateFamilyID = () => {
  const minLength = 5;
  const startingDigit = 8;

  let familyID = startingDigit.toString();

  while (familyID.length < minLength) {
    familyID += Math.floor(Math.random() * 10);
  }

  return familyID;
};
export default NewMemberAdd;
