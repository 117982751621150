import Index from "../../../Index";
import React, { useEffect, useState } from "react";
import PagesIndex from "../../../PagesIndex";

import { getRecoveryLoanList } from "../../../../redux/features/AddService";

import PageLoading from "../../../../components/common/pageLoading/PageLoading";
import RowPerPageDropdown from "../../../../components/common/paginationRowPerpage/RowPerPageDropdown";
import styled from "styled-components";
import { Tooltip, tooltipClasses } from "@mui/material";
import RecoveryAddEdit from "./RecoveryAddEdit";

const RecoveryLoan = () => {
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      background: "#eaac35",
      color: "#ffffff",
      fontSize: "11px",
      fontFamily: "Poppins-Regular",
      padding: "5px 9px",
      lineHeight: "19px",
      borderRadius: "10px",
    },
  }));

  // all state

  const [pageData, setPageData] = useState([]);
  const [rowPerPage, setRowPerPage] = useState("25");
  const [SahaytypeMasterlist, setSahaytypeMasterlist] = useState([]);
  const [searchedData, setSearchedData] = React.useState([]);
  const [amountOpen, setAmountOpen] = React.useState(false);
  const [loader, setLoader] = useState(true);
  const [claimedAmount, setClaimedAmount] = useState("");
  const [approvedAmount, setApprovedAmount] = useState("");
  const [loanInstallment, setLoanInstallment] = useState([]);
  const [SahayApplicationId, setSahayApplicationId] = useState("");
  const [recoveryAmount, setRecoveryAmount] = useState([]);
  const [totalDisbursedAmount, setTotalDisbursedAmount] = useState([]);
  const [completed, setCompleted] =useState(false)

  const dispatch = PagesIndex.useDispatch();
  const rollData = JSON.parse(localStorage?.getItem("RollName"))?.toLowerCase();

  const { getAdminLoginDetails } = PagesIndex.useSelector(
    (state) => state.admin
  );

  const handleOpenApproved = (
    applicationId,
    claimedAmount,
    sahayAmount,
    approvedAmountData,
    loanInstallment,
    recoveryAmount,
    totalDisbursedAmount,
    recoveryStatus
  ) => {
    const totalAmount = claimedAmount + sahayAmount;
    setAmountOpen(true);
    setSahayApplicationId(applicationId);
    setClaimedAmount(totalAmount);

    setApprovedAmount(approvedAmountData);
    setTotalDisbursedAmount(totalDisbursedAmount);
    setLoanInstallment(JSON.parse(loanInstallment));
    setRecoveryAmount(recoveryAmount && JSON.parse(recoveryAmount));
    setCompleted(recoveryStatus === "Complete"
      ? true
      : false)
  };



  const getPrograms = () => {
    dispatch(getRecoveryLoanList()).then((data) => {
      if (data?.payload) {
        setLoader(false);

        const filterData = data?.payload.filter((row) => {
          // Check if isDisbursed is 1
          const isDisbursedCondition = row?.isDisbursed == 1;

          // Additional check based on the user role and permissions
          const permissionCondition =
            rollData === "admin" || rollData === "staff" ||
            (getAdminLoginDetails?.sahayPermission &&
              getAdminLoginDetails?.sahayPermission.includes(row?.subSahayTypeName));

          // Only include data if both conditions are satisfied
          return isDisbursedCondition && permissionCondition;
        });

        setSahaytypeMasterlist(filterData);
      }
    });
  };

  const handleCloseApproved = () => {
    setAmountOpen(false);
    getPrograms();
  };


  useEffect(() => {
    getPrograms();
  }, []);

  useEffect(() => {
    setSearchedData(SahaytypeMasterlist);
  }, [SahaytypeMasterlist]);

  const requestSearch = (searched) => {
    if (!searched) return setSearchedData(SahaytypeMasterlist);
    setSearchedData(
      SahaytypeMasterlist.filter((item) =>
        item?.programName.toLowerCase().includes(searched.toLowerCase())
      )
    );
  };

  //   if (
  //     rollPermissionData?.permissionArray?.includes("Program Master_view") ||
  //     rollPermissionNameData === "admin"
  //   ) {
  //   } else {
  //     navigate(`/${rollData}/sahay-application`);
  //   }

  return (
    <>
      {loader ? (
        <PageLoading />
      ) : (
        <>
          <Index.Box className="page-content-box">
            <Index.Box className="barge-common-box">
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex res-title-header-flex">
                  <Index.Box className="plus-hidden-show-mobile">
                    <Index.Box className="title-main">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="page-title"
                      >
                        Recovery Loan
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="d-flex align-items-center res-set-search">
                    <PagesIndex.Search
                      className="search"
                      onChange={(e) => requestSearch(e.target.value)}
                    >
                      <PagesIndex.StyledInputBase
                        placeholder="Search"
                        inputProps={{ "aria-label": "search" }}
                      />
                    </PagesIndex.Search>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="sahay-table">
                <Index.TableContainer
                  sx={{ maxHeight: 340 }}
                  // component={Index.Paper}
                  className="table-container sahay-application-container scroll-table common-table-container-custom "
                >
                  <Index.Table
                    stickyHeader aria-label="sticky table"
                    className="table-design-main barge-table sahay-appli-table"
                  >
                    <Index.TableHead className="sahay-appli-head">
                      <Index.TableRow className="sahay-appli-tr">
                        <Index.TableCell className="sahay-appli-tr">
                          Type
                        </Index.TableCell>
                        {rollData?.toLowerCase()?.match("staff")?.length && (
                          <Index.TableCell className="sahay-appli-tr">
                            Application Id
                          </Index.TableCell>
                        )}
                        <Index.TableCell className="sahay-appli-tr">
                          Family Id
                        </Index.TableCell>
                        <Index.TableCell
                          className="user-sahay-td"
                          style={{ cursor: "pointer" }}
                        >
                          User Name
                        </Index.TableCell>
                        <Index.TableCell
                          className="sahay-appli-tr"
                          align="left"
                        >
                          Sahay Category
                        </Index.TableCell>
                        <Index.TableCell
                          className="sahay-appli-tr"
                          align="left"
                        >
                          Sub Sahay Category
                        </Index.TableCell>
                        <Index.TableCell
                          className="sahay-appli-tr"
                          align="left"
                        >
                          Requested Sahay
                        </Index.TableCell>

                        <Index.TableCell
                          className="sahay-appli-tr"
                          align="left"
                        >
                          Approved Amount
                        </Index.TableCell>

                        <Index.TableCell
                          className="sahay-appli-tr"
                          align="left"
                        >
                          Beneficiary Name
                        </Index.TableCell>

                        <Index.TableCell
                          className="sahay-appli-tr"
                          align="left"
                        >
                          Staff Status
                        </Index.TableCell>
                        <Index.TableCell
                          className="sahay-appli-tr"
                          align="left"
                        >
                          Staff Remark
                        </Index.TableCell>
                        <Index.TableCell
                          className="sahay-appli-tr"
                          align="left"
                        >
                          Committee Status
                        </Index.TableCell>
                        <Index.TableCell
                          className="sahay-appli-tr"
                          align="left"
                        >
                          Committee Remark
                        </Index.TableCell>
                        <Index.TableCell
                          className="sahay-appli-tr"
                          align="left"
                        >
                          Disbursement Status
                        </Index.TableCell>
                        <Index.TableCell
                          className="sahay-appli-tr"
                          align="left"
                        >
                          Recovery Status
                        </Index.TableCell>

                        {rollData?.toLowerCase()?.match("committee")
                          ?.length && (
                            <Index.TableCell
                              className="sahay-appli-tr"
                              align="left"
                            >
                              Approved By
                            </Index.TableCell>
                          )}

                        <Index.TableCell
                          className="sahay-appli-tr"
                          align="left"
                        >
                          {rollData != "admin" && <>Action</>}
                        </Index.TableCell>
                      </Index.TableRow>
                    </Index.TableHead>
                    <Index.TableBody>
                      {pageData?.length ? (
                        pageData?.map((row, index) => (
                          <Index.TableRow
                            key={row?.id}
                            className={`sahay-appli-tr 
                                  }`}
                          >
                            <Index.TableCell
                              align="left"
                              className="sahay-appli-td"
                            >
                              {row?.vastipatrak == 1
                                ? "Vastipatrak"
                                : "Non Vastipatrak"}
                            </Index.TableCell>
                            {rollData?.toLowerCase()?.match("staff")
                              ?.length && (
                                <Index.TableCell
                                  align="left"
                                  className="sahay-appli-td"
                                >
                                  {row?.applicationId}
                                </Index.TableCell>
                              )}
                            <Index.TableCell
                              align="left"
                              className="sahay-appli-td"
                            >
                              {row?.familyId}
                            </Index.TableCell>
                            <Index.TableCell
                              align="left"
                              className="sahay-appli-td"
                            >
                              {row?.mem_fname} {row?.mem_mname} {row?.mem_lname}
                            </Index.TableCell>

                            <Index.TableCell
                              align="left"
                              className="sahay-appli-td"
                            >
                              {row?.SahayTypeName}
                            </Index.TableCell>
                            <Index.TableCell
                              align="left"
                              className="sahay-appli-td"
                            >
                              {row?.subSahayTypeName}
                            </Index.TableCell>
                            <Index.TableCell
                              align="left"
                              className="sahay-appli-td"
                            >
                              {row?.SahayAmmount == null ||
                                row?.SahayAmmount == 0
                                ? row?.sahay_amount
                                : row?.sahay_amount == null ||
                                  row?.sahay_amount == 0
                                  ? row?.SahayAmmount
                                  : "0"}
                            </Index.TableCell>

                            <Index.TableCell
                              align="left"
                              className="sahay-appli-td"
                            >
                              {row?.approveAmount || "-"}
                            </Index.TableCell>

                            <Index.TableCell
                              align="left"
                              className="sahay-appli-td"
                            >
                              {row?.beneficiaryName}
                            </Index.TableCell>

                            <Index.TableCell
                              align="left"
                              className="sahay-appli-td"
                            >
                              <Index.Box
                                className={`status-details ${row?.newStatusColumn == "Pending"
                                    ? "pending - status"
                                    : row?.newStatusColumn == "Approved"
                                      ? "approved-status"
                                      : row?.newStatusColumn == "Rejected"
                                        ? "reject-status"
                                        : ""
                                  }`}
                              >
                                {row?.newStatusColumn}
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell
                              align="left"
                              className="sahay-appli-td"
                              sx={{ textAlign: "center" }}
                            >
                              {row?.staffRemark === "null" ? (
                                "-"
                              ) : row?.staffRemark === "undefined" ? (
                                "-"
                              ) : row?.staffRemark === null ? (
                                "-"
                              ) : (
                                <LightTooltip title={row?.staffRemark}>
                                  {row?.staffRemark
                                    ? row.staffRemark?.slice(0, 15) + "......"
                                    : "-"}
                                </LightTooltip>
                              )}
                            </Index.TableCell>
                            <Index.TableCell
                              align="left"
                              className="sahay-appli-td"
                              sx={{ textAlign: "center" }}
                            >
                              <Index.Box
                                className={`status-details ${row?.committeeStatus == "Pending"
                                    ? "pending - status"
                                    : row?.committeeStatus == "Approved"
                                      ? "approved-status"
                                      : row?.committeeStatus == "Rejected"
                                        ? "reject-status"
                                        : ""
                                  }`}
                              >
                                {row?.committeeStatus === null
                                  ? "-"
                                  : row?.committeeStatus}
                              </Index.Box>
                            </Index.TableCell>

                            <Index.TableCell
                              align="left"
                              className="sahay-appli-td"
                              sx={{ textAlign: "center" }}
                            >
                              {row?.committeeRemark === "null" ? (
                                "-"
                              ) : row?.committeeRemark === "undefined" ? (
                                "-"
                              ) : row?.committeeRemark === null ? (
                                "-"
                              ) : (
                                <LightTooltip title={row?.committeeRemark}>
                                  {row.committeeRemark?.slice(0, 15) + "......"}
                                </LightTooltip>
                              )}
                            </Index.TableCell>
                            <Index.TableCell
                              align="left"
                              className="sahay-appli-td"
                              sx={{ textAlign: "center" }}
                            >
                              <Index.Box
                                className={`status-details ${row?.isDisbursed === 0
                                    ? "pending-status"
                                    : row?.isDisbursed === 1
                                      ? "approved-status"
                                      : ""
                                  }`}
                              >
                                {/* {checkStatus.toUpperCase()} */}
                                {row?.isDisbursed === 0 &&
                                  row?.disbursedStatus == null
                                  ? "Pending"
                                  : row?.isDisbursed === 1 &&
                                    (row?.disbursedStatus == "Complete" ||
                                      row?.disbursedStatus == null)
                                    ? "Completed"
                                    : row?.isDisbursed === 0 &&
                                      row?.disbursedStatus == "PartialyPending"
                                      ? "Partially Pending"
                                      : "-"}
                                {/* {row?.newStatusColumn} */}
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell
                              align="left"
                              className="sahay-appli-td"
                            >
                              <Index.Box
                                className={`status-details ${row?.recoveryStatus == "Pending"
                                    ? "pending - status"
                                    : row?.recoveryStatus == "Complete"
                                      ? "approved-status"
                                      : ""
                                  }`}
                              >
                                {row?.recoveryStatus === "Complete"
                                  ? "Complete"
                                  : "Pending"}
                              </Index.Box>
                            </Index.TableCell>
                            {rollData?.toLowerCase()?.match("committee")
                              ?.length && (
                                <Index.TableCell
                                  align="left"
                                  className="sahay-appli-td"
                                >
                                  {row?.actionBy || "-"}
                                </Index.TableCell>
                              )}

                            <Index.TableCell
                              sx={{ display: "flex" }}
                              className="sahay-appli-td"
                            >
                              <Index.Box className="flex-content-sahay">
                                {rollData != "admin" && (
                                  <LightTooltip title="Add recovery">
                                    <Index.Box className="approved-btn">
                                      <Index.Button
                                        className="approve-btn-list"
                                        onClick={() => {
                                          handleOpenApproved(
                                            row?.applicationId,
                                            row?.SahayAmmount,
                                            row?.sahay_amount,
                                            row?.approveAmount,
                                            row?.loanInstallment,
                                            row?.RecoveryAmount,
                                            row?.totalDisbursedAmount,
                                            row?.recoveryStatus
                                          );
                                        }}
                                      >
                                        <img
                                          src={PagesIndex.Svg.approve}
                                          className="approve"
                                        ></img>
                                      </Index.Button>
                                    </Index.Box>
                                  </LightTooltip>
                                )}
                              </Index.Box>
                            </Index.TableCell>
                          </Index.TableRow>
                        ))
                      ) : (
                        <PagesIndex.NoRecordFound colSpan={12} />
                      )}
                    </Index.TableBody>
                  </Index.Table>
                </Index.TableContainer>
              </Index.Box>
              <Index.Box className="pagination-design flex-end">
                <RowPerPageDropdown
                  rowPerPage={rowPerPage}
                  setRowPerPage={setRowPerPage}
                />
                <Index.Stack spacing={2}>
                  <PagesIndex.Pagination
                    fetchData={searchedData}
                    setPageData={setPageData}
                    pageData={pageData}
                    rowPerPage={rowPerPage}
                  />
                </Index.Stack>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <RecoveryAddEdit
            handleCloseApproved={handleCloseApproved}
            amountOpen={amountOpen}
            showClaimedAmount={claimedAmount}
            approvedAmonut={approvedAmount}
            loanInstallment={loanInstallment}
            SahayApplicationId={SahayApplicationId}
            recoveryAmount={recoveryAmount}
            totalDisbursedAmount={totalDisbursedAmount}
            isCompleted={completed}
          />
        </>
      )}
    </>
  );
};

export default RecoveryLoan;
