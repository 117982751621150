import React, { useEffect, useState } from "react";
import Sidebar from "../../../../components/user/defaultLayout/Sidebar";
import Index from "../../../../container/Index";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import PagesIndex from "../../../PagesIndex";
import { Autocomplete, Tooltip, tooltipClasses } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import moment from "moment";
import dayjs from "dayjs";
import * as yup from "yup";
import {
  getFamilyIds,
  getSahayTypeList,
  getSahaySubTypeList,
  getFamilyMemberById,
  applyForSahayAPI,
  listYearOfSahay,
  bankAccountsList,
  getRelationMasterList,
  addPreviousSahayData,
  getListOfFamilyId,
  updatePreviousData,
  addDisbursementData,
  addSingleDisbursementData,
  editSingleDisbursementData,
  getAllBankList,
} from "../../../../redux/features/AddService";
import {
  validationSchemaDisbursement,
  validationSchemaApplyForSahayBankMember,
} from "../../../../validation/Validation";
import { Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { Api } from "../../../../config/Api";
import dataService, { imagePath } from "../../../../config/DataService";
import { toast } from "react-toastify";
import PageLoading from "../../../../components/common/pageLoading/PageLoading";
import { useSelector } from "react-redux";
import { MobileDatePicker } from "@mui/x-date-pickers";
import styled from "styled-components";
import { FieldArray } from "formik";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: "#eaac35",
    color: "#ffffff",
    fontSize: "11px",
    fontFamily: "Poppins-Regular",
    padding: "5px 9px",
    lineHeight: "19px",
    borderRadius: "10px",
  },
}));

const applySahayValidation = yup.object().shape({
  AadharNo: yup
    .number()
    .typeError("Only Number allowed")
    .required("Aadhar No Required"),
});

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const ariaLabel = { "aria-label": "description" };

const AddDisbursement = () => {
  const { editPreviousSahayData, getUpdatedPriviousData } =
    PagesIndex.useSelector((state) => state.admin);
  const {
    getUserLoginDetails,
    sahayApplicationAdminSingleGet,
    getRelationList,
  } = PagesIndex.useSelector((state) => state.admin);

  const theme = useTheme();
  const dispatch = PagesIndex.useDispatch();
  const navigate = PagesIndex.useNavigate();
  const location = PagesIndex.useLocation();
  const stateNavigate = location?.state?.editDisbursement ? location?.state?.editDisbursement : location?.state?.SahayApplicationId;

  const sahayId = location?.state?.SahayApplicationId;
  const editDisbursement = location?.state?.editDisbursement;
  const rollData = JSON.parse(localStorage?.getItem("RollName"))?.toLowerCase();

  //all state
  const [personName, setPersonName] = React.useState([]);
  const [documentError, setDocumentError] = React.useState(false);
  const [listYearOfSahayData, setListYearOfSahayData] = useState([]);
  const [bankAccountsListData, setBankAccountsListData] = useState([]);
  const [approvedAmountData, setApprovedAmountData] = useState(null);
  const [applicantId, setApplicantId] = useState();
  const [familyIdsList, setFamilyIdsList] = useState();
  const [sahayTypeList, setSahayTypeList] = useState();
  const [sahaySubTypeList, setSahaySubTypeList] = useState();
  const [familyMembers, setFamilyMembers] = useState();
  const [selectedImage, setSelectedImage] = useState();
  const [sahayTypecondition, setSahayTypeCondition] = useState();
  const [sahayCondition, setSahayCondition] = useState();
  const [singlaFamilyId, setSingleFamilyId] = useState();
  const [getDocument, setGetDocument] = useState({});
  const [selectApplicant, setSelectApplicant] = useState();
  const [getSingleSubSahay, setGetSingleSubSahay] = useState();
  const [applicantSelectedMember, setApplicantSelectedMember] = useState([]);
  const [applicantAllMember, setApplicantAllMember] = useState([]);
  const [pageLoader, setPageLoader] = useState(false);
  const [singleDisbursementData, setSingleDisbursementData] = useState({});
  const [singleDisbursementDocumentsData, setSingleDisbursementDocumentsData] =
    useState([]);
  const [initialAmount, setinitialAmount] = useState(sahayId?.ApproveAmount);
  const [isCompleted, setIsCompleted] = useState(0);
  const [bankList, setBankList] = useState([]);

  ///// Previous Sahay Data

  const [familyListData, setFamilyListData] = useState([]);
  const [updatePreviousSahayData, setUpdatePreviousSahayData] = useState([]);
  const [familyIdForApplicant, setFamilyIdForApplicant] = useState("");

  const EducationSubsahayValue = [
    1015, 1017, 1010, 1007, 1006, 1008, 1009, 1010, 1011, 1012, 1013, 1019,
    1018, 1020, 1021, 1022, 1023,
  ];

  let familyMemberName =
    getUserLoginDetails &&
    getUserLoginDetails?.data?.mem_fname +
      " " +
      getUserLoginDetails?.data?.mem_mname +
      " " +
      getUserLoginDetails?.data?.mem_lname;

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const [alignment, setAlignment] = React.useState("web");

  //   useEffect(() => {
  //     dispatch(updatePreviousData(editPreviousData?.id)).then((data) => {
  //       if (data?.payload?.status) {
  //         setUpdatePreviousSahayData(data?.payload?.data);
  //       }
  //     });
  //   }, [editPreviousData?.id]);

  const getFamilyMemberIds = (status) => {
    dispatch(getFamilyIds(status)).then((res) => {
      setFamilyIdsList(
        res?.payload?.map((ele, i) => {
          return {
            familyId: ele?.familyId,
            firstName: ele?.firstName,
            lastName: ele?.lastName,
            middleName: ele?.middleName,
            fullName:
              ele?.firstName + " " + ele?.middleName + " " + ele?.lastName,
          };
        })
      );
    });
  };

  //   VoucherNo, ChequeNo, BankIssueName, RepayDetail, SahayApplicationId
  let initialValues;
  if (Object?.keys(singleDisbursementData).length) {

    initialValues = {
      VoucherNo: singleDisbursementData?.VoucherNo
        ? singleDisbursementData?.VoucherNo
        : singleDisbursementData?.voucherNo
        ? singleDisbursementData?.voucherNo
        : "",
      approveAmount: singleDisbursementData?.approveAmount
        ? singleDisbursementData?.approveAmount
        : singleDisbursementData?.ApproveAmount
        ? singleDisbursementData?.ApproveAmount
        : "",
      checkIssueDate: singleDisbursementData?.checkIssueDate
        ? singleDisbursementData?.checkIssueDate
        : singleDisbursementData?.checkIssueDate
        ? singleDisbursementData?.checkIssueDate
        : "",
      SahayApplicationId: editDisbursement?.id,
      RepayDetail: singleDisbursementData?.RepayDetail
        ? singleDisbursementData?.RepayDetail
        : singleDisbursementData?.repayDetails
        ? singleDisbursementData?.repayDetails
        : "",
      BankIssueName: singleDisbursementData?.BankIssueName
        ? singleDisbursementData?.BankIssueName
        : singleDisbursementData?.bankIssueName
        ? singleDisbursementData?.bankIssueName
        : "",
      ChequeNo: singleDisbursementData?.ChequeNo
        ? singleDisbursementData?.ChequeNo
        : singleDisbursementData?.chequeNo
        ? singleDisbursementData?.chequeNo
        : "",
      chequeDate: singleDisbursementData?.chequeDate
        ? singleDisbursementData?.chequeDate
        : singleDisbursementData?.chequeDate
        ? singleDisbursementData?.chequeDate
        : "",
      StartDate: singleDisbursementData?.StartDate
        ? singleDisbursementData?.StartDate
        : singleDisbursementData?.startDate
        ? singleDisbursementData?.startDate
        : "",
      documents: singleDisbursementDocumentsData
        ? singleDisbursementDocumentsData
        : [],
      userName: location?.state
        ? `${location?.state?.editDisbursement?.mem_fname} ${location?.state?.editDisbursement?.mem_lname}`
        : "",
      familyId: location?.state
        ? location?.state?.editDisbursement?.familyId
        : "",

      isSubmit: false,

      partialAmount: singleDisbursementData?.disbursedInstallment
      ? (singleDisbursementData?.disbursedInstallment !== undefined && singleDisbursementData?.disbursedInstallment !== null)
        ? JSON.parse(singleDisbursementData?.disbursedInstallment).map((row) => {
            return {
              disbursedAmount: row?.disbursedAmount,
              pendingAmount: row?.pendingAmount,
              DisbursedDate: row?.DisbursedDate,
              isNewRow: false,
            };
          })
        : []  // If disbursedInstallment is null or undefined, return an empty array
      : []  // If disbursedInstallment is not present at all, return an empty array
    
        
    };

    
  } else {
    initialValues = {
      VoucherNo: "",
      checkIssueDate: "",
      SahayApplicationId: sahayId?.id,
      RepayDetail: "",
      BankIssueName: "",
      ChequeNo: "",
      chequeDate: "",
      StartDate: "",
      documents: [],
      userName: location?.state
        ? `${location?.state?.SahayApplicationId?.mem_fname} ${location?.state?.SahayApplicationId?.mem_lname}`
        : "",
      familyId: location?.state
        ? location?.state?.SahayApplicationId?.familyId
        : "",
      isSubmit: false,
      partialAmount: [
        {
          disbursedAmount: "",
          pendingAmount: "",
          DisbursedDate: new Date(),
          isNewRow: true,
        },
      ],
    };
  }

  //   let initialValues = {
  //     VoucherNo: sahayId?.id ? +sahayId?.VoucherNo : "",
  //     SahayApplicationId: sahayId?.id ? +sahayId?.id : sahayId?.id,
  //     RepayDetail: sahayId?.id ? sahayId?.RepayDetail : "",
  //     BankIssueName: sahayId?.id ? sahayId?.BankIssueName : "",
  //     ChequeNo: sahayId?.id ? sahayId?.ChequeNo : "",
  //     documents: sahayId?.id ? updatePreviousSahayData?.documents : [],
  //   };

  useEffect(() => {
    dispatch(listYearOfSahay()).then((data) => {
      if (data?.payload) {
        setListYearOfSahayData(data?.payload);
      } else {
        setListYearOfSahayData([]);
      }
    });
  }, []);

  useEffect(() => {
    if (applicantId) {
      dispatch(bankAccountsList(applicantId)).then((data) => {
        if (data?.payload) {
          setBankAccountsListData(data?.payload);
        } else {
          setBankAccountsListData([]);
        }
      });
    }
  }, [applicantId]);

  ///////////////// Previous sahay ///////////////

  useEffect(() => {
    dispatch(getListOfFamilyId()).then((res) => {
      if (res?.payload) {
        setFamilyListData(res?.payload?.data);
      } else {
        setFamilyListData([]);
      }
    });
  }, []);

  // const handleBack = () => {
  //   navigate("/admin/disbursement-application");
  // };

  const handleBack = () => {
    navigate(`/${rollData}/disbursement-application`, {
      state: {
        rowID: stateNavigate?.id,
        prevState: location?.state?.editDisbursement?.lastStatus ? location?.state?.editDisbursement?.lastStatus : location?.state?.SahayApplicationId?.lastStatus,
      },
    });
  };

  //handle submit
  // SahayType:1
  // subSahayType:2
  // SahayAmmount:12222
  // yearOfSahay:1
  // familyId:221
  // RepayDetails:nothing
  // totalMonth:12
  // startDate:2023-09-28
  // applicantId:2221

  const handleFormSubmit = (values, { setFieldValue }) => {
    setFieldValue("isSubmit", true);
    const data = new FormData();

    if (editDisbursement?.id) {
      data.append("id", +singleDisbursementData?.id);
    }
    data.append("SahayApplicationId", +values?.SahayApplicationId);
    data.append("BankIssueName", values?.BankIssueName);
    data.append("ChequeNo", values?.ChequeNo);
    data.append("chequeDate", values?.chequeDate);
    data.append("RepayDetail", values?.RepayDetail);
    data.append("StartDate", values?.StartDate);
    data.append("VoucherNo", values?.VoucherNo);
    data.append("checkIssueDate", values?.checkIssueDate);
    data.append("approvedAmount", sahayId?.ApproveAmount);

    // if (values?.documents?.length == 0) {
    //   setDocumentError(true);
    //   return 0;
    // }
    values?.partialAmount?.forEach((amount, index) => {
      data.append(
        `disbursedInstallment[${index}][pendingAmount]`,
        amount?.pendingAmount
      );
      data.append(
        `disbursedInstallment[${index}][disbursedAmount]`,
        amount?.disbursedAmount
      );
      data.append(
        `disbursedInstallment[${index}][DisbursedDate]`,
        moment(amount?.DisbursedDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
      );
    });

    values?.documents?.length > 0 &&
      values?.documents?.forEach((ele) => {
        if (ele instanceof Blob) {
          data.append("documents", ele);
        }
      });

    // if (values?.documents?.length > 0) {
    //   const oldDocumentsArray = [];

    //   values.documents.forEach((ele) => {

    //       const documentObject = {
    //         Documents: ele?.Documents,
    //         Filename: ele?.filename,
    //       };

    //       oldDocumentsArray.push(documentObject);

    //   });

    //   // Now, you can append the oldDocumentsArray to the FormData
    //   data.append("oldDocuments", JSON.stringify(oldDocumentsArray));
    // }

    values?.documents?.length > 0 &&
      values?.documents?.forEach((ele) => {
        if (ele instanceof Blob) {
        } else {
          data.append("oldDocuments", ele?.Documents);
        }
      });
    if (editDisbursement?.id) {
      dispatch(editSingleDisbursementData(data)).then((res) => {
        if (
          (res?.payload !== undefined && res?.payload?.status == 200) ||
          201
        ) {
          navigate(`/${rollData}/disbursement-application`);
          // setTimeout(() => {
          //   setFieldValue("isSubmit", false);
          // }, 1000);
        } else {
          setTimeout(() => {
            setFieldValue("isSubmit", false);
          }, 1000);
        }
      });
    } else {
      dispatch(addSingleDisbursementData(data)).then((res) => {
        if (res?.payload?.status == 201) {
          // navigate("/admin/disbursement-application");
          navigate(`/${rollData}/disbursement-application`);
          // setTimeout(() => {
          //   setFieldValue("isSubmit", false);
          // }, 1000);
        } else {
          setTimeout(() => {
            setFieldValue("isSubmit", false);
          }, 1000);
        }
      });
    }
  };

  //handle submit

  //get single disbursement
  const getSingleSahayApplication = async () => {
    setPageLoader(true);

    try {
      const response = await dataService.get(Api.VIEW_DISBURSEMENT_DATA, {
        params: { applicationId: editDisbursement?.id },
      });
      if (response.data.status == 200) {
        if (response?.data?.data?.disbursedDetails) {
          setSingleDisbursementData(response?.data?.data?.disbursedDetails);
          setApprovedAmountData(
            response?.data?.data?.application?.approveAmount
          );
          setIsCompleted(response?.data?.data?.application?.isDisbursement);
        } else {
          setSingleDisbursementData(response?.data?.data?.application);
          setApprovedAmountData(
            response?.data?.data?.application?.approveAmount
          );
          setIsCompleted(response?.data?.data?.application?.isDisbursement);
        }
        if (response?.data?.data?.disbursedDocuments) {
          setSingleDisbursementDocumentsData(
            response?.data?.data?.disbursedDocuments
          );
        }
        setTimeout(() => {
          setPageLoader(false);
        }, 1000);
      }
    } catch (error) {
      setTimeout(() => {
        setPageLoader(false);
      }, 1000);
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  useEffect(() => {
    // getSingleSahayApplication()
    if (editDisbursement?.id) {
      getSingleSahayApplication();
    }
  }, []);

  const getAllBanksList = () => {
    dispatch(getAllBankList()).then((res) => {
      if (res?.payload) {
        setBankList(res?.payload?.data);
      } else {
        setBankList([]);
      }
    });
  };

  useEffect(() => {
    getAllBanksList();
  }, []);

  const { rollPermissionData } = useSelector((state) => state.admin);
  const rollPermissionNameData =
    rollPermissionData?.findData?.parentRoleName?.toLowerCase();

  if (
    rollPermissionData?.permissionArray?.includes("Disbursement_add") ||
    rollPermissionData?.permissionArray?.includes("Disbursement_edit")
  ) {
  } else {
    navigate(`/${rollData}/sahay-application`);
  }

  return (
    <>
      <div>
        {pageLoader ? (
          <PageLoading />
        ) : (
          <>
            <Index.Box className="apply-sahay-main">
              {/* {location.pathname !== "/admin/add-disbursement-application" && (
                <Sidebar />
              )} */}

              <Index.Box className="head-content">
                <Index.Box
                  // className={location.pathname == "/admin/add-disbursement-application" ? "admin-border-radius" : "user-right"}
                  className={`user-right ${
                    location.pathname == "/admin/add-disbursement-application"
                      ? "admin-border-radius"
                      : ""
                  }`}
                >
                  <Index.Box
                    // className={location.pathname == "/admin/add-disbursement-application" ? "" :"bottom-user-right"}
                    className={`bottom-user-right ${
                      location.pathname == "/admin/add-disbursement-application"
                        ? "admin-height-remove"
                        : ""
                    }`}
                  >
                    <Index.Box className="user-apply-content">
                      <PagesIndex.Formik
                        enableReinitialize
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={validationSchemaDisbursement}
                      >
                        {({
                          values,
                          handleChange,
                          handleSubmit,
                          touched,
                          setFieldValue,
                          handleBlur,
                          errors,
                          isValid,
                          isSubmitting,
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <Index.Box className="main-in-row">
                              <Index.Box className="user-basic user-heading">
                                <Index.Typography
                                  component="h1"
                                  className="head-apply"
                                >
                                  {editDisbursement
                                    ? "Edit disbursement"
                                    : "Apply for disbursement"}
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className="main-in-row">
                              <Index.Box
                                className="user-basic"
                                sx={{ display: "flex" }}
                              ></Index.Box>
                              <Index.Box
                                sx={{ flexGrow: 1 }}
                                className="al-form-groups"
                              >
                                <Index.Grid container spacing={2}>
                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        Family Id (કૌટુંબિક આઈડી)
                                      </Index.Typography>
                                      <Index.TextField
                                        disabled
                                        type="number"
                                        inputProps={ariaLabel}
                                        className="basic-input input-width"
                                        name="familyId"
                                        value={values?.familyId}
                                      />
                                    </Index.Box>
                                    {/* {errors?.familyId &&
                                      touched?.familyId && (
                                        <p className="error-text">
                                          {errors?.familyId}
                                        </p>
                                      )} */}
                                  </Index.Grid>
                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        User Name (વપરાશકર્તા નામ)
                                      </Index.Typography>
                                      <Index.TextField
                                        disabled
                                        type="text"
                                        inputProps={ariaLabel}
                                        className="basic-input input-width"
                                        name="userName"
                                        value={values?.userName}
                                      />
                                    </Index.Box>
                                    {/* {errors?.userName &&
                                      touched?.userName && (
                                        <p className="error-text">
                                          {errors?.userName}
                                        </p>
                                      )} */}
                                  </Index.Grid>

                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        Voucher No (વાઉચર નં.)
                                      </Index.Typography>
                                      <Index.TextField
                                        type="number"
                                        inputProps={ariaLabel}
                                        className="basic-input input-width"
                                        name="VoucherNo"
                                        value={values?.VoucherNo}
                                        onChange={(e) => {
                                          if (e.target.value.length <= 12) {
                                            // Only update the value if it's not more than 40 characters
                                            handleChange(e);
                                          }
                                        }}
                                        onBlur={handleBlur}
                                      />
                                    </Index.Box>
                                    {errors?.VoucherNo &&
                                      touched?.VoucherNo && (
                                        <p className="error-text">
                                          {errors?.VoucherNo}
                                        </p>
                                      )}
                                  </Index.Grid>
                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        Cheque No. (ચેક નં.)
                                      </Index.Typography>
                                      <Index.TextField
                                        inputProps={ariaLabel}
                                        className="basic-input input-width"
                                        name="ChequeNo"
                                        value={values?.ChequeNo}
                                        onChange={(e) => {
                                          if (e.target.value.length <= 10) {
                                            // Only update the value if it's not more than 40 characters
                                            handleChange(e);
                                          }
                                        }}
                                        onBlur={handleBlur}
                                      />
                                    </Index.Box>
                                    {errors?.ChequeNo && touched?.ChequeNo && (
                                      <p className="error-text">
                                        {errors?.ChequeNo}
                                      </p>
                                    )}
                                  </Index.Grid>

                                  {/* <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Typography
                                      component="p"
                                      className="in-label"
                                    >
                                      Bank Issue Name
                                    </Index.Typography>
                                    <Index.Box className="input-design-div with-border">
                                      <Index.Select
                                        hiddenLabel
                                        fullWidth
                                        inputProps={ariaLabel}
                                        id="hgh"
                                        name="BankIssueName"
                                        value={values?.BankIssueName}
                                        defaultValue={
                                          values ? values?.BankIssueName : ""
                                        }
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      >
                                        <Index.MenuItem value={"PCB"}>
                                          PCB
                                        </Index.MenuItem>
                                        <Index.MenuItem value={"BOB"}>
                                          BOB
                                        </Index.MenuItem>
                                        <Index.MenuItem value={"GCB"}>
                                          GCB
                                        </Index.MenuItem>
                                        {/* Add a dynamic MenuItem to show data not in the menu 
                                        {!["PCB", "BOB", "GCB"].includes(
                                          values?.BankIssueName
                                        ) && (
                                          <Index.MenuItem
                                            value={values?.BankIssueName}
                                          >
                                            {values?.BankIssueName}
                                          </Index.MenuItem>
                                        )}
                                      </Index.Select>
                                    </Index.Box>

                                    {errors?.BankIssueName &&
                                      touched?.BankIssueName && (
                                        <p className="error-text">
                                          {errors?.BankIssueName}
                                        </p>
                                      )}
                                  </Index.Grid> */}

                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        Bank Issue Name (બેંક ઇશ્યુનું નામ)
                                      </Index.Typography>
                                      <Index.Box className="sahay-multiple-input-main">
                                        <Autocomplete
                                          disablePortal
                                          className="multiple-autocomplet-control"
                                          id="combo-box-bank-issue"
                                          options={bankList?.map(
                                            (row) => row?.BankIssueName
                                          )}
                                          getOptionLabel={(option) => option}
                                          value={values?.BankIssueName}
                                          onChange={(event, newValue) => {
                                            setFieldValue(
                                              "BankIssueName",
                                              newValue
                                            );
                                          }}
                                          onBlur={handleBlur}
                                          renderInput={(params) => (
                                            <Index.TextField
                                              {...params}
                                              placeholder="Select Bank Issue Name"
                                            />
                                          )}
                                        />
                                      </Index.Box>
                                      {errors?.BankIssueName &&
                                        touched?.BankIssueName && (
                                          <p className="error-text">
                                            {errors?.BankIssueName}
                                          </p>
                                        )}
                                    </Index.Box>
                                  </Index.Grid>

                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Typography
                                      component="p"
                                      className="in-label"
                                    >
                                      Cheque Date (તારીખ તપાસો)
                                    </Index.Typography>
                                    <Index.Box className="input-design-div with-border ">
                                      <Index.LocalizationProvider
                                        dateAdapter={Index.AdapterDayjs}
                                        sx={{ position: "absolute" }}
                                      >
                                        <Index.DemoContainer
                                          components={["DatePicker"]}
                                        >
                                          <MobileDatePicker
                                            name="chequeDate"
                                            onChange={(value) => {
                                              setFieldValue(
                                                "chequeDate",
                                                moment(value.$d).format(
                                                  "YYYY-MM-DD"
                                                )
                                              );
                                            }}
                                            value={dayjs(values?.chequeDate)}
                                            format="YYYY-MM-DD"
                                            sx={{
                                              overflowY: "hidden",
                                              position: "relative",
                                              bottom: "8px",
                                              width: "100%",
                                            }}
                                            slotProps={{
                                              textField: {
                                                size: "small",
                                                error: false,
                                              },
                                            }}
                                          />
                                        </Index.DemoContainer>
                                      </Index.LocalizationProvider>
                                    </Index.Box>
                                    {errors?.chequeDate &&
                                      touched.chequeDate && (
                                        <p className="error-text">
                                          {errors?.chequeDate}
                                        </p>
                                      )}
                                  </Index.Grid>
                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        Repay Details (ચુકવણી વિગતો)
                                      </Index.Typography>
                                      <Index.Textarea
                                        style={{
                                          fontSize: "15px",
                                          color: "black",
                                          padding: "4px",
                                        }}
                                        fullWidth
                                        minRows={2}
                                        inputProps={ariaLabel}
                                        className="basic-input input-width"
                                        name="RepayDetail"
                                        value={values?.RepayDetail}
                                        onChange={(e) => {
                                          if (e.target.value.length <= 200) {
                                            // Only update the value if it's not more than 40 characters
                                            handleChange(e);
                                          }
                                        }}
                                        onBlur={handleBlur}
                                      />
                                    </Index.Box>
                                    {errors?.RepayDetail &&
                                      touched?.RepayDetail && (
                                        <p className="error-text">
                                          {errors?.RepayDetail}
                                        </p>
                                      )}
                                  </Index.Grid>
                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Typography
                                      component="p"
                                      className="in-label"
                                    >
                                      Start Date (પ્રારંભ તારીખ)
                                    </Index.Typography>
                                    <Index.Box className="input-design-div with-border ">
                                      <Index.LocalizationProvider
                                        dateAdapter={Index.AdapterDayjs}
                                        sx={{ position: "absolute" }}
                                      >
                                        <Index.DemoContainer
                                          components={["DatePicker"]}
                                        >
                                          <MobileDatePicker
                                            name="StartDate"
                                            onChange={(value) => {
                                              setFieldValue(
                                                "StartDate",
                                                moment(value.$d).format(
                                                  "YYYY-MM-DD"
                                                )
                                              );
                                            }}
                                            value={dayjs(values?.StartDate)}
                                            format="YYYY-MM-DD"
                                            sx={{
                                              overflowY: "hidden",
                                              position: "relative",
                                              bottom: "8px",
                                              width: "100%",
                                            }}
                                            slotProps={{
                                              textField: {
                                                size: "small",
                                                error: false,
                                              },
                                            }}
                                          />
                                        </Index.DemoContainer>
                                      </Index.LocalizationProvider>
                                    </Index.Box>
                                  </Index.Grid>
                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Typography
                                      component="p"
                                      className="in-label"
                                    >
                                      Check Issue date (ઇશ્યૂ તારીખ તપાસો)
                                    </Index.Typography>
                                    <Index.Box className="input-design-div with-border ">
                                      <Index.LocalizationProvider
                                        dateAdapter={Index.AdapterDayjs}
                                        sx={{ position: "absolute" }}
                                      >
                                        <Index.DemoContainer
                                          components={["DatePicker"]}
                                        >
                                          <MobileDatePicker
                                            name="checkIssueDate"
                                            onChange={(value) => {
                                              setFieldValue(
                                                "checkIssueDate",
                                                moment(value.$d).format(
                                                  "YYYY-MM-DD"
                                                )
                                              );
                                            }}
                                            value={dayjs(
                                              values?.checkIssueDate
                                            )}
                                            format="YYYY-MM-DD"
                                            sx={{
                                              overflowY: "hidden",
                                              position: "relative",
                                              bottom: "8px",
                                              width: "100%",
                                            }}
                                            slotProps={{
                                              textField: {
                                                size: "small",
                                                error: false,
                                              },
                                            }}
                                          />
                                        </Index.DemoContainer>
                                      </Index.LocalizationProvider>
                                    </Index.Box>
                                    {errors?.checkIssueDate &&
                                      touched.checkIssueDate && (
                                        <p className="error-text">
                                          {errors?.checkIssueDate}
                                        </p>
                                      )}
                                  </Index.Grid>

                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        Bank Name (બેંકનું નામ)
                                      </Index.Typography>
                                      <Index.TextField
                                        disabled
                                        type="text"
                                        inputProps={ariaLabel}
                                        className="basic-input input-width"
                                        name="userName"
                                        value={sahayId?.bankName}
                                      />
                                    </Index.Box>
                                  </Index.Grid>

                                  {/* {approvedAmountData && (
                                    <Index.Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      md={4}
                                      lg={4}
                                      mb={2}
                                    >
                                      <Index.Box className="lable-input-grp">
                                        <Index.Typography
                                          component="p"
                                          className="in-label"
                                        >
                                          Approved Amount
                                        </Index.Typography>
                                        <Index.TextField
                                          disabled
                                          type="number"
                                          inputProps={ariaLabel}
                                          className="basic-input input-width"
                                          name="approveAmount"
                                          value={approvedAmountData}
                                        />
                                      </Index.Box>
                                    </Index.Grid>
                                  )} */}

                                  <>
                                    <Index.Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      md={4}
                                      lg={3}
                                      mb={2}
                                    >
                                      <Index.Box className="lable-input-grp">
                                        <Index.Typography
                                          component="p"
                                          className="in-label"
                                        >
                                          Approved Amount (મંજૂર રકમ)
                                        </Index.Typography>
                                        <Index.TextField
                                          disabled
                                          type="number"
                                          inputProps={ariaLabel}
                                          className="basic-input input-width"
                                          name="approveAmount"
                                          value={sahayId?.ApproveAmount}
                                        />
                                      </Index.Box>
                                    </Index.Grid>

                                    <FieldArray
                                      name="partialAmount"
                                      render={({ push, remove }) => {
                                        return (
                                          <>
                                            {values?.partialAmount?.map(
                                              (amount, index) => (
                                                <Index.Grid
                                                  item
                                                  xs={12}
                                                  sm={6}
                                                  md={4}
                                                  lg={4}
                                                  mb={2}
                                                  key={index}
                                                >
                                                  <Index.Grid
                                                    container
                                                    spacing={2}
                                                  >
                                                    {/* Disbursed Amount */}
                                                    <Index.Grid
                                                      item
                                                      xs={12}
                                                      sm={6}
                                                      md={4}
                                                      lg={5}
                                                      mb={2}
                                                    >
                                                      <Index.Box className="label-input-grp">
                                                        <Index.Typography
                                                          component="p"
                                                          className="in-label"
                                                        >
                                                          Disbursed Amount (વિતરિત રકમ)
                                                        </Index.Typography>

                                                        <Index.TextField
                                                          type="number"
                                                          inputProps={{
                                                            ...ariaLabel,
                                                            disabled:
                                                             amount?.isNewRow == false
                                                          }}
                                                          className="basic-input input-width"
                                                          name={`partialAmount[${index}].disbursedAmount`}
                                                          value={
                                                            amount?.disbursedAmount
                                                          }
                                                          onChange={(e) => {
                                                            // Calculate the new value from the input
                                                            const newValue =
                                                              parseFloat(
                                                                e.target.value
                                                              ) || 0;

                                                            // Calculate the pending amount dynamically
                                                            const previousPendingAmount =
                                                              index > 0
                                                                ? parseFloat(
                                                                    values
                                                                      .partialAmount[
                                                                      index - 1
                                                                    ]
                                                                      .pendingAmount
                                                                  ) || 0
                                                                : parseFloat(
                                                                    initialAmount ||
                                                                      0
                                                                  );

                                                            const newPendingAmount =
                                                              previousPendingAmount -
                                                              newValue;

                                                            // If pendingAmount becomes less than or equal to 0, stop updating the value
                                                            if (
                                                              newPendingAmount <
                                                              0
                                                            ) {
                                                              return; // Stop updating if pendingAmount reaches 0 or less
                                                            }

                                                            // Otherwise, update the disbursedAmount and pendingAmount
                                                            setFieldValue(
                                                              `partialAmount[${index}].disbursedAmount`,
                                                              newValue
                                                            );
                                                            setFieldValue(
                                                              `partialAmount[${index}].pendingAmount`,
                                                              newPendingAmount
                                                            );
                                                          }}
                                                        />
                                                      </Index.Box>
                                                    </Index.Grid>

                                                    {/* Pending Amount */}
                                                    <Index.Grid
                                                      item
                                                      xs={12}
                                                      sm={6}
                                                      md={4}
                                                      lg={5}
                                                      mb={2}
                                                    >
                                                      <Index.Box className="label-input-grp">
                                                        <Index.Typography
                                                          component="p"
                                                          className="in-label"
                                                        >
                                                          Pending Amount (બાકી રકમ)
                                                        </Index.Typography>
                                                        <Index.TextField
                                                          disabled
                                                          type="number"
                                                          inputProps={ariaLabel}
                                                          className="basic-input input-width"
                                                          name={`partialAmount[${index}].pendingAmount`}
                                                          value={
                                                            amount?.pendingAmount
                                                          }
                                                        />
                                                      </Index.Box>
                                                    </Index.Grid>

                                                    {/* Add Button */}
                                                    {+amount?.pendingAmount !==
                                                      0 &&
                                                      isCompleted == 0 &&
                                                      index ===
                                                        values.partialAmount
                                                          .length -
                                                          1 &&
                                                      editDisbursement &&
                                                      !amount?.isNewRow && (
                                                        <Index.Grid
                                                          item
                                                          xs={12}
                                                          sm={6}
                                                          md={4}
                                                          lg={1}
                                                          mb={2}
                                                          mt={3}
                                                        >
                                                          <Index.Box className="approved-btn">
                                                            <LightTooltip title="Add">
                                                              <Index.Button
                                                                className="approve-btn-list"
                                                                onClick={() => {
                                                                  const previousPendingAmount =
                                                                    parseFloat(
                                                                      values
                                                                        .partialAmount[
                                                                        index
                                                                      ]
                                                                        .pendingAmount
                                                                    ) || 0;

                                                                  push({
                                                                    disbursedAmount:
                                                                      "",
                                                                    pendingAmount:
                                                                      previousPendingAmount,
                                                                    isNewRow: true,
                                                                    DisbursedDate:
                                                                      new Date(),
                                                                  });
                                                                }}
                                                              >
                                                                <img
                                                                  src={
                                                                    PagesIndex
                                                                      .Svg
                                                                      .plusyellow
                                                                  }
                                                                  className="approve-plus"
                                                                />
                                                              </Index.Button>
                                                            </LightTooltip>
                                                          </Index.Box>
                                                        </Index.Grid>
                                                      )}

                                                    {/* Remove Button */}
                                                    {/* Remove Button */}
                                                    {index ===
                                                      values.partialAmount
                                                        .length -
                                                        1 && // Check if it's the last index
                                                      values.partialAmount
                                                        .length > 1 && // Ensure there's more than 1 entry
                                                      editDisbursement && // Edit mode must be active
                                                      amount?.isNewRow && // It must be a new row
                                                      parseFloat(
                                                        amount?.pendingAmount
                                                      ) !== 0 && ( // Pending amount should not be zero
                                                        <Index.Grid
                                                          item
                                                          xs={12}
                                                          sm={6}
                                                          md={4}
                                                          lg={1}
                                                          mb={2}
                                                          mt={3}
                                                        >
                                                          <Index.Box className="approved-btn">
                                                            <LightTooltip title="Minus">
                                                              <Index.Button
                                                                className="approve-btn-list"
                                                                onClick={() => {
                                                                  remove(index);
                                                                }}
                                                              >
                                                                <img
                                                                  src={
                                                                    PagesIndex
                                                                      .Png
                                                                      .minusYello
                                                                  }
                                                                  className="approve-plus"
                                                                />
                                                              </Index.Button>
                                                            </LightTooltip>
                                                          </Index.Box>
                                                        </Index.Grid>
                                                      )}
                                                  </Index.Grid>
                                                </Index.Grid>
                                              )
                                            )}
                                          </>
                                        );
                                      }}
                                    />
                                  </>
                                </Index.Grid>
                              </Index.Box>
                            </Index.Box>

                            <Index.Box
                              sx={{ flexGrow: 1, marginTop: "15px" }}
                              className="al-form-groups fam-in-grp multiple-doc mt-0px-sahay{"
                            >
                              <Index.Grid container spacing={2}>
                                <Index.Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  md={4}
                                  lg={4}
                                  mb={2}
                                >
                                  <Index.Box className="lable-input-grp">
                                    <Index.Typography
                                      component="p"
                                      className="in-label"
                                    >
                                      Add Multiple Document (બહુવિધ દસ્તાવેજ ઉમેરો)
                                    </Index.Typography>
                                    <Index.Box className="select-position">
                                      <input
                                        type="file"
                                        name="documents"
                                        multiple
                                        className="ori-input"
                                        onChange={(event) => {
                                          const files = event.target.files;
                                          setFieldValue("documents", [
                                            ...values?.documents,
                                            ...files,
                                          ]);
                                        }}
                                      />

                                      <Index.Box className="select-file">
                                        Upload Your Document
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Grid>

                                <Index.Grid item xs={8}>
                                  <Index.Box className="select-view-document-name">
                                    {values?.documents?.map((file, index) => (
                                      <Index.Box
                                        className="document-name"
                                        key={index}
                                      >
                                        <Index.Typography
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            // Implement the logic to view the document here
                                            // You can use a modal or a new browser tab/window to show the document
                                            window.open(
                                              file instanceof Blob
                                                ? URL.createObjectURL(file)
                                                : `${imagePath}${file?.Documents}`
                                            );
                                          }}
                                        >
                                          {file?.name
                                            ? file?.name
                                            : file?.filename}
                                        </Index.Typography>
                                        <Index.IconButton
                                          onClick={() => {
                                            const updatedDocuments =
                                              values.documents.filter(
                                                (_, i) => i !== index
                                              );
                                            setFieldValue(
                                              "documents",
                                              updatedDocuments
                                            );
                                          }}
                                        >
                                          <Index.ClearIcon className="muiico-cancel" />
                                        </Index.IconButton>
                                      </Index.Box>
                                    ))}
                                  </Index.Box>
                                </Index.Grid>
                              </Index.Grid>
                            </Index.Box>

                            {/* {errors?.documents && touched?.documents && (
                            <p className="error-text">{errors?.documents}</p>
                          )} */}

                            <Index.Box
                              sx={{ flexGrow: 1 }}
                              className="al-form-groups fam-in-grp"
                            >
                              <Index.Grid item xs={6}>
                                <Index.Box className="common-button blue-button flex-end save-btn back-btn">
                                  <Index.Button
                                    variant="contained"
                                    onClick={() => handleBack()}
                                  >
                                    Back
                                  </Index.Button>
                                </Index.Box>
                              </Index.Grid>
                              <Index.Grid container spacing={2}>
                                <Index.Grid item xs={6}>
                                  <Index.Box className="lable-input-grp ">
                                    <Index.Box className="select-position">
                                      <Index.Box
                                        className={
                                          values.isSubmit === false
                                            ? "apply-now"
                                            : "disable-now"
                                        }
                                      >
                                        <button
                                          type="submit"
                                          disabled={values.isSubmit}
                                        >
                                          {sahayId?.id ? "Submit" : "Update"}
                                        </button>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Grid>
                              </Index.Grid>
                            </Index.Box>
                          </form>
                        )}
                      </PagesIndex.Formik>

                      <Index.Box className="title-header-flex "></Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </>
        )}
      </div>
    </>
  );
};

export default AddDisbursement;
