import dashboard from "./images/svg/dashboard.svg";
import logo from "./images/svg/logo.svg";
import filter from "./images/svg/filter.svg";
import close from "./images/svg/close.svg";
import cancel from "./images/svg/cancel.svg";
import righticon from "./images/svg/righticon.svg";
import righticonblue from "./images/svg/righticonblue.svg";
import incorrectDisabled from "./images/svg/incorrectDisabled.svg";
import sidebar1 from "./images/svg/sidebar1.svg";
import sidebar2 from "./images/svg/sidebar2.svg";
import sidebar3 from "./images/svg/sidebar3.svg";
import sidebar4 from "./images/svg/sidebar4.svg";
import sidebar5 from "./images/svg/sidebar5.svg";
import sidebar6 from "./images/svg/sidebar6.svg";
import sidebar7 from "./images/svg/sidebar7.svg";
import sidebar8 from "./images/svg/sidebar8.svg";
import bell from "./images/svg/bell.svg";
import search from "./images/svg/search.svg";
import collapse from "./images/svg/collapse.svg";
import headprofile from "./images/svg/head-profile.svg";
import closeold from "./images/svg/closeold.svg";
import plus from "./images/svg/plus.svg";
import pencil from "./images/svg/pencil.svg";
import approve from "./images/svg/approve.svg";
import incorrect from "./images/svg/incorrect.svg";
import approveDisable from "./images/svg/approveDisable.svg";
import copy from "./images/svg/copy.svg";
import plusyellow from "./images/svg/plus-yellow.svg";
import ImportButton from "./images/svg/importButton.svg";
import downarrow from "./images/svg/downarrow.svg";
import uploadImg from "./images/svg/upload-svgrepo-com.svg"

const Svg = {
  uploadImg,
  ImportButton,
  approveDisable,
  incorrectDisabled,
  headprofile,
  bell,
  search,
  dashboard,
  collapse,
  logo,
  filter,
  close,
  cancel,
  righticon,
  righticonblue,

  sidebar1,
  sidebar2,
  sidebar3,
  sidebar4,
  sidebar5,
  sidebar6,
  sidebar7,
  sidebar8,
  closeold,
  plus,
  pencil,
  approve,
  incorrect,
  copy,
  plusyellow,
  downarrow,
};

export default Svg;
