import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import Pagination from "../../../../components/common/Pagination";
import PagesIndex from "../../../PagesIndex";
import { getActivityList } from "../../../../redux/features/AddService";
import dayjs from "dayjs";
import PageLoading from "../../../../components/common/pageLoading/PageLoading";
import RowPerPageDropdown from "../../../../components/common/paginationRowPerpage/RowPerPageDropdown";
import { ListItemSecondaryAction } from "@mui/material";

export default function ActivityLog() {
  const [pageData, setPageData] = useState([]);
  const [rowPerPage, setRowPerPage] = useState("25");
  const [rowId, setRowId] = useState("");
  const [loader, setLoader] = useState(true);
  const navigate = PagesIndex.useNavigate();
  const dispatch = PagesIndex.useDispatch();
  // let deleteText = "User";
  const [searchedData, setSearchedData] = useState([]);
  const { state } = PagesIndex.useLocation();
  const RowIdData = state?.rowID;
  const [activityLogList, setActivityLogList] = useState([]);
  const [showModal, setShowModal] = useState(true);
  const [openModalId, setOpenModalId] = useState();
  // const [tankerLoadingDetail, setTankerLoadingDetail] = useState({});
  const [checkDateFilter, setCheckDateFilter] = useState("");

  const showLogDetails = (item, action) => {
    setOpenModalId(item?.id);
    if (openModalId === item?.id && action === "down") {
      setShowModal(false);
    } else {
      setShowModal(true);
    }
    // setTankerLoadingDetail(item);
  };

  // get activity log
  const getActivityLog = () => {
    dispatch(getActivityList()).then((res) => {
      if (res?.payload?.status === 200) {
        setActivityLogList(res?.payload?.data);
        setLoader(false);
      }
      setLoader(false);
    });
  };
  useEffect(() => {
    getActivityLog();
  }, []);

  // Copy original data to searched data
  useEffect(() => {
    setSearchedData(activityLogList);
  }, [activityLogList]);

  // Filter Data
  useEffect(() => {
    if (checkDateFilter) {
      const filterData = activityLogList?.filter((ele) => {
        return (
          PagesIndex.moment(ele?.updatedAt).utc().format("YYYY-MM-DD") ===
          checkDateFilter
        );
      });
      setSearchedData(filterData);
    }
  }, [checkDateFilter]);

  // Search on table
  const requestSearch = (searched) => {
    if (!searched) return setSearchedData(activityLogList);
    setSearchedData(
      activityLogList?.filter(
        (item) =>
          item.users
            ?.toString()
            ?.toLowerCase()
            .includes(searched?.toLowerCase()) ||
          PagesIndex.moment(item?.updatedAt)
            .utc()
            .format("DD/MM/YYYY h:mm:ss a")
            ?.toString()
            ?.toLowerCase()
            .includes(searched.toString()?.toLowerCase()) ||
          item.role?.toString()?.toLowerCase().includes(searched?.toLowerCase())
      )
    );
  };

  return (
    <>
      {loader ? (
        <PageLoading />
      ) : (
        <>
          <Index.Box className="page-content-box">
            <Index.Box className="barge-common-box">
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex res-title-header-flex">
                  <Index.Box className="plus-hidden-show-mobile">
                    <Index.Box className="title-main">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="page-title"
                      >
                        Activity Log
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="main-search-date custom-date-picker">
                    <Index.Box className="d-flex align-items-center res-set-search " >
                      <PagesIndex.Search
                        className="search"
                        onChange={(e) => requestSearch(e.target.value)}
                      >
                        <PagesIndex.StyledInputBase placeholder="Search" />
                      </PagesIndex.Search>
                    </Index.Box>

                    <Index.Box className="input-design-div with-border ">
                      <Index.LocalizationProvider
                        dateAdapter={Index.AdapterDayjs}
                        sx={{ position: "absolute" }}
                      >
                        <Index.DemoContainer components={["DatePicker"]}>
                          <Index.DatePicker
                            className="cus-date-picker"
                            onChange={(value) => {
                              setCheckDateFilter(
                                PagesIndex.moment(value?.$d).format(
                                  "YYYY-MM-DD"
                                )
                              );
                            }}
                            value={dayjs(checkDateFilter)}
                            format="DD-MM-YYYY"
                            // disableFuture={true}
                            sx={{
                              overflowY: "hidden",

                              position: "relative",
                              bottom: "8px",
                            }}
                            slotProps={{
                              textField: {
                                size: "small",
                                error: false,
                              },
                            }}
                          />
                        </Index.DemoContainer>
                      </Index.LocalizationProvider>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="table-list">
                <Index.TableContainer
                sx={{ maxHeight: 340 }}
                  // component={Index.Paper}
                  className="table-container user-master-container scroll-table common-table-container-custom"
                >
                  <Index.Table
                    stickyHeader aria-label="sticky table"
                    className="table-design-main barge-table user-master-table cus-user-master-table"
                  >
                    <Index.TableHead className="user-master-head">
                      <Index.TableRow className="user-master-tr">
                        <Index.TableCell className="user-master-th">
                          Sr. No.
                        </Index.TableCell>
                        <Index.TableCell
                          className="user-master-th"
                          align="left"
                        >
                          Message
                        </Index.TableCell>

                        <Index.TableCell
                          className="user-master-th"
                          align="left"
                        >
                          Role
                        </Index.TableCell>
                        <Index.TableCell
                          className="user-master-th"
                          align="left"
                        >
                          Created Date
                        </Index.TableCell>
                        <Index.TableCell
                          className="user-master-th"
                          align="center"
                        >
                          Action
                        </Index.TableCell>
                      </Index.TableRow>
                    </Index.TableHead>
                    <Index.TableBody className="user-master-tbody">
                      {pageData?.length ? (
                        pageData?.map((data, index) => (
                          <>
                            <Index.TableRow
                              className={`user-master-tr ${
                                data.id === RowIdData ? "highlighted-row" : ""
                              }`}
                            >
                              <Index.TableCell className="user-master-td">
                                <Index.Box className="user-role-name">
                                  {index + 1}
                                </Index.Box>
                              </Index.TableCell>
                              <Index.TableCell className="user-master-td">
                                <Index.Box className="user-details-name">
                                  {data?.users
                                    ? data.users.split(".").shift()
                                    : "-"}
                                </Index.Box>
                              </Index.TableCell>

                              <Index.TableCell className="user-master-td">
                                <Index.Box className="user-email-name">
                                  {data?.role ? data?.role : "-"}
                                </Index.Box>
                              </Index.TableCell>
                              <Index.TableCell className="user-master-td">
                                <Index.Box className="user-number-name">
                                  {PagesIndex.moment(data?.updatedAt)
                                    .utc()
                                    .format("DD/MM/YYYY h:mm:ss A")}
                                </Index.Box>
                              </Index.TableCell>


                              {data?.action == "Updated" ? (
                                <Index.TableCell>
                                  {showModal ? (
                                    data?.id == openModalId ? (
                                      <Index.IconButton
                                        aria-label="expand row"
                                        size="small"
                                      >
                                        <PagesIndex.KeyboardArrowDownIcon
                                          onClick={() =>
                                            showLogDetails(data, "down")
                                          }
                                        />
                                      </Index.IconButton>
                                    ) : (
                                      <Index.IconButton
                                        aria-label="expand row"
                                        size="small"
                                      >
                                        <PagesIndex.KeyboardArrowUpIcon
                                          onClick={() =>
                                            showLogDetails(data, "up")
                                          }
                                        />
                                      </Index.IconButton>
                                    )
                                  ) : (
                                    <Index.IconButton
                                      aria-label="expand row"
                                      size="small"
                                    >
                                      <PagesIndex.KeyboardArrowUpIcon
                                        onClick={() =>
                                          showLogDetails(data, "up")
                                        }
                                      />
                                    </Index.IconButton>
                                  )}
                                </Index.TableCell>
                              ) : (
                                <Index.TableCell className="user-master-td">
                                  <Index.Box className="user-number-name"></Index.Box>
                                </Index.TableCell>
                              )}
                            </Index.TableRow>

                            {showModal &&
                              // tankerLoadingDetail &&
                              data?.id == openModalId && (
                                <Index.TableRow>
                                  <Index.TableCell
                                    colSpan={6}
                                    className="table-view-data"
                                  >
                                    <Index.Collapse
                                      in={showModal}
                                      timeout="auto"
                                      unmountOnExit
                                    >
                                      <Index.Box className="set-edit-timing-box pd-loading-details">
                                        <Index.Grid container spacing={2}>
                                          <Index.Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                          >
                                            <Index.Box className="title-header-flex order-loading-details">
                                              <Index.Box className="title-main order-loading-content mb-10">
                                                <Index.Typography
                                                  variant="p"
                                                  component="p"
                                                  className="page-title order-loading-title"
                                                >
                                                  Updated Fields
                                                </Index.Typography>
                                              </Index.Box>
                                            </Index.Box>
                                          </Index.Grid>

                                          <Index.Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={3}
                                          >
                                            <Index.Box className="input-design-div with-border">
                                              {/* {data?.users
                                                ? data?.users?.split(
                                                    "Updated fields: "
                                                  )[1]
                                                : "-"} */}
                                              {data?.users
                                                ? data?.users?.split(
                                                    "Updated fields: "
                                                  )[1] ?? "-"
                                                : "-"}
                                            </Index.Box>
                                          </Index.Grid>
                                        </Index.Grid>
                                      </Index.Box>
                                    </Index.Collapse>
                                  </Index.TableCell>
                                </Index.TableRow>
                              )}
                          </>
                        ))
                      ) : (
                        <PagesIndex.NoRecordFound colSpan={12} />
                      )}
                    </Index.TableBody>
                  </Index.Table>
                </Index.TableContainer>
              </Index.Box>
              <Index.Box className="pagination-design flex-end ">
                <RowPerPageDropdown
                  rowPerPage={rowPerPage}
                  setRowPerPage={setRowPerPage}
                />
                <Index.Stack spacing={2}>
                  <Pagination
                    fetchData={searchedData}
                    setPageData={setPageData}
                    pageData={pageData}
                    rowPerPage={rowPerPage}
                  />
                </Index.Stack>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          {/* <PagesIndex.DeleteModal
            deleteText={deleteText}
            deleteOpen={deleteOpen}
            handleDeleteRecord={handleDeleteRecord}
            handleDeleteClose={handleDeleteClose}
          /> */}
        </>
      )}
    </>
  );
}
