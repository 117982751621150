import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import DataService, { imagePath } from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import PageLoading from "../../../../components/common/pageLoading/PageLoading";
import { useSelector } from "react-redux";

const ViewDisbursementData = () => {
  const { state } = PagesIndex.useLocation();
  const navigate = PagesIndex.useNavigate();
  const dispatch = PagesIndex.useDispatch();
  const [getViewData, setGetViewData] = useState(null);
  const [getViewDataDocuments, setGetViewDataDocuments] = useState(null);
  const [userDetails, setUserDetails] = useState();
  const [approvedAmountData, setApprovedAmountData] = useState(null);
  const [loader, setLoader] = useState(true);
  const initialValues = {};
  const ariaLabel = { "aria-label": "description" };

  const rollData = JSON.parse(localStorage?.getItem("RollName"))?.toLowerCase();
  const handleBack = () => {
    navigate(`/${rollData}/disbursement-application`, {
      state: { rowID: state?.id, prevState: state.lastStatus },
    });
  };

  const getSingleSahayApplication = async () => {
    try {
      const response = await DataService.get(Api.VIEW_DISBURSEMENT_DATA, {
        params: { applicationId: state?.id },
      });
      if (response.data.status == 200) {
        setUserDetails(response?.data?.data?.application);
        setLoader(false);
        // setGetViewData(response.data.data);
        if (response?.data?.data?.disbursedDetails) {
          setGetViewData(response?.data?.data?.disbursedDetails);
          setApprovedAmountData(
            response?.data?.data?.application?.approveAmount
          );
        } else {
          setGetViewData(response?.data?.data?.application);
          setApprovedAmountData(
            response?.data?.data?.application?.approveAmount
          );
        }
        if (response?.data?.data?.disbursedDocuments) {
          setGetViewDataDocuments(response?.data?.data?.disbursedDocuments);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  useEffect(() => {
    getSingleSahayApplication();
  }, []);

  const { rollPermissionData } = useSelector((state) => state.admin);
  const rollPermissionNameData =
    rollPermissionData?.findData?.parentRoleName?.toLowerCase();

  if (
    rollPermissionData?.permissionArray?.includes("Disbursement_view") ||
    rollPermissionNameData === "admin"
  ) {
  } else {
    navigate(`/${rollData}/sahay-application`);
  }

  return (
    <>
      {loader ? (
        <PageLoading />
      ) : (
        <>
          <Index.Box className="page-content-box">
            <Index.Box className="barge-common-box">
              <Index.Box>
                <Index.Box className="title-header-flex-button">
                  <Index.Box className="common-button blue-button flex-start save-btn res-set-search">
                    <Index.Button
                      variant="contained"
                      onClick={() => handleBack()}
                    >
                      Back
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex">
                  <Index.Box className="title-main mb-10">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="page-title"
                    >
                      View Disbursement Data
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <PagesIndex.Formik
                initialValues={initialValues}
                enableReinitialize
              >
                {({ values }) => (
                  <form>
                    <Index.Box className="">
                      <Index.Grid container spacing={3}>
                        {/* <Index.Grid item xs={12} sm={12} md={12}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label "
                      >
                        Image
                      </Index.Typography>
                      <Index.Box className=" mb-5 mt-5">
                        {getViewData?.application[0]?.image && (
                          <img
                            style={{ width: "120px", height: "120" }}
                            src={`${imagePath}${getViewData?.application[0]?.image}`}
                            className="  mb-5 mt-5"
                          />
                        )}
                      </Index.Box>
                    </Index.Grid> */}

                        {getViewDataDocuments?.length > 0 && (
                          <Index.Grid item xs={12} sm={12} md={12}>
                            <Index.Box className="title-header">
                              <Index.Box className="title-header-flex">
                                <Index.Box className="title-main ">
                                  <Index.Typography
                                    variant="p"
                                    component="p"
                                    className="page-title"
                                  >
                                    Documents (દસ્તાવેજો)
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Grid item xs={8}>
                              <Index.Box className="select-view-document-name">
                                {getViewDataDocuments?.map((file, index) => (
                                  <Index.Box
                                    className="document-name"
                                    key={index}
                                  >
                                    <Index.Typography
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // Implement the logic to view the document here
                                        // You can use a modal or a new browser tab/window to show the document
                                        window.open(
                                          `${imagePath}${file.Documents}`
                                        );
                                      }}
                                    >
                                      {file.filename}
                                    </Index.Typography>
                                    {/* <Index.IconButton
                                onClick={() => {
                                  const updatedDocuments =
                                    values.documents.filter(
                                      (_, i) => i !== index
                                    );
                                  // setFieldValue("documents", updatedDocuments);
                                }}
                              >
                                <Index.ClearIcon className="muiico-cancel" />
                              </Index.IconButton> */}
                                  </Index.Box>
                                ))}
                              </Index.Box>
                            </Index.Grid>
                          </Index.Grid>
                        )}

                        <Index.Grid item xs={12} sm={12} md={12}>
                          <Index.Box className="title-header">
                            <Index.Box className="title-header-flex">
                              <Index.Box className="title-main ">
                                <Index.Typography
                                  variant="p"
                                  component="p"
                                  className="page-title"
                                >
                                  Basic Details (મૂળભૂત વિગતો)
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Grid>

                        <Index.Grid item xs={12} sm={6} md={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Family Id (કૌટુંબિક આઈડી)
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              autoComplete="off"
                              name="familyId"
                              disabled
                              value={state?.familyId ? state?.familyId : "---"}
                            />
                          </Index.Box>
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={6} md={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            User Name (વપરાશકર્તા નામ)
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              autoComplete="off"
                              name="familyId"
                              disabled
                              value={
                                `${state?.mem_fname} ${state?.mem_lname}`
                                  ? `${state?.mem_fname} ${state?.mem_lname}`
                                  : `---`
                              }
                            />
                          </Index.Box>
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={6} md={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Voucher No (વાઉચર નં.)
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              autoComplete="off"
                              name="familyId"
                              disabled
                              value={
                                getViewData?.VoucherNo
                                  ? getViewData?.VoucherNo
                                  : getViewData?.voucherNo
                              }
                            />
                          </Index.Box>
                        </Index.Grid>

                        <Index.Grid item xs={12} sm={6} md={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Cheque No (ચેક નં)
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              autoComplete="off"
                              name="Addharcard No."
                              disabled
                              value={
                                getViewData?.ChequeNo
                                  ? getViewData?.ChequeNo
                                  : getViewData?.chequeNo
                              }
                            />
                          </Index.Box>
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={6} md={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Bank Name (બેંકનું નામ)
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              autoComplete="off"
                              name="bankName"
                              disabled
                              value={state?.bankName ? state?.bankName : "---"}
                            />
                          </Index.Box>
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={6} md={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Bank Issue Name (બેંક ઇશ્યુનું નામ)
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              autoComplete="off"
                              name="Sahay Category"
                              disabled
                              value={
                                getViewData?.BankIssueName
                                  ? getViewData?.BankIssueName
                                  : getViewData?.bankIssueName
                              }
                            />
                          </Index.Box>
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={6} md={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Cheque Date (તારીખ તપાસો)
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              autoComplete="off"
                              name="Sahay Category"
                              disabled
                              value={
                                getViewData?.chequeDate
                                  ? new Date(
                                      getViewData?.chequeDate
                                    )?.toLocaleDateString("en-GB")
                                  : "-"
                              }
                            />
                          </Index.Box>
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={6} md={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Repay Details (ચુકવણી વિગતો)
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.TextareaAutosize
                              style={{
                                fontSize: "15px",
                                color: "black",
                                padding: "4px",
                              }}
                              //   minRows={2}
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              autoComplete="off"
                              name="Addharcard No."
                              disabled
                              // value={getViewData?.RepayDetail}
                              value={
                                getViewData?.repayDetails
                                  ? getViewData?.repayDetails
                                  : getViewData?.RepayDetail
                              }
                            />
                          </Index.Box>
                        </Index.Grid>
                        {(getViewData?.StartDate || getViewData?.startDate) && (
                          <Index.Grid item xs={12} sm={6} md={3}>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label"
                            >
                              Start Date (પ્રારંભ તારીખ)
                            </Index.Typography>
                            <Index.Box className="input-design-div with-border">
                              <Index.TextField
                                hiddenLabel
                                id="filled-hidden-label-normal"
                                variant="filled"
                                className="admin-input-design input-placeholder"
                                autoComplete="off"
                                name="Sahay Category"
                                disabled
                                value={new Date(
                                  getViewData?.StartDate
                                    ? getViewData?.StartDate
                                    : getViewData?.startDate
                                )?.toLocaleDateString("en-GB")}
                              />
                            </Index.Box>
                          </Index.Grid>
                        )}

                        {(getViewData?.checkIssueDate ||
                          getViewData?.checkIssueDate) && (
                          <Index.Grid item xs={12} sm={6} md={3}>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label"
                            >
                              Check Issue date (ઇશ્યૂ તારીખ તપાસો)
                            </Index.Typography>
                            <Index.Box className="input-design-div with-border">
                              <Index.TextField
                                hiddenLabel
                                id="filled-hidden-label-normal"
                                variant="filled"
                                className="admin-input-design input-placeholder"
                                autoComplete="off"
                                name="Sahay Category"
                                disabled
                                value={new Date(
                                  getViewData?.checkIssueDate
                                    ? getViewData?.checkIssueDate
                                    : getViewData?.checkIssueDate
                                )?.toLocaleDateString("en-GB")}
                              />
                            </Index.Box>
                          </Index.Grid>
                        )}

                        <>
                          {getViewData?.disbursedInstallment &&
                            JSON.parse(getViewData?.disbursedInstallment)?.map(
                              (amount, index) => (
                                <Index.Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  md={6}
                                  lg={6}
                                  mb={2}
                                  key={index}
                                >
                                  <Index.Grid container spacing={2}>
                                    {/* Disbursed Amount */}
                                    <Index.Grid item xs={12} sm={6} md={6}>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label"
                            >
                              Disbursed Amount (વિતરિત રકમ)
                            </Index.Typography>
                            <Index.Box className="input-design-div with-border">
                              <Index.TextField
                                hiddenLabel
                                id="filled-hidden-label-normal"
                                variant="filled"
                                className="admin-input-design input-placeholder"
                                autoComplete="off"
                                name="Sahay Category"
                                disabled
                                value={amount?.disbursedAmount}
                              />
                            </Index.Box>
                          </Index.Grid>

                                    {/* Pending Amount */}
                                    <Index.Grid item xs={12} sm={6} md={6}>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label"
                            >
                              Pending Amount (બાકી રકમ)
                            </Index.Typography>
                            <Index.Box className="input-design-div with-border">
                              <Index.TextField
                                hiddenLabel
                                id="filled-hidden-label-normal"
                                variant="filled"
                                className="admin-input-design input-placeholder"
                                autoComplete="off"
                                name="Sahay Category"
                                disabled
                                value={amount?.pendingAmount}
                              />
                            </Index.Box>
                          </Index.Grid>
                                  </Index.Grid>
                                </Index.Grid>
                              )
                            )}
                        </>
                        {/* table */}
                      </Index.Grid>

                      <Index.Box className="line" mt={3} mb={3}></Index.Box>
                    </Index.Box>
                  </form>
                )}
              </PagesIndex.Formik>
            </Index.Box>
          </Index.Box>
        </>
      )}
    </>
  );
};

export default ViewDisbursementData;
