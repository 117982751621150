import React, { useEffect, useState } from "react";

import Index from "../../../Index";
import "../../pages/newMember/NewMember.css";
import {
  getAllFamilyWithMohollaNameAndAreaList,
  programAddEdit,
} from "../../../../redux/features/AddService";
import PagesIndex from "../../../PagesIndex";
import moment from "moment";
import dayjs from "dayjs";
import { validationProgramSchema } from "../../../../validation/Validation";

import PageLoading from "../../../../components/common/pageLoading/PageLoading";
import { useSelector } from "react-redux";
import { Autocomplete } from "@mui/material";

const AddEditProgram = () => {
  const navigate = PagesIndex.useNavigate();

  const { state } = PagesIndex.useLocation();
  const editData = state?.editData;
  const dispatch = PagesIndex.useDispatch();

  const [loaderRelation, setLoaderRelation] = useState(true);
  const [loader, setLoader] = useState(false);
  const [familyListWithArea, setFamilyListWithArea] = useState([]);

  const rollData = JSON.parse(localStorage?.getItem("RollName"))?.toLowerCase();

  const { rollPermissionData } = useSelector((state) => state.admin);

  const handleMenuRelationOpen = () => {
    setLoaderRelation(true);

    familyListWithArea?.length > 0 &&
      setTimeout(() => {
        setLoaderRelation(false);
      }, 2000);
  };
  const handleMenuRelationClose = () => {
    setLoaderRelation(true);
    setTimeout(() => {
      setLoaderRelation(false);
    }, 2000);
  };

  const getFamilyListWithArea = () => {
    setLoader(true);
    dispatch(getAllFamilyWithMohollaNameAndAreaList()).then((res) => {
      if (res?.payload) {
        setLoader(false);
        setFamilyListWithArea(res?.payload?.data);
      } else {
        setLoader(false);
        setFamilyListWithArea([]);
      }
    });
  };

  useEffect(() => {
    getFamilyListWithArea();
  }, []);

  //intial values for update and edit
  const initialValues = {
    programName: editData?.programName || "",
    startDate: editData?.startDate || "",
    endDate: editData?.endDate || "",
    memberList: editData?.members?.map((row) => row?.mem_id) || [],
  };

  // handle submit for add and edit
  const handleFormSubmit = (values, { setFieldValue }) => {
    if (editData?.id) {
      values = {
        ...values,
        id: editData?.id,
      };
    }
    dispatch(programAddEdit(values)).then((data) => {
      if (data?.payload == 201 || data?.payload == 200) {
        navigate(-1);
      } else {
        setTimeout(() => {
          setFieldValue("isSubmit", false);
        }, 1000);
      }
    });
  };

  const rollPermissionNameData =
    rollPermissionData?.findData?.parentRoleName?.toLowerCase();

  if (
    rollPermissionData?.permissionArray?.includes("Program Master_add") ||
    rollPermissionData?.permissionArray?.includes("Program Master_edit") ||
    rollPermissionNameData === "admin"
  ) {
  } else {
    navigate(`/${rollData}/sahay-application`);
  }

  //end handle submit
  return (
    <>
      {loader ? (
        <PageLoading />
      ) : (
        <>
          <Index.Box className="page-content-box">
            <Index.Box className="barge-common-box">
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex">
                  <Index.Box className="title-main mb-10">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="page-title"
                    >
                      {state ? state?.edit : "Add"} Program
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <PagesIndex.Formik
                enableReinitialize={true}
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={validationProgramSchema}
              >
                {({
                  values,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  errors,
                  handleBlur,
                  touched,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Index.Box className="">
                      <Index.Grid container spacing={3}>
                        <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Program Name(પ્રોગ્રામનું નામ)*
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              placeholder="Program Name"
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              autoComplete="off"
                              name="programName"
                              onChange={handleChange}
                              value={values.programName}
                              onBlur={handleBlur}
                            />
                          </Index.Box>
                          {errors?.programName && touched.programName && (
                            <p className="error-text">{errors?.programName}</p>
                          )}
                        </Index.Grid>

                        <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Start Date(પ્રારંભ તારીખ)*
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border ">
                            <Index.LocalizationProvider
                              dateAdapter={Index.AdapterDayjs}
                              sx={{ position: "absolute" }}
                            >
                              <Index.DemoContainer components={["DatePicker"]}>
                                <Index.DatePicker
                                  name="startDate"
                                  onChange={(value) => {
                                    setFieldValue(
                                      "startDate",
                                      moment(value.$d).format("YYYY-MM-DD")
                                    );
                                  }}
                                  value={dayjs(values?.startDate)}
                                  format="YYYY-MM-DD"
                                  sx={{
                                    overflowY: "hidden",

                                    position: "relative",
                                    bottom: "8px",
                                  }}
                                  slotProps={{
                                    textField: {
                                      size: "small",
                                      error: false,
                                    },
                                  }}
                                />
                              </Index.DemoContainer>
                            </Index.LocalizationProvider>
                          </Index.Box>
                          {errors?.startDate && touched.startDate && (
                            <p className="error-text">{errors?.startDate}</p>
                          )}
                        </Index.Grid>

                        <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            End Date(સમાપ્તિ તારીખ)*
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border ">
                            <Index.LocalizationProvider
                              dateAdapter={Index.AdapterDayjs}
                              sx={{ position: "absolute" }}
                            >
                              <Index.DemoContainer components={["DatePicker"]}>
                                <Index.DatePicker
                                  name="endDate"
                                  onChange={(value) => {
                                    setFieldValue(
                                      "endDate",
                                      moment(value.$d).format("YYYY-MM-DD")
                                    );
                                  }}
                                  value={dayjs(values?.endDate)}
                                  format="YYYY-MM-DD"
                                  sx={{
                                    overflowY: "hidden",
                                    position: "relative",
                                    bottom: "8px",
                                  }}
                                  slotProps={{
                                    textField: {
                                      size: "small",
                                      error: false,
                                    },
                                  }}
                                />
                              </Index.DemoContainer>
                            </Index.LocalizationProvider>
                          </Index.Box>
                          {errors?.endDate && touched.endDate && (
                            <p className="error-text">{errors?.endDate}</p>
                          )}
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={12} md={12} lg={12}>
                          <Index.Box className="lable-input-grp custom-lable-input-grp">
                            <Index.Typography
                              component="p"
                              className="in-label"
                            >
                              Member(સભ્ય)*
                            </Index.Typography>
                            <Index.Box className="sahay-multiple-input-main">
                              <Autocomplete
                                multiple
                                className="multiple-autocomplet-control"
                                id="combo-box-demo"
                                options={familyListWithArea}
                                onOpen={handleMenuRelationOpen}
                                onClose={handleMenuRelationClose}
                                loading={loaderRelation}
                                value={familyListWithArea?.filter((member) =>
                                  values.memberList.includes(member?.mem_id)
                                )}
                                getOptionLabel={(option) =>
                                  `${
                                    option?.mem_family_id?.toString() || ""
                                  }  ${option?.mem_fname || ""} ${
                                    option?.mem_mname || ""
                                  } ${option?.mem_lname || ""}`
                                }
                                disableCloseOnSelect
                                onChange={(value, newvalue) => {
                                  const ids = newvalue?.map(
                                    (member) => member.mem_id
                                  );

                                  setFieldValue("memberList", ids);
                                }}
                                renderInput={(params) => (
                                  <Index.TextField
                                    {...params}
                                    placeholder={
                                      values?.memberList?.length === 0
                                        ? "Select Member"
                                        : ""
                                    }
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <React.Fragment>
                                          {/* {loader ? <CircularProgress color="inherit" size={20} /> : null} */}
                                          {params.InputProps.endAdornment}
                                        </React.Fragment>
                                      ),
                                    }}
                                  />
                                )}
                              />
                            </Index.Box>

                            {errors?.memberList && touched?.memberList && (
                              <p className="error-text">{errors?.memberList}</p>
                            )}
                          </Index.Box>
                        </Index.Grid>
                      </Index.Grid>

                      <Index.Box className="line" mt={3} mb={3}></Index.Box>
                      <Index.Box className="common-button blue-button flex-start save-btn text-end-details">
                        <Index.Button
                          variant="contained"
                          onClick={() => navigate(-1)}
                        >
                          Back
                        </Index.Button>
                        <Index.Button
                          variant="contained"
                          type="submit"
                          disabled={values.isSubmit}
                        >
                          Submit
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </form>
                )}
              </PagesIndex.Formik>
            </Index.Box>
          </Index.Box>
        </>
      )}
    </>
  );
};

export default AddEditProgram;
