import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import MenuIcon from "@mui/icons-material/MoreVert";
import { styled, alpha } from "@mui/material/styles";
import PagesIndex from "../../../PagesIndex";
import { useDispatch, useSelector } from "react-redux";
import { pink } from "@mui/material/colors";
import {
  getNewMemberDatalist,
  getUSerProfileUpdate,
  multipleDeleteNewMember,
  newMemberDelete,
} from "../../../../redux/features/AddService";
import moment from "moment";
import Pagination from "../../../../components/common/Pagination";
import { STATUSES } from "../../../../redux/features/adminslice/AdminSlice";
import PageLoading from "../../../../components/common/pageLoading/PageLoading";
import RowPerPageDropdown from "../../../../components/common/paginationRowPerpage/RowPerPageDropdown";

const ActionItem = styled(Index.MenuItem)(() => ({
  fontFamily: "poppins",
  lineHeight: "15px",
  fontSize: "14px",
  fontWeight: "400",
  color: "#595F69",
}));

const NewMemberList = () => {
  const { getDataList, status } = useSelector((state) => state.admin);

  const navigate = PagesIndex.useNavigate();
  const dispatch = useDispatch();

  //all state
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [checkVastikpatrak, setCheckVastikpatrak] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [actionIndex, setActionIndex] = useState();
  const [pageData, setPageData] = useState([]);
  const [rowPerPage, setRowPerPage] = useState("25");
  const [searchedData, setSearchedData] = React.useState([]);
  const [listData, setListData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [rowId, setRowId] = useState("");
  const [loader, setLoader] = useState(true);
  const rollData = JSON.parse(localStorage?.getItem("RollName"))?.toLowerCase();
  let deleteText = "member";

  const { state } = PagesIndex.useLocation();
  const RowIdData = state?.familyActiveRowid;

  const handleClickMenu = (e, index) => {
    setActionIndex(index);
    setAnchorEl(e.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  //update list
  const handleEdit = (row) => {
    let id = {
      familyId: row.mem_family_id,
    };
    dispatch(getUSerProfileUpdate(id));
    navigate(`/${rollData}/new-member/add`, { state: { row, edit: "Edit" } });
  };
  const handleView = (row) => {
    navigate(`/${rollData}/new-member/view`, {
      state: { id: row?.mem_family_id },
    });
  };

  useEffect(() => {
    dispatch(getNewMemberDatalist(checkVastikpatrak)).then((data) => {
      if (data) {
        setLoader(false);
        setListData(data.payload);
      }
      setLoader(false);
    });
  }, [checkVastikpatrak]);

  const { rollPermissionData } = useSelector((state) => state.admin);
  const rollPermissionNameData =
    rollPermissionData?.findData?.roleName?.toLowerCase();

  // Copy original data to searched data
  useEffect(() => {
    setSearchedData(getDataList);
  }, [getDataList]);

  // Search on table
  const requestSearch = (searched) => {
    if (!searched) return setSearchedData(getDataList);
    setSearchedData(
      getDataList.filter(
        (item) =>
          item?.mem_family_id.toString().includes(searched.toLowerCase()) ||
          item?.mem_fname
            .toString()
            ?.toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.mem_mname.toLowerCase().includes(searched.toLowerCase()) ||
          item?.mem_lname.toLowerCase().includes(searched.toLowerCase()) ||
          item?.mem_emailid
            ?.toString()
            ?.toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.mem_mobile
            ?.toString()
            ?.toLowerCase()
            .includes(searched.toLowerCase()) ||
          moment(item?.mem_dob)
            .format("YYYY-MM-DD")
            ?.toString()
            .toLowerCase()
            .includes(searched.toString().toLowerCase())
      )
    );
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleDeleteRecord = () => {
    let data = {
      rowId: rowId,
      vastipatrak: checkVastikpatrak,
    };

    dispatch(newMemberDelete(data)).then((res) => {
      if (res?.payload == 200) {
        // dispatch(getNewMemberDatalist());
        dispatch(getNewMemberDatalist(checkVastikpatrak)).then((data) => {
          if (data) {
            setLoader(false);
            setListData(data.payload);
          }
          setLoader(false);
        });
      }
    });
    setDeleteOpen(false);
  };

  const handleDeleteOpen = (data) => {
    setRowId(data.mem_family_id);
    // setIsDelete(data.isDelete);
    setAnchorEl(null);
    setDeleteOpen(true);
  };

  const handleCheckboxChange = (itemId) => {
    if (selectedRows.includes(itemId)) {
      setSelectedRows(selectedRows.filter((id) => id !== itemId));
    } else {
      setSelectedRows([...selectedRows, itemId]);
    }
  };

  const handleDelete = async () => {
    const urlEncodedData = new URLSearchParams();

    selectedRows?.forEach((id, i) => {
      urlEncodedData.append(`ids[0][id${i + 1}]`, id);
    });

    dispatch(multipleDeleteNewMember(urlEncodedData)).then((res) => {
      if (res.payload == 200) {
        dispatch(getNewMemberDatalist());
      }
    });
  };

  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      const allItemIds = pageData.map((item) => item.mem_id);
      setSelectedRows(allItemIds);
    }
    setSelectAll(!selectAll);
    
  };

  

  if (rollPermissionData?.permissionArray?.includes("New Member Master_view") || rollPermissionNameData === "admin") {
  } else {
    navigate(`/${rollData}/sahay-application`);
  }
  return (
    <>
      {loader ? (
        <PageLoading />
      ) : (
        <>
          <Index.Box className="page-content-box">
            <Index.Box className="barge-common-box">
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex res-title-header-flex">
                  <Index.Box className="plus-hidden-show-mobile">
                    <Index.Box className="title-main">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="page-title"
                      >
                        Family Member Master
                      </Index.Typography>
                    </Index.Box>
                    {rollPermissionData?.permissionArray?.includes(
                      "New Member Master_add"
                    ) || rollPermissionNameData === "admin" ? (
                      <>
                        <Index.Box className="common-button blue-button res-blue-button res-mobile-show">
                          <Index.Box className="mobile-menu-show-hover">
                            <PagesIndex.Link
                              // to="/admin/new-member/add"
                              className="no-text-decoration"
                            >
                              {" "}
                              <Index.Button
                                variant="contained"
                                disableRipple
                                className="apply-sahay-btn"
                              >
                                <Index.ControlPointIcon />
                              </Index.Button>
                            </PagesIndex.Link>
                            <Index.Box className="hover-tooltip-custom">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="title-tooltip"
                              >
                                {" "}
                                Add Member
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </>
                    ) : (
                      ""
                    )}
                  </Index.Box>

                  <Index.Box className="d-flex align-items-center res-set-search">
                    <PagesIndex.Search
                      className="search"
                      onChange={(e) => requestSearch(e.target.value)}
                    >
                      <PagesIndex.StyledInputBase
                        placeholder="Search"
                        inputProps={{ "aria-label": "search" }}
                      />
                    </PagesIndex.Search>
                    {rollPermissionData?.permissionArray?.includes(
                      "New Member Master_add"
                    ) || rollPermissionNameData === "admin" ? (
                      <>
                        <Index.Box className="common-button blue-button res-blue-button desk-hidden-show">
                          <PagesIndex.Link
                            to={`/${rollData}/new-member/add`}
                            className="no-text-decoration"
                          >
                            {" "}
                            <Index.Button variant="contained" disableRipple>
                              <Index.ControlPointIcon className="plus-add" />
                              Add Member
                            </Index.Button>
                          </PagesIndex.Link>
                        </Index.Box>
                      </>
                    ) : (
                      ""
                    )}
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Grid container spacing={3} mb={2}>
                <Index.Grid item xs={12} sm={12} md={12}>
                  <Index.Typography
                    variant="label"
                    component="label"
                    className="input-label"
                  >
                    Non Vastrikpatrak Member
                  </Index.Typography>
                  <Index.Box className="input-design-div with-border"></Index.Box>
                </Index.Grid>
              </Index.Grid>

              <Index.Box className="">
                <Index.TableContainer
                sx={{ maxHeight: 320 }}
                  // component={Index.Paper}
                  className="table-container new-member-container  scroll-table common-table-container-custom"
                >
                  <Index.Table
                    stickyHeader aria-label="sticky table"
                    className="table-design-main barge-table new-member-table"
                  >
                    <Index.TableHead className="new-member-head">
                      <Index.TableRow className="new-member-tr">
                        <Index.TableCell className="new-member-th">
                          Family ID
                        </Index.TableCell>
                        <Index.TableCell className="new-member-th" align="left">
                          First Name
                        </Index.TableCell>
                        <Index.TableCell className="new-member-th" align="left">
                          Middle Name
                        </Index.TableCell>
                        <Index.TableCell className="new-member-th" align="left">
                          Last Name
                        </Index.TableCell>
                        <Index.TableCell className="new-member-th" align="left">
                          Email Id{" "}
                        </Index.TableCell>
                        <Index.TableCell className="new-member-th" align="left">
                          Mobile No{" "}
                        </Index.TableCell>
                        <Index.TableCell className="new-member-th" align="left">
                          Date Of Birth
                        </Index.TableCell>
                        <Index.TableCell align="center">Action</Index.TableCell>
                      </Index.TableRow>
                    </Index.TableHead>
                    <Index.TableBody
                      className={
                        status !== STATUSES.LOADING
                          ? "new-member-tbody"
                          : "loading-table"
                      }
                    >
                      {status !== STATUSES.LOADING ? (
                        pageData?.length ? (
                          pageData?.map((data, index) => (
                            <Index.TableRow
                              className={`new-member-tr ${
                                data.mem_family_id === RowIdData
                                  ? "highlighted-row"
                                  : ""
                              }`}
                            >
                              <Index.TableCell className="new-member-td">
                                {data?.mem_family_id}
                              </Index.TableCell>
                              <Index.TableCell className="new-member-td">
                                {data?.mem_fname}
                              </Index.TableCell>
                              <Index.TableCell className="new-member-td">
                                {data?.mem_mname}
                              </Index.TableCell>

                              <Index.TableCell
                                className="new-member-td"
                                align="left"
                              >
                                {data?.mem_lname}
                              </Index.TableCell>
                              <Index.TableCell
                                className="new-member-td"
                                align="left"
                              >
                                {data?.mem_emailid}
                              </Index.TableCell>
                              <Index.TableCell
                                className="new-member-td"
                                align="left"
                              >
                                {data?.mem_mobile}
                              </Index.TableCell>
                              <Index.TableCell
                                className="new-member-td"
                                align="left"
                              >
                                {moment(data?.mem_dob).format("YYYY-MM-DD")}
                              </Index.TableCell>
                              <Index.TableCell
                                className="new-member-td icon-cell"
                                align="center"
                              >
                                <Index.Box className="icon-cell">
                                  {" "}
                                  {rollPermissionData?.permissionArray?.includes(
                                    "New Member Master_view"
                                  ) || rollPermissionNameData === "admin" ? (
                                    <>
                                      <Index.IconButton
                                        onClick={() => handleView(data)}
                                      >
                                        <Index.Visibility className="muiico-view" />
                                      </Index.IconButton>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {rollPermissionData?.permissionArray?.includes(
                                    "New Member Master_edit"
                                  ) || rollPermissionNameData === "admin" ? (
                                    <>
                                      <Index.IconButton
                                        onClick={() => handleEdit(data)}
                                      >
                                        <Index.EditIcon className="muiico-edit" />
                                      </Index.IconButton>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {rollPermissionData?.permissionArray?.includes(
                                    "New Member Master_delete"
                                  ) || rollPermissionNameData === "admin" ? (
                                    <>
                                      <Index.IconButton
                                        onClick={() => handleDeleteOpen(data)}
                                      >
                                        <Index.DeleteIcon className="muiico-delete" />
                                      </Index.IconButton>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </Index.Box>
                              </Index.TableCell>
                            </Index.TableRow>
                          ))
                        ) : (
                          <PagesIndex.NoRecordFound colSpan={12} />
                        )
                      ) : (
                        <PagesIndex.PageLoading colSpan={12} />
                      )}
                    </Index.TableBody>
                  </Index.Table>
                </Index.TableContainer>
              </Index.Box>
              <Index.Box className="pagination-design flex-end">
                <RowPerPageDropdown
                  rowPerPage={rowPerPage}
                  setRowPerPage={setRowPerPage}
                />
                <Index.Stack spacing={2}>
                  <Pagination
                    fetchData={searchedData}
                    setPageData={setPageData}
                    pageData={pageData}
                    rowPerPage={rowPerPage}
                  />
                </Index.Stack>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <PagesIndex.DeleteModal
            deleteText={deleteText}
            deleteOpen={deleteOpen}
            handleDeleteRecord={handleDeleteRecord}
            handleDeleteClose={handleDeleteClose}
          />
        </>
      )}
    </>
  );
};

export default NewMemberList;
