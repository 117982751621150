import React from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { FieldArray } from "formik";
import { MobileDatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import dayjs from "dayjs";
import { Tooltip, tooltipClasses } from "@mui/material";
import styled from "styled-components";
import { addRecoveryData } from "../../../../redux/features/AddService";
import { recoverySchema } from "../../../../validation/Validation";

const RecoveryAddEdit = ({
  amountOpen,
  handleCloseApproved,
  showClaimedAmount,
  loanInstallment,
  approvedAmonut,
  SahayApplicationId,
  recoveryAmount, 
  totalDisbursedAmount,
  isCompleted
}) => {

  
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      background: "#eaac35",
      color: "#ffffff",
      fontSize: "11px",
      fontFamily: "Poppins-Regular",
      padding: "5px 9px",
      lineHeight: "19px",
      borderRadius: "10px",
    },
  }));
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const dispatch = PagesIndex.useDispatch()

  const handleAddRecovery = (values) => {

    delete values?.loanInstallment
    delete values?.claimedAmount
    delete values?.approvedAmount
    delete values?.totalDisbursedAmount

    values = {
      ...values,
      SahayApplicationId : SahayApplicationId
    }
        dispatch(addRecoveryData(values)).then((res) => {

          
          if (res?.payload?.status == 201 || res?.payload?.status == 200) {
            handleCloseApproved()

          } 
        });
    
  }
  return (
    <Index.Modal
      open={amountOpen}
      onClose={handleCloseApproved}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal-delete modal"
    >
      <Index.Box
        sx={style}
        className="delete-modal-inner-main modal-inner approve-amount-model"
      >
        <PagesIndex.Formik
          enableReinitialize
          onSubmit={handleAddRecovery}
          validationSchema={recoverySchema}
          initialValues={{
            claimedAmount: showClaimedAmount,
            approvedAmount: approvedAmonut ? approvedAmonut : "",
            totalDisbursedAmount : totalDisbursedAmount ? totalDisbursedAmount : "",
            recoveryAmount:recoveryAmount ? recoveryAmount : [
              {
                recoveryDate: "",
                recoveryAmount: "",
              },
            ],
            loanInstallment: loanInstallment
              ? loanInstallment
              : [
                  {
                    EMIDate: "",
                    EMIAmount: "",
                  },
                ],
          }}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            touched,
            setFieldValue,
            handleBlur,
            errors,
            isValid,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <Index.Box>
                <Index.Box className="set-text-area ">
                  <Index.Box sx={{ flexGrow: 1 }} className="al-form-groups">
                    <Index.Grid container spacing={2}>
                      <Index.Grid item xs={12} sm={12} md={12} lg={12}>
                        <Index.Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "end",
                          }}
                        >
                          <Index.IconButton onClick={handleCloseApproved}>
                            <Index.CloseIcon />
                          </Index.IconButton>
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={12} md={12} lg={12}>
                        <Index.Box className="lable-input-grp">
                          <Index.Typography component="p" className="in-label">
                            Claimed Amount (દાવો કરેલ રકમ)
                          </Index.Typography>
                          <Index.TextField
                            type="number"
                            disabled
                            placeholder="Enter claimed amount"
                            className="basic-input input-width"
                            name="claimedAmount"
                            value={values?.claimedAmount}
                            onChange={(event) => {
                              const inputValue = event.target.value;
                              if (inputValue.length <= 10) {
                                // Only update the value if it's not more than 30 characters
                                handleChange(event);
                              }
                            }}
                            onBlur={handleBlur}
                          />
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={12} md={12} lg={12}>
                        <Index.Box className="lable-input-grp">
                          <Index.Typography component="p" className="in-label">
                            Approved Amount(મંજૂર રકમ)
                          </Index.Typography>
                          <Index.TextField
                            disabled
                            type="number"
                            placeholder="Enter approved amount"
                            // inputProps={ariaLabel}
                            className="basic-input input-width"
                            name="approvedAmount"
                            value={values?.approvedAmount}
                            onChange={(event) => {
                              const inputValue = event.target.value;
                              if (
                                Number(inputValue) <=
                                Number(values?.claimedAmount)
                              ) {
                                handleChange(event);
                              }
                            }}
                            onBlur={handleBlur}
                          />
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={12} md={12} lg={12}>
                        <Index.Box className="lable-input-grp">
                          <Index.Typography component="p" className="in-label">
                            Disbused Amount(મંજૂર રકમ)
                          </Index.Typography>
                          <Index.TextField
                    
                            placeholder="Enter approved amount"
                            // inputProps={ariaLabel}
                            className="basic-input input-width"
                            name="approvedAmount"
                            value={values?.totalDisbursedAmount}
                            
                            onBlur={handleBlur}
                          />
                        </Index.Box>
                      </Index.Grid>

                      <FieldArray name="loanInstallment">
                        <>
                          {values?.loanInstallment?.length > 0 &&
                            values?.loanInstallment?.map((_, index) => (
                              <React.Fragment key={index}>
                                <Index.Grid item xs={12} sm={12} md={6} lg={6}>
                                  <Index.Typography
                                    component="p"
                                    className="in-label"
                                  >
                                    EMI Date(EMI તારીખો)
                                  </Index.Typography>
                                  <Index.Box className="input-design-div with-border">
                                    <Index.LocalizationProvider
                                      dateAdapter={Index.AdapterDayjs}
                                      sx={{ position: "absolute" }}
                                    >
                                      <Index.DemoContainer
                                        components={["DatePicker"]}
                                      >
                                        <MobileDatePicker
                                          name={`loanInstallment[${index}].EMIDate`}
                                          disabled
                                          value={dayjs(
                                            values?.loanInstallment?.[index]
                                              ?.EMIDate
                                          )}
                                          format="YYYY-MM-DD"
                                          sx={{
                                            overflowY: "hidden",
                                            position: "relative",
                                            bottom: "8px",
                                            width: "100%",
                                          }}
                                          slotProps={{
                                            textField: {
                                              size: "small",
                                              error: false,
                                            },
                                          }}
                                        />
                                      </Index.DemoContainer>
                                    </Index.LocalizationProvider>
                                  </Index.Box>
                                </Index.Grid>

                                <Index.Grid item xs={12} sm={12} md={4} lg={4}>
                                  <Index.Box className="lable-input-grp">
                                    <Index.Typography
                                      component="p"
                                      className="in-label"
                                    >
                                      Amount(રકમ)
                                    </Index.Typography>
                                    <Index.TextField
                                      type="number"
                                      placeholder="Enter amount"
                                      className="basic-input input-width"
                                      name={`loanInstallment[${index}].EMIAmount`}
                                      value={
                                        values.loanInstallment[index]?.EMIAmount
                                      }
                                      disabled
                                      onBlur={handleBlur}
                                    />
                                  </Index.Box>
                                </Index.Grid>
                              </React.Fragment>
                            ))}
                        </>
                      </FieldArray>

                      <FieldArray name="recoveryAmount">
                                {({ push, remove }) => (
                                  <>
                                    {values?.recoveryAmount?.map(
                                      (_, index) => (
                                        <React.Fragment key={index}>
                                          <Index.Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={6}
                                            lg={6}
                                          >
                                            <Index.Typography
                                              component="p"
                                              className="in-label"
                                            >
                                              Recovery Date(Recovery તારીખો)
                                            </Index.Typography>
                                            <Index.Box className="input-design-div with-border">
                                              <Index.LocalizationProvider
                                                dateAdapter={Index.AdapterDayjs}
                                                sx={{ position: "absolute" }}
                                              >
                                                <Index.DemoContainer
                                                  components={["DatePicker"]}
                                                >
                                                  <MobileDatePicker
                                                    name={`recoveryAmount[${index}].recoveryDate`}
                                                    onChange={(value) => {
                                                      const formattedDate =
                                                        moment(
                                                          value?.$d
                                                        ).format("YYYY-MM-DD");
                                               
                                                      setFieldValue(
                                                        `recoveryAmount[${index}].recoveryDate`,
                                                        formattedDate
                                                      );
                                                    }}
                                                    disabled={isCompleted}
                                                    value={dayjs(
                                                      values?.recoveryAmount?.[
                                                        index
                                                      ]?.recoveryDate
                                                    )}
                                                    format="YYYY-MM-DD"
                                                    sx={{
                                                      overflowY: "hidden",
                                                      position: "relative",
                                                      bottom: "8px",
                                                      width: "100%",
                                                    }}
                                                    slotProps={{
                                                      textField: {
                                                        size: "small",
                                                        error: false,
                                                      },
                                                    }}
                                                  />
                                                </Index.DemoContainer>
                                              </Index.LocalizationProvider>
                                            </Index.Box>
                                            {errors?.recoveryAmount?.[index]
                                              ?.recoveryDate &&
                                              touched.recoveryAmount?.[index]
                                                ?.recoveryDate && (
                                                <p
                                                  className="error-text"
                                                  style={{ marginTop: "10px" }}
                                                >
                                                  {
                                                    errors?.recoveryAmount[
                                                      index
                                                    ]?.recoveryDate
                                                  }
                                                </p>
                                              )}
                                          </Index.Grid>

                                          <Index.Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={4}
                                            lg={4}
                                          >
                                            <Index.Box className="lable-input-grp">
                                              <Index.Typography
                                                component="p"
                                                className="in-label"
                                              >
                                                Amount(રકમ)
                                              </Index.Typography>
                                              <Index.TextField
                                                type="number"
                                                placeholder="Enter amount"
                                                className="basic-input input-width"
                                                name={`recoveryAmount[${index}].recoveryAmount`}
                                                value={
                                                  values.recoveryAmount[index]
                                                    ?.recoveryAmount
                                                }
                                                disabled={isCompleted}
                                                onChange={(event) => {
                                                  const inputValue =
                                                    event.target.value;
                                                  const totalEMIAmount =
                                                    values.recoveryAmount.reduce(
                                                      (acc, curr, i) => {
                                                        return (
                                                          acc +
                                                          (i === index
                                                            ? parseFloat(
                                                              inputValue
                                                            ) || 0
                                                            : parseFloat(
                                                              curr.recoveryAmount
                                                            ) || 0)
                                                        );
                                                      },
                                                      0
                                                    );

                                                  // Allow input only if totalEMIAmount is less than or equal to approvedAmount
                                                  if (
                                                    totalEMIAmount <=
                                                    values.totalDisbursedAmount
                                                  ) {
                                                    handleChange(event);
                                                  }
                                                }}
                                                onBlur={handleBlur}
                                              />
                                            </Index.Box>
                                            {errors?.recoveryAmount?.[index]
                                              ?.recoveryAmount &&
                                              touched.recoveryAmount?.[index]
                                                ?.recoveryAmount && (
                                                <p
                                                  className="error-text"
                                                  style={{ marginTop: "10px" }}
                                                >
                                                  {
                                                    errors.recoveryAmount[
                                                      index
                                                    ]?.recoveryAmount
                                                  }
                                                </p>
                                              )}
                                          </Index.Grid>

                                          {index ===
                                            values?.recoveryAmount?.length -
                                            1 &&
                                            values?.recoveryAmount?.length >
                                            1 && (
                                              <Index.Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={1}
                                                lg={1}
                                                mt={3}
                                              >
                                                <Index.Box className="approved-btn">
                                                  <LightTooltip title="Minus">
                                                    <Index.Button
                                                      className="approve-btn-list"
                                                      onClick={() =>
                                                        remove(index)
                                                      }
                                                    >
                                                      <img
                                                        src={
                                                          PagesIndex.Png
                                                            .minusYello
                                                        }
                                                        className="approve-plus"
                                                      />
                                                    </Index.Button>
                                                  </LightTooltip>
                                                </Index.Box>
                                              </Index.Grid>
                                            )}
                                        </React.Fragment>
                                      )
                                    )}
                                    {values?.totalDisbursedAmount !== "" &&
                                      values?.recoveryAmount?.reduce(
                                        (acc, curr) =>
                                          acc +
                                          (parseFloat(curr.recoveryAmount) || 0),
                                        0
                                      ) < values.totalDisbursedAmount && (
                                        <Index.Grid
                                          item
                                          xs={12}
                                          sm={6}
                                          md={1}
                                          lg={1}
                                          mt={3}
                                        >
                                          <Index.Box className="approved-btn">
                                            <LightTooltip title="Add">
                                              <Index.Button
                                                className="approve-btn-list"
                                                onClick={() =>
                                                  push({
                                                    recoveryDate: "",
                                                    recoveryAmount: "",
                                                  })
                                                }
                                              >
                                                <img
                                                  src={
                                                    PagesIndex.Svg.plusyellow
                                                  }
                                                  className="approve-plus"
                                                />
                                              </Index.Button>
                                            </LightTooltip>
                                          </Index.Box>
                                        </Index.Grid>
                                      )}
                                  </>
                                )}
                              </FieldArray>
                    </Index.Grid>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="btn-submit-area">
                  <Index.Box className="common-button blue-button change-password-btn update_priofile_btn">
                    <Index.Button
                      variant="contained"
                      disableRipple
                      type="submit"
                      disabled={isCompleted}
                    >
                      Submit
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </form>
          )}
        </PagesIndex.Formik>
      </Index.Box>
    </Index.Modal>
  );
};

export default RecoveryAddEdit;
