import React, { useEffect, useState } from "react";
import Index from "../../../../container/Index";
import PagesIndex from "../../../PagesIndex";
import Pagination from "../../../../components/common/Pagination";
import { getBillList } from "../../../../redux/features/AddService";
import moment from "moment";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import PageLoading from "../../../../components/common/pageLoading/PageLoading";
import RowPerPageDropdown from "../../../../components/common/paginationRowPerpage/RowPerPageDropdown";
import { useSelector } from "react-redux";
import { OutlinedInput } from "@mui/material";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: "#eaac35",
    color: "#ffffff",
    fontSize: "11px",
    fontFamily: "Poppins-Regular",
    padding: "5px 9px",
    lineHeight: "19px",
    borderRadius: "10px",
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 100,
    },
  },
};

const DisbursementBill = () => {
  const location = PagesIndex.useLocation();
  const navigate = PagesIndex.useNavigate();
  const dispatch = PagesIndex.useDispatch();

  //all state
  const [searchedData, setSearchedData] = React.useState([]);

  const [pageData, setPageData] = useState([]);
  const [rowPerPage, setRowPerPage] = useState("25");

  const [statusFilter, setStatusFilter] = useState(0);

  const [disbursementList, setDisbursementList] = React.useState([]);
  const [loader, setLoader] = useState(true);


  const { state } = PagesIndex.useLocation();

  const RowIdHighlightData = state?.rowID;

  
  //Redux
  const { rollPermissionData } = useSelector((state) => state.admin);
  const rollPermissionNameData =
    rollPermissionData?.findData?.roleName?.toLowerCase();

  const rollData = JSON.parse(localStorage?.getItem("RollName"))?.toLowerCase();

  const disbursementBillList = () => {
    if (rollData?.toLowerCase()?.match("admin")?.length) {
      dispatch(getBillList()).then((data) => {
        if (data?.payload) {
          const filterData = data?.payload?.filter((ele) => {
            return ele?.billStatus == "Approved";
          });
          setLoader(false);
          setDisbursementList(filterData);
        }
        setLoader(false);
      });
    } else if (
      rollData?.toLowerCase()?.match("staff")?.length ||
      rollData?.toLowerCase()?.match("committee")?.length
    ) {
      dispatch(getBillList()).then((data) => {
        if (data?.payload) {
          setLoader(false);

          const filterData = data?.payload?.filter((ele) => {
            if (ele?.billStatus == "Approved") {
              if (statusFilter === 0) {
                return (
                  ele?.isBillDisbursed === 0 &&
                  ele?.billDisbursedStatus === null
                );
              } else if (statusFilter === 1) {
                return (
                  ele?.isBillDisbursed === 1 &&
                  (ele?.billDisbursedStatus === "Complete" ||
                    ele?.billDisbursedStatus === null)
                );
              } else if (statusFilter === "PartiallyPending") {
                return (
                  ele?.isBillDisbursed === 0 &&
                  ele?.billDisbursedStatus === "PartiallyPending"
                );
              }
            }
          });

          setDisbursementList(filterData);
        }
        setLoader(false);
      });
    }
  };

  useEffect(() => {
    disbursementBillList();
  }, [statusFilter]);

  useEffect(() => {
    setSearchedData(disbursementList);
  }, [disbursementList]);

  // Search on table
  const requestSearch = (searched) => {
    if (!searched) return setSearchedData(disbursementList);
    setSearchedData(
      disbursementList?.filter(
        (item) =>
          item?.vendorName?.toString().includes(searched?.toLowerCase()) ||
          item?.billNumber
            ?.toString()
            .toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.totalAmount
            ?.toString()
            .toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.ProgramData?.[0]?.programName
            ?.toString()
            .toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.billStatus
            ?.toString()
            ?.toLowerCase()
            ?.includes(searched?.toLowerCase())
      )
    );
  };

  const handleViewDisbursementData = (billForDisbursement) => {
    billForDisbursement["lastStatus"] = statusFilter;
    navigate(`/${rollData}/view-bill-disbursement`, {
      state: {
        billForDisbursement: billForDisbursement,
      },
    });
  };

  //Edit Disbursement
  const handleEdit = (row) => {
    row["lastStatus"] = statusFilter;
    navigate(`/${rollData}/add-edit-bill-disbursement`, {
      state: {
        billForDisbursement: row,
        isEdit: true,
      },
    });
  };

  //#region Active Status
  useState(() => {
    if (location?.state?.prevState) {
      setStatusFilter(location?.state?.prevState);
    }
  }, [location?.state?.prevState]);

  if (
    rollPermissionData?.permissionArray?.includes("Disbursement_view") ||
    rollPermissionNameData === "admin"
  ) {
  } else {
    navigate(`/${rollData}/sahay-application`);
  }

  return (
    <>
      {loader ? (
        <PageLoading />
      ) : (
        <>
          <Index.Box className="page-content-box">
            <Index.Box className="barge-common-box">
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex res-title-header-flex">
                  <Index.Box className="plus-hidden-show-mobile">
                    <Index.Box className="title-main">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="page-title"
                      >
                        Bill Disbursement
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="d-flex align-items-center res-set-search">
                    <PagesIndex.Search
                      className="search"
                      onChange={(e) => requestSearch(e.target.value)}
                    >
                      <PagesIndex.StyledInputBase
                        placeholder="Search"
                        inputProps={{ "aria-label": "search" }}
                      />
                    </PagesIndex.Search>
                    {rollData?.toLowerCase()?.match("staff")?.length ||
                    rollData?.toLowerCase()?.match("committee")?.length ? (
                      <Index.Box className="lable-input-grp pending-button-sahay">
                        <Index.FormControl
                          sx={{ width: "100%" }}
                          className="main-select"
                        >
                          <Index.Select
                            className="select-drop"
                            displayEmpty
                            value={statusFilter}
                            onChange={(e) => {
                              setStatusFilter(e.target.value);
                            }}
                            input={<OutlinedInput />}
                            MenuProps={MenuProps}
                            inputProps={{
                              "aria-label": "Without label",
                            }}
                          >
                            <Index.MenuItem value={0}>Pending</Index.MenuItem>
                            <Index.MenuItem value={1}>Completed</Index.MenuItem>
                            <Index.MenuItem value="PartiallyPending">
                              Partially Pending
                            </Index.MenuItem>
                          </Index.Select>
                        </Index.FormControl>
                      </Index.Box>
                    ) : null}
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="">
                <Index.TableContainer
                  sx={{ maxHeight: 380 }}
                  // component={Index.Paper}
                  className="table-container sahay-application-container scroll-table common-table-container-custom"
                >
                  <Index.Table
                    stickyHeader
                    aria-label="sticky table"
                    className="table-design-main barge-table sahay-appli-table"
                  >
                    <Index.TableHead className="sahay-appli-head">
                      <Index.TableRow className="sahay-appli-tr">
                        <Index.TableCell className="sahay-appli-tr">
                          Vendor Name
                        </Index.TableCell>
                        <Index.TableCell
                          className="sahay-appli-tr"
                          align="left"
                        >
                          Bill Number
                        </Index.TableCell>
                        <Index.TableCell
                          className="user-sahay-td"
                          style={{ cursor: "pointer" }}
                        >
                          Bill Date
                        </Index.TableCell>
                        <Index.TableCell
                          className="sahay-appli-tr"
                          align="left"
                        >
                          Total Amount
                        </Index.TableCell>
                        <Index.TableCell
                          className="sahay-appli-tr"
                          align="left"
                        >
                          Program Name
                        </Index.TableCell>

                        <Index.TableCell
                          className="sahay-appli-tr"
                          align="left"
                        >
                          Disbursement Status
                        </Index.TableCell>
                        {rollPermissionData?.permissionArray?.includes(
                          "Disbursement_view"
                        ) ||
                        rollPermissionData?.permissionArray?.includes(
                          "Disbursement_add"
                        ) ||
                        rollPermissionData?.permissionArray?.includes(
                          "Disbursement_edit"
                        ) ||
                        rollPermissionNameData === "admin" ? (
                          <>
                            <Index.TableCell
                              className="sahay-appli-tr"
                              align="left"
                            >
                              Action
                            </Index.TableCell>{" "}
                          </>
                        ) : (
                          ""
                        )}
                      </Index.TableRow>
                    </Index.TableHead>
                    <Index.TableBody>
                      {pageData?.length ? (
                        pageData?.map((row, index) => (
                          <Index.TableRow
                            key={index}
                            className={`sahay-appli-tr ${
                              row?.Id === RowIdHighlightData
                                ? "highlighted-row"
                                : ""
                            }`}
                          >
                            <Index.TableCell
                              align="left"
                              className="sahay-appli-td"
                            >
                              {row?.vendorName}
                            </Index.TableCell>
                            <Index.TableCell
                              align="left"
                              className="sahay-appli-td"
                            >
                              {row?.billNumber}
                            </Index.TableCell>
                            <Index.TableCell
                              align="left"
                              className="sahay-appli-td"
                            >
                              {moment(row?.billDate).format("DD-MM-YYYY")}
                            </Index.TableCell>

                            <Index.TableCell
                              align="left"
                              className="sahay-appli-td"
                            >
                              {row?.totalAmount}
                            </Index.TableCell>
                            <Index.TableCell
                              align="left"
                              className="sahay-appli-td"
                            >
                              {row?.ProgramData?.[0]?.programName}
                            </Index.TableCell>

                            <Index.TableCell
                              align="left"
                              className="sahay-appli-td"
                              sx={{ textAlign: "center" }}
                            >
                              <Index.Box
                                className={`status-details ${
                                  row?.isBillDisbursed === 0
                                    ? "pending - status"
                                    : row?.isBillDisbursed === 1
                                    ? "approved-status"
                                    : ""
                                }`}
                              >
                                {/* {checkStatus.toUpperCase()} */}
                                {row?.isBillDisbursed === 0 &&
                                row?.billDisbursedStatus == null
                                  ? "Pending"
                                  : row?.isBillDisbursed === 1 &&
                                    (row?.billDisbursedStatus == "Complete" ||
                                      row?.billDisbursedStatus == null)
                                  ? "Completed"
                                  : row?.isBillDisbursed === 0 &&
                                    row?.billDisbursedStatus ==
                                      "PartiallyPending"
                                  ? "Partially Pending"
                                  : "-"}
                              </Index.Box>
                            </Index.TableCell>

                            {rollPermissionData?.permissionArray?.includes(
                              "Disbursement_view"
                            ) ||
                            rollPermissionData?.permissionArray?.includes(
                              "Disbursement_add"
                            ) ||
                            rollPermissionData?.permissionArray?.includes(
                              "Disbursement_edit"
                            ) ||
                            rollPermissionNameData === "admin" ? (
                              <>
                                <Index.TableCell
                                  sx={{ display: "flex" }}
                                  className="sahay-appli-td"
                                >
                                  <Index.Box className="flex-content-sahay">
                                    {rollPermissionData?.permissionArray?.includes(
                                      "Disbursement_add"
                                    ) ? (
                                      rollData?.toLowerCase()?.match("staff")
                                        ?.length &&
                                      row?.billDisbursedStatus !==
                                        "PartiallyPending" ? (
                                        <Index.Box className="approved-btn">
                                          <LightTooltip title="Apply For Disbursement">
                                            <Index.Button
                                              disabled={
                                                row?.isBillDisbursed === 1
                                                  ? true
                                                  : false
                                              }
                                              className="approve-btn-list"
                                              onClick={() =>
                                                navigate(
                                                  `/${rollData}/add-edit-bill-disbursement`,
                                                  {
                                                    state: {
                                                      billForDisbursement: row,
                                                      prevState: statusFilter,
                                                    },
                                                  }
                                                )
                                              }
                                            >
                                              <img
                                                src={PagesIndex.Svg.plusyellow}
                                                className="approve-plus"
                                              ></img>
                                            </Index.Button>
                                          </LightTooltip>
                                        </Index.Box>
                                      ) : (
                                        ""
                                      )
                                    ) : (
                                      ""
                                    )}
                                    {rollPermissionData?.permissionArray?.includes(
                                      "Disbursement_view"
                                    ) || rollPermissionNameData === "admin" ? (
                                      <LightTooltip title="View Disbursement">
                                        <Index.IconButton
                                          disabled={
                                            row?.isBillDisbursed === 0
                                              ? true
                                              : false
                                          }
                                          onClick={() =>
                                            handleViewDisbursementData(row)
                                          }
                                        >
                                          <Index.Visibility className="muiico-view" />
                                        </Index.IconButton>
                                      </LightTooltip>
                                    ) : (
                                      ""
                                    )}

                                    {rollData?.toLowerCase()?.match("staff")
                                      ?.length &&
                                      ((row?.isBillDisbursed === 1 &&
                                        (row?.billDisbursedStatus ===
                                          "Complete" ||
                                          row?.billDisbursedStatus === null)) ||
                                        (row?.isBillDisbursed === 0 &&
                                          row?.billDisbursedStatus ===
                                            "PartiallyPending")) && (
                                        <>
                                          <LightTooltip title="Edit">
                                            <Index.IconButton
                                              onClick={() => handleEdit(row)}
                                            >
                                              <Index.EditIcon className="muiico-edit" />
                                            </Index.IconButton>
                                          </LightTooltip>
                                        </>
                                      )}
                                  </Index.Box>
                                </Index.TableCell>{" "}
                              </>
                            ) : (
                              ""
                            )}
                          </Index.TableRow>
                        ))
                      ) : (
                        <PagesIndex.NoRecordFound colSpan={12} />
                      )}
                    </Index.TableBody>
                  </Index.Table>
                </Index.TableContainer>
              </Index.Box>

              <Index.Box className="pagination-design flex-end">
                <RowPerPageDropdown
                  rowPerPage={rowPerPage}
                  setRowPerPage={setRowPerPage}
                />
                <Index.Stack spacing={2}>
                  <Pagination
                    fetchData={searchedData}
                    setPageData={setPageData}
                    pageData={pageData}
                    rowPerPage={rowPerPage}
                  />
                </Index.Stack>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </>
      )}
    </>
  );
};

export default DisbursementBill;
