import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import Sidebar from "../../../../components/user/defaultLayout/Sidebar";
import {
  getSahayApplicationList,
  getSahaySubTypeList,
  getSahayTypeList,
} from "../../../../redux/features/AddService";
import PagesIndex from "../../../PagesIndex";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import RowPerPageDropdown from "../../../../components/common/paginationRowPerpage/RowPerPageDropdown";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import { OutlinedInput } from "@mui/material";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#eaac35",
    color: "#fffff",
    fontSize: "11px",
    fontFamily: "inter-semibold",
    padding: "3px 10px",
    lineHeight: "15px",
    borderRadius: "10px",
    textTransform: "capitalize",
  },
}));

//

const Listing = () => {
  // useSelectors
  const { getUserLoginDetails } = PagesIndex.useSelector(
    (state) => state.admin
  );

  const navigate = PagesIndex.useNavigate();
  const dispatch = PagesIndex.useDispatch();

  // All States

  const [applicationListData, setApplicationListData] = useState();
  const [pageData, setPageData] = useState();
  const [rowPerPage, setRowPerPage] = useState("25");

  const [searchedData, setSearchedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortColumn, setSortColumn] = useState("userName");
  const [sortDirection, setSortDirection] = useState("asc");
  const [sahayTypeList, setSahayTypeList] = useState([]);
  const [sahaySubTypeList, setSahaySubTypeList] = useState([]);
  const [sahayType, setSahayType] = useState("");
  const [subSahay, setSubSahay] = useState("");
  //get userid
  const userId = getUserLoginDetails ? getUserLoginDetails?.mem_family_id : 0;
  const handleEdit = (row) => {
    navigate("/user/user-listing-edit", {
      state: { row: row, editSahay: "edit" },
    });
  };

  //view list
  const handleSahayApplicationView = (row) => {
    navigate("/user/user-listing-view", { state: row });
  };
  useEffect(() => {
    dispatch(getSahayApplicationList()).then((data) => {
      if (data?.payload) {
        let filterdData = data?.payload?.filter((data) => {
          return data.familyId == userId || data.mem_family_id == userId;
        });

        setApplicationListData(filterdData);
        setLoading(false);
      }
    });
  }, [userId]);

  //search start
  // Copy original data to searched data
  // useEffect(() => {
  //   setSearchedData(applicationListData);
  // }, [applicationListData]);

  // Search on table
  const requestSearch = (searched) => {
    if (!searched) return setSearchedData(applicationListData);
    setSearchedData(
      applicationListData?.filter(
        (item) =>
          item?.mem_family_id?.toString().includes(searched.toLowerCase()) ||
          item?.mem_fname
            ?.toString()
            .toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.mem_lname
            ?.toString()
            .toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.Sahay_category?.toString()
            .toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.SahayAmmount?.toString().includes(searched.toLowerCase()) ||
          item?.AadharNo?.toString().includes(searched.toLowerCase())
      )
    );
  };

  //search end
  const handleSort = (column) => {
    const isAsc = sortColumn === column && sortDirection === "asc";
    setSortDirection(isAsc ? "desc" : "asc");
    setSortColumn(column);

    const sortedData = [...searchedData].sort((a, b) => {
      if (column === "userName") {
        const nameA = `${a.mem_fname} ${a.mem_lname}`.toLowerCase();
        const nameB = `${b.mem_fname} ${b.mem_lname}`.toLowerCase();
        return isAsc ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
      }
      return 0;
    });

    setSearchedData(sortedData);
  };

  //#region sahay and sub sahay filter
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 100,
      },
    },
  };

  const getSahayTypeData = () => {
    dispatch(getSahayTypeList()).then((res) => {
      setSahayTypeList(res?.payload);
    });
  };

  const getSahaySubTypeData = (sahayTypeId) => {
    dispatch(getSahaySubTypeList(sahayTypeId)).then((res) => {
      setSahaySubTypeList(res?.payload);
    });
  };

  useEffect(() => {
    getSahayTypeData();
  }, []);

  useEffect(() => {
    const filters = {
      SahayType: sahayType,
      subSahayType: subSahay,
    };

    const filterData = applicationListData?.filter((ele) => {
      if (
        Object.values(filters)?.some(
          (value) => value !== undefined && value !== null
        )
      ) {
        return Object.entries(filters).every(([key, value]) => {
          if (value === "") return true;

          return +ele[key] === value;
        });
      } else {
        return true;
      }
    });


    setSearchedData(filterData);
  }, [subSahay, sahayType, applicationListData]);

  return (
    <>
      <Index.Box className="apply-sahay-main">
        <Sidebar />
        <Index.Box className="head-content">
          <Index.Box className="admin-panel-content user-admin-panel-content">
            {loading ? (
              <PagesIndex.PageLoading />
            ) : (
              <Index.Box className="page-content-box">
                <Index.Box className="barge-common-box">
                  <Index.Box className="title-header">
                    <Index.Box className="title-header-flex res-title-header-flex">
                      <Index.Box className="plus-hidden-show-mobile">
                        <Index.Box className="title-main">
                          <Index.Typography
                            variant="p"
                            component="p"
                            className="page-title"
                          >
                            Sahay Application
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className="common-button blue-button res-blue-button res-mobile-show">
                          <LightTooltip
                            title="Apply for sahay"
                            className="mobile-menu-show"
                          >
                            <PagesIndex.Link
                              to="/user/apply-for-sahay"
                              className="no-text-decoration"
                            >
                              {" "}
                              <Index.Button
                                variant="contained"
                                disableRipple
                                className="apply-sahay-btn"
                              >
                                <Index.ControlPointIcon />
                              </Index.Button>
                            </PagesIndex.Link>
                          </LightTooltip>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="d-flex align-items-center res-set-search">
                        <Index.Box className="lable-input-grp pending-button-sahay">
                          <Index.FormControl
                            sx={{ width: "100%" }}
                            className="main-select"
                          >
                            <Index.Select
                              className="select-drop"
                              displayEmpty
                              value={sahayType}
                              onChange={(e) => {
                                setSahayType(e.target.value);
                                setSubSahay("");
                                getSahaySubTypeData(e.target.value);
                              }}
                              input={<OutlinedInput />}
                              MenuProps={MenuProps}
                              inputProps={{
                                "aria-label": "Without label",
                              }}
                            >
                              <Index.MenuItem value="">Sahay</Index.MenuItem>
                              {sahayTypeList?.map((ele) => (
                                <Index.MenuItem key={ele?.Id} value={ele?.Id}>
                                  {ele?.Sahay_category}
                                </Index.MenuItem>
                              ))}
                            </Index.Select>
                          </Index.FormControl>
                        </Index.Box>
                        <Index.Box
                          className="lable-input-grp pending-button-sahay"
                          sx={{ marginRight: "10px" }}
                        >
                          <Index.FormControl
                            sx={{ width: "100%" }}
                            className="main-select"
                          >
                            <Index.Select
                              className="select-drop"
                              displayEmpty
                              value={subSahay}
                              onChange={(e) => {
                                setSubSahay(e.target.value);
                              }}
                              input={<OutlinedInput />}
                              MenuProps={MenuProps}
                              inputProps={{
                                "aria-label": "Without label",
                              }}
                            >
                              <Index.MenuItem value="">
                                Sub Sahay
                              </Index.MenuItem>
                              {sahaySubTypeList?.map((ele) => (
                                <Index.MenuItem key={ele?.Id} value={ele?.Id}>
                                  {ele?.SahayName}
                                </Index.MenuItem>
                              ))}
                            </Index.Select>
                          </Index.FormControl>
                        </Index.Box>
                        <PagesIndex.Search className="search">
                          <PagesIndex.StyledInputBase
                            placeholder="Search"
                            onChange={(e) => requestSearch(e.target.value)}
                            inputProps={{ "aria-label": "search" }}
                          />
                        </PagesIndex.Search>
                        <Index.Box className="common-button blue-button res-blue-button desk-hidden-show">
                          <PagesIndex.Link
                            to="/user/apply-for-sahay"
                            className="no-text-decoration"
                          >
                            {" "}
                            <Index.Button
                              variant="contained"
                              disableRipple
                              className="apply-sahay-btn"
                            >
                              <Index.ControlPointIcon />{" "}
                              <span>Apply for sahay</span>
                            </Index.Button>
                          </PagesIndex.Link>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="">
                    <Index.TableContainer
                      sx={{ maxHeight: 340 }}
                      // component={Index.Paper}
                      className="table-container user-sahay-container scroll-table common-table-container-custom"
                    >
                      <Index.Table
                        stickyHeader
                        aria-label="sticky table"
                        className="table-design-main barge-table user-sahay-table"
                      >
                        <Index.TableHead className="user-sahay-thead">
                          <Index.TableRow className="user-sahay-tr">
                            {/* <Index.TableCell className="user-sahay-td">
                              Family Id
                            </Index.TableCell> */}
                            <Index.TableCell
                              className="user-sahay-td"
                              onClick={() => handleSort("userName")}
                              style={{ cursor: "pointer" }}
                            >
                              User Name
                              {sortColumn === "userName" &&
                                (sortDirection === "asc" ? (
                                  <SwapVertIcon
                                    className="swap-icon"
                                    fontSize="small"
                                  />
                                ) : (
                                  <SwapVertIcon
                                    className="swap-icon"
                                    fontSize="small"
                                  />
                                ))}
                            </Index.TableCell>
                            <Index.TableCell
                              className="user-sahay-td"
                              align="left"
                            >
                              Sahay Category
                            </Index.TableCell>
                            <Index.TableCell
                              className="user-sahay-td"
                              align="left"
                            >
                              Sub Sahay Category
                            </Index.TableCell>
                            <Index.TableCell
                              className="user-sahay-td"
                              align="left"
                            >
                              Sahay Amount
                            </Index.TableCell>
                            {/* <Index.TableCell align="left">
                              Addharcard No.
                            </Index.TableCell> */}
                            <Index.TableCell
                              className="user-sahay-td"
                              align="left"
                            >
                              Disbursement Status
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              Staff Status
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              Staff Remark
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              Committee Status
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              Committee Remark
                            </Index.TableCell>

                            <Index.TableCell
                              className="user-sahay-td"
                              align="center"
                            >
                              Action
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody
                          className={
                            !loading ? "user-sahay-tbody" : "loading-table"
                          }
                        >
                          {pageData?.length ? (
                            pageData?.map((row, index) => {
                              return (
                                <Index.TableRow key={index}>
                                  {/* <Index.TableCell align="left">
                                    {row?.mem_family_id? row?.mem_family_id : row?.familyId}
                                  </Index.TableCell> */}
                                  <Index.TableCell align="left">
                                    {row?.mem_fname} {row?.mem_lname}
                                  </Index.TableCell>

                                  <Index.TableCell align="left">
                                    {row?.Sahay_category}
                                  </Index.TableCell>
                                  <Index.TableCell align="left">
                                    {row?.SahayName}
                                  </Index.TableCell>
                                  <Index.TableCell align="left">
                                    {row?.claimedAmmount}
                                  </Index.TableCell>
                                  {/* <Index.TableCell align="left">
                                    {row?.AadharNo}
                                  </Index.TableCell> */}

                                  <Index.TableCell
                                    align="left"
                                    className="sahay-appli-td"
                                    sx={{ textAlign: "center" }}
                                  >
                                    <Index.Box
                                      className={`status-details ${
                                        row?.isDisbursed === 0
                                          ? "pending - status"
                                          : row?.isDisbursed === 1
                                          ? "approved-status"
                                          : ""
                                      }`}
                                    >
                                      {/* {checkStatus.toUpperCase()} */}
                                      {row?.isDisbursed === 0 &&
                                      row?.disbursedStatus == null
                                        ? "Pending"
                                        : row?.isDisbursed === 1 &&
                                          (row?.disbursedStatus == "Complete" ||
                                            row?.disbursedStatus == null)
                                        ? "Completed"
                                        : row?.isDisbursed === 0 &&
                                          row?.disbursedStatus ==
                                            "PartialyPending"
                                        ? "Partially Pending"
                                        : "-"}
                                      {/* {row?.newStatusColumn} */}
                                    </Index.Box>
                                  </Index.TableCell>
                                  <Index.TableCell
                                    align="center"
                                    style={{
                                      color: `${
                                        row?.NewStatusColumn === "Approved"
                                          ? "green"
                                          : row?.NewStatusColumn === "Rejected"
                                          ? "red"
                                          : "grey"
                                      }`,
                                    }}
                                  >
                                    {row?.NewStatusColumn === null
                                      ? "-"
                                      : row?.NewStatusColumn}
                                  </Index.TableCell>

                                  <Index.TableCell
                                    align="left"
                                    className="sahay-appli-td"
                                    sx={{ textAlign: "center" }}
                                  >
                                    {row?.staffRemark === "null" ? (
                                      "-"
                                    ) : row?.staffRemark === "undefined" ? (
                                      "-"
                                    ) : row?.staffRemark === null ? (
                                      "-"
                                    ) : (
                                      <LightTooltip title={row?.staffRemark}>
                                        {
                                          row.staffRemark
                                            // ?.split(" ")
                                            ?.slice(0, 15) + "......"
                                          // ?.join(" ")
                                        }
                                      </LightTooltip>
                                    )}
                                  </Index.TableCell>

                                  <Index.TableCell
                                    align="center"
                                    style={{
                                      color: `${
                                        row?.committeeStatus === "Approved"
                                          ? "green"
                                          : row?.committeeStatus === "Rejected"
                                          ? "red"
                                          : "grey"
                                      }`,
                                    }}
                                  >
                                    {row?.committeeStatus === null
                                      ? "-"
                                      : row?.committeeStatus}
                                  </Index.TableCell>

                                  <Index.TableCell
                                    align="center"
                                    style={{
                                      color: `${
                                        row?.committeeRemark === "Approved"
                                          ? "green"
                                          : row?.committeeRemark === "Rejected"
                                          ? "red"
                                          : "grey"
                                      }`,
                                    }}
                                  >
                                    {row?.committeeRemark === null
                                      ? "-"
                                      : row?.committeeRemark}
                                  </Index.TableCell>

                                  <Index.TableCell align="center">
                                    <Index.Box className="icon-cell">
                                      <Index.IconButton
                                        onClick={() =>
                                          handleSahayApplicationView(row)
                                        }
                                      >
                                        <Index.Visibility className="muiico-view" />
                                      </Index.IconButton>
                                      {row?.SaveSahay == 0 ||
                                      row?.NewStatusColumn.toLowerCase() ==
                                        "rejected" ||
                                      row?.NewStatusColumn.toLowerCase() ==
                                        "hold" ? (
                                        <Index.IconButton
                                          onClick={() => handleEdit(row)}
                                        >
                                          <Index.EditIcon className="muiico-edit" />
                                        </Index.IconButton>
                                      ) : (
                                        ""
                                      )}
                                      {/* <Index.IconButton
                                      // onClick={() => handleDeleteOpen(item)}
                                      >
                                        <Index.DeleteIcon className="muiico-delete" />
                                      </Index.IconButton> */}
                                    </Index.Box>
                                  </Index.TableCell>
                                </Index.TableRow>
                              );
                            })
                          ) : (
                            <PagesIndex.NoRecordFound colSpan={14} />
                          )}
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>
                  </Index.Box>
                  <Index.Box className="pagination-design flex-end">
                    <RowPerPageDropdown
                      rowPerPage={rowPerPage}
                      setRowPerPage={setRowPerPage}
                    />
                    <Index.Stack spacing={2}>
                      <PagesIndex.Pagination
                        fetchData={searchedData}
                        setPageData={setPageData}
                        pageData={pageData}
                        rowPerPage={rowPerPage}
                      />
                    </Index.Stack>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            )}
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default Listing;
