import React, { useEffect, useState } from "react";
import { imagePath } from "../../../../config/DataService";

// Application Form Component
const PrintSahayApplication = React.forwardRef(
  ({ formData, state, familyMembers, editSingleApplicantData }, ref) => {
    return (
      <div
        ref={ref}
        style={{ padding: "20px", fontFamily: "Arial, sans-serif", lineHeight: "1.6" }}
      >
        <h1 style={{ textAlign: "center", fontSize: "24px", marginBottom: "20px" }}>
          Application Form
        </h1>

        {formData?.document
          ?.map((data) => ({
            documentType: data.documentType,
            documents: data.documents?.filter((doc) => doc.document !== "null"),
          }))
          ?.filter((item) => item.documents?.length > 0)
          ?.map((item, fileIndex) => (
            <div key={fileIndex} style={{ marginBottom: "20px" }}>
              <h3 style={{ fontSize: "18px", marginBottom: "10px" }}>
                {item.documentType}
              </h3>

              {/* Iterate over filtered documents and display them */}
              {item.documents.map((document, documentIndex) => (
                <div
                  className="select-view-document-name mt-select-doc"
                  key={documentIndex}
                  style={{
                    marginBottom: "10px",
                    padding: "5px",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    cursor: "pointer",
                    backgroundColor: "#f9f9f9",
                  }}
                >
                  <a
                    href="#"
                    style={{
                      color: "#007bff",
                      textDecoration: "none",
                    }}
                    onClick={() => {
                      window.open(
                        document instanceof Blob
                          ? URL.createObjectURL(document)
                          : `${imagePath}${document?.filename}`
                      );
                    }}
                  >
                    {document instanceof Blob ? document.name : document?.document}
                  </a>
                </div>
              ))}
            </div>
          ))}

        {/* Main Application Details */}
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            marginTop: "20px",
            marginBottom: "30px",
            borderRadius: "8px",
            overflow: "hidden",
            boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
          }}
        >
          <tbody>
            {/* Personal Information */}
            {[
              { label: "Total Requested Sahay Amount", value: formData?.application[0]?.claimedAmmount },
              { label: "Approve Amount", value: formData?.application[0]?.approveAmount },
              { label: "Family ID", value: state?.familyId },
              { label: "Aadhar Card No.", value: formData?.application[0]?.AadharNo },
              { label: "Sahay Category", value: formData?.application[0]?.SahayName },
              { label: "Sub Sahay Category", value: formData?.application[0]?.subSahayName },
              { label: "Year Of Sahay", value: formData?.application[0]?.Year || `${formData?.application[0]?.fromYear}-${formData?.application[0]?.toYear}` },
              { label: "Remark", value: formData?.application[0]?.remark },
              { label: "Application Received Date", value: formData?.application[0]?.applicationReceiveDate },
              { label: "Filing Date", value: formData?.application[0]?.formFillingDate },
              { label: "First Name", value: formData?.application[0]?.firstName },
              { label: "Middle Name", value: formData?.application[0]?.middleName },
              { label: "Last Name", value: formData?.application[0]?.lastName },
              { label: "Relation", value: editSingleApplicantData?.relation || formData?.application[0]?.relation },
              { label: "Age", value: formData?.application[0]?.age },
              { label: "Working Type", value: formData?.application[0]?.workingType },
              {
                label: "Reference / Gurantor",
                value: formData?.application?.[0]?.patanMandalMember
                  ? `${formData?.PatanMemberData?.[0]?.mem_family_id} ${formData?.PatanMemberData?.[0]?.mem_fname} ${formData?.PatanMemberData?.[0]?.mem_mname} ${formData?.PatanMemberData?.[0]?.mem_lname}`
                  : `${formData?.application[0]?.patanReferenceName}`,
              },
              
              { label: "Mohalla Name", value: formData?.application[0]?.patanMandalMember ? formData?.PatanMemberData?.[0]?.mohollo_name : formData?.application?.[0]?.patanReferenceMohollo  },
              { label: "Mobile Number", value: formData?.application[0]?.patanMemberMobile },
              { label: "Area", value: formData?.application[0]?.patanMandalMember ? formData?.PatanMemberData?.[0]?.family_area : formData?.application?.[0]?.patanReferenceArea  },
              { label: "Type Of Disease", value: formData?.application[0]?.disease },
              { label: "Bank Name", value: formData?.application[0]?.bankName },
              { label: "Branch", value: formData?.application[0]?.branch },
              { label: "Account Holder Name", value: formData?.application[0]?.HolderName },
              { label: "Account Number", value: formData?.application[0]?.AccountNo },
              { label: "IFSC code", value: formData?.application[0]?.IFSCCode },
            ].map((row, index) => (
              <tr key={index}>
                <th style={styles.th}>{row.label}</th>
                <td style={styles.td}>{row.value || "N/A"}</td>
              </tr>
            ))}

            {formData?.application[0]?.subSahayName === "Educational Loan" ||
            formData?.application[0]?.subSahayName === "Swawlamban" ? (
              <tr>
                <th style={styles.th}>PAN Card No.</th>
                <td style={styles.td}>{formData?.application?.[0]?.PANCardNo || "N/A"}</td>
              </tr>
            ) : null}
          </tbody>
        </table>

        {/* Family Members Table */}
        {familyMembers?.length ? (
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              marginTop: "20px",
              marginBottom: "20px",
              borderRadius: "8px",
              overflow: "hidden",
              boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
            }}
          >
            <thead>
              <tr>
                <th style={styles.th}>Name</th>
                <th style={styles.th}>Age</th>
                <th style={styles.th}>Relation</th>
                <th style={styles.th}>Working Type</th>
              </tr>
            </thead>
            <tbody>
              {familyMembers
                ?.filter((ele) => ele?.id !== formData?.application?.[0]?.applicantId)
                ?.map((data, index) => (
                  <tr key={index}>
                    <td style={styles.td}>
                      {`${data?.firstName} ${data?.middleName} ${data?.lastName}` || "-"}
                    </td>
                    <td style={styles.td}>{data?.age || "-"}</td>
                    <td style={styles.td}>{data?.relation || "-"}</td>
                    <td style={styles.td}>{data?.workingType || "-"}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        ) : (
          <p>No family members data available</p>
        )}
      </div>
    );
  }
);

// Styles for the table
const styles = {
  th: {
    textAlign: "left",
    padding: "12px",
    border: "1px solid #ccc",
    backgroundColor: "#f2f2f2",
    fontWeight: "bold",
    fontSize: "14px",
  },
  td: {
    padding: "12px",
    border: "1px solid #ccc",
    fontSize: "14px",
  },
};

export default PrintSahayApplication;
