import React, { useEffect, useState } from "react";
import Index from "../../../../container/Index";
import PagesIndex from "../../../PagesIndex";
import { Tooltip, tooltipClasses } from "@mui/material";
import moment from "moment";
import dayjs from "dayjs";
import {
  addBillDisbursement,
  getSingleDisbursedBill,
  editBillDisbursement,
} from "../../../../redux/features/AddService";
import { Api } from "../../../../config/Api";
import dataService, { imagePath } from "../../../../config/DataService";
import { toast } from "react-toastify";
import PageLoading from "../../../../components/common/pageLoading/PageLoading";
import { useSelector } from "react-redux";
import { MobileDatePicker } from "@mui/x-date-pickers";
import styled from "styled-components";
import { FieldArray } from "formik";
import { validationSchemaDisbursementBill } from "../../../../validation/Validation";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: "#eaac35",
    color: "#ffffff",
    fontSize: "11px",
    fontFamily: "Poppins-Regular",
    padding: "5px 9px",
    lineHeight: "19px",
    borderRadius: "10px",
  },
}));

const ariaLabel = { "aria-label": "description" };

const ViewBillDisbursement = () => {
  const dispatch = PagesIndex.useDispatch();
  const navigate = PagesIndex.useNavigate();
  const location = PagesIndex.useLocation();

  const editDisbursement = location?.state?.billForDisbursement;
  const rollData = JSON.parse(localStorage?.getItem("RollName"))?.toLowerCase();
  const [pageLoader, setPageLoader] = useState(false);

  const [singleBillDisburseData, setSingleBillDisburseData] = useState("");
  const [getViewDataDocuments, setGetViewDataDocuments] = useState([]);

  ///// Previous Sahay Data

  const handleBack = () => {
    navigate(`/${rollData}/disbursed-bill-list`, {
      state: {
        rowID: location?.state?.billForDisbursement?.Id,
        prevState: location?.state?.billForDisbursement?.lastStatus,
      },
    });
  };

  const getSingleDisburseData = () => {
    setPageLoader(true);
    dispatch(getSingleDisbursedBill(editDisbursement?.Id)).then((res) => {
      if (res?.payload?.status == 200) {
        setSingleBillDisburseData(res?.payload?.data);
        if (res?.payload?.data?.BillDisbursedDocuments) {
          setGetViewDataDocuments(res?.payload?.data?.BillDisbursedDocuments);
        }
        setPageLoader(false);
      }
    });
  };

  useEffect(() => {
    getSingleDisburseData();
  }, []);

  const { rollPermissionData } = useSelector((state) => state.admin);
  const rollPermissionNameData =
    rollPermissionData?.findData?.parentRoleName?.toLowerCase();

  // if (
  //   rollPermissionData?.permissionArray?.includes("Disbursement_add") ||
  //   rollPermissionData?.permissionArray?.includes("Disbursement_edit")
  // ) {
  // } else {
  //   navigate(`/${rollData}/sahay-application`);
  // }

  return (
    <>
      <div>
        {pageLoader ? (
          <PageLoading />
        ) : (
          <>
            <Index.Box className="apply-sahay-main">
              <Index.Box className="head-content">
                <Index.Box
                  className={`user-right ${
                    location.pathname == "/admin/add-edit-bill-disbursement"
                      ? "admin-border-radius"
                      : ""
                  }`}
                >
                  <Index.Box
                    className={`bottom-user-right ${
                      location.pathname == "/admin/add-edit-bill-disbursement"
                        ? "admin-height-remove"
                        : ""
                    }`}
                  >
                    <Index.Box className="user-apply-content">
                      <Index.Box className="main-in-row">
                        <Index.Box className="user-basic user-heading">
                          <Index.Typography
                            component="h1"
                            className="head-apply"
                          >
                            View Bill disbursement
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="main-in-row">
                        <Index.Box
                          className="user-basic"
                          sx={{ display: "flex" }}
                        ></Index.Box>
                        <Index.Box
                          sx={{ flexGrow: 1 }}
                          className="al-form-groups"
                        >
                          <Index.Grid container spacing={2}>
                            {getViewDataDocuments?.length > 0 && (
                              <Index.Grid item xs={12} sm={12} md={12}>
                                <Index.Box className="title-header">
                                  <Index.Box className="title-header-flex">
                                    <Index.Box className="title-main ">
                                      <Index.Typography
                                        variant="p"
                                        component="p"
                                        className="page-title"
                                      >
                                        Documents
                                      </Index.Typography>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                                <Index.Grid item xs={8}>
                                  <Index.Box className="select-view-document-name">
                                    {getViewDataDocuments?.map(
                                      (file, index) => (
                                        <Index.Box
                                          className="document-name"
                                          key={index}
                                        >
                                          <Index.Typography
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                              // Implement the logic to view the document here
                                              // You can use a modal or a new browser tab/window to show the document
                                              window.open(
                                                `${imagePath}${file.Documents}`
                                              );
                                            }}
                                          >
                                            {file.Filename}
                                          </Index.Typography>
                                        </Index.Box>
                                      )
                                    )}
                                  </Index.Box>
                                </Index.Grid>
                              </Index.Grid>
                            )}
                            <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                              <Index.Box className="lable-input-grp">
                                <Index.Typography
                                  component="p"
                                  className="in-label"
                                >
                                  Bill Number (બિલ નંબર)
                                </Index.Typography>
                                <Index.TextField
                                  disabled
                                  inputProps={ariaLabel}
                                  className="basic-input input-width"
                                  name="familyId"
                                  value={
                                    singleBillDisburseData?.ProgramBill
                                      ?.billNumber
                                  }
                                />
                              </Index.Box>
                            </Index.Grid>
                            <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                              <Index.Box className="lable-input-grp">
                                <Index.Typography
                                  component="p"
                                  className="in-label"
                                >
                                  Vendor Name (વિક્રેતાનું નામ)
                                </Index.Typography>
                                <Index.TextField
                                  disabled
                                  type="text"
                                  inputProps={ariaLabel}
                                  className="basic-input input-width"
                                  name="userName"
                                  value={
                                    singleBillDisburseData?.ProgramBill
                                      ?.vendorName
                                  }
                                />
                              </Index.Box>
                            </Index.Grid>

                            <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                              <Index.Box className="lable-input-grp">
                                <Index.Typography
                                  component="p"
                                  className="in-label"
                                >
                                  Cheque No. (ચેક નં.)
                                </Index.Typography>
                                <Index.TextField
                                  inputProps={ariaLabel}
                                  className="basic-input input-width"
                                  name="chequeNumber"
                                  value={
                                    singleBillDisburseData?.BillDisbursedDetails
                                      ?.chequeNumber
                                  }
                                  disabled
                                />
                              </Index.Box>
                            </Index.Grid>

                            <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                              <Index.Typography
                                component="p"
                                className="in-label"
                              >
                                Cheque Date (તારીખ તપાસો)
                              </Index.Typography>
                              <Index.Box className="input-design-div with-border ">
                                <Index.LocalizationProvider
                                  dateAdapter={Index.AdapterDayjs}
                                  sx={{ position: "absolute" }}
                                >
                                  <Index.DemoContainer
                                    components={["DatePicker"]}
                                  >
                                    <MobileDatePicker
                                      name="chequeDate"
                                      disabled
                                      value={dayjs(
                                        singleBillDisburseData
                                          ?.BillDisbursedDetails?.chequeDate
                                      )}
                                      format="YYYY-MM-DD"
                                      sx={{
                                        overflowY: "hidden",
                                        position: "relative",
                                        bottom: "8px",
                                        width: "100%",
                                      }}
                                      slotProps={{
                                        textField: {
                                          size: "small",
                                          error: false,
                                        },
                                      }}
                                    />
                                  </Index.DemoContainer>
                                </Index.LocalizationProvider>
                              </Index.Box>
                            </Index.Grid>

                            <>
                              <Index.Grid
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                lg={3}
                                mb={2}
                              >
                                <Index.Box className="lable-input-grp">
                                  <Index.Typography
                                    component="p"
                                    className="in-label"
                                  >
                                    Bill Amount (બિલની રકમ)
                                  </Index.Typography>
                                  <Index.TextField
                                    disabled
                                    type="number"
                                    inputProps={ariaLabel}
                                    className="basic-input input-width"
                                    name="payAmount"
                                    value={
                                      singleBillDisburseData?.ProgramBill
                                        ?.totalAmount
                                    }
                                  />
                                </Index.Box>
                              </Index.Grid>

                              <>
                                {singleBillDisburseData?.BillDisbursedDetails &&
                                  JSON.parse(
                                    singleBillDisburseData?.BillDisbursedDetails
                                      ?.disbursedInstallments
                                  )?.map((amount, index) => (
                                    <Index.Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      md={4}
                                      lg={4}
                                      mb={2}
                                      key={index}
                                    >
                                      <Index.Grid container spacing={2}>
                                        {/* Disbursed Amount */}
                                        <Index.Grid
                                          item
                                          xs={12}
                                          sm={6}
                                          md={4}
                                          lg={5}
                                          mb={2}
                                        >
                                          <Index.Box className="label-input-grp">
                                            <Index.Typography
                                              component="p"
                                              className="in-label"
                                            >
                                              Disbursed Amount (વિતરિત રકમ)
                                            </Index.Typography>

                                            <Index.TextField
                                              type="number"
                                              disabled
                                              inputProps={{
                                                ...ariaLabel,
                                              }}
                                              className="basic-input input-width"
                                              value={amount?.disbursedAmount}
                                            />
                                          </Index.Box>
                                        </Index.Grid>

                                        {/* Pending Amount */}
                                        <Index.Grid
                                          item
                                          xs={12}
                                          sm={6}
                                          md={4}
                                          lg={5}
                                          mb={2}
                                        >
                                          <Index.Box className="label-input-grp">
                                            <Index.Typography
                                              component="p"
                                              className="in-label"
                                            >
                                              Pending Amount (બાકી રકમ)
                                            </Index.Typography>
                                            <Index.TextField
                                              disabled
                                              type="number"
                                              inputProps={ariaLabel}
                                              className="basic-input input-width"
                                              value={amount?.pendingAmount}
                                            />
                                          </Index.Box>
                                        </Index.Grid>
                                      </Index.Grid>
                                    </Index.Grid>
                                  ))}
                              </>
                            </>
                          </Index.Grid>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box
                        sx={{ flexGrow: 1 }}
                        className="al-form-groups fam-in-grp"
                      >
                        <Index.Grid item xs={6}>
                          <Index.Box className="common-button blue-button flex-end save-btn back-btn">
                            <Index.Button
                              variant="contained"
                              onClick={() => handleBack()}
                            >
                              Back
                            </Index.Button>
                          </Index.Box>
                        </Index.Grid>
                      </Index.Box>

                      <Index.Box className="title-header-flex "></Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </>
        )}
      </div>
    </>
  );
};

export default ViewBillDisbursement;
