import { createSlice } from "@reduxjs/toolkit";
import {
  getBloodmasterList,
  getCityMasterList,
  getCountryMasterList,
  getGnyatiMasterList,
  getMaritalstatusList,
  getMohallalist,
  getNewMemberDatalist,
  getRelationMasterList,
  getSahaylist,
  getStateMasterList,
  getSubSahaylist,
  loginAdmin,
  getBankMasterList,
  getYearOfSahayMasterList,
  getCMSList,
  getUSerProfileUpdate,
  getSahayApplicationListAdmin,
  getSingleSahayApplication,
  getAdminProfile,
  getUserProfileData,
  getAdminOtpData,
  getRoleMaster,
  getAdminUserMaster,
  getReportData,
  getDisbursementData,
  getEscReportData,
  getCommiteData,
  getRepaymentData,
  getSahayDetailsReport,
  getSahayDetailsData,
  getFamilyWiseReport,
  getSubSahayReport,
  getNewReportData,

  /////////////////////
  getListOfFamilyId,
  getPreviousSahayApplicationListAdmin,
  updatePreviousData,
  getSubSahayReportData,
  getAllFamilyIDs,
} from "../AddService";

export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});

const AdminSlice = createSlice({
  name: "admin",
  initialState: {
    adminData: {},
    rollPermissionData: [],
    getSahaydata: [],
    getSubSahaydata: [],
    token: "",
    userToken: "",
    loading: false,
    isAuthenticated: false,
    status: STATUSES.IDLE,
    bankMasterList: [],
    yearOfSahayMasterList: [],
    getMohalla: [],
    getBloodList: [],
    getMaritalList: [],
    getGnyatiList: [],
    getRelationList: [],
    getStateList: [],
    getCountryList: [],
    getCityList: [],
    getDataList: [],
    getCmslist: [],
    getTermsAndCon: {},
    getContactUs: {},
    getPrivacyPolicy: {},
    getUserLoginDetails: {},
    getUserLoginProfileDetails: {},
    getUSerProfileUpdate: [],
    sahayApplicationAdminGet: [],
    sahayApplicationAdminSingleGet: [],
    adminProfileGet: {},
    userProfileGet: {},
    adminOtpData: {},
    getRoleMasterData: [],
    getAdminUserMasterData: [],
    getReportDataList: [],
    getDisbursementDataList: [],
    getSahayDetailsDataList: [],
    getSahayDetailsReportList: [],
    getRepaymentDataList: [],
    getNewReportDataList: [],
    getCommiteDataList: [],
    getEscReportDataList: [],
    getFamilyWiseReportList: [],
    getSubSahayReportList: [],

    //////////////////////////////////
    getFamilyIdList: [],
    editPreviousSahayData: {},
    getPriviousListData: [],
    getUpdatedPriviousData: {},
    getPermissionDataArray: [],

    /////////
    getAllRepotsFamilyIds: [],
  },
  reducers: {
    logout: (state) => {
      state.isAuthenticated = false;
      state.adminData = {};
      state.token = "";
    },
    userLogout: (state) => {
      state.isAuthenticated = false;
      state.getUserLoginProfileDetails = {};
      state.userToken = "";
    },

    userLoginDetails: (state, action) => {
      state.getUserLoginDetails = action?.payload?.data;
      state.userToken = action?.payload?.token;
    },
    adminLoginDetails: (state, action) => {
      state.getAdminLoginDetails = action?.payload;
    },

    getPermissionData: (state, action) => {
      state.getPermissionDataArray = action?.payload?.permissionArray;
    },

    editPreviousSahay: (state, action) => {
      state.editPreviousSahayData = action.payload;
    },

    userLoginProfileDetails: (state, action) => {
      let data = action?.payload;
      state.getUserLoginProfileDetails = {
        mem_fname: data?.mem_fname,
        mem_mname: data?.mem_mname,
        mem_lname: data?.mem_lname,
        mem_mobile: data?.mem_mobile,
        mem_pic: data?.mem_pic,
        mem_emailid: data?.mem_emailid,
        vastipatrak: data?.vastipatrak,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginAdmin.fulfilled, (state, action) => {
    
      state.adminData = action.payload?.findData;
      state.rollPermissionData = action.payload;
      state.isAuthenticated = true;
      state.token = action.payload?.token;
    });
    builder
      .addCase(getSahaylist.pending, (state, action) => {
        state.status = STATUSES.LOADING;
        state.loading = true;
        // if (action?.payload !== undefined) {
        //   state.status = STATUSES.LOADING;
        //   state.loading = true;
        // }
      })
      .addCase(getSahaylist.fulfilled, (state, action) => {
        state.status = STATUSES.IDLE;
        state.getSahaydata = action.payload?.data;
        state.loading = false;
        // if (action?.payload !== undefined) {
        //   state.status = STATUSES.IDLE;
        //   state.getSahaydata = action.payload?.data;
        //   state.loading = false;
        // }
      })
      .addCase(getSahaylist.rejected, (state, action) => {
        // if (action?.payload !== undefined) {
        state.status = STATUSES.ERROR;
        state.loading = true;
        // }
      });
    builder
      .addCase(getSubSahaylist.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })

      .addCase(getSubSahaylist.fulfilled, (state, action) => {
        state.status = STATUSES.IDLE;
        state.getSubSahaydata = action.payload;
      })

      .addCase(getSubSahaylist.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      })
      .addCase(getBankMasterList.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getBankMasterList.fulfilled, (state, action) => {
        state.status = STATUSES.IDLE;
        state.bankMasterList = action.payload;
      })
      .addCase(getBankMasterList.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      })
      .addCase(getYearOfSahayMasterList.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getYearOfSahayMasterList.fulfilled, (state, action) => {
        state.status = STATUSES.IDLE;
        state.yearOfSahayMasterList = action.payload;
      })
      .addCase(getYearOfSahayMasterList.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
    builder.addCase(getMohallalist.fulfilled, (state, action) => {
      state.getMohalla = action.payload;
      state.status = STATUSES.IDLE;
    });
    builder.addCase(getBloodmasterList.fulfilled, (state, action) => {
      state.getBloodList = action.payload;
      state.status = STATUSES.IDLE;
    });
    builder.addCase(getMaritalstatusList.fulfilled, (state, action) => {
      state.getMaritalList = action.payload;
      state.status = STATUSES.IDLE;
    });
    builder.addCase(getGnyatiMasterList.fulfilled, (state, action) => {
      state.getGnyatiList = action.payload;
      state.status = STATUSES.IDLE;
    });
    builder.addCase(getRelationMasterList.fulfilled, (state, action) => {
      state.getRelationList = action.payload;
      state.status = STATUSES.IDLE;
    });
    builder.addCase(getStateMasterList.fulfilled, (state, action) => {
      state.getStateList = action.payload;
      state.status = STATUSES.IDLE;
    });
    builder.addCase(getCountryMasterList.fulfilled, (state, action) => {
      state.getCountryList = action.payload;
      state.status = STATUSES.IDLE;
    });
    builder.addCase(getCityMasterList.fulfilled, (state, action) => {
      state.getCityList = action.payload;
      state.status = STATUSES.IDLE;
    });
    builder
      .addCase(getNewMemberDatalist.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getNewMemberDatalist.fulfilled, (state, action) => {
        state.getDataList = action.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(getNewMemberDatalist.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(getCMSList.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getCMSList.fulfilled, (state, action) => {
        state.getTermsAndCon = action.payload?.filter((ele) => {
          return ele?.type == "termsAndCondition";
        });
        state.getContactUs = action.payload?.filter((ele) => {
          return ele?.type == "contactus";
        });
        state.getPrivacyPolicy = action.payload?.filter((ele) => {
          return ele?.type == "privacyPolicy";
        });
      })
      .addCase(getCMSList.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
    builder.addCase(getUSerProfileUpdate.fulfilled, (state, action) => {
      state.getUserProfile = action.payload;
      state.status = STATUSES.IDLE;
    });
    builder.addCase(getSahayApplicationListAdmin.pending, (state, action) => {
      state.status = STATUSES.LOADING;
    });

    builder.addCase(getSahayApplicationListAdmin.fulfilled, (state, action) => {
      state.sahayApplicationAdminGet = action.payload;
      state.status = STATUSES.IDLE;
    });
    builder.addCase(getSahayApplicationListAdmin.rejected, (state, action) => {
      state.status = STATUSES.ERROR;
    });
    builder.addCase(getSingleSahayApplication.fulfilled, (state, action) => {
      state.sahayApplicationAdminSingleGet = action.payload;
      state.status = STATUSES.IDLE;
    });
    builder.addCase(getAdminProfile.fulfilled, (state, action) => {
      state.adminProfileGet = action.payload;
      state.status = STATUSES.IDLE;
    });
    builder.addCase(getUserProfileData.fulfilled, (state, action) => {
      state.userProfileGet = action.payload;
      state.status = STATUSES.IDLE;
    });
    builder.addCase(getAdminOtpData.fulfilled, (state, action) => {
      state.adminOtpData = action.payload;
      state.status = STATUSES.IDLE;
    });
    builder
      .addCase(getRoleMaster.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getRoleMaster.fulfilled, (state, action) => {
  
        state.getRoleMasterData = action?.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(getRoleMaster.rejected, (state) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(getAdminUserMaster.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getAdminUserMaster.fulfilled, (state, action) => {
        state.getAdminUserMasterData = action?.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(getAdminUserMaster.rejected, (state) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(getReportData.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getReportData.fulfilled, (state, action) => {
        state.getReportDataList = action?.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(getReportData.rejected, (state) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(getSubSahayReport.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getSubSahayReport.fulfilled, (state, action) => {
        state.getSubSahayReportList = action?.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(getSubSahayReport.rejected, (state) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(getFamilyWiseReport.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getFamilyWiseReport.fulfilled, (state, action) => {
        
        state.getFamilyWiseReportList = action?.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(getFamilyWiseReport.rejected, (state) => {
        state.status = STATUSES.ERROR;
      });

    builder
      .addCase(getDisbursementData.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getDisbursementData.fulfilled, (state, action) => {
        state.getDisbursementDataList = action?.payload?.data;
        state.status = STATUSES.IDLE;
      })
      .addCase(getDisbursementData.rejected, (state) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(getEscReportData.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getEscReportData.fulfilled, (state, action) => {
        state.getEscReportDataList = action?.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(getEscReportData.rejected, (state) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(getCommiteData.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getCommiteData.fulfilled, (state, action) => {
        state.getCommiteDataList = action?.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(getCommiteData.rejected, (state) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(getRepaymentData.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getRepaymentData.fulfilled, (state, action) => {
        state.getRepaymentDataList = action?.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(getRepaymentData.rejected, (state) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(getNewReportData.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getNewReportData.fulfilled, (state, action) => {
        state.getNewReportDataList = action?.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(getNewReportData.rejected, (state) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(getSahayDetailsReport.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getSahayDetailsReport.fulfilled, (state, action) => {
        state.getSahayDetailsReportList = action?.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(getSahayDetailsReport.rejected, (state) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(getSubSahayReportData.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getSubSahayReportData.fulfilled, (state, action) => {
        state.getSubSahayReportDataList = action?.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(getSubSahayReportData.rejected, (state) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(getSahayDetailsData.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getSahayDetailsData.fulfilled, (state, action) => {
        state.getSahayDetailsDataList = action?.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(getSahayDetailsData.rejected, (state) => {
        state.status = STATUSES.ERROR;
      });

    ////////////////////Previous Sahay ////////////////

    builder
      .addCase(getListOfFamilyId.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getListOfFamilyId.fulfilled, (state, action) => {
        state.getFamilyIdList = action?.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(getListOfFamilyId.rejected, (state) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(
        getPreviousSahayApplicationListAdmin.pending,
        (state, action) => {
          state.status = STATUSES.LOADING;
        }
      )
      .addCase(
        getPreviousSahayApplicationListAdmin.fulfilled,
        (state, action) => {
          state.getPriviousListData = action?.payload;
          state.status = STATUSES.IDLE;
        }
      )
      .addCase(getPreviousSahayApplicationListAdmin.rejected, (state) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(updatePreviousData.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(updatePreviousData.fulfilled, (state, action) => {
        state.getUpdatedPriviousData = action?.payload?.data;
        state.status = STATUSES.IDLE;
      })
      .addCase(updatePreviousData.rejected, (state) => {
        state.status = STATUSES.ERROR;
      });
    builder.addCase(getAllFamilyIDs.fulfilled, (state, action) => {
 
      state.getAllRepotsFamilyIds = action?.payload?.Data;
      // state.status = STATUSES.IDLE;
    });
  },
});

export const {
  adminLoginDetails,
  logout,
  getPermissionData,
  userLoginDetails,
  userLoginProfileDetails,
  userLogout,
  editPreviousSahay,
} = AdminSlice.actions;

export default AdminSlice.reducer;
