import React, { useEffect, useState } from "react";
import Index from "../../../../container/Index";

import PagesIndex from "../../../PagesIndex";

import moment from "moment";

import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import { useSelector } from "react-redux";
import PageLoading from "../../../../components/common/pageLoading/PageLoading";
import RowPerPageDropdown from "../../../../components/common/paginationRowPerpage/RowPerPageDropdown";
import { OutlinedInput } from "@mui/material";
import {
  billDelete,
  billStatus,
  getBillList,
} from "../../../../redux/features/AddService";
import DeleteModal from "../../../../components/common/deletemodel/DeleteModel";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: "#eaac35",
    color: "#ffffff",
    fontSize: "11px",
    fontFamily: "Poppins-Regular",
    padding: "5px 9px",
    lineHeight: "19px",
    borderRadius: "10px",
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 100,
    },
  },
};

const BillList = () => {
  let deleteText = "bill";
  const location = PagesIndex.useLocation();
  const navigate = PagesIndex.useNavigate();
  const dispatch = PagesIndex.useDispatch();
  const [searchedData, setSearchedData] = React.useState([]);
  const [pageData, setPageData] = useState([]);
  const [rowPerPage, setRowPerPage] = useState("25");
  const [statusFilter, setStatusFilter] = useState("Pending");
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const [loader, setLoader] = useState(true);

  const [applicationListData, setApplicationListData] = useState([]);

  const rollData = JSON.parse(localStorage?.getItem("RollName"))?.toLowerCase();

  const { state } = PagesIndex.useLocation();

  const RowIdHighlightData = state?.rowID;

  const handleDeleteOpen = (data) => {
    setDeleteId(data?.Id);

    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setDeleteId("");
  };

  const getPrograms = () => {
    dispatch(getBillList()).then((data) => {
      if (data?.payload) {
        setLoader(false);
        const filterData = data?.payload?.filter((ele) => {
          if (rollData === "staff" || rollData === "committee") {
            return ele?.billStatus == statusFilter;
          }
          return true;
        });
        setApplicationListData(filterData);
      }
      setLoader(false);
    });
  };

  const handleDeleteRecord = () => {
    let data = {
      id: deleteId,
    };

    dispatch(billDelete(data)).then((res) => {
      if (res?.payload == 200) {
        getPrograms();
      }
    });
    setDeleteOpen(false);
  };

  const handleApproveReject = (id, status) => {
    let data = {
      id: id,
      status: status,
    };

    dispatch(billStatus(data)).then((res) => {
      if (res?.payload == 200) {
        getPrograms();
      }
    });
  };

  useEffect(() => {
    getPrograms();
  }, [statusFilter]);

  useEffect(() => {
    setSearchedData(applicationListData);
  }, [applicationListData]);

  const requestSearch = (searched) => {
    if (!searched) return setSearchedData(applicationListData);
    setSearchedData(
      applicationListData.filter(
        (item) =>
          item?.vendorName?.toString().includes(searched?.toLowerCase()) ||
          item?.billNumber
            ?.toString()
            .toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.totalAmount
            ?.toString()
            .toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.ProgramData?.[0]?.programName
            ?.toString()
            .toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.billStatus
            ?.toString()
            ?.toLowerCase()
            ?.includes(searched?.toLowerCase())
      )
    );
  };

  const { rollPermissionData } = useSelector((state) => state.admin);

  //Edit Sahay application
  const handleEdit = (data) => {
    data["lastStatus"] = statusFilter;
    navigate(`/${rollData}/add-edit-bill`, {
      state: { editBill: data },
    });
  };

  const handleView = (data) => {
    data["lastStatus"] = statusFilter;
    navigate(`/${rollData}/view-bill`, {
      state: { viewBillData: data },
    });
  };

  //#region Active Status
  useState(() => {
    if (location?.state?.prevState) {
      setStatusFilter(location?.state?.prevState);
    }
  }, [location?.state?.prevState]);

  return (
    <>
      {loader ? (
        <PageLoading />
      ) : (
        <>
          <Index.Box className="page-content-box">
            <Index.Box className="barge-common-box">
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex res-title-header-flex">
                  <Index.Box className="plus-hidden-show-mobile">
                    <Index.Box className="title-main">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="page-title"
                      >
                        Bills
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="d-flex align-items-center res-set-search">
                    <PagesIndex.Search
                      className="search"
                      onChange={(e) => {
                        requestSearch(e.target.value);
                      }}
                    >
                      <PagesIndex.StyledInputBase
                        placeholder="Search"
                        inputProps={{ "aria-label": "search" }}
                      />
                    </PagesIndex.Search>

                    {["committee", "staff"]?.includes(
                      rollData?.toLowerCase()
                    ) ? (
                      <Index.Box className="lable-input-grp pending-button-sahay">
                        <Index.FormControl
                          sx={{ width: "100%" }}
                          className="main-select"
                        >
                          <Index.Select
                            className="select-drop"
                            displayEmpty
                            value={statusFilter}
                            onChange={(e) => {
                              setStatusFilter(e.target.value);
                            }}
                            input={<OutlinedInput />}
                            MenuProps={MenuProps}
                            inputProps={{
                              "aria-label": "Without label",
                            }}
                          >
                            <Index.MenuItem value={"Pending"}>
                              Pending
                            </Index.MenuItem>
                            <Index.MenuItem value={"Approved"}>
                              Approved
                            </Index.MenuItem>
                            <Index.MenuItem value={"Rejected"}>
                              Rejected
                            </Index.MenuItem>
                          </Index.Select>
                        </Index.FormControl>
                      </Index.Box>
                    ) : null}

                    <>
                      {rollData == "staff" && (
                        <Index.Box className="common-button blue-button res-blue-button desk-hidden-show ">
                          <PagesIndex.Link
                            to={`/${rollData}/add-edit-bill`}
                            className="no-text-decoration"
                          >
                            <Index.Button
                              variant="contained"
                              disableRipple
                              className="apply-sahay-btn"
                            >
                              <Index.ControlPointIcon /> <span>Add Bill</span>
                            </Index.Button>
                          </PagesIndex.Link>
                        </Index.Box>
                      )}
                    </>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="">
                <Index.TableContainer
                  sx={{ maxHeight: 325 }}
                  // component={Index.Paper}
                  className="table-container sahay-application-container scroll-table common-table-container-custom"
                >
                  <Index.Table
                    stickyHeader
                    aria-label="sticky table"
                    className="table-design-main barge-table sahay-appli-table"
                  >
                    <Index.TableHead className="sahay-appli-head">
                      <Index.TableRow className="sahay-appli-tr">
                        <Index.TableCell className="sahay-appli-tr">
                          Vendor Name
                        </Index.TableCell>

                        <Index.TableCell className="sahay-appli-tr">
                          Bill Number
                        </Index.TableCell>
                        <Index.TableCell
                          className="user-sahay-td"
                          style={{ cursor: "pointer" }}
                        >
                          Bill Date
                        </Index.TableCell>
                        <Index.TableCell
                          className="sahay-appli-tr"
                          align="left"
                        >
                          Total Amount
                        </Index.TableCell>
                        <Index.TableCell
                          className="sahay-appli-tr"
                          align="left"
                        >
                          Program Name
                        </Index.TableCell>
                        <Index.TableCell
                          className="sahay-appli-tr"
                          align="left"
                        >
                          Disbursement status
                        </Index.TableCell>

                        <Index.TableCell
                          className="sahay-appli-tr"
                          align="left"
                        >
                          Bill Status
                        </Index.TableCell>

                        <Index.TableCell
                          className="sahay-appli-tr"
                          align="left"
                        >
                          Action
                        </Index.TableCell>
                      </Index.TableRow>
                    </Index.TableHead>
                    <Index.TableBody>
                      {pageData?.length ? (
                        pageData?.map((row, index) => (
                          <Index.TableRow
                            key={row?.Id}
                            className={`sahay-appli-tr ${
                              row?.Id === RowIdHighlightData
                                ? "highlighted-row"
                                : ""
                            }`}
                          >
                           
                            <Index.TableCell
                              align="left"
                              className="sahay-appli-td"
                            >
                              {row?.vendorName || "-"}
                            </Index.TableCell>

                            <Index.TableCell
                              align="left"
                              className="sahay-appli-td"
                            >
                              {row?.billNumber || "-"}
                            </Index.TableCell>
                            <Index.TableCell
                              align="left"
                              className="sahay-appli-td"
                            >
                              {moment(row?.billDate).format("DD/MM/YYYY")}
                            </Index.TableCell>

                            <Index.TableCell
                              align="left"
                              className="sahay-appli-td"
                            >
                              {row?.totalAmount}
                            </Index.TableCell>
                            <Index.TableCell
                              align="left"
                              className="sahay-appli-td"
                            >
                              {row?.ProgramData?.[0]?.programName}
                            </Index.TableCell>
                            <Index.TableCell
                              align="left"
                              className="sahay-appli-td"
                            >
                              {row?.billDisbursedStatus == null
                                ? "Pending"
                                : row?.billDisbursedStatus}
                            </Index.TableCell>

                            <Index.TableCell
                              align="left"
                              className="sahay-appli-td"
                            >
                              <Index.Box
                                className={`status-details ${
                                  row?.billStatus == "Pending"
                                    ? "pending - status"
                                    : row?.billStatus == "Approved"
                                    ? "approved-status"
                                    : row?.billStatus == "Rejected"
                                    ? "reject-status"
                                    : ""
                                }`}
                              >
                                {row?.billStatus}
                              </Index.Box>
                            </Index.TableCell>

                            <Index.TableCell
                              sx={{ display: "flex" }}
                              className="sahay-appli-td"
                            >
                              <Index.Box className="flex-content-sahay">
                                {row?.billStatus?.toLowerCase() == "pending" &&
                                rollData == "staff" ? (
                                  <>
                                    <LightTooltip title="Edit">
                                      <Index.IconButton
                                        onClick={() => handleEdit(row)}
                                      >
                                        <Index.EditIcon className="muiico-edit" />
                                      </Index.IconButton>
                                    </LightTooltip>
                                  </>
                                ) : null}
                                <LightTooltip title="View">
                                  <Index.IconButton
                                    onClick={() => handleView(row)}
                                  >
                                    <Index.Visibility className="muiico-view" />
                                  </Index.IconButton>
                                </LightTooltip>

                                {row?.billStatus?.toLowerCase() == "pending" &&
                                rollData == "staff" ? (
                                  <>
                                    <LightTooltip title="Delete">
                                      <Index.IconButton
                                        onClick={() => handleDeleteOpen(row)}
                                      >
                                        <Index.DeleteIcon className="muiico-delete" />
                                      </Index.IconButton>
                                    </LightTooltip>
                                  </>
                                ) : null}

                                {rollData == "committee" &&
                                  row?.billDisbursedStatus == null && (
                                    <LightTooltip title="Approve">
                                      <Index.Box className="approved-btn">
                                        <Index.Button
                                          className="approve-btn-list"
                                          onClick={() =>
                                            handleApproveReject(
                                              row?.Id,
                                              "Approved"
                                            )
                                          }
                                        >
                                          <img
                                            src={PagesIndex.Svg.approve}
                                            className="approve"
                                          ></img>
                                        </Index.Button>
                                      </Index.Box>
                                    </LightTooltip>
                                  )}

                                {rollData == "committee" &&
                                  row?.billDisbursedStatus == null && (
                                    <LightTooltip title="Reject">
                                      <Index.Box className="reject-btn">
                                        <Index.Button
                                          className="reject-btn-list"
                                          onClick={() =>
                                            handleApproveReject(
                                              row?.Id,
                                              "Rejected"
                                            )
                                          }
                                        >
                                          <img
                                            src={PagesIndex.Svg.incorrect}
                                            className="reject"
                                          ></img>
                                        </Index.Button>
                                      </Index.Box>
                                    </LightTooltip>
                                  )}
                              </Index.Box>
                            </Index.TableCell>
                          </Index.TableRow>
                        ))
                      ) : (
                        <PagesIndex.NoRecordFound colSpan={12} />
                      )}
                    </Index.TableBody>
                  </Index.Table>
                </Index.TableContainer>
              </Index.Box>

              <Index.Box className="pagination-design flex-end">
                <RowPerPageDropdown
                  rowPerPage={rowPerPage}
                  setRowPerPage={setRowPerPage}
                />
                <Index.Stack spacing={2}>
                  <PagesIndex.Pagination
                    fetchData={searchedData}
                    setPageData={setPageData}
                    pageData={pageData}
                    rowPerPage={rowPerPage}
                  />
                </Index.Stack>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <PagesIndex.DeleteModal
            deleteText={deleteText}
            deleteOpen={deleteOpen}
            handleDeleteRecord={handleDeleteRecord}
            handleDeleteClose={handleDeleteClose}
          />
        </>
      )}
    </>
  );
};

export default BillList;
