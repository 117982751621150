import React, { useEffect, useState } from "react";
import Index from "../../../../Index";
import "../NewMember.css";
import { getAllFamilyMemberByIdView } from "../../../../../redux/features/AddService";
import moment from "moment";
import PagesIndex from "../../../../PagesIndex";
import PageLoading from "../../../../../components/common/pageLoading/PageLoading";
import { useSelector } from "react-redux";

const ViewNewMember = () => {
  const navigate = PagesIndex.useNavigate();
  const dispatch = PagesIndex.useDispatch();
  const { state } = PagesIndex.useLocation();
  const family_id = state?.id;




  //all state
  const [newMemberList, setNewMemberList] = useState([]);
  const [dataHeadoftheFamily, setheadOftheFamily] = useState();
  const [relationData, setRelationData]=useState("")
  const [headOfFamilyMember, setHeadOfFamilyMember] = useState();
  const [loader, setLoader] = useState(true);


  const { rollPermissionData } = useSelector((state) => state.admin);
  const rollData = JSON.parse(localStorage?.getItem("RollName"))?.toLowerCase();
  
  let values = {
    familyId: dataHeadoftheFamily ? family_id : "",
    firstName: dataHeadoftheFamily ? dataHeadoftheFamily?.firstName : "",
    middleName: dataHeadoftheFamily ? dataHeadoftheFamily?.middleName : "",
    lastName: dataHeadoftheFamily ? dataHeadoftheFamily?.lastName : "",
    MohollaId: dataHeadoftheFamily ? dataHeadoftheFamily?.mohollaName : "",
    dob: dataHeadoftheFamily ? dataHeadoftheFamily?.dob : "",
    age: dataHeadoftheFamily ? dataHeadoftheFamily?.mem_age : "",
    mobileNumber: dataHeadoftheFamily ? dataHeadoftheFamily?.mem_mobile : "",
    email: dataHeadoftheFamily ? dataHeadoftheFamily?.email : "",
    res_add1: dataHeadoftheFamily ? dataHeadoftheFamily?.res_add1 : "",
    res_add2: dataHeadoftheFamily ? dataHeadoftheFamily?.res_add2 : "",
    gender: dataHeadoftheFamily
      ? dataHeadoftheFamily?.mem_gender == 0
        ? "female"
        : "male"
      : "",
    bloodGroupId: dataHeadoftheFamily
      ? dataHeadoftheFamily?.bloodgroup_name
      : "",
    maritialStatusId: dataHeadoftheFamily
      ? dataHeadoftheFamily?.maritalstatus_name
      : "",
    medicalPolicy: dataHeadoftheFamily
      ? dataHeadoftheFamily?.mem_medical_policy
      : "",
    gnyatiId: dataHeadoftheFamily ? dataHeadoftheFamily?.gnyatiName : "",
    area: dataHeadoftheFamily ? dataHeadoftheFamily?.area : "",
    cityId: dataHeadoftheFamily ? dataHeadoftheFamily?.City : "",
    pinCode: dataHeadoftheFamily ? dataHeadoftheFamily?.pinCode : "",
    country: dataHeadoftheFamily ? dataHeadoftheFamily?.countryName : "",
    grandFather: dataHeadoftheFamily ? dataHeadoftheFamily?.grandFather : "",
    isBusiness: dataHeadoftheFamily ? dataHeadoftheFamily?.mem_isBusiness : "",
    stateId: dataHeadoftheFamily ? dataHeadoftheFamily?.State : "",
    oldFamilyId : dataHeadoftheFamily ? dataHeadoftheFamily?.oldFamilyId : ""

  };
  

  useEffect(() => {
    dispatch(getAllFamilyMemberByIdView({ familyId: family_id })).then(
      (data) => {
        if (data?.payload) {
          setLoader(false);
          setNewMemberList(data?.payload?.FamilyMembers);
          setheadOftheFamily(data?.payload?.HeadOfFamily);
          setRelationData(data?.payload?.PatanMemberData?.[0])
        }
      }
    );
  }, []);

  

  const rollPermissionNameData =
  rollPermissionData?.findData?.parentRoleName?.toLowerCase();

  if (rollPermissionData?.permissionArray?.includes("New Member Master_view") || rollPermissionNameData === "admin") {
  } else {
    navigate(`/${rollData}/sahay-application`);
  }
  return (
    <>
      {loader ? (
        <PageLoading />
      ) : (
        <>
          <Index.Box className="page-content-box">
            <Index.Box className="barge-common-box">
              <Index.Box>
                <Index.Box className="title-header-flex-button res-set-search">
                  <Index.Box className="common-button blue-button flex-start save-btn">
                    <Index.Button
                      variant="contained"
                      onClick={() => {
                        navigate("/admin/new-member/list",{
                          state:{familyActiveRowid:family_id}
                        });
                      }}
                    >
                      Back
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex">
                  <Index.Box className="title-main mb-10">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="page-title"
                    >
                      View Head Of The Family Member
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="">
                <Index.Grid container spacing={3}>
                  <Index.Grid item xs={12} sm={6} md={3}>
                    <Index.Typography
                      variant="label"
                      component="label"
                      className="input-label"
                    >
                      Family ID (કુટુંબ ID)
                    </Index.Typography>
                    <Index.Box className="input-design-div with-border">
                      <Index.TextField
                        hiddenLabel
                        id="filled-hidden-label-normal"
                        placeholder="Family ID"
                        variant="filled"
                        className="admin-input-design input-placeholder"
                        autoComplete="off"
                        name="familyId"
                        type="number"
                        value={values?.familyId}
                        disabled
                      />
                    </Index.Box>
                  </Index.Grid>
                  <Index.Grid item xs={12} sm={6} md={3}>
                    <Index.Typography
                      variant="label"
                      component="label"
                      className="input-label"
                    >
                      Relation (સંબંધ)
                    </Index.Typography>
                    <Index.Box className="input-design-div with-border">
                      <Index.TextField
                        hiddenLabel
                        id="filled-hidden-label-normal"
                        placeholder="Email Id  "
                        variant="filled"
                        className="admin-input-design input-placeholder"
                        autoComplete="off"
                     
                        disabled
                        value={`${relationData?.mem_family_id || ""} ${relationData?.mem_fname || ""} ${relationData?.mem_mname || ""} ${relationData?.mem_lname || ""}`}
                      />

                      
                    </Index.Box>
                  </Index.Grid>

                  <Index.Grid item xs={12} sm={6} md={3}>
                    <Index.Typography
                      variant="label"
                      component="label"
                      className="input-label"
                    >
                      Old Family Id (જૂની ફેમિલી આઈડી)
                    </Index.Typography>
                    <Index.Box className="input-design-div with-border">
                      <Index.TextField
                        hiddenLabel
                        id="filled-hidden-label-normal"
                    
                        variant="filled"
                        className="admin-input-design input-placeholder"
                        autoComplete="off"
                     
                        disabled
                       value={values?.oldFamilyId}
                      />

                      
                    </Index.Box>
                  </Index.Grid>
                  <Index.Grid item xs={12} sm={6} md={3}>
                    <Index.Typography
                      variant="label"
                      component="label"
                      className="input-label"
                    >
                      Email Id (ઈમેલ આઈડી)
                    </Index.Typography>
                    <Index.Box className="input-design-div with-border">
                      <Index.TextField
                        hiddenLabel
                        id="filled-hidden-label-normal"
                        placeholder="Email Id  "
                        variant="filled"
                        className="admin-input-design input-placeholder"
                        autoComplete="off"
                        name="email"
                        disabled
                        value={values?.email}
                      />
                    </Index.Box>
                  </Index.Grid>
                  <Index.Grid item xs={12} sm={6} md={3}>
                    <Index.Typography
                      variant="label"
                      component="label"
                      className="input-label"
                    >
                      Mobile No (મોબાઈલ નં)
                    </Index.Typography>
                    <Index.Box className="input-design-div with-border">
                      <Index.TextField
                        type="number"
                        hiddenLabel
                        id="filled-hidden-label-normal"
                        placeholder="Mobile No"
                        variant="filled"
                        className="admin-input-design input-placeholder"
                        autoComplete="off"
                        name="mobileNumber"
                        disabled
                        value={values?.mobileNumber}
                      />
                    </Index.Box>
                  </Index.Grid>

                  <Index.Grid item xs={12} sm={6} md={3}>
                    <Index.Typography
                      variant="label"
                      component="label"
                      className="input-label"
                    >
                      First Name (પ્રથમ નામ)
                    </Index.Typography>
                    <Index.Box className="input-design-div with-border">
                      <Index.TextField
                        hiddenLabel
                        id="filled-hidden-label-normal"
                        placeholder="First Name"
                        variant="filled"
                        className="admin-input-design input-placeholder"
                        autoComplete="off"
                        name="firstName"
                        disabled
                        value={values?.firstName}
                      />
                    </Index.Box>
                  </Index.Grid>
                  <Index.Grid item xs={12} sm={6} md={3}>
                    <Index.Typography
                      variant="label"
                      component="label"
                      className="input-label"
                    >
                      Middle Name (મધ્ય નામ)
                    </Index.Typography>
                    <Index.Box className="input-design-div with-border">
                      <Index.TextField
                        hiddenLabel
                        id="filled-hidden-label-normal"
                        placeholder="Middle Name  "
                        variant="filled"
                        className="admin-input-design input-placeholder"
                        autoComplete="off"
                        name="middleName"
                        disabled
                        value={values?.middleName}
                      />
                    </Index.Box>
                  </Index.Grid>
                  <Index.Grid item xs={12} sm={6} md={3}>
                    <Index.Typography
                      variant="label"
                      component="label"
                      className="input-label"
                    >
                      Last Name (છેલ્લું નામ)
                    </Index.Typography>
                    <Index.Box className="input-design-div with-border">
                      <Index.TextField
                        hiddenLabel
                        id="filled-hidden-label-normal"
                        placeholder="Last Name  "
                        variant="filled"
                        className="admin-input-design input-placeholder"
                        autoComplete="off"
                        name="lastName"
                        disabled
                        value={values?.lastName}
                      />
                    </Index.Box>
                  </Index.Grid>

                  <Index.Grid item xs={12} sm={6} md={3}>
                    <Index.Typography
                      variant="label"
                      component="label"
                      className="input-label"
                    >
                      Grand Father (દાદા)
                    </Index.Typography>
                    <Index.Box className="input-design-div with-border">
                      <Index.TextField
                        hiddenLabel
                        id="filled-hidden-label-normal"
                        placeholder="GrandFather Name"
                        variant="filled"
                        className="admin-input-design input-placeholder"
                        autoComplete="off"
                        name="grandFather"
                        disabled
                        value={values?.grandFather}
                      />
                    </Index.Box>
                  </Index.Grid>
                  <Index.Grid item xs={12} sm={6} md={3}>
                    <Index.Typography
                      variant="label"
                      component="label"
                      className="input-label"
                    >
                      Date Of Birth (જન્મ તારીખ)
                    </Index.Typography>
                    <Index.Box className="input-design-div with-border">
                      <Index.TextField
                        hiddenLabel
                        id="filled-hidden-label-normal"
                        placeholder="Date Of Birth
"
                        variant="filled"
                        className="admin-input-design input-placeholder"
                        autoComplete="off"
                        name="DOB"
                        disabled
                        value={moment(values?.dob).format("YYYY-MM-DD")}
                      />
                    </Index.Box>
                  </Index.Grid>

                  <Index.Grid item xs={12} sm={6} md={3}>
                    <Index.Typography
                      variant="label"
                      component="label"
                      className="input-label"
                    >
                      Gender (જાતિ)
                    </Index.Typography>
                    <Index.Box className="input-design-div with-border">
                      <Index.TextField
                        hiddenLabel
                        id="filled-hidden-label-normal"
                        placeholder="GrandFather Name"
                        variant="filled"
                        className="admin-input-design input-placeholder"
                        autoComplete="off"
                        name="gender"
                        disabled
                        value={values?.gender}
                      />
                    </Index.Box>
                  </Index.Grid>
                  <Index.Grid item xs={12} sm={6} md={3}>
                    <Index.Typography
                      variant="label"
                      component="label"
                      className="input-label"
                    >
                      Age (ઉંમર)
                    </Index.Typography>
                    <Index.Box className="input-design-div with-border">
                      <Index.TextField
                        hiddenLabel
                        id="filled-hidden-label-normal"
                        placeholder="Age"
                        variant="filled"
                        type="number"
                        className="admin-input-design input-placeholder"
                        autoComplete="off"
                        name="age"
                        value={values?.age}
                        disabled
                      />
                    </Index.Box>
                  </Index.Grid>

                  <Index.Grid item xs={12} sm={6} md={3}>
                    <Index.Typography
                      variant="label"
                      component="label"
                      className="input-label"
                    >
                      Blood Group (બ્લડ ગ્રુપ)
                    </Index.Typography>
                    <Index.Box className="input-design-div with-border">
                      <Index.TextField
                        hiddenLabel
                        id="filled-hidden-label-normal"
                        placeholder="Blood Group"
                        variant="filled"
                        className="admin-input-design input-placeholder"
                        autoComplete="off"
                        name="bloodGroupId"
                        disabled
                        value={values?.bloodGroupId}
                      />
                    </Index.Box>
                  </Index.Grid>

                  <Index.Grid item xs={12} sm={6} md={3}>
                    <Index.Typography
                      variant="label"
                      component="label"
                      className="input-label"
                    >
                      Marital Status (વૈવાહિક સ્થિતિ)
                    </Index.Typography>
                    <Index.Box className="input-design-div with-border">
                      <Index.TextField
                        hiddenLabel
                        id="filled-hidden-label-normal"
                        placeholder="Marital Status"
                        variant="filled"
                        className="admin-input-design input-placeholder"
                        autoComplete="off"
                        name="maritialStatusId"
                        disabled
                        value={values?.maritialStatusId}
                      />
                    </Index.Box>
                  </Index.Grid>

                  <Index.Grid item xs={12} sm={6} md={3}>
                    <Index.Typography
                      variant="label"
                      component="label"
                      className="input-label"
                    >
                      Is Business (બિઝનેસ છે)
                    </Index.Typography>
                    <Index.Box className="input-design-div with-border">
                      <Index.TextField
                        hiddenLabel
                        id="filled-hidden-label-normal"
                        placeholder="Is Business
"
                        variant="filled"
                        className="admin-input-design input-placeholder"
                        autoComplete="off"
                        name="isBusiness"
                        disabled
                        value={values?.isBusiness}
                      />
                    </Index.Box>
                  </Index.Grid>

                  <Index.Grid item xs={12} sm={6} md={3}>
                    <Index.Typography
                      variant="label"
                      component="label"
                      className="input-label"
                    >
                      Medical Policy (તબીબી નીતિ)
                    </Index.Typography>
                    <Index.Box className="input-design-div with-border">
                      <Index.TextField
                        hiddenLabel
                        id="filled-hidden-label-normal"
                        placeholder="Medical Policy"
                        variant="filled"
                        className="admin-input-design input-placeholder"
                        autoComplete="off"
                        name="medicalPolicy"
                        disabled
                        value={values?.medicalPolicy}
                      />
                    </Index.Box>
                  </Index.Grid>

                  <Index.Grid item xs={12} sm={6} md={3}>
                    <Index.Typography
                      variant="label"
                      component="label"
                      className="input-label"
                    >
                      Gyanti (જ્ઞાની)
                    </Index.Typography>
                    <Index.Box className="input-design-div with-border">
                      <Index.TextField
                        hiddenLabel
                        id="filled-hidden-label-normal"
                        placeholder="Gyanti"
                        variant="filled"
                        className="admin-input-design input-placeholder"
                        autoComplete="off"
                        name="gnyatiId"
                        disabled
                        value={values?.gnyatiId}
                      />
                    </Index.Box>
                  </Index.Grid>

                  <Index.Grid item xs={12} sm={6} md={3}>
                    <Index.Typography
                      variant="label"
                      component="label"
                      className="input-label"
                    >
                      Moholla Name (વિસ્તારનું નામ)
                    </Index.Typography>
                    <Index.Box className="input-design-div with-border">
                      <Index.TextField
                        hiddenLabel
                        id="filled-hidden-label-normal"
                        placeholder="Moholla Name"
                        variant="filled"
                        className="admin-input-design input-placeholder"
                        autoComplete="off"
                        name="MohollaId"
                        disabled
                        value={values?.MohollaId}
                      />
                    </Index.Box>
                  </Index.Grid>

                  <Index.Grid item xs={12} sm={6} md={3}>
                    <Index.Typography
                      variant="label"
                      component="label"
                      className="input-label"
                    >
                      Res. address1 (રેસ. સરનામું1)
                    </Index.Typography>
                    <Index.Box className="input-design-div with-border">
                      <Index.TextField
                        hiddenLabel
                        type="text"
                        id="filled-hidden-label-normal"
                        placeholder="Res. address1  "
                        variant="filled"
                        InputProps={{
                          inputProps: { min: 0, max: 10 },
                        }}
                        className="admin-input-design input-placeholder"
                        autoComplete="off"
                        name="res_add1"
                        disabled
                        value={values?.res_add1}
                      />
                    </Index.Box>
                  </Index.Grid>

                  <Index.Grid item xs={12} sm={6} md={3}>
                    <Index.Typography
                      variant="label"
                      component="label"
                      className="input-label"
                    >
                      Res. address2 (રેસ. સરનામું2)
                    </Index.Typography>
                    <Index.Box className="input-design-div with-border">
                      <Index.TextField
                        hiddenLabel
                        type="text"
                        id="filled-hidden-label-normal"
                        placeholder="Res. address2"
                        variant="filled"
                        InputProps={{
                          inputProps: { min: 0, max: 10 },
                        }}
                        className="admin-input-design input-placeholder"
                        autoComplete="off"
                        name="res_add2"
                        value={values?.res_add2}
                        disabled
                      />
                    </Index.Box>
                  </Index.Grid>

                  <Index.Grid item xs={12} sm={6} md={3}>
                    <Index.Typography
                      variant="label"
                      component="label"
                      className="input-label"
                    >
                      Area (વિસ્તાર)
                    </Index.Typography>
                    <Index.Box className="input-design-div with-border">
                      <Index.TextField
                        hiddenLabel
                        id="filled-hidden-label-normal"
                        placeholder="Area "
                        variant="filled"
                        className="admin-input-design input-placeholder"
                        autoComplete="off"
                        name="area"
                        value={values?.area}
                        disabled
                      />
                    </Index.Box>
                  </Index.Grid>

                  <Index.Grid item xs={12} sm={6} md={3}>
                    <Index.Typography
                      variant="label"
                      component="label"
                      className="input-label"
                    >
                      Country (દેશ)
                    </Index.Typography>
                    <Index.Box className="input-design-div with-border">
                      <Index.TextField
                        hiddenLabel
                        id="filled-hidden-label-normal"
                        placeholder="GrandFather Name"
                        variant="filled"
                        className="admin-input-design input-placeholder"
                        autoComplete="off"
                        name="country"
                        disabled
                        value={values?.country}
                      />
                    </Index.Box>
                  </Index.Grid>

                  <Index.Grid item xs={12} sm={6} md={3}>
                    <Index.Typography
                      variant="label"
                      component="label"
                      className="input-label"
                    >
                      State (રાજ્ય)
                    </Index.Typography>
                    <Index.Box className="input-design-div with-border">
                      <Index.TextField
                        hiddenLabel
                        id="filled-hidden-label-normal"
                        placeholder="GrandFather Name"
                        variant="filled"
                        className="admin-input-design input-placeholder"
                        autoComplete="off"
                        name="stateId"
                        disabled
                        value={values?.stateId}
                      />
                    </Index.Box>
                  </Index.Grid>

                  <Index.Grid item xs={12} sm={6} md={3}>
                    <Index.Typography
                      variant="label"
                      component="label"
                      className="input-label"
                    >
                      City (શહેર)
                    </Index.Typography>
                    <Index.Box className="input-design-div with-border">
                      <Index.TextField
                        hiddenLabel
                        id="filled-hidden-label-normal"
                        placeholder="City Name"
                        variant="filled"
                        className="admin-input-design input-placeholder"
                        autoComplete="off"
                        name="cityId"
                        disabled
                        value={values?.cityId}
                      />
                    </Index.Box>
                  </Index.Grid>

                  <Index.Grid item xs={12} sm={6} md={3}>
                    <Index.Typography
                      variant="label"
                      component="label"
                      className="input-label"
                    >
                      Pin code (પિન કોડ)
                    </Index.Typography>
                    <Index.Box className="input-design-div with-border">
                      <Index.TextField
                        hiddenLabel
                        id="filled-hidden-label-normal"
                        placeholder="Pin code"
                        variant="filled"
                        className="admin-input-design input-placeholder"
                        autoComplete="off"
                        name="pinCode"
                        disabled
                        value={values?.pinCode}
                      />
                    </Index.Box>
                  </Index.Grid>
                </Index.Grid>

                <Index.Box className="new-member-top-spacing">
                  <Index.Box className="title-header-flex">
                    <Index.Box className="title-main mb-10">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="page-title"
                      >
                        View Family Member
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>

                  {newMemberList &&
                    newMemberList.map((data, index) => {
                      return (
                        <Index.Grid
                          container
                          spacing={3}
                          className="new-member-top-spacing-family"
                        >
                          <Index.Grid item xs={12} sm={6} md={3}>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label"
                            >
                              Family id (કૌટુંબિક આઈડી)
                            </Index.Typography>
                            <Index.Box className="input-design-div with-border">
                              <Index.TextField
                                hiddenLabel
                                id="filled-hidden-label-normal"
                                placeholder="Family id"
                                variant="filled"
                                className="admin-input-design input-placeholder"
                                autoComplete="off"
                                disabled
                                value={family_id}
                              />
                            </Index.Box>
                          </Index.Grid>
                          <Index.Grid item xs={12} sm={6} md={3}>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label"
                            >
                              First Name (પ્રથમ નામ)
                            </Index.Typography>
                            <Index.Box className="input-design-div with-border">
                              <Index.TextField
                                hiddenLabel
                                id="filled-hidden-label-normal"
                                placeholder="First Name"
                                variant="filled"
                                className="admin-input-design input-placeholder"
                                autoComplete="off"
                                disabled
                                value={data.firstName}
                              />
                            </Index.Box>
                          </Index.Grid>

                          <Index.Grid item xs={12} sm={6} md={3}>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label"
                            >
                              Middle Name (મધ્ય નામ)
                            </Index.Typography>
                            <Index.Box className="input-design-div with-border">
                              <Index.TextField
                                hiddenLabel
                                id="filled-hidden-label-normal"
                                placeholder="Middle Name  "
                                variant="filled"
                                className="admin-input-design input-placeholder"
                                autoComplete="off"
                                disabled
                                value={data.middleName}
                              />
                            </Index.Box>
                          </Index.Grid>
                          <Index.Grid item xs={12} sm={6} md={3}>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label"
                            >
                              Last Name (છેલ્લું નામ)
                            </Index.Typography>
                            <Index.Box className="input-design-div with-border">
                              <Index.TextField
                                hiddenLabel
                                id="filled-hidden-label-normal"
                                placeholder="Last Name  "
                                variant="filled"
                                className="admin-input-design input-placeholder"
                                autoComplete="off"
                                disabled
                                value={data.lastName}
                              />
                            </Index.Box>
                          </Index.Grid>
                          <Index.Grid item xs={12} sm={6} md={3}>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label"
                            >
                              Age (ઉંમર)
                            </Index.Typography>
                            <Index.Box className="input-design-div with-border">
                              <Index.TextField
                                hiddenLabel
                                id="filled-hidden-label-normal"
                                placeholder="Age"
                                variant="filled"
                                type="number"
                                className="admin-input-design input-placeholder"
                                autoComplete="off"
                                disabled
                                value={data.mem_age}
                              />
                            </Index.Box>
                          </Index.Grid>
                          <Index.Grid item xs={12} sm={6} md={3}>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label"
                            >
                              Date Of Birth (જન્મ તારીખ)
                            </Index.Typography>
                            <Index.Box className="input-design-div with-border">
                              <Index.TextField
                                hiddenLabel
                                id="filled-hidden-label-normal"
                                placeholder="DOB"
                                variant="filled"
                                type="text"
                                className="admin-input-design input-placeholder"
                                autoComplete="off"
                                disabled
                                value={moment(data.mem_dob).format(
                                  "YYYY-MM-DD"
                                )}
                              />
                            </Index.Box>
                          </Index.Grid>

                          <Index.Grid item xs={12} sm={6} md={3}>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label"
                            >
                              Is Business (બિઝનેસ છે)
                            </Index.Typography>
                            <Index.Box className="input-design-div with-border">
                              <Index.TextField
                                hiddenLabel
                                id="filled-hidden-label-normal"
                                placeholder="Age"
                                variant="filled"
                                type="text"
                                className="admin-input-design input-placeholder"
                                autoComplete="off"
                                disabled
                                value={data.mem_isBusiness}
                              />
                            </Index.Box>
                          </Index.Grid>

                          <Index.Grid item xs={12} sm={6} md={3}>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label"
                            >
                              Relation (સંબંધ)
                            </Index.Typography>
                            <Index.Box className="input-design-div with-border">
                              <Index.TextField
                                hiddenLabel
                                id="filled-hidden-label-normal"
                                placeholder="Relation"
                                variant="filled"
                                type="text"
                                className="admin-input-design input-placeholder"
                                autoComplete="off"
                                disabled
                                value={data.relation}
                              />
                            </Index.Box>
                          </Index.Grid>

                          <Index.Grid item xs={12} sm={6} md={3}>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label"
                            >
                              Maritial Status (વૈવાહિક સ્થિતિ)
                            </Index.Typography>
                            <Index.Box className="input-design-div with-border">
                              <Index.TextField
                                hiddenLabel
                                id="filled-hidden-label-normal"
                                placeholder="Maritial Status"
                                variant="filled"
                                type="text"
                                className="admin-input-design input-placeholder"
                                autoComplete="off"
                                disabled
                                value={data.maritialStatus}
                              />
                            </Index.Box>
                          </Index.Grid>
                          <Index.Grid item xs={12} sm={6} md={3}>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label"
                            >
                              Mobile No (મોબાઈલ નં)
                            </Index.Typography>
                            <Index.Box className="input-design-div with-border">
                              <Index.TextField
                                type="number"
                                hiddenLabel
                                id="filled-hidden-label-normal"
                                placeholder="Mobile No"
                                variant="filled"
                                className="admin-input-design input-placeholder"
                                autoComplete="off"
                                disabled
                                value={data.mem_mobile}
                              />
                            </Index.Box>
                          </Index.Grid>
                        </Index.Grid>
                      );
                    })}
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </>
      )}
    </>
  );
};

export default ViewNewMember;
