import React, { useState } from "react";
import "./Login.css";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";

import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import OTPInput from "react-otp-input";

import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  userLoginDetails,
  userLoginProfileDetails,
} from "../../../../redux/features/adminslice/AdminSlice";

function OTP() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const data = PagesIndex.useLocation();

  // Initital values
  let initialValues = {
    number: +data.state.number,
    vastipatrak: true,
    otp: "",
    isSubmit: false,
  };

  const ResendOtp = async () => {
    try {
      const res = await DataService.post(Api.USER_RESEND_OTP, {
        number: data.state.number,
      });
      if (res?.data?.status == 200) {
        toast.success(res.data.message);
      }
    } catch (error) {
      toast.error(error.res.data.message || error.message);
    }
  };

  const handleResendOtp = () => {
    ResendOtp();
  };

  // Login
  const handleFormSubmit = async (values, { setFieldValue }) => {
    let data = {
      number: values?.number,
      vastipatrak: values?.vastipatrak,
      otp: values?.otp,
    };
    setFieldValue("isSubmit", true);
    try {
      let response = await DataService.post(Api.VERIFY_OTP, data);

      if (response?.data?.status == 200) {
        DataService.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${response?.data?.token}`;

        localStorage.setItem(
          "id",
          JSON.stringify(response?.data?.data?.data?.mem_id)
        );

        localStorage.setItem(
          "mohollo_name",
          JSON.stringify(response?.data?.data?.data?.mohollo_name)
        );

        localStorage.setItem(
          "familyId",
          JSON.stringify(response?.data?.data?.data?.mem_family_id)
        );
        localStorage.setItem(
          "token",
          JSON.stringify(response?.data?.data?.token)
        );

        dispatch(userLoginDetails(response?.data?.data));
        dispatch(userLoginProfileDetails(response?.data?.data?.data));

        toast.success(response.data.message);
        navigate("/user/user-listing");

        setTimeout(() => {
          setFieldValue("isSubmit", false);
        }, 1000);
      }
    } catch (error) {
      toast.error(error.response.data.message || error.message);
      setTimeout(() => {
        setFieldValue("isSubmit", false);
      }, 1000);
    }
  };

  return (
    <div>
      <Index.Box>
        <Index.Box className="main-login">
          <Index.Box>
            <Index.Box className=" white-login-main">
              <Index.Box className="white-login-box">
                <Index.Box className="logo-set2 text-center">
                  <img src={PagesIndex.Png.sahay_log} alt="logo" className="" />
                </Index.Box>
                <Index.Box className="main-box">
                  <Index.Box>
                    <Index.Box className="box-text bluebox-text text-center">
                      <Index.Typography
                        variant="body1"
                        component="p"
                        className=""
                      >
                        Verify Otp!
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="box-login-text bluebox-text text-center">
                      <Index.Typography
                        variant="body1"
                        component="p"
                        className=""
                      >
                        Please Enter Otp.
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="input-design-div admin-design-div login-input-design-div">
                      <PagesIndex.Formik
                        enableReinitialize
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={PagesIndex.validationSchemaUserOtp}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          setFieldValue,
                          handleSubmit,
                        }) => (
                          <Index.Stack
                            component="form"
                            spacing={2}
                            noValidate
                            autoComplete="off"
                            onSubmit={handleSubmit}
                          >
                            <OTPInput
                              name="otp"
                              numInputs={4}
                              inputStyle="otp-verification-input"
                              containerStyle="otp-verification-input-wrap"
                              inputType="number"
                              value={values.otp}
                              onChange={(file) => setFieldValue("otp", file)}
                              // error={Boolean(errors.otp)}
                              renderInput={(props) => <input {...props} />}
                            />
                            {errors?.otp && touched.otp && (
                              <p className="error-text">{errors?.otp}</p>
                            )}
                            <Index.Box className="orange-btn login-btn login-btn-main">
                              <Index.Button
                                type="submit"
                                data-testid="button"
                                variant="contained"
                                disabled={values.isSubmit}
                              >
                                Login
                              </Index.Button>
                            </Index.Box>
                          </Index.Stack>
                        )}
                      </PagesIndex.Formik>
                    </Index.Box>
                    <Index.Box className="resend-otp-main">
                      <Index.Box className="resend-otp" mt={1}>
                        <Index.Button
                          className="resend-otp-btn"
                          onClick={() => {
                            handleResendOtp();
                          }}
                        >
                          Resend Otp
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}

export default OTP;
