import React, { useEffect, useState } from "react";

import Index from "../../../../container/Index";

import OutlinedInput from "@mui/material/OutlinedInput";
import PagesIndex from "../../../PagesIndex";
import { Autocomplete, Tooltip, tooltipClasses } from "@mui/material";

import moment from "moment";
import dayjs from "dayjs";

import {
  addUpdateBill,
  getAllFamilyWithMohollaNameAndAreaList,
  getAllProgramList,
  getSingleDisbursedBill,
} from "../../../../redux/features/AddService";
import { billSchema } from "../../../../validation/Validation";

import { imagePath } from "../../../../config/DataService";

import PageLoading from "../../../../components/common/pageLoading/PageLoading";
import { useSelector } from "react-redux";
import { MobileDatePicker } from "@mui/x-date-pickers";
import styled from "styled-components";
import { FieldArray } from "formik";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: "#eaac35",
    color: "#ffffff",
    fontSize: "11px",
    fontFamily: "Poppins-Regular",
    padding: "5px 9px",
    lineHeight: "19px",
    borderRadius: "10px",
  },
}));

const ariaLabel = { "aria-label": "description" };

const AddBill = () => {
  const { getUserLoginDetails } = PagesIndex.useSelector(
    (state) => state.admin
  );

  const dispatch = PagesIndex.useDispatch();
  const navigate = PagesIndex.useNavigate();
  const location = PagesIndex.useLocation();
  const stateNavigate = location?.state?.editBill;

  const editBillData = location?.state?.editBill;
  const rollData = JSON.parse(localStorage?.getItem("RollName"))?.toLowerCase();

  //all state

  const [programList, setProgramList] = useState([]);

  const [pageLoader, setPageLoader] = useState(false);

  const [loader, setLoader] = useState(false);

  const initialValues = {
    vendorName: editBillData?.vendorName || "",
    billNumber: editBillData?.billNumber || "",
    totalAmount: editBillData?.totalAmount || "",
    programId: editBillData?.programId || "",
    billDate: editBillData?.billDate || "",
    memberAmount:
      editBillData?.memberAmountMain?.map((row) => ({
        ...row,
        isChecked: row?.amount > 0 ? true : false,
        amount: +row?.amount,
      })) || [],
    BillDocuments: editBillData?.BillDocuments || [],
    isSubmit: false,
  };

  const handleBack = () => {
    navigate(`/${rollData}/bill-list`, {
      state: {
        rowID: location?.state?.editBill?.Id,
        prevState: location?.state?.editBill?.lastStatus,
      },
    });
  };

  const handleFormSubmit = (values, { setFieldValue }) => {
    setFieldValue("isSubmit", true);
    const data = new FormData();

    if (editBillData?.Id) {
      data.append("id", editBillData?.Id);
    }

    data.append("vendorName", values?.vendorName);
    data.append("billNumber", values?.billNumber);
    data.append("totalAmount", values?.totalAmount);
    data.append("programId", values?.programId);
    data.append("billDate", values?.billDate);
    
   
    values?.memberAmount?.forEach((amount, index) => {
      data.append(`memberAmount[${index}][memberId]`, amount?.mem_id ? amount?.mem_id : amount?.memberId);
      data.append(`memberAmount[${index}][amount]`, amount?.amount);
    });



    values?.BillDocuments?.length > 0 &&
      values?.BillDocuments?.forEach((ele) => {
        if (ele instanceof Blob) {
          data.append("BillDocuments", ele);
        }
      });

    values?.BillDocuments?.length > 0 &&
      values?.BillDocuments?.forEach((ele) => {
        if (ele instanceof Blob) {
        } else {
          data.append("oldDocuments", ele?.Documents);
        }
      });

    dispatch(addUpdateBill(data)).then((res) => {
      if (res?.payload?.status == 201 || 200) {
        navigate(`/${rollData}/bill-list`);
      } else {
        setTimeout(() => {
          setFieldValue("isSubmit", false);
        }, 1000);
      }
    });
  };

  const getPrograms = () => {
    dispatch(getAllProgramList()).then((res) => {
      if (res?.payload?.status === 200) {
        //   setLoader(false);
        setProgramList(res?.payload?.data);
      }
      // setLoader(false);
    });
  };

  useEffect(() => {
    getPrograms();
  }, []);

  const { rollPermissionData } = useSelector((state) => state.admin);

  if (
    rollPermissionData?.permissionArray?.includes("Disbursement_add") ||
    rollPermissionData?.permissionArray?.includes("Disbursement_edit")
  ) {
  } else {
    navigate(`/${rollData}/bill-list`);
  }

  return (
    <>
      <div>
        {pageLoader ? (
          <PageLoading />
        ) : (
          <>
            <Index.Box className="apply-sahay-main">
              <Index.Box className="head-content">
                <Index.Box
                  className={`user-right ${
                    location.pathname == "/admin/add-disbursement-application"
                      ? "admin-border-radius"
                      : ""
                  }`}
                >
                  <Index.Box
                    className={`bottom-user-right ${
                      location.pathname == "/admin/add-disbursement-application"
                        ? "admin-height-remove"
                        : ""
                    }`}
                  >
                    <Index.Box className="user-apply-content">
                      <PagesIndex.Formik
                        enableReinitialize
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={billSchema}
                      >
                        {({
                          values,
                          handleChange,
                          handleSubmit,
                          touched,
                          setFieldValue,
                          handleBlur,
                          errors,
                          isValid,
                          isSubmitting,
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <Index.Box className="main-in-row">
                              <Index.Box className="user-basic user-heading">
                                <Index.Typography
                                  component="h1"
                                  className="head-apply"
                                >
                                  {editBillData ? "Edit Bill" : "Add Bill"}
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className="main-in-row">
                              <Index.Box
                                className="user-basic"
                                sx={{ display: "flex" }}
                              ></Index.Box>
                              <Index.Box
                                sx={{ flexGrow: 1 }}
                                className="al-form-groups"
                              >
                                <Index.Grid container spacing={2}>
                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        Program Name (પ્રોગ્રામનું નામ)
                                      </Index.Typography>
                                      <Index.FormControl
                                        sx={{ width: "100%" }}
                                        className="main-select"
                                      >
                                        <Index.Select
                                          className="select-drop"
                                          displayEmpty
                                          value={values?.programId}
                                          onChange={(e) => {
                                            setFieldValue(
                                              "programId",
                                              e.target.value
                                            );

                                            const memberList =
                                              programList?.find(
                                                (program) =>
                                                  program?.id == e.target.value
                                              );

                                            setFieldValue(
                                              "memberAmount",
                                              memberList?.members?.map(
                                                (row) => ({
                                                  ...row,
                                                  isChecked: false,
                                                  amount: 0,
                                              
                                                })
                                              )
                                            );
                                          }}
                                          onBlur={handleBlur}
                                          input={<OutlinedInput />}
                                          MenuProps={MenuProps}
                                          inputProps={{
                                            "aria-label": "Without label",
                                          }}
                                        >
                                          {programList?.map((ele) => (
                                            <Index.MenuItem
                                              key={ele?.Id}
                                              value={ele?.id}
                                            >
                                              {ele?.programName}
                                            </Index.MenuItem>
                                          ))}
                                        </Index.Select>
                                      </Index.FormControl>
                                    </Index.Box>
                                    {errors?.programId &&
                                      touched?.programId && (
                                        <p className="error-text">
                                          {errors?.programId}
                                        </p>
                                      )}
                                  </Index.Grid>

                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        Vendor Name (વિક્રેતાનું નામ)
                                      </Index.Typography>
                                      <Index.TextField
                                        type="text"
                                        inputProps={ariaLabel}
                                        className="basic-input input-width"
                                        name="vendorName"
                                        value={values?.vendorName}
                                        onChange={(e) => {
                                          const newValue = e.target.value
                                            .replace(/^\s+/, "")
                                            .replace(/\s\s+/g, " ");
                                          setFieldValue("vendorName", newValue);
                                        }}
                                        onBlur={handleBlur}
                                      />
                                    </Index.Box>
                                    {errors?.vendorName &&
                                      touched?.vendorName && (
                                        <p className="error-text">
                                          {errors?.vendorName}
                                        </p>
                                      )}
                                  </Index.Grid>

                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        Bill Number (બિલ નંબર)
                                      </Index.Typography>
                                      <Index.TextField
                                        inputProps={ariaLabel}
                                        className="basic-input input-width"
                                        name="billNumber"
                                        value={values?.billNumber}
                                        onChange={(e) => {
                                          if (e.target.value.length <= 12) {
                                            // Only update the value if it's not more than 40 characters
                                            handleChange(e);
                                          }
                                        }}
                                        onBlur={handleBlur}
                                      />
                                    </Index.Box>
                                    {errors?.billNumber &&
                                      touched?.billNumber && (
                                        <p className="error-text">
                                          {errors?.billNumber}
                                        </p>
                                      )}
                                  </Index.Grid>

                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Typography
                                      component="p"
                                      className="in-label"
                                    >
                                      Bill Date (બિલ તારીખ)
                                    </Index.Typography>
                                    <Index.Box className="input-design-div with-border ">
                                      <Index.LocalizationProvider
                                        dateAdapter={Index.AdapterDayjs}
                                        sx={{ position: "absolute" }}
                                      >
                                        <Index.DemoContainer
                                          components={["DatePicker"]}
                                        >
                                          <MobileDatePicker
                                            name="billDate"
                                            onChange={(value) => {
                                              setFieldValue(
                                                "billDate",
                                                moment(value.$d).format(
                                                  "YYYY-MM-DD"
                                                )
                                              );
                                            }}
                                            value={dayjs(values?.billDate)}
                                            format="YYYY-MM-DD"
                                            sx={{
                                              overflowY: "hidden",
                                              position: "relative",
                                              bottom: "8px",
                                              width: "100%",
                                            }}
                                            slotProps={{
                                              textField: {
                                                size: "small",
                                                error: false,
                                              },
                                            }}
                                          />
                                        </Index.DemoContainer>
                                      </Index.LocalizationProvider>
                                    </Index.Box>
                                    {errors?.billDate && touched.billDate && (
                                      <p className="error-text">
                                        {errors?.billDate}
                                      </p>
                                    )}
                                  </Index.Grid>

                                  <>
                                    <Index.Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      md={6}
                                      lg={3}
                                      mb={2}
                                    >
                                      <Index.Box className="lable-input-grp">
                                        <Index.Typography
                                          component="p"
                                          className="in-label"
                                        >
                                          Total Amount (કુલ રકમ)
                                        </Index.Typography>
                                        <Index.TextField
                                          type="number"
                                          inputProps={ariaLabel}
                                          className="basic-input input-width"
                                          name="totalAmount"
                                          value={values?.totalAmount}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                        />
                                      </Index.Box>
                                      {errors?.totalAmount &&
                                        touched.totalAmount && (
                                          <p className="error-text">
                                            {errors?.totalAmount}
                                          </p>
                                        )}
                                    </Index.Grid>
                                    <Index.Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      md={6}
                                      lg={3}
                                      mb={2}
                                    ></Index.Grid>

                                    <Index.Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      md={4}
                                      lg={4}
                                      mb={2}
                                    >
                                      <Index.Box className="lable-input-grp">
                                        <Index.Typography
                                          component="p"
                                          className="in-label"
                                        >
                                          Add Multiple Document (બહુવિધ દસ્તાવેજ
                                          ઉમેરો)
                                        </Index.Typography>
                                        <Index.Box className="select-position">
                                          <input
                                            type="file"
                                            name="BillDocuments"
                                            multiple
                                            className="ori-input"
                                            onChange={(event) => {
                                              const files = event.target.files;
                                              setFieldValue("BillDocuments", [
                                                ...values?.BillDocuments,
                                                ...files,
                                              ]);
                                            }}
                                          />

                                          <Index.Box className="select-file">
                                            Upload Your Document
                                          </Index.Box>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Grid>

                                    <Index.Grid item xs={8}>
                                      <Index.Box className="select-view-document-name">
                                        {values?.BillDocuments?.map(
                                          (file, index) => (
                                            <Index.Box
                                              className="document-name"
                                              key={index}
                                            >
                                              <Index.Typography
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                  // Implement the logic to view the document here
                                                  // You can use a modal or a new browser tab/window to show the document
                                                  window.open(
                                                    file instanceof Blob
                                                      ? URL.createObjectURL(
                                                          file
                                                        )
                                                      : `${imagePath}${file?.Documents}`
                                                  );
                                                }}
                                              >
                                                {file?.name
                                                  ? file?.name
                                                  : file?.Filename}
                                              </Index.Typography>
                                              <Index.IconButton
                                                onClick={() => {
                                                  const updatedDocuments =
                                                    values?.BillDocuments?.filter(
                                                      (_, i) => i !== index
                                                    );
                                                  setFieldValue(
                                                    "BillDocuments",
                                                    updatedDocuments
                                                  );
                                                }}
                                              >
                                                <Index.ClearIcon className="muiico-cancel" />
                                              </Index.IconButton>
                                            </Index.Box>
                                          )
                                        )}
                                      </Index.Box>
                                    </Index.Grid>
                                    

                                    <Index.Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={12}
                                      mb={12}
                                    >
                                      <Index.Box className="">
                                        <Index.TableContainer
                                          sx={{ maxHeight: 380 }}
                                          component={Index.Paper}
                                          className="table-container sahay-application-container scroll-table"
                                        >
                                          <Index.Table
                                            stickyHeader
                                            aria-label="sticky table"
                                            className="table-design-main barge-table sahay-appli-table add-bill-table-custom"
                                          >
                                            <Index.TableHead className="sahay-appli-head">
                                              <Index.TableRow className="sahay-appli-tr">
                                                <Index.TableCell
                                                  className="sahay-appli-tr"
                                                  align="left"
                                                ></Index.TableCell>
                                                <Index.TableCell
                                                  className="sahay-appli-tr"
                                                  align="left"
                                                >
                                                  Member (સભ્ય)
                                                </Index.TableCell>

                                                <Index.TableCell
                                                  className="sahay-appli-tr"
                                                  align="left"
                                                >
                                                  Amount (રકમ)
                                                </Index.TableCell>
                                              </Index.TableRow>
                                            </Index.TableHead>
                                       
                                            <Index.TableBody>
                                              <FieldArray name="memberAmount">
                                                <>
                                                  {values?.memberAmount
                                                    ?.length ? (
                                                    values?.memberAmount?.map(
                                                      (row, index) => (
                                                        <Index.TableRow
                                                          key={index}
                                                          className={`sahay-appli-tr`}
                                                        >
                                                          <Index.TableCell
                                                            component="td"
                                                            variant="td"
                                                            scope="row"
                                                            className="sahay-appli-td"
                                                            align="left"
                                                          >
                                                            <Index.Checkbox
                                                              component="td"
                                                              variant="td"
                                                              scope="row"
                                                              className="check-box-role"
                                                              align="left"
                                                              checked={
                                                                row?.isChecked
                                                              }
                                                              onChange={(e) =>
                                                                setFieldValue(
                                                                  `memberAmount[${index}].isChecked`,
                                                                  !row?.isChecked
                                                                )
                                                              }
                                                            />
                                                          </Index.TableCell>

                                                          <Index.TableCell
                                                            align="left"
                                                            className="sahay-appli-td"
                                                          >
                                                            {row?.memberName}
                                                          </Index.TableCell>

                                                          <Index.TableCell
                                                            align="left"
                                                            className="sahay-appli-td"
                                                          >
                                                            <Index.Grid
                                                              item
                                                              xs={12}
                                                              sm={12}
                                                              md={12}
                                                              lg={12}
                                                              key={index}
                                                            >
                                                              <Index.Box className="input-design-div with-border">
                                                                <Index.TextField
                                                                  inputProps={{
                                                                    ...ariaLabel,
                                                                    disabled:
                                                                      row?.isChecked ==
                                                                      false,
                                                                  }}
                                                                  onBlur={
                                                                    handleBlur
                                                                  }
                                                                  className="basic-input input-width"
                                                                  name={`memberAmount[${index}].amount`}
                                                                  value={
                                                                    values
                                                                      ?.memberAmount[
                                                                      index
                                                                    ].amount
                                                                  }
                                                                  type="number"
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    const newValue =
                                                                      e.target
                                                                        .value;

                                                                    setFieldValue(
                                                                      `memberAmount[${index}].amount`,
                                                                      newValue
                                                                    );
                                                                   
                                                                  }}
                                                                />
                                                              </Index.Box>

                                                              {console.log(values, 671)
                                                              }

                                                              {errors
                                                                ?.memberAmount
                                                                ?.length !==
                                                              0 ? (
                                                                errors
                                                                  ?.memberAmount?.[
                                                                  index
                                                                ]?.amount &&
                                                                touched
                                                                  ?.memberAmount?.[
                                                                  index
                                                                ]?.amount && (
                                                                  <p className="error-text">
                                                                    {
                                                                      errors
                                                                        ?.memberAmount[
                                                                        index
                                                                      ]?.amount
                                                                    }
                                                                  </p>
                                                                )
                                                              ) : (
                                                                <></>
                                                              )}
                                                            </Index.Grid>
                                                          </Index.TableCell>
                                                        </Index.TableRow>
                                                      )
                                                    )
                                                  ) : (
                                                    <PagesIndex.NoRecordFound
                                                      colSpan={12}
                                                    />
                                                  )}
                                                </>
                                              </FieldArray>
                                            </Index.TableBody>
                                          </Index.Table>
                                        </Index.TableContainer>
                                        {typeof errors?.memberAmount ==
                                          "string" && (
                                          <p className="error-text">
                                            {touched?.memberAmount &&
                                              errors?.memberAmount}
                                          </p>
                                        )}
                                      </Index.Box>
                                    </Index.Grid>
                                  </>
                                </Index.Grid>
                              </Index.Box>
                            </Index.Box>

                            <Index.Box
                              sx={{ flexGrow: 1 }}
                              className="al-form-groups fam-in-grp"
                            >
                              <Index.Grid item xs={6}>
                                <Index.Box className="common-button blue-button flex-end save-btn back-btn">
                                  <Index.Button
                                    variant="contained"
                                    onClick={() => handleBack()}
                                  >
                                    Back
                                  </Index.Button>
                                </Index.Box>
                              </Index.Grid>
                              <Index.Grid container spacing={2}>
                                <Index.Grid item xs={6}>
                                  <Index.Box className="lable-input-grp ">
                                    <Index.Box className="select-position">
                                      <Index.Box
                                        className={
                                          values.isSubmit === false
                                            ? "apply-now"
                                            : "disable-now"
                                        }
                                      >
                                        <button
                                          type="submit"
                                          disabled={values.isSubmit}
                                        >
                                          {editBillData == undefined
                                            ? "Submit"
                                            : "Update"}
                                        </button>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Grid>
                              </Index.Grid>
                            </Index.Box>
                          </form>
                        )}
                      </PagesIndex.Formik>

                      <Index.Box className="title-header-flex "></Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </>
        )}
      </div>
    </>
  );
};

export default AddBill;
