import Index from "../../../Index";
import React, { useEffect, useState } from "react";
import PagesIndex from "../../../PagesIndex";
import moment from "moment";
import {
  getAllProgramList,
  getSahaylist,
  getSahaylistDelete,
  programDelete,
} from "../../../../redux/features/AddService";
import { STATUSES } from "../../../../redux/features/adminslice/AdminSlice";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import PageLoading from "../../../../components/common/pageLoading/PageLoading";
import RowPerPageDropdown from "../../../../components/common/paginationRowPerpage/RowPerPageDropdown";

const Program = () => {
  const { getSahaydata, status, loading } = PagesIndex.useSelector(
    (state) => state.admin
  );

  // all state
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [rowId, setRowId] = useState("");

  const [pageData, setPageData] = useState([]);
  const [rowPerPage, setRowPerPage] = useState("25");
  const [SahaytypeMasterlist, setSahaytypeMasterlist] = useState([]);
  const [searchedData, setSearchedData] = React.useState([]);

  const [loader, setLoader] = useState(true);
  const navigate = PagesIndex.useNavigate();
  const dispatch = PagesIndex.useDispatch();
  const rollData = JSON.parse(localStorage?.getItem("RollName"))?.toLowerCase();

  let deleteText = "program";
  const { rollPermissionData } = useSelector((state) => state.admin);
  const rollPermissionNameData =
    rollPermissionData?.findData?.roleName?.toLowerCase();

  const handleSahaytypeEdit = (editData) => {
    navigate(`/${rollData}/add-edit-program`, {
      state: { editData, edit: "Edit" },
    });
  };

  const getPrograms = () => {
    dispatch(getAllProgramList()).then((res) => {
      if (res?.payload?.status === 200) {
        setLoader(false);
        setSahaytypeMasterlist(res?.payload?.data);
      }
      setLoader(false);
    });
  };

  useEffect(() => {
    getPrograms();
  }, []);

  const handleDeleteOpen = (data) => {
    setRowId(data.id);

    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleDeleteRecord = () => {
    dispatch(programDelete(rowId)).then((data) => {
      if (data?.payload == 200) {
        setLoader(false);
        getPrograms();
      }
    });
    setDeleteOpen(false);
  };

  useEffect(() => {
    setSearchedData(SahaytypeMasterlist);
  }, [SahaytypeMasterlist]);

  const requestSearch = (searched) => {
    if (!searched) return setSearchedData(SahaytypeMasterlist);
    setSearchedData(
      SahaytypeMasterlist.filter((item) =>
        item?.programName.toLowerCase().includes(searched.toLowerCase())
      )
    );
  };

  if (
    rollPermissionData?.permissionArray?.includes("Program Master_view") ||
    rollPermissionNameData === "admin"
  ) {
  } else {
    navigate(`/${rollData}/sahay-application`);
  }

  return (
    <>
      {loader ? (
        <PageLoading />
      ) : (
        <>
          <Index.Box className="page-content-box">
            <Index.Box className="barge-common-box">
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex res-title-header-flex">
                  <Index.Box className="plus-hidden-show-mobile">
                    <Index.Box className="title-main">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="page-title"
                      >
                        Program Master
                      </Index.Typography>
                    </Index.Box>
                    {rollPermissionData?.permissionArray?.includes(
                      "Program Master_add"
                    ) || rollPermissionNameData === "admin" ? (
                      <>
                        <Index.Box className="common-button blue-button res-blue-button res-mobile-show">
                          <Index.Box className="mobile-menu-show-hover">
                            <PagesIndex.Link
                              to={`/${rollData}/add-edit-program`}
                              className="no-text-decoration"
                            >
                              {" "}
                              <Index.Button
                                variant="contained"
                                disableRipple
                                className="apply-sahay-btn"
                              >
                                <Index.ControlPointIcon />
                              </Index.Button>
                            </PagesIndex.Link>
                            <Index.Box className="hover-tooltip-custom">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="title-tooltip"
                              >
                                Add Program
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </>
                    ) : (
                      ""
                    )}
                  </Index.Box>
                  <Index.Box className="d-flex align-items-center res-set-search">
                    <PagesIndex.Search
                      className="search"
                      onChange={(e) => requestSearch(e.target.value)}
                    >
                      <PagesIndex.StyledInputBase
                        placeholder="Search"
                        inputProps={{ "aria-label": "search" }}
                      />
                    </PagesIndex.Search>
                    {rollPermissionData?.permissionArray?.includes(
                      "Program Master_add"
                    ) || rollPermissionNameData === "admin" ? (
                      <>
                        <Index.Box className="common-button blue-button res-blue-button desk-hidden-show">
                          <Index.Button
                            onClick={() =>
                              navigate(`/${rollData}/add-edit-program`)
                            }
                            variant="contained"
                          >
                            <Index.ControlPointIcon className="plus-add" />
                            Add Program
                          </Index.Button>
                        </Index.Box>
                      </>
                    ) : (
                      ""
                    )}
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="sahay-table">
                <Index.TableContainer
                sx={{ maxHeight: 380 }}
                  // component={Index.Paper}
                  className="table-container sahay-type-container scroll-table common-table-container-custom"
                >
                  <Index.Table
                    stickyHeader aria-label="sticky table"
                    className="table-design-main barge-table sahay-type-table"
                  >
                    <Index.TableHead className="sahay-type-head">
                      <Index.TableRow className="sahay-type-tr">
                        <Index.TableCell className="sahay-type-th">
                          Program Name
                        </Index.TableCell>
                        <Index.TableCell className="sahay-type-th" align="left">
                          Start Date
                        </Index.TableCell>
                        <Index.TableCell className="sahay-type-th" align="left">
                          End Date
                        </Index.TableCell>
                        {rollPermissionData?.permissionArray?.includes(
                          "Program Master_edit"
                        ) ||
                        rollPermissionData?.permissionArray?.includes(
                          "Program Master_delete"
                        ) ||
                        rollPermissionNameData === "admin" ? (
                          <>
                            <Index.TableCell
                              className="sahay-type-th"
                              align="center"
                            >
                              Action
                            </Index.TableCell>
                          </>
                        ) : (
                          ""
                        )}
                      </Index.TableRow>
                    </Index.TableHead>
                    <Index.TableBody
                      className={
                        status !== STATUSES.LOADING
                          ? "sahay-type-body"
                          : "loading-table"
                      }
                    >
                      {pageData?.length ? (
                        pageData?.map((item, index) => (
                          <Index.TableRow
                            key={item.id}
                            className="sahay-type-tr"
                          >
                            <Index.TableCell
                              align="left"
                              className="sahay-type-td"
                            >
                              {item?.programName}
                            </Index.TableCell>
                            <Index.TableCell
                              align="left"
                              className="sahay-type-td"
                            >
                              {moment
                                .utc(item?.startDate ? item?.startDate : null)
                                .format("YYYY-MM-DD")}
                            </Index.TableCell>
                            <Index.TableCell
                              align="left"
                              className="sahay-type-td"
                            >
                              {moment
                                .utc(item?.endDate ? item?.endDate : null)
                                .format("YYYY-MM-DD")}
                            </Index.TableCell>
                            {rollPermissionData?.permissionArray?.includes(
                              "Program Master_edit"
                            ) ||
                            rollPermissionData?.permissionArray?.includes(
                              "Program Master_delete"
                            ) ||
                            rollPermissionNameData === "admin" ? (
                              <>
                                <Index.TableCell
                                  className="sahay-type-td flex-program"
                                  align="center"
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                    alignContent: "space-around",
                                  }}
                                >
                                  {rollPermissionData?.permissionArray?.includes(
                                    "Program Master_edit"
                                  ) || rollPermissionNameData === "admin" ? (
                                    <>
                                      <Index.IconButton
                                        onClick={() =>
                                          handleSahaytypeEdit(item)
                                        }
                                      >
                                        <Index.EditIcon className="muiico-edit" />
                                      </Index.IconButton>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {rollPermissionData?.permissionArray?.includes(
                                    "Program Master_delete"
                                  ) || rollPermissionNameData === "admin" ? (
                                    <>
                                      <Index.IconButton
                                        onClick={() => handleDeleteOpen(item)}
                                      >
                                        <Index.DeleteIcon className="muiico-delete" />
                                      </Index.IconButton>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </Index.TableCell>
                              </>
                            ) : (
                              ""
                            )}
                          </Index.TableRow>
                        ))
                      ) : (
                        <PagesIndex.NoRecordFound colSpan={12} />
                      )}
                    </Index.TableBody>
                  </Index.Table>
                </Index.TableContainer>
              </Index.Box>
              <Index.Box className="pagination-design flex-end">
                <RowPerPageDropdown
                  rowPerPage={rowPerPage}
                  setRowPerPage={setRowPerPage}
                />
                <Index.Stack spacing={2}>
                  <PagesIndex.Pagination
                    fetchData={searchedData}
                    setPageData={setPageData}
                    pageData={pageData}
                    rowPerPage={rowPerPage}
                  />
                </Index.Stack>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <PagesIndex.DeleteModal
            deleteText={deleteText}
            deleteOpen={deleteOpen}
            handleDeleteRecord={handleDeleteRecord}
            handleDeleteClose={handleDeleteClose}
          />
        </>
      )}
    </>
  );
};

export default Program;
