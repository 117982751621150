import React, { useEffect, useState } from "react";
import Sidebar from "../../../../components/user/defaultLayout/Sidebar";
import Index from "../../../../container/Index";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import PagesIndex from "../../../PagesIndex";
import RemoveIcon from "@mui/icons-material/Remove";
import moment from "moment";
import dayjs from "dayjs";

import * as yup from "yup";
import {
  getFamilyIds,
  getSahayTypeList,
  getSahaySubTypeList,
  getFamilyMemberById,
  applyForSahayAPI,
  listYearOfSahay,
  bankAccountsList,
  getRelationMasterList,
  getAllFamilyWithMohollaNameAndAreaList,
  getSahayWiseDonnerList,
} from "../../../../redux/features/AddService";
import {
  validationSchemaApplyForSahayBankMemberUser,
  validationSchemaApplyForSahayUser,
  validationSchemaSave,
} from "../../../../validation/Validation";
import { Autocomplete, Typography } from "@mui/material";
import { imagePath } from "../../../../config/DataService";
import { sahayDocumentArray } from "../../../admin/pages/AddSahayAplicationAdmin/DocumentsPermissions";
import { FieldArray } from "formik";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const applySahayValidation = yup.object().shape({
  AadharNo: yup
    .number()
    .typeError("Only Number allowed")
    .required("Aadhar No Required"),
});

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const familyIncomeAmountList = [
  { id: 1, ammount: "Less than 5 Lakh" },
  { id: 2, ammount: "5 Lakh or Above" },
];

const electricBillAmountList = [
  { id: 1, ammount: "0 to 1000" },
  { id: 2, ammount: "1001 to 2500" },
  { id: 3, ammount: "2501 to 3500" },
  { id: 4, ammount: "3501 and Above" },
];
const workingTypeList = [
  { id: 1, name: "Business" },
  { id: 2, name: "House Wife" },
  { id: 3, name: "Job" },
  { id: 4, name: "Retire" },
  { id: 5, name: "Student" },
  { id: 6, name: "Not Applicable" },
];

const ariaLabel = { "aria-label": "description" };

const ApplyForSahay = () => {
  const {
    getUserLoginDetails,
    sahayApplicationAdminSingleGet,
    getRelationList,
  } = PagesIndex.useSelector((state) => state.admin);
  const theme = useTheme();
  const dispatch = PagesIndex.useDispatch();
  const navigate = PagesIndex.useNavigate();
  const location = PagesIndex.useLocation();

  //all state

  const [personName, setPersonName] = React.useState([]);
  const [documentError, setDocumentError] = React.useState(false);
  const [listYearOfSahayData, setListYearOfSahayData] = useState([]);
  const [bankAccountsListData, setBankAccountsListData] = useState([]);
  const [applicantId, setApplicantId] = useState();
  const [familyIdsList, setFamilyIdsList] = useState();
  const [sahayTypeList, setSahayTypeList] = useState();
  const [sahaySubTypeList, setSahaySubTypeList] = useState();
  const [familyMembers, setFamilyMembers] = useState();
  const [select̥edImage, setSelectedImage] = useState();
  const [sahayTypecondition, setSahayTypeCondition] = useState();
  const [sahayCondition, setSahayCondition] = useState();
  const [singlaFamilyId, setSingleFamilyId] = useState();
  const [singlaMohallaName, setSingleMohallaName] = useState();
  const [getDocument, setGetDocument] = useState({});
  const [selectApplicant, setSelectApplicant] = useState();
  const [getSingleSubSahay, setGetSingleSubSahay] = useState();
  const [applicantSelectedMember, setApplicantSelectedMember] = useState([]);
  const [applicantAllMember, setApplicantAllMember] = useState([]);
  const [demoDocument, setDemoDocument] = useState("");
  const [mohallaSelectedMember, setMohallaSelectedMember] = useState("");
  const [areaSelectedMember, setAreaSelectedMember] = useState("");
  const [familyListWithArea, setFamilyListWithArea] = useState([]);
  const [loader, setLoader] = useState(false);
  const [sahayWiseDonnerList, setSahayWiseDonnerList] = useState();

  const donnerNames = sahayWiseDonnerList?.map((donner) =>
    donner.DonnerName.trim()
  );

  const EducationSubsahayValue = [
    1015, 1017, 1010, 1007, 1006, 1008, 1009, 1010, 1011, 1012, 1013, 1019,
    1018, 1020, 1021, 1022, 1023,
  ];

  let familyMemberName =
    getUserLoginDetails &&
    getUserLoginDetails?.data?.mem_fname +
      " " +
      getUserLoginDetails?.data?.mem_mname +
      " " +
      getUserLoginDetails?.data?.mem_lname;

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const [alignment, setAlignment] = React.useState("web");

  const getFamilyMemberIds = (status) => {
    dispatch(getFamilyIds(status)).then((res) => {
      setFamilyIdsList(
        res?.payload?.map((ele, i) => {
          return {
            familyId: ele?.familyId,
            firstName: ele?.firstName,
            lastName: ele?.lastName,
            middleName: ele?.middleName,
            fullName:
              ele?.firstName + " " + ele?.middleName + " " + ele?.lastName,
          };
        })
      );
    });
  };

  const getSahayTypeData = () => {
    dispatch(getSahayTypeList()).then((res) => {
      setSahayTypeList(res?.payload);
    });
  };

  const getSahaySubTypeData = (sahayTypeId) => {
    dispatch(getSahaySubTypeList(sahayTypeId)).then((res) => {
      setSahaySubTypeList(res?.payload);
    });
  };
  const getSahayWiseDonner = (sahayTypeId) => {
    dispatch(getSahayWiseDonnerList(sahayTypeId)).then((res) => {
      setSahayWiseDonnerList(res?.payload);
    });
  };
  const setMemberDeatils = (applicantId, setFieldValue) => {
    const member =
      familyMembers &&
      familyMembers?.filter((member) => member?.id == applicantId);
    if (member?.[0]) {
      setFieldValue(
        "memberName",
        member?.[0]?.firstName +
          " " +
          member?.[0]?.middleName +
          " " +
          member?.[0]?.lastName
      );
      setFieldValue("age", member?.[0]?.age);
      setFieldValue("workingType", member?.[0]?.workingType);
      setFieldValue("relation", member?.[0]?.relation);
      setFieldValue("relation_id", member?.[0]?.relation_id);
      setFieldValue("monthlyIncome", member?.[0]?.annualIncome);
      setFieldValue(
        "firstNameApplicant",
        member?.[0]?.firstName + " " + member?.[0]?.lastName
      );
    }
  };

  useEffect(() => {
    getFamilyMemberIds(true);
    getSahayTypeData();
    dispatch(getRelationMasterList());
  }, []);

  const resetImage = (setFieldValue) => {
    setFieldValue("passportPic", "");
    setSelectedImage("");
  };

  //Get current financial year
  function getCurrentFinancialYear() {
    var financialYear = "";
    var today = new Date();
    if (today.getMonth() + 1 <= 3) {
      financialYear = today.getFullYear() - 1 + "-" + today.getFullYear();
    } else {
      financialYear = today.getFullYear() + "-" + (today.getFullYear() + 1);
    }
    return financialYear;
  }

  let initialValues = {
    isDraft: 0,
    patanMemberMobile: "",
    patanMandalMember: "",
    patanReferenceName: "",
    patanReferenceArea: "",
    patanReferenceMohollo: "",
    vastiPatrak: "true",
    familyId: singlaFamilyId && singlaFamilyId,
    formFillingDate: "",
    applicationReceiveDate: new Date().toISOString().split("T")[0],
    AadharNo: "",
    SahayType: "",
    subSahayType: "",
    applicantId: "",
    beneficiaryName: "",
    memberName: "",
    relation: "",
    relation_id: "",
    age: "",
    workingType: "",
    monthlyIncome: 0,
    threeMonthBillAmount: "",
    bankName: "",
    branch: "",
    HolderName: "",
    IFSCCode: "",
    AccountNo: "",
    isMediclaim: "No",
    mediclaimAmmount: "",
    isSahay: "No",
    trust: "no",
    SahayAmmount: "",
    rentSahayAmmount: "",
    claimedAmmount: 0,
    // trustName: "",
    // ammount: "",
    yearOfSahay: getCurrentFinancialYear(),
    bankMasterId: "",
    typeOfDisease: "",
    documents: [],
    passportPic: "",
    remark: "",
    isMediclaim: "No",
    mediclaimPremium: 0,
    isSubmit: false,
    dataArray: [
      {
        trustName: "",
        ammount: 0,
      },
    ],
    relationRecords: [
      {
        firstName: "",
        lastName: "",
        middleName: "",
        age: "",
        relation_id: "",
        workingType: "",
        AnnualIncome: 0,
      },
    ],
    PANCardNo: "",
    subSahayName: "",
  };

  useEffect(() => {
    let familyId = localStorage.getItem("familyId")
      ? localStorage.getItem("familyId")
      : 0;
    let MohallaName = localStorage.getItem("mohollo_name")
      ? localStorage.getItem("mohollo_name")
      : "";
    const data = {
      familyId: familyId,
      VastiPatra: getUserLoginDetails?.vastipatrak,
    };

    setSingleFamilyId(familyId);
    setSingleMohallaName(MohallaName);
    dispatch(getFamilyMemberById(data)).then((res) => {
      setFamilyMembers(res?.payload);
    });

    dispatch(listYearOfSahay()).then((data) => {
      if (data?.payload) {
        setListYearOfSahayData(data?.payload);
      } else {
        setListYearOfSahayData([]);
      }
    });
  }, []);

  useEffect(() => {
    if (applicantId) {
      dispatch(bankAccountsList(applicantId)).then((data) => {
        if (data?.payload) {
          setBankAccountsListData(data?.payload);
        } else {
          setBankAccountsListData([]);
        }
      });
    }
  }, [applicantId]);

  const getFamilyListWithArea = () => {
    dispatch(getAllFamilyWithMohollaNameAndAreaList()).then((res) => {
      if (res?.payload) {
        setFamilyListWithArea(res?.payload?.data);
      } else {
        setFamilyListWithArea([]);
      }
    });
  };

  useEffect(() => {
    getFamilyListWithArea();
  }, []);

  const handleMenuRelationOpen = () => {
    setLoader(true);

    familyListWithArea?.length > 0 &&
      setTimeout(() => {
        setLoader(false);
      }, 2000);
  };
  const handleMenuRelationClose = () => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  };

  const handleBack = () => {
    navigate("/user/user-listing");
  };

  //handle submit

  const handleFormSubmit = (values, { setFieldValue }) => {
    setFieldValue("isSubmit", true);

    const data = new FormData();
    const currentDate = new Date().toISOString().split("T")[0];
    data.append("formFillingDate", currentDate);
    let id = getUserLoginDetails?.mem_id;
    data.append("applicationReceiveDate", values?.applicationReceiveDate);
    // data.append("formFillingDate", values?.formFillingDate);
    data.append("AadharNo", +values?.AadharNo);
    data.append("SahayType", +values?.SahayType);
    data.append("subSahayType", +values?.subSahayType);
    data.append("appliedBy", +id);
    data.append("applicantId", +values?.applicantId);
    data.append("beneficiaryName", +values?.beneficiaryName);
    data.append("threeMonthBillAmount", values?.threeMonthBillAmount);
    data.append("bankName", values?.bankName);
    data.append("branch", values?.branch);
    data.append("HolderName", values?.HolderName);
    data.append("IFSCCode", values?.IFSCCode);
    data.append("AccountNo", +values?.AccountNo);
    data.append("isMediclaim", values?.isMediclaim == "No" ? false : true);
    data.append("mediclaimAmmount", +values?.mediclaimAmmount);
    data.append("mediclaimPremium", +values?.mediclaimPremium);
    data.append("isSahay", values?.isSahay == "No" ? false : true);
    data.append("vastiPatrak", getUserLoginDetails?.vastipatrak ? 1 : 0);
    data.append("SahayAmmount", +values?.SahayAmmount);
    data.append("rentSahayAmmount", +values?.rentSahayAmmount);
    data.append("PANCardNo", values?.PANCardNo);
    {
      values?.patanReferenceName &&
        data.append("patanReferenceName", values?.patanReferenceName);
    }
    {
      values?.patanReferenceArea &&
        data.append("patanReferenceArea", values?.patanReferenceArea);
    }

    {
      values?.patanReferenceMohollo &&
        data.append("patanReferenceMohollo", values?.patanReferenceMohollo);
    }

    // data.append("typeOfDisease", +values?.typeOfDisease);

    // data.append(
    //   "claimedAmmount",
    //   getSingleSubSahay?.SahayName == "Mediclaim Premium Sahay"
    //     ? values?.mediclaimAmmount
    //     : values?.claimedAmmount
    // );
    data.append("claimedAmmount", values?.claimedAmmount);
    data.append("trustName", "TEST");
    data.append("yearOfSahay", values?.yearOfSahay);
    data.append(
      "monthlyIncome",
      values?.monthlyIncome ? values?.monthlyIncome : ""
    );
    data.append("name", values?.memberName);
    data.append("age", values?.age);
    data.append("workingType", values?.workingType);
    data.append("relation", values?.relation_id);
    data.append("remark", values?.remark);
    data.append("patanMandalMember", values?.patanMandalMember);
    data.append("patanMemberMobile", values?.patanMemberMobile);
    data.append("SaveSahay", values?.isDraft);

    if (values?.documents?.length == 0) {
      setDocumentError(true);
      return 0;
    }

    // values?.documents?.length > 0 &&
    //   values?.documents?.forEach((ele) => {
    //     data.append("documents", ele);
    //   });
    const documentData = new FormData();
    let uploadIndex = 0;

    values?.documents?.forEach((ele) => {
      // if(ele?.file?.length > 0){
      // if (ele?.file == "" || ele?.file instanceof Blob)
      //  {

      data.append(
        `documentsArray[${uploadIndex}][sequenceNo]`,
        uploadIndex + 1
      );
      data.append(
        `documentsArray[${uploadIndex}][documentType]`,
        ele?.documentType
      );

      // ele?.file?.length > 0  && data.append(`documentsArray[0][file]`, demoDocument);

      ele?.file?.length > 0
        ? ele?.file?.forEach((ele) => {
            data.append(`documentsArray[${uploadIndex}][file]`, ele);
          })
        : data.append(`documentsArray[${uploadIndex}][file]`, demoDocument);
      data.append(
        `documentsArray[${uploadIndex}][subSahayName]`,
        values?.subSahayName
      );
      data.append(
        `documentsArray[${uploadIndex}][isUpload]`,
        ele?.isUpload ? 1 : 0
      );
      uploadIndex++;
      // }
      // }
    });

    {
      bankAccountsListData &&
        bankAccountsListData?.length > 0 &&
        data.append("bankMasterId", +values?.bankMasterId);
    }
    if (values?.passportPic) {
      data.append("passportPic", values?.passportPic);
    }

    {
      sahayCondition == "yes" &&
        values?.dataArray?.forEach((ele, i) => {
          data.append(`trustRecords[${i}][trustName]`, ele.trustName);
          data.append(`trustRecords[${i}][ammount]`, ele.ammount);
        });
    }

    let newArr = [];

    values?.relationRecords?.filter(async (ele) => {
      await newArr.push({
        middleName: ele.middleName,
        age: ele.age,
        relation: ele.relation_id,
        workingType: ele.workingType,
        monthlyIncome: ele.monthlyIncome,
      });
    });

    data.append(`relationRecords[0][name]`, values?.memberName);
    data.append(`relationRecords[0][age]`, values?.age);
    data.append(`relationRecords[0][workingType]`, values?.workingType);
    data.append(`relationRecords[0][relation]`, values?.relation_id);
    data.append(
      `relationRecords[0][monthlyIncome]`,
      values?.monthlyIncome ? values?.monthlyIncome : 0
    );

    values?.relationRecords?.forEach((ele, i) => {
      data.append(`relationRecords[${i + 1}][name]`, ele.firstName);
      data.append(`relationRecords[${i + 1}][age]`, ele.age);
      data.append(`relationRecords[${i + 1}][relation]`, ele.relation_id);
      data.append(
        `relationRecords[${i + 1}][monthlyIncome]`,
        ele.AnnualIncome ? ele.AnnualIncome : 0
      );
      data.append(`relationRecords[${i + 1}][workingType]`, ele.workingType);
    });

    dispatch(applyForSahayAPI(data)).then((res) => {
      if (res?.payload?.status == 201) {
        navigate("/user/user-listing");
        setTimeout(() => {
          setFieldValue("isSubmit", false);
        }, 1000);
      } else {
        setTimeout(() => {
          setFieldValue("isSubmit", false);
        }, 1000);
      }
    });
  };

  //handle submit

  //Document Uploads Sub sahay wise
  const uploadDocuments = (id, setFieldValue) => {
    const subSahayFilter = sahaySubTypeList?.filter((ele) => {
      return ele?.Id == id;
    });
    const filterData = subSahayFilter?.filter((ele) => {
      const subSahayMatch = sahayDocumentArray?.filter((subSahay) => {
        return subSahay?.subSahayName === ele?.SahayName;
      });
      if (
        subSahayMatch?.length > 0 &&
        subSahayMatch?.[0]?.documentData?.length > 0
      ) {
        setFieldValue("documents", subSahayMatch?.[0]?.documentData);
        setFieldValue("subSahayName", subSahayMatch?.[0]?.subSahayName);
      } else {
        setFieldValue("documents", []);
      }
    });
  };

  //Demo document
  useEffect(() => {
    const file = {
      name: "demo.png",
      lastModified: 1701162357663,
      lastModifiedDate: new Date(
        "Tue Nov 28 2023 14:35:57 GMT+0530 (India Standard Time)"
      ),
      webkitRelativePath: "",
      size: 8595,
      type: "image/png",
    };
    const fileData = new File([new Blob([file])], file.name, {
      type: file.type,
    });
    setDemoDocument(fileData);
  }, []);

  return (
    <>
      <div>
        {" "}
        <Index.Box className="apply-sahay-main">
          {location.pathname !== "/admin/apply-for-sahay" && <Sidebar />}

          <Index.Box className="head-content">
            <Index.Box
              // className={location.pathname == "/admin/apply-for-sahay" ? "admin-border-radius" : "user-right"}
              className={`user-right ${
                location.pathname == "/admin/apply-for-sahay"
                  ? "admin-border-radius"
                  : ""
              }`}
            >
              <Index.Box
                // className={location.pathname == "/admin/apply-for-sahay" ? "" :"bottom-user-right"}
                className={`bottom-user-right ${
                  location.pathname == "/admin/apply-for-sahay"
                    ? "admin-height-remove"
                    : ""
                }`}
              >
                <Index.Box className="user-apply-content">
                  <PagesIndex.Formik
                    enableReinitialize
                    onSubmit={handleFormSubmit}
                    initialValues={initialValues}
                    validationSchema={
                      bankAccountsListData && bankAccountsListData?.length == 0
                        ? validationSchemaApplyForSahayUser
                        : validationSchemaApplyForSahayBankMemberUser
                    }
                  >
                    {({
                      values,
                      handleChange,
                      handleSubmit,
                      touched,
                      setFieldValue,
                      handleBlur,
                      errors,
                      isValid,
                      isSubmitting,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <Index.Box className="main-in-row">
                          <Index.Box className="user-basic user-heading">
                            <Index.Typography
                              component="h1"
                              className="head-apply"
                            >
                              Apply for sahay (સહાય માટે અરજી કરો)
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                        
                        <Index.Box className="main-in-row">
                          <Index.Box
                            className="user-basic"
                            sx={{ display: "flex" }}
                          >
                            <Index.Typography
                              component="p"
                              className="head-apply"
                            >
                              Basic Details (મૂળભૂત વિગતો)
                            </Index.Typography>
                            <Index.Typography
                              component="h1"
                              className="head-apply"
                            >
                              ({" "}
                              {getUserLoginDetails?.vastipatrak
                                ? "Member"
                                : "Non Member"}{" "}
                              )
                            </Index.Typography>
                          </Index.Box>

                          <Index.Box
                            sx={{ flexGrow: 1 }}
                            className="al-form-groups"
                          >
                            <Index.Grid container spacing={2}>
                              <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                {location.pathname ==
                                "/admin/apply-for-sahay" ? (
                                  <Index.Box className="lable-input-grp">
                                    <Index.Typography
                                      component="p"
                                      className="in-label"
                                    >
                                      FamilyId
                                    </Index.Typography>
                                    <Index.FormControl
                                      sx={{ width: "100%" }}
                                      className="main-select"
                                    >
                                      <Index.Select
                                        className="select-drop"
                                        displayEmpty
                                        value={values?.SahayType}
                                        onChange={(e) => {
                                          setSahayTypeCondition(e.target.value);
                                          setFieldValue(
                                            "SahayType",
                                            e.target.value
                                          );
                                          getSahaySubTypeData(e.target.value);
                                          getSahayWiseDonner(e.target.value);
                                        }}
                                        onBlur={handleBlur}
                                        input={<OutlinedInput />}
                                        MenuProps={MenuProps}
                                        inputProps={{
                                          "aria-label": "Without label",
                                        }}
                                      >
                                        {sahayTypeList?.map((ele) => (
                                          <Index.MenuItem
                                            key={ele?.Id}
                                            value={ele?.Id}
                                          >
                                            {ele?.Sahay_category}
                                          </Index.MenuItem>
                                        ))}
                                      </Index.Select>
                                    </Index.FormControl>
                                    {errors?.SahayType &&
                                      touched?.SahayType && (
                                        <p className="error-text">
                                          {errors?.SahayType}
                                        </p>
                                      )}
                                  </Index.Box>
                                ) : (
                                  <>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        FamilyId (કૌટુંબિક આઈડી)
                                      </Index.Typography>
                                      <Index.TextField
                                        inputProps={ariaLabel}
                                        className="basic-input input-width"
                                        value={
                                          singlaFamilyId
                                            ? singlaFamilyId.replace(/"/g, "")
                                            : ""
                                        }
                                      />
                                    </Index.Box>{" "}
                                  </>
                                )}
                              </Index.Grid>
                              <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                <Index.Box className="lable-input-grp">
                                  <Index.Typography
                                    component="p"
                                    className="in-label"
                                  >
                                    Mohalla Name (મોહલ્લાનું નામ)
                                  </Index.Typography>
                                  <Index.TextField
                                    inputProps={ariaLabel}
                                    className="basic-input input-width"
                                    value={
                                      singlaMohallaName
                                        ? singlaMohallaName.replace(/"/g, "")
                                        : ""
                                    }
                                  />
                                </Index.Box>{" "}
                              </Index.Grid>
                              <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                <Index.Box className="lable-input-grp">
                                  <Index.Typography
                                    component="p"
                                    className="in-label"
                                  >
                                    Aadhar Card Number* (આધાર કાર્ડ નંબર)*
                                  </Index.Typography>
                                  <Index.TextField
                                    type="string"
                                    inputProps={ariaLabel}
                                    className="basic-input input-width"
                                    name="AadharNo"
                                    value={values?.AadharNo}
                                    onChange={(e) => {
                                      const inputValue = e.target.value.replace(
                                        /\D/g,
                                        ""
                                      ); // Remove non-numeric characters
                                      setFieldValue(
                                        "AadharNo",
                                        inputValue.slice(0, 12)
                                      );
                                    }}
                                    onBlur={handleBlur}
                                  />
                                </Index.Box>
                                {errors?.AadharNo && touched?.AadharNo && (
                                  <p className="error-text">
                                    {errors?.AadharNo}
                                  </p>
                                )}
                              </Index.Grid>
                              {sahayTypeList?.filter((data) => {
                                if (data?.Id == values?.SahayType) {
                                  setSahayTypeCondition(data);
                                }
                              })}
                              <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                <Index.Box className="lable-input-grp">
                                  <Index.Typography
                                    component="p"
                                    className="in-label"
                                  >
                                    Sahay Type* (શાખાનો પ્રકાર)*
                                  </Index.Typography>
                                  <Index.FormControl
                                    sx={{ width: "100%" }}
                                    className="main-select"
                                  >
                                    <Index.Select
                                      className="select-drop"
                                      displayEmpty
                                      value={values?.SahayType}
                                      onChange={(e) => {
                                      
                                        setFieldValue(
                                          "SahayType",
                                          e.target.value
                                        );
                                        getSahaySubTypeData(e.target.value);
                                      }}
                                      onBlur={handleBlur}
                                      input={<OutlinedInput />}
                                      MenuProps={MenuProps}
                                      inputProps={{
                                        "aria-label": "Without label",
                                      }}
                                    >
                                      {sahayTypeList?.map((ele) => (
                                        <Index.MenuItem
                                          key={ele?.Id}
                                          value={ele?.Id}
                                        >
                                          {ele?.Sahay_category}
                                        </Index.MenuItem>
                                      ))}
                                    </Index.Select>
                                  </Index.FormControl>
                                  {errors?.SahayType && touched?.SahayType && (
                                    <p className="error-text">
                                      {errors?.SahayType}
                                    </p>
                                  )}
                                </Index.Box>
                              </Index.Grid>
                              {sahaySubTypeList?.filter((data) => {
                                if (data?.Id == values?.subSahayType) {
                                  setGetSingleSubSahay(data);
                                }
                              })}

                              <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                <Index.Box className="lable-input-grp">
                                  <Index.Typography
                                    component="p"
                                    className="in-label"
                                  >
                                    Sub Sahay Type* (સબ સહાય પ્રકાર)*
                                  </Index.Typography>
                                  <Index.FormControl
                                    sx={{ width: "100%" }}
                                    className="main-select"
                                  >
                                    <Index.Select
                                      className="select-drop"
                                      displayEmpty
                                      value={values?.subSahayType}
                                      onChange={(e) => {
                                        getSahayWiseDonner(e.target.value);
                                        // setGetSingleSubSahay(e.target.value);
                                        setFieldValue(
                                          "subSahayType",
                                          e.target.value
                                        );
                                        setFieldValue("documents", []);
                                        uploadDocuments(
                                          e.target.value,
                                          setFieldValue
                                        );
                                        setFieldValue(
                                          "subSahayName",
                                          sahaySubTypeList?.find(
                                            (data) =>
                                              +data?.Id == +e.target.value
                                          )?.SahayName
                                        );
                                      }}
                                      onBlur={handleBlur}
                                      input={<OutlinedInput />}
                                      MenuProps={MenuProps}
                                      inputProps={{
                                        "aria-label": "Without label",
                                      }}
                                    >
                                      {sahaySubTypeList?.map((ele) => (
                                        <Index.MenuItem
                                          key={ele?.Id}
                                          value={ele?.Id}
                                        >
                                          {ele?.SahayName}
                                        </Index.MenuItem>
                                      ))}
                                    </Index.Select>
                                  </Index.FormControl>
                                  {errors?.subSahayType &&
                                    touched?.subSahayType && (
                                      <p className="error-text">
                                        {errors?.subSahayType}
                                      </p>
                                    )}
                                </Index.Box>
                              </Index.Grid>

                              <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                <Index.Box className="lable-input-grp">
                                  <Index.Typography
                                    component="p"
                                    className="in-label"
                                  >
                                    Year Of Sahay* (સહાયનું વર્ષ)*
                                  </Index.Typography>
                                  <Index.TextField
                                    disabled
                                    type="string"
                                    inputProps={ariaLabel}
                                    className="basic-input input-width"
                                    name="yearOfSahay"
                                    value={values?.yearOfSahay}
                                  />
                                </Index.Box>
                                {errors?.yearOfSahay &&
                                  touched?.yearOfSahay && (
                                    <p className="error-text">
                                      {errors?.yearOfSahay}
                                    </p>
                                  )}
                              </Index.Grid>

                              {Array.isArray(sahayWiseDonnerList) &&
                                sahayWiseDonnerList.length > 0 && (
                                  <Index.Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        Donner Name (દાતા નામ)
                                      </Index.Typography>
                                      <Index.Box className="donner-name">
                                        <Index.Typography>
                                          {sahayWiseDonnerList
                                            ?.map((donner) =>
                                              donner.DonnerName.trim()
                                            )
                                            .join(", ")}
                                        </Index.Typography>
                                      </Index.Box>
                                      {/* <Index.TextField
                                          name="donnerNames"
                                          className="basic-input input-width"
                                          value={sahayWiseDonnerList
                                            ?.map((donner) => donner.DonnerName.trim())
                                            .join(", ")}
                                          onChange={(e) => {
                                            setFieldValue("donnerNames", e.target.value);
                                          }}
                                          variant="outlined"
                                          fullWidth
                                          size="small"
                                          InputProps={{
                                            readOnly: true,
                                            sx: {
                                              overflow: "auto",
                                              whiteSpace: "pre-wrap",
                                              wordWrap: "break-word",
                                              "& textarea": {
                                                overflow: "auto",
                                                resize: "none",
                                              },
                                            },
                                          }}
                                        /> */}
                                    </Index.Box>
                                    {errors?.donnerNames &&
                                      touched.donnerNames && (
                                        <p className="error-text">
                                          {errors?.donnerNames}
                                        </p>
                                      )}
                                  </Index.Grid>
                                )}

                              {sahaySubTypeList?.find(
                                (data) => +data?.Id == +values?.subSahayType
                              )?.SahayName == "Swawlamban" ||
                              sahaySubTypeList?.find(
                                (data) => +data?.Id == +values?.subSahayType
                              )?.SahayName == "Educational Loan" ? (
                                <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                  <Index.Box className="lable-input-grp">
                                    <Index.Typography
                                      component="p"
                                      className="in-label"
                                    >
                                      PAN Card No. (પાન કાર્ડ નંબર)
                                    </Index.Typography>
                                    <Index.Box className="sahay-multiple-input-main">
                                      <Index.TextField
                                        inputProps={ariaLabel}
                                        className="basic-input input-width"
                                        name="PANCardNo"
                                        value={values?.PANCardNo}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                    </Index.Box>
                                  </Index.Box>
                                  <Index.FormHelperText error>
                                    {touched?.PANCardNo && errors?.PANCardNo}
                                  </Index.FormHelperText>
                                </Index.Grid>
                              ) : (
                                <></>
                              )}

                              {getSingleSubSahay?.SahayName ==
                              "Medical Sahay" ? (
                                <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                  <Index.Box className="lable-input-grp">
                                    <Index.Typography
                                      component="p"
                                      className="in-label"
                                    >
                                      Type Of Disease (રોગનો પ્રકાર)
                                    </Index.Typography>
                                    <Index.Box className="sahay-multiple-input-main">
                                      <Index.TextField
                                        inputProps={ariaLabel}
                                        className="basic-input input-width"
                                        name="typeOfDisease"
                                        value={values?.typeOfDisease}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Grid>
                              ) : (
                                <></>
                              )}

<Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                {getSingleSubSahay?.SahayName ==
                                "Rental Sahay" ? (
                                  <Index.Box className="lable-input-grp">
                                    <Index.Typography
                                      component="p"
                                      className="in-label"
                                    >
                                      Rent Amount For Rent Sahay (ભાડા સહાય માટે
                                      ભાડાની રકમ)
                                    </Index.Typography>
                                    <Index.TextField
                                      inputProps={ariaLabel}
                                      className="basic-input input-width"
                                      name="rentSahayAmmount"
                                      value={values?.rentSahayAmmount}
                                      onChange={handleChange}
                                      // onBlur={handleBlur}
                                    />
                                  </Index.Box>
                                ) : null}
                                {/* {errors?.rentSahayAmmount && (
                                  <p className="error-text">
                                    {errors?.rentSahayAmmount}
                                  </p>
                                )} */}
                              </Index.Grid>
                            </Index.Grid>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="main-in-row">
                          <Index.Box className="user-basic">
                            <Index.Typography
                              component="p"
                              className="head-apply"
                            >
                              Family Members Details (કુટુંબના સભ્યોની વિગતો)
                            </Index.Typography>
                          </Index.Box>
                          <Index.Box
                            sx={{ flexGrow: 1 }}
                            className="al-form-groups family-detail"
                          >
                            <Index.Grid container spacing={2}>
                              <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                <Index.Box className="lable-input-grp">
                                  <Index.Typography
                                    component="p"
                                    className="in-label"
                                  >
                                    Name Of Applicant* (અરજદારનું નામ)*
                                  </Index.Typography>
                                  <Index.FormControl
                                    sx={{ width: "100%" }}
                                    className="main-select"
                                  >
                                    <Index.Select
                                      className="select-drop"
                                      displayEmpty
                                      value={values?.applicantId}
                                      onChange={(e) => {
                                        setFieldValue(
                                          "applicantId",
                                          e.target.value
                                        );

                                        setMemberDeatils(
                                          e.target.value,
                                          setFieldValue
                                        );

                                        setFieldValue("bankName", "");
                                        setFieldValue("branch", "");
                                        setFieldValue("HolderName", "");
                                        setFieldValue("IFSCCode", "");
                                        setFieldValue("AccountNo", "");

                                        setApplicantId(e.target.value);
                                        setApplicantSelectedMember(
                                          familyMembers?.filter((item) => {
                                            return item?.id == e.target.value;
                                          })
                                        );
                                        setApplicantAllMember(
                                          familyMembers?.filter((item) => {
                                            return item?.id != e.target.value;
                                          })
                                        );
                                        setFieldValue(
                                          "relationRecords",
                                          familyMembers?.filter((item) => {
                                            return item?.id !== e.target.value;
                                          })
                                        );
                                      }}
                                      onBlur={handleBlur}
                                      input={<OutlinedInput />}
                                      MenuProps={MenuProps}
                                      inputProps={{
                                        "aria-label": "Without label",
                                      }}
                                    >
                                      {familyMembers?.map((ele) => (
                                        <Index.MenuItem
                                          key={ele?.id}
                                          value={ele?.id}
                                        >
                                          {`${ele?.firstName} ${ele.middleName} ${ele.lastName}`}
                                        </Index.MenuItem>
                                      ))}
                                    </Index.Select>
                                  </Index.FormControl>
                                  {errors?.applicantId &&
                                    touched?.applicantId && (
                                      <p className="error-text">
                                        {errors?.applicantId}
                                      </p>
                                    )}
                                </Index.Box>
                              </Index.Grid>
                              <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                <Index.Box className="lable-input-grp">
                                  <Index.Typography
                                    component="p"
                                    className="in-label"
                                  >
                                    Beneficiary Name* (લાભાર્થીનું નામ)*
                                  </Index.Typography>
                                  <Index.FormControl
                                    sx={{ width: "100%" }}
                                    className="main-select"
                                  >
                                    <Index.Select
                                      className="select-drop"
                                      displayEmpty
                                      value={values?.beneficiaryName}
                                      onChange={(e) => {
                                        setFieldValue(
                                          "beneficiaryName",
                                          e.target.value
                                        );
                                      }}
                                      onBlur={handleBlur}
                                      input={<OutlinedInput />}
                                      MenuProps={MenuProps}
                                      inputProps={{
                                        "aria-label": "Without label",
                                      }}
                                    >
                                      {familyMembers?.map((ele) => (
                                        <Index.MenuItem
                                          key={ele?.id}
                                          value={ele?.id}
                                        >
                                          {`${ele?.firstName} ${ele?.middleName} ${ele?.lastName}`}
                                        </Index.MenuItem>
                                      ))}
                                    </Index.Select>
                                  </Index.FormControl>
                                  {errors?.beneficiaryName &&
                                    touched?.beneficiaryName && (
                                      <p className="error-text">
                                        {errors?.beneficiaryName}
                                      </p>
                                    )}
                                </Index.Box>
                              </Index.Grid>
                            </Index.Grid>
                          </Index.Box>
                          <Index.Box
                            sx={{ flexGrow: 1 }}
                            className="al-form-groups fam-in-grp"
                          >
                            <Index.Grid container spacing={2}>
                              <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                <Index.Box className="lable-input-grp">
                                  <Index.Typography
                                    component="p"
                                    className="in-label"
                                  >
                                    Name* (નામ)*
                                  </Index.Typography>
                                  <Index.TextField
                                    disabled
                                    inputProps={ariaLabel}
                                    className="basic-input input-width"
                                    name="memberName"
                                    value={values?.memberName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {errors?.memberName &&
                                    touched?.memberName && (
                                      <p className="error-text">
                                        {errors?.memberName}
                                      </p>
                                    )}
                                </Index.Box>
                              </Index.Grid>
                              <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                <Index.Box className="lable-input-grp">
                                  <Index.Typography
                                    component="p"
                                    className="in-label"
                                  >
                                    Relation (સંબંધ)
                                  </Index.Typography>
                                  <Index.TextField
                                    disabled
                                    inputProps={ariaLabel}
                                    className="basic-input input-width"
                                    name="relation"
                                    value={values?.relation}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {errors?.relation && touched?.relation && (
                                    <p className="error-text">
                                      {errors?.relation}
                                    </p>
                                  )}
                                </Index.Box>
                              </Index.Grid>
                              <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                <Index.Box className="lable-input-grp">
                                  <Index.Typography
                                    component="p"
                                    className="in-label"
                                  >
                                    Age (ઉંમર)
                                  </Index.Typography>
                                  <Index.TextField
                                    disabled
                                    type="number"
                                    inputProps={ariaLabel}
                                    className="basic-input input-width"
                                    name="age"
                                    value={values?.age}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {errors?.age && touched?.age && (
                                    <p className="error-text">{errors?.age}</p>
                                  )}
                                </Index.Box>
                              </Index.Grid>
                              <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                <Index.Box className="lable-input-grp">
                                  <Index.Typography
                                    component="p"
                                    className="in-label"
                                  >
                                    Working Type (કામનો પ્રકાર)
                                  </Index.Typography>
                                  <Index.FormControl
                                    sx={{ width: "100%" }}
                                    className="main-select"
                                  >
                                    <Index.Select
                                      className="select-drop"
                                      displayEmpty
                                      name="workingType"
                                      value={values?.workingType}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      input={<OutlinedInput />}
                                      MenuProps={MenuProps}
                                      inputProps={{
                                        "aria-label": "Without label",
                                      }}
                                    >
                                      {workingTypeList?.map((ele) => (
                                        <Index.MenuItem
                                          key={ele?.id}
                                          value={ele?.name}
                                        >
                                          {ele?.name}
                                        </Index.MenuItem>
                                      ))}
                                    </Index.Select>
                                  </Index.FormControl>
                                  {errors?.workingType &&
                                    touched?.workingType && (
                                      <p className="error-text">
                                        {errors?.workingType}
                                      </p>
                                    )}
                                </Index.Box>
                              </Index.Grid>
                              <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                <Index.Box className="lable-input-grp">
                                  <Index.Typography
                                    component="p"
                                    className="in-label"
                                  >
                                    Family Income (કૌટુંબિક આવક)
                                  </Index.Typography>
                                  <Index.FormControl
                                    sx={{ width: "100%" }}
                                    className="main-select"
                                  >
                                    <Index.Select
                                      className="select-drop"
                                      displayEmpty
                                      name="monthlyIncome"
                                      value={values?.monthlyIncome}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      input={<OutlinedInput />}
                                      MenuProps={MenuProps}
                                      inputProps={{
                                        "aria-label": "Without label",
                                      }}
                                    >
                                      {familyIncomeAmountList?.map((ele) => (
                                        <Index.MenuItem
                                          key={ele?.id}
                                          value={ele?.ammount}
                                        >
                                          {ele?.ammount}
                                        </Index.MenuItem>
                                      ))}
                                    </Index.Select>
                                  </Index.FormControl>
                                  {errors?.monthlyIncome &&
                                    touched?.monthlyIncome && (
                                      <p className="error-text">
                                        {errors?.monthlyIncome}
                                      </p>
                                    )}
                                </Index.Box>
                              </Index.Grid>
                              <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                <Index.Box className="lable-input-grp">
                                  <Index.Typography
                                    component="p"
                                    className="in-label"
                                  >
                                    Electric Bill amount (ઇલેક્ટ્રિક બિલની રકમ)
                                  </Index.Typography>
                                  <Index.FormControl
                                    sx={{ width: "100%" }}
                                    className="main-select"
                                  >
                                    <Index.Select
                                      className="select-drop"
                                      displayEmpty
                                      name="threeMonthBillAmount"
                                      value={values?.threeMonthBillAmount}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      input={<OutlinedInput />}
                                      MenuProps={MenuProps}
                                      inputProps={{
                                        "aria-label": "Without label",
                                      }}
                                    >
                                      {electricBillAmountList?.map((ele) => (
                                        <Index.MenuItem
                                          key={ele?.id}
                                          value={ele?.ammount}
                                        >
                                          {ele?.ammount}
                                        </Index.MenuItem>
                                      ))}
                                    </Index.Select>
                                  </Index.FormControl>
                                  {errors?.threeMonthBillAmount &&
                                    touched?.threeMonthBillAmount && (
                                      <p className="error-text">
                                        {errors?.threeMonthBillAmount}
                                      </p>
                                    )}
                                </Index.Box>
                              </Index.Grid>
                            </Index.Grid>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="main-in-row row-bank">
                          <Index.Box className="user-basic">
                            <Index.Typography
                              component="p"
                              className="head-apply"
                            >
                              Bank Details (બેંક વિગતો)
                            </Index.Typography>
                          </Index.Box>
                          <Index.Box
                            sx={{ flexGrow: 1 }}
                            className="al-form-groups fam-in-grp bank-in-grp"
                          >
                            <Index.Grid container spacing={2}>                             
                              <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                <Index.Box className="lable-input-grp">
                                  <Index.Typography
                                    component="p"
                                    className="in-label"
                                  >
                                    Bank Name* (બેંકનું નામ)*
                                  </Index.Typography>
                                  <Index.TextField
                                    inputProps={ariaLabel}
                                    className="basic-input input-width"
                                    name="bankName"
                                    value={values?.bankName}
                                    onChange={(e) => {
                                      if (e.target.value.length <= 20) {
                                        const uppercaseValue =
                                          e.target.value.toUpperCase();
                                        handleChange(e.target.name)(
                                          uppercaseValue
                                        );
                                      }
                                    }}
                                    onBlur={handleBlur}
                                  />
                                </Index.Box>
                                {errors?.bankName && touched?.bankName && (
                                  <p className="error-text">
                                    {errors?.bankName}
                                  </p>
                                )}
                              </Index.Grid>
                              <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                <Index.Box className="lable-input-grp">
                                  <Index.Typography
                                    component="p"
                                    className="in-label"
                                  >
                                    Branch* (શાખા)*
                                  </Index.Typography>
                                  <Index.TextField
                                    inputProps={ariaLabel}
                                    className="basic-input input-width"
                                    name="branch"
                                    value={values?.branch}
                                    onChange={(e) => {
                                      if (e.target.value.length <= 25) {
                                        const uppercaseValue =
                                          e.target.value.toUpperCase();
                                        handleChange(e.target.name)(
                                          uppercaseValue
                                        );
                                      }
                                    }}
                                    onBlur={handleBlur}
                                  />
                                </Index.Box>
                                {errors?.branch && touched?.branch && (
                                  <p className="error-text">{errors?.branch}</p>
                                )}
                              </Index.Grid>
                              <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                <Index.Box className="lable-input-grp">
                                  <Index.Typography
                                    component="p"
                                    className="in-label"
                                  >
                                    Account Holder Name* (એકાઉન્ટ ધારકનું નામ)*
                                  </Index.Typography>
                                  <Index.TextField
                                    type="text"
                                    inputProps={ariaLabel}
                                    className="basic-input input-width"
                                    name="HolderName"
                                    value={values?.HolderName}
                                    onChange={(e) => {
                                      // const uppercaseValue =
                                      //   e.target.value.toUpperCase();

                                      // handleChange(e.target.name)(
                                      //   uppercaseValue
                                      // );
                                      if (e.target.value.length <= 25) {
                                        let uppercaseValue =
                                          e.target.value.toUpperCase();
                                        if (uppercaseValue.length > 21) {
                                          uppercaseValue = uppercaseValue.slice(
                                            0,
                                            21
                                          );
                                        }
                                        handleChange(e);
                                        setFieldValue(
                                          "HolderName",
                                          uppercaseValue
                                        );
                                      }
                                    }}
                                    onBlur={handleBlur}
                                  />
                                </Index.Box>
                                {errors?.HolderName && touched?.HolderName && (
                                  <p className="error-text">
                                    {errors?.HolderName}
                                  </p>
                                )}
                              </Index.Grid>
                              <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                <Index.Box className="lable-input-grp">
                                  <Index.Typography
                                    component="p"
                                    className="in-label"
                                  >
                                    Account Number (એકાઉન્ટ નંબર)
                                  </Index.Typography>
                                  <Index.TextField
                                    type="string"
                                    inputProps={ariaLabel}
                                    className="basic-input input-width"
                                    name="AccountNo"
                                    value={values?.AccountNo}
                                    onChange={(e) => {
                                      const inputValue = e.target.value.replace(
                                        /\D/g,
                                        ""
                                      ); // Remove non-numeric characters
                                      setFieldValue(
                                        "AccountNo",
                                        inputValue.slice(0, 18)
                                      );
                                    }}
                                    onBlur={handleBlur}
                                  />
                                </Index.Box>
                                {errors?.AccountNo && touched?.AccountNo && (
                                  <p className="error-text">
                                    {errors?.AccountNo}
                                  </p>
                                )}
                              </Index.Grid>
                              <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                <Index.Box className="lable-input-grp">
                                  <Index.Typography
                                    component="p"
                                    className="in-label"
                                  >
                                    IFSC Code* (IFSC કોડ)*
                                  </Index.Typography>
                                  <Index.TextField
                                    inputProps={ariaLabel}
                                    className="basic-input input-width"
                                    name="IFSCCode"
                                    value={values?.IFSCCode}
                                    onChange={(e) => {
                                      const uppercaseValue =
                                        e.target.value.toUpperCase();
                                      handleChange(e.target.name)(
                                        uppercaseValue
                                      );
                                    }}
                                    onBlur={handleBlur}
                                  />
                                </Index.Box>
                                {errors?.IFSCCode && touched?.IFSCCode && (
                                  <p className="error-text">
                                    {errors?.IFSCCode}
                                  </p>
                                )}
                              </Index.Grid>
                        
                            </Index.Grid>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box className="main-in-row row-bank">
                          <Index.Box
                            sx={{ flexGrow: 1, marginTop: "20px" }}
                            className="al-form-groups fam-in-grp bank-in-grp"
                          >
                            <Index.Grid container spacing={2}>
                              {getSingleSubSahay?.SahayName !==
                                "Medicine Sahay" &&
                              getSingleSubSahay?.SahayName !==
                                "Medical Sahay" &&
                              sahayTypecondition?.Sahay_category !==
                                "Samaj Utkars Sahay" &&
                              sahayTypecondition?.Sahay_category !==
                                "Education Sahay" ? (
                                <>
                                  {/* <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        Mediclaim Policy Amount
                                      </Index.Typography>
                                      <Index.TextField
                                        inputProps={ariaLabel}
                                        className="basic-input input-width"
                                        name="mediclaimAmmount"
                                        value={values?.mediclaimAmmount}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                    </Index.Box>

                                    {errors?.mediclaimAmmount &&
                                      touched?.mediclaimAmmount && (
                                        <p className="error-text">
                                          {errors?.mediclaimAmmount}
                                        </p>
                                      )}
                                  </Index.Grid> */}
                                </>
                              ) : null}

                              {getSingleSubSahay?.SahayName !==
                                "Medical Sahay" &&
                                getSingleSubSahay?.SahayName !==
                                  "Rental Sahay" &&
                                values?.isSahay == "yes" && (
                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        Sahay Amount (સહાય રકમ)
                                      </Index.Typography>
                                      <Index.TextField
                                        type="number"
                                        inputProps={ariaLabel}
                                        className="basic-input input-width"
                                        name="SahayAmmount"
                                        value={values?.SahayAmmount}
                                        onChange={handleChange}
                                        // onBlur={handleBlur}
                                      />
                                    </Index.Box>
                                    {/* {errors?.SahayAmmount &&
                                    touched?.SahayAmmount && (
                                      <p className="error-text">
                                        {errors?.SahayAmmount}
                                      </p>
                                    )} */}
                                  </Index.Grid>
                                )}
                    
                            </Index.Grid>
                          </Index.Box>

                          {/* <Index.Box
                            sx={{ flexGrow: 1 }}
                            className="al-form-groups fam-in-grp mb-0px-sahay"
                          >
                            <Index.Grid container spacing={2}>
                              <Index.Grid item xs={12} sm={8} md={8} lg={8}>
                                <Index.Box className="lable-input-grp">
                                  <Index.Typography
                                    component="p"
                                    className="in-label"
                                  >
                                    Remark
                                  </Index.Typography>
                                  <Index.TextField
                                    inputProps={ariaLabel}
                                    className="basic-input input-width"
                                    name="remark"
                                    value={values?.remark}
                                    onChange={(event) => {
                                      const inputValue = event.target.value;
                                      if (inputValue.length <= 150) {
                                        // Only update the value if it's not more than 30 characters
                                        handleChange(event);
                                      }
                                    }}
                                    // onBlur={handleBlur}
                                  />
                                </Index.Box>
                              </Index.Grid>
                            </Index.Grid>
                          </Index.Box> */}

                          <Index.Box
                            sx={{ flexGrow: 1 }}
                            className="al-form-groups fam-in-grp mb-0px-sahay"
                          >
                            <Index.Grid container spacing={2}>
                            <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                <Index.Box className="lable-input-grp">
                                  <Index.Typography
                                    component="p"
                                    className="in-label"
                                  >
                                    {/* Total Claimed Sahay Amount */}
                                    Total Requested Sahay Amount(કુલ વિનંતી કરેલ
                                    સહાય રકમ)
                                  </Index.Typography>
                                  <Index.TextField
                                    type="number"
                                    inputProps={ariaLabel}
                                    className="basic-input input-width"
                                    name="claimedAmmount"
                                    // disabled={
                                    //   getSingleSubSahay?.SahayName ==
                                    //   "Mediclaim Premium Sahay"
                                    //     ? true
                                    //     : false
                                    // }
                                    value={values?.claimedAmmount}
                                    onChange={(e) => {
                                      if (e.target.value.length <= 7) {
                                        // Only update the value if it's not more than 40 characters
                                        handleChange(e);
                                      }
                                    }}
                                    onBlur={handleBlur}
                                  />
                                </Index.Box>
                                {errors?.claimedAmmount &&
                                  touched?.claimedAmmount && (
                                    <p className="error-text">
                                      {errors?.claimedAmmount}
                                    </p>
                                  )}
                              </Index.Grid>
                              <Index.Grid item xs={12} sm={8} md={8} lg={8}>
                                <Index.Box className="lable-input-grp">
                                  <Index.Typography
                                    component="p"
                                    className="in-label"
                                  >
                                    Remark (ટિપ્પણી)
                                  </Index.Typography>
                                  {/* <Index.TextField
                                        inputProps={ariaLabel}
                                        className="basic-input input-width"
                                        name="remark"
                                        value={values?.remark}
                                        onChange={handleChange}
                                        // onBlur={handleBlur}
                                      /> */}
                                  <Index.Textarea
                                    style={{
                                      fontSize: "15px",
                                      color: "black",
                                      padding: "4px",
                                      minHeight: "50px",
                                      width: "100%",
                                    }}
                                    minRows={1}
                                    inputProps={ariaLabel}
                                    className="basic-input input-width"
                                    name="remark"
                                    value={values?.remark}
                                    onChange={handleChange}
                                    // onBlur={handleBlur}
                                  />
                                </Index.Box>
                              </Index.Grid>
                       
                            </Index.Grid>
                          </Index.Box>

                          <Index.Box className="main-in-row">
                                                        <Index.Box className="user-basic">
                                                          <Index.Typography
                                                            component="p"
                                                            className="head-apply"
                                                          >
                                                            Reference / Guarantor (સંદર્ભ / બાંયધરી
                                                              આપનાર)
                                                          </Index.Typography>
                                                        </Index.Box>
                                                        <Index.Box
                                                          sx={{ flexGrow: 1 }}
                                                          className="al-form-groups family-detail"
                                                        >
                                                          <Index.Grid container spacing={2}>
                                              <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                                                        <Index.Box className="lable-input-grp">
                                                                          <Index.Typography
                                                                            component="p"
                                                                            className="in-label"
                                                                          >
                                                                            Mobile Number (મોબાઈલ નંબર)
                                                                          </Index.Typography>
                                                                          <Index.Box className="sahay-multiple-input-main">
                                                                            <Index.TextField
                                                                              type="number"
                                                                              inputProps={ariaLabel}
                                                                              className="basic-input input-width"
                                                                              name="patanMemberMobile"
                                                                              value={values?.patanMemberMobile}
                                                                              onChange={(e) => {
                                                                                const valueData = e.target.value?.slice(
                                                                                  0,
                                                                                  10
                                                                                );
                                                                                setFieldValue(
                                                                                  "patanMemberMobile",
                                                                                  valueData
                                                                                );
                                                                                if (valueData?.length == 10) {
                                                                                  const familyData =
                                                                                    familyListWithArea?.find(
                                                                                      (family) =>
                                                                                        family?.mem_mobile == valueData
                                                                                    );
                                        
                                                                                  setFieldValue(
                                                                                    "patanMandalMember",
                                                                                    familyData?.mem_id ? familyData?.mem_id : ""
                                                                                  );
                                                                                }
                                                                              }}
                                                                              onBlur={handleBlur}
                                                                            />
                                                                          </Index.Box>
                                        
                                                                          {errors?.patanMemberMobile &&
                                                                            touched?.patanMemberMobile && (
                                                                              <p className="error-text">
                                                                                {errors?.patanMemberMobile}
                                                                              </p>
                                                                            )}
                                                                        </Index.Box>
                                                                      </Index.Grid>
                                                                      <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                                                        <Index.Box className="lable-input-grp">
                                                                          <Index.Typography
                                                                            component="p"
                                                                            className="in-label"
                                                                          >
                                                                            FID (કૌટુંબિક આઈડી)
                                                                          </Index.Typography>
                                                                          <Index.Box className="sahay-multiple-input-main">
                                                                            <Index.TextField
                                                                              inputProps={ariaLabel}
                                                                              className="basic-input input-width"
                                                                              name="patanMandalMember"
                                                                              value={
                                                                                familyListWithArea?.find(
                                                                                  (row) =>
                                                                                    row?.mem_mobile ==
                                                                                    values?.patanMemberMobile
                                                                                ) ? familyListWithArea?.find(
                                                                                  (row) =>
                                                                                    row?.mem_mobile ==
                                                                                    values?.patanMemberMobile
                                                                                )?.mem_family_id
                                                                                : ""
                                                                              }
                                                                         disabled
                                                                            />
                                                                          </Index.Box>
                                        
                                                                      
                                                                        </Index.Box>
                                                                      </Index.Grid>
                                                                      <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                                                        <Index.Box className="lable-input-grp">
                                                                          <Index.Typography
                                                                            component="p"
                                                                            className="in-label"
                                                                          >
                                                                            Reference / Guarantor (સંદર્ભ / બાંયધરી
                                                                            આપનાર)
                                                                          </Index.Typography>
                                                                          <Index.Box className="sahay-multiple-input-main">
                                                                            <Index.TextField
                                                                              className="basic-input input-width"
                                                                              value={
                                                                                familyListWithArea?.find(
                                                                                  (row) =>
                                                                                    row?.mem_mobile ==
                                                                                    values?.patanMemberMobile
                                                                                )
                                                                                  ? `${
                                                                                      familyListWithArea.find(
                                                                                        (row) =>
                                                                                          row?.mem_mobile ==
                                                                                          values?.patanMemberMobile
                                                                                      )?.mem_fname
                                                                                    } ${
                                                                                      familyListWithArea.find(
                                                                                        (row) =>
                                                                                          row?.mem_mobile ==
                                                                                          values?.patanMemberMobile
                                                                                      )?.mem_mname
                                                                                    } ${
                                                                                      familyListWithArea.find(
                                                                                        (row) =>
                                                                                          row?.mem_mobile ==
                                                                                          values?.patanMemberMobile
                                                                                      )?.mem_lname
                                                                                    }`
                                                                                  : values?.patanReferenceName
                                                                              }
                                                                              name = "patanReferenceName"
                                                                              onChange={(e)=> {setFieldValue("patanReferenceName", e.target.value)}}
                                                                              inputProps={{
                                                                                ...ariaLabel,
                                                                                disabled: !!familyListWithArea?.find(
                                                                                  (row) =>
                                                                                    row?.mem_mobile ===
                                                                                    values?.patanMemberMobile
                                                                                ),
                                                                              }}
                                                                            />
                                                                          </Index.Box>
                                        
                                                                          {errors?.patanMandalMember &&
                                                                            touched?.patanMandalMember && (
                                                                              <p className="error-text">
                                                                                {errors?.patanMandalMember}
                                                                              </p>
                                                                            )}
                                                                        </Index.Box>
                                                                      </Index.Grid>
                                                                   
                                                                      <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                                                        <Index.Box className="lable-input-grp">
                                                                          <Index.Typography
                                                                            component="p"
                                                                            className="in-label"
                                                                          >
                                                                            Mohalla Name (મોહલ્લાનું નામ)
                                                                          </Index.Typography>
                                                                          <Index.Box className="sahay-multiple-input-main">
                                                                            <Index.TextField
                                                                              inputProps={{
                                                                                ...ariaLabel,
                                                                                disabled: !!familyListWithArea?.find(
                                                                                  (row) =>
                                                                                    row?.mem_mobile ===
                                                                                    values?.patanMemberMobile
                                                                                ),
                                                                              }}
                                                                              className="basic-input input-width"
                                                                              name="patanReferenceMohollo"
                                                                              value={   familyListWithArea?.find(
                                                                                (row) =>
                                                                                  row?.mem_mobile ==
                                                                                  values?.patanMemberMobile
                                                                              ) ? 
                                                                                familyListWithArea?.find(
                                                                                  (row) =>
                                                                                    row?.mem_mobile ==
                                                                                    values?.patanMemberMobile
                                                                                )?.mohollo_name : values?.patanReferenceMohollo
                                                                              }
                                                                              onChange={(e)=>{
                                                                                setFieldValue("patanReferenceMohollo", e.target.value)
                                                                              }}
                                                                            />
                                                                          </Index.Box>
                                                                        </Index.Box>
                                                                      </Index.Grid>
                                        
                                                                      <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                                                        <Index.Box className="lable-input-grp">
                                                                          <Index.Typography
                                                                            component="p"
                                                                            className="in-label"
                                                                          >
                                                                            Area (વિસ્તાર)
                                                                          </Index.Typography>
                                                                          <Index.Box className="sahay-multiple-input-main">
                                                                            <Index.TextField
                                                                              className="basic-input input-width"
                                                                              name="patanReferenceArea"
                                                                              value={familyListWithArea?.find(
                                                                                (row) =>
                                                                                  row?.mem_mobile ==
                                                                                  values?.patanMemberMobile
                                                                              ) ?
                                                                                familyListWithArea?.find(
                                                                                  (row) =>
                                                                                    row?.mem_mobile ==
                                                                                    values?.patanMemberMobile
                                                                                )?.family_area : values?.patanReferenceArea
                                                                              }
                                                                              onChange={(e)=> {
                                                                                setFieldValue("patanReferenceArea", e.target.value)
                                                                              }}
                                                                              inputProps={{
                                                                                ...ariaLabel,
                                                                                disabled: !!familyListWithArea?.find(
                                                                                  (row) =>
                                                                                    row?.mem_mobile ===
                                                                                    values?.patanMemberMobile
                                                                                ),
                                                                              }}
                                                                            />
                                                                          </Index.Box>
                                                                        </Index.Box>
                                                                      </Index.Grid>
                                                                      </Index.Grid>
                                                                      </Index.Box>
                                                                      </Index.Box>
                          <Index.Grid
                            item
                            xs={12}
                            sm={4}
                            md={4}
                            lg={4}
                            className="al-form-groups fam-in-grp"
                          ></Index.Grid>

                          {/* <Index.Box
                            sx={{ flexGrow: 1 }}
                            className="al-form-groups family-detail fam-in-grp"
                          >
                            <Index.Grid container spacing={2}>
                              {getSingleSubSahay?.SahayName !==
                              "Mediclaim Premium Sahay" ? (
                                <>
                                  <Index.Grid item xs={12} sm={6} md={4} lg={4}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        Does The Applicant Take Mediclaim
                                        Premium Sahay
                                      </Index.Typography>
                                      <Index.ToggleButtonGroup
                                        color="primary"
                                        value={values?.isSahay}
                                        exclusive
                                        onChange={(e) => {
                                          setFieldValue(
                                            "isSahay",
                                            e.target.value
                                          );
                                        }}
                                        aria-label="Platform"
                                        className="toggle-switch-main"
                                      >
                                        <Index.ToggleButton value={"Yes"}>
                                          Yes
                                        </Index.ToggleButton>
                                        <Index.ToggleButton value={"No"}>
                                          No
                                        </Index.ToggleButton>
                                      </Index.ToggleButtonGroup>
                                    </Index.Box>
                                  </Index.Grid>
                                  <Index.Grid item xs={12} sm={6} md={4} lg={4}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        Does The Applicant Have A Mediclaim
                                        Policys
                                      </Index.Typography>
                                      <Index.ToggleButtonGroup
                                        color="primary"
                                        value={values?.isMediclaim}
                                        exclusive
                                        onChange={(e) => {
                                          setFieldValue(
                                            "isMediclaim",
                                            e.target.value
                                          );
                                        }}
                                        aria-label="Platform"
                                        className="toggle-switch-main"
                                      >
                                        <Index.ToggleButton value="Yes">
                                          Yes
                                        </Index.ToggleButton>
                                        <Index.ToggleButton value="No">
                                          No
                                        </Index.ToggleButton>
                                      </Index.ToggleButtonGroup>
                                    </Index.Box>
                                  </Index.Grid>
                                </>
                              ) : null}
                            </Index.Grid>
                          </Index.Box> */}

                          {/* <Index.Box
                            sx={{ flexGrow: 1 }}
                            className="al-form-groups family-detail fam-in-grp"
                          >
                            <Index.Grid container spacing={2}>
                              <Index.Grid item xs={12} sm={12} md={12} lg={4}>
                                <Index.Box className="lable-input-grp">
                                  <Index.Typography
                                    component="p"
                                    className="in-label"
                                    sx={{ fontSize: "12px" }}
                                  >
                                    Does applicant took sahay from any trust
                                  </Index.Typography>
                                  <Index.ToggleButtonGroup
                                    color="primary"
                                    value={values?.trust}
                                    exclusive
                                    onChange={(e) => {
                                      setSahayCondition(e.target.value);
                                      setFieldValue("trust", e.target.value);
                                    }}
                                    aria-label="Platform"
                                    className="toggle-switch-main"
                                  >
                                    <Index.ToggleButton value="yes">
                                      Yes
                                    </Index.ToggleButton>
                                    <Index.ToggleButton value="no">
                                      No
                                    </Index.ToggleButton>
                                  </Index.ToggleButtonGroup>
                                </Index.Box>
                              </Index.Grid>
                            </Index.Grid>
                          </Index.Box> */}

                          {/* {sahayCondition == "yes" ? (
                            <>
                              <Index.Box
                                sx={{ flexGrow: 1 }}
                                className="al-form-groups fam-in-grp"
                              >
                                <Index.Grid container spacing={2}>
                                 
                                  <Index.Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                  >
                                    <PagesIndex.FieldArray name="dataArray">
                                      {({ push, remove }) => (
                                        <div className="trust-main-div">
                                          <Index.Box className="common-button  flex-end save-btn flex-start-admin">
                                            <Index.Button
                                              variant="contained"
                                              className="comman-btn-add-details"
                                              onClick={() =>
                                                push({
                                                  trustName: "",
                                                  ammount: 0,
                                                })
                                              }
                                            >
                                              <Index.ControlPointIcon className="plus-add" />{" "}
                                              Add Trust
                                            </Index.Button>
                                          </Index.Box>

                                          {values?.dataArray?.map(
                                            (data, index) => (
                                              <Index.Grid
                                                container
                                                spacing={2}
                                                key={index}
                                                mb={2}
                                              >
                                                <Index.Grid
                                                  item
                                                  xs={12}
                                                  sm={4}
                                                  md={4}
                                                  lg={4}
                                                >
                                                  <Index.Box className="lable-input-grp">
                                                    <Index.Typography
                                                      component="p"
                                                      className="in-label"
                                                    >
                                                      Trust Name
                                                    </Index.Typography>
                                                    <Index.TextField
                                                      inputProps={ariaLabel}
                                                      className="basic-input input-width"
                                                      onBlur={handleBlur}
                                                      name={`dataArray.${index}.trustName`}
                                                      onChange={handleChange}
                                                      value={
                                                        values.dataArray[index]
                                                          .trustName
                                                      }
                                                    />
                                                  </Index.Box>
                                                  {errors?.trustName &&
                                                    touched?.trustName && (
                                                      <p className="error-text">
                                                        {errors?.trustName}
                                                      </p>
                                                    )}
                                                </Index.Grid>
                                                <Index.Grid
                                                  item
                                                  xs={12}
                                                  sm={4}
                                                  md={4}
                                                  lg={4}
                                                >
                                                  <Index.Box className="lable-input-grp">
                                                    <Index.Typography
                                                      component="p"
                                                      className="in-label"
                                                    >
                                                      Amount
                                                    </Index.Typography>
                                                    <Index.TextField
                                                      type="number"
                                                      inputProps={ariaLabel}
                                                      className="basic-input input-width"
                                                      onBlur={handleBlur}
                                                      name={`dataArray.${index}.ammount`}
                                                      onChange={handleChange}
                                                      value={
                                                        values.dataArray[index]
                                                          .ammount
                                                      }
                                                    />
                                                  </Index.Box>
                                                  {errors?.ammount &&
                                                    touched?.ammount && (
                                                      <p className="error-text">
                                                        {errors?.ammount}
                                                      </p>
                                                    )}
                                                </Index.Grid>

                                                {index > 0 && (
                                                  <Index.Grid
                                                    item
                                                    xs={12}
                                                    sm={4}
                                                    md={2}
                                                    lg={2}
                                                  >
                                                    <Index.Box className="common-button  ">
                                                      <Index.Button
                                                        variant="contained"
                                                        className="remove-details-content"
                                                        onClick={() =>
                                                          remove(index)
                                                        }
                                                      >
                                                        <RemoveIcon />
                                                      </Index.Button>
                                                    </Index.Box>{" "}
                                                  </Index.Grid>
                                                )}
                                              </Index.Grid>
                                            )
                                          )}
                                        </div>
                                      )}
                                    </PagesIndex.FieldArray>
                                  </Index.Grid>

                                 
                                </Index.Grid>
                              </Index.Box>
                            </>
                          ) : null} */}
                          <Index.Box
                            sx={{ flexGrow: 1 }}
                            className="al-form-groups fam-in-grp mb-0px-sahay"
                          >
                            <Index.Grid container spacing={2}>
                              <Index.Grid item xs={12} sm={6} md={4} lg={4}>
                                <Index.Box className="lable-input-grp ">
                                  <Index.Typography
                                    component="p"
                                    className="in-label"
                                  >
                                    Passport Size Photo* (પાસપોર્ટ સાઈઝ ફોટો)*
                                  </Index.Typography>
                                  <Index.Box className="select-position">
                                    <input
                                      onBlur={handleBlur}
                                      type="file"
                                      name="passportPic"
                                      className="ori-input"
                                      onChange={(e) => {
                                        setFieldValue(
                                          "passportPic",
                                          e.target?.files?.[0]
                                        );
                                        setSelectedImage(e.target?.files?.[0]);
                                        e.target.value = null;
                                      }}
                                    />

                                    <Index.Box className="select-file">
                                      Upload Your Photo
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>

                                {errors?.passportPic &&
                                  touched?.passportPic && (
                                    <p className="error-text">
                                      {errors?.passportPic}
                                    </p>
                                  )}
                              </Index.Grid>

                              <Index.Grid item xs={12} sm={6} md={4} lg={4}>
                                <Index.Box className="lable-input-grp ">
                                  <Index.Box className="select-view">
                                    <img
                                      src={
                                        select̥edImage
                                          ? URL.createObjectURL(select̥edImage)
                                          : "https://cdn.landesa.org/wp-content/uploads/default-user-image.png"
                                      }
                                    />
                                    <Index.Box
                                      className="select-remove"
                                      onClick={() => resetImage(setFieldValue)}
                                    >
                                      Remove
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Grid>
                            </Index.Grid>
                          </Index.Box>
                          {/* {errors?.passportPic && touched?.passportPic && (
                            <p className="error-text">{errors?.passportPic}</p>
                          )} */}
                          {/* <Index.Box
                            sx={{ flexGrow: 1 }}
                            className="al-form-groups fam-in-grp multiple-doc mt-0px-sahay{"
                          >
                            <Index.Grid container spacing={2}>
                              <Index.Grid
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                lg={4}
                                mb={2}
                              >
                                <Index.Box className="lable-input-grp">
                                  <Index.Typography
                                    component="p"
                                    className="in-label"
                                  >
                                    Add Multiple Document*
                                  </Index.Typography>
                                  <Index.Box className="select-position">
                                    <input
                                      type="file"
                                      name="documents"
                                      multiple
                                      className="ori-input"
                                      onChange={(event) => {
                                        const files = event.target.files;
                                        setFieldValue("documents", [
                                          ...values.documents,
                                          ...files,
                                        ]);
                                      }}
                                    />

                                    <Index.Box className="select-file">
                                      Upload Your Document
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                                {errors?.documents && touched?.documents && (
                                  <p className="error-text">
                                    {errors?.documents}
                                  </p>
                                )}
                                
                              </Index.Grid>
                              <Index.Grid item xs={8}>
                                <Index.Box className="select-view-document-name">
                                  {values.documents.map((file, index) => (
                                    <Index.Box
                                      className="document-name"
                                      key={index}
                                    >
                                      <Index.Typography
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          // Implement the logic to view the document here
                                          // You can use a modal or a new browser tab/window to show the document
                                          window.open(
                                            URL.createObjectURL(file)
                                          );
                                        }}
                                      >
                                        {file.name}
                                      </Index.Typography>
                                      <Index.IconButton
                                        onClick={() => {
                                          const updatedDocuments =
                                            values.documents.filter(
                                              (_, i) => i !== index
                                            );
                                          setFieldValue(
                                            "documents",
                                            updatedDocuments
                                          );
                                        }}
                                      >
                                        <Index.ClearIcon className="muiico-cancel" />
                                      </Index.IconButton>
                                    </Index.Box>
                                  ))}
                                </Index.Box>
                              </Index.Grid>
                            </Index.Grid>
                          </Index.Box> */}

                          {/* <Index.Box
                            sx={{ flexGrow: 1 }}
                            className="al-form-groups fam-in-grp multiple-doc mt-0px-sahay{"
                          >
                            <Index.Grid container spacing={2}>
                              <Index.Grid item xs={12}>
                                <Index.Box className="lable-input-grp">
                                  <Index.Typography
                                    component="p"
                                    className="in-label"
                                  >
                                    Add Multiple Document*
                                  </Index.Typography>
                                  <Index.Box
                                    display="grid"
                                    className="display-row-add-user"
                                    gridTemplateColumns="repeat(12, 1fr)"
                                    gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                                  >
                                    {values?.documents?.length > 0 && (
                                      <FieldArray name="documentData">
                                        {({ push, remove }) => (
                                          <>
                                            {values?.documents.map(
                                              (document, index) => (
                                                <Index.Box
                                                  gridColumn={{
                                                    xs: "span 3",
                                                    sm: "span 3",
                                                    md: "span 3",
                                                    lg: "span 3",
                                                    xl: "span 3",
                                                  }}
                                                  className="grid-column"
                                                  key={document.documentType}
                                                >
                                                  <Index.Box className="roles-data-box add-check-flex-doc">
                                                    <Index.Box className="add-role-flex">
                                                      <Index.Checkbox
                                                        className="check-box-role"
                                                        checked={
                                                          document?.isUpload
                                                        }
                                                        onChange={() => {
                                                          setFieldValue(
                                                            `documents[${index}].isUpload`,
                                                            !document.isUpload
                                                          );
                                                          setFieldValue(
                                                            `documents[${index}].file`,
                                                            ""
                                                          );
                                                        }}

                                                        // name={`${subSahay.subSahayName}-${document.documentType}`}
                                                      />
                                                      <Index.Box className="role-data-title">
                                                        {document.documentType}
                                                      </Index.Box>
                                                    </Index.Box>
                                                  </Index.Box>
                                                  {document?.isUpload && (
                                                    <>
                                                      <Index.Box className="select-position">
                                                        <input
                                                          type="file"
                                                          name="documents"
                                                          // multiple
                                                          className="ori-input"
                                                          onChange={(event) => {
                                                            const files =
                                                              event.target
                                                                .files[0];
                                                            setFieldValue(
                                                              `documents[${index}].file`,
                                                              files
                                                            );
                                                          }}
                                                          onBlur={(event) => {
                                                            if (
                                                              values.documents[
                                                                index
                                                              ].isUpload
                                                            ) {
                                                              handleBlur(event);
                                                            }
                                                          }}
                                                        />

                                                        <Index.Box className="select-file">
                                                          Upload Your Document
                                                        </Index.Box>

                                                        {errors?.documents?.[
                                                          index
                                                        ]?.file && (
                                                          <p className="error-text">
                                                            {
                                                              errors
                                                                ?.documents?.[
                                                                index
                                                              ]?.file
                                                            }
                                                          </p>
                                                        )}
                                                      </Index.Box>
                                                      {values.documents[index]
                                                        .file && (
                                                        <Index.Box className="select-view-document-name mt-select-doc">
                                                          <Index.Box className="document-name">
                                                            <Index.Typography
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              onClick={() => {
                                                                window.open(
                                                                  values
                                                                    .documents[
                                                                    index
                                                                  ]
                                                                    .file instanceof
                                                                    Blob
                                                                    ? URL.createObjectURL(
                                                                        values
                                                                          .documents[
                                                                          index
                                                                        ].file
                                                                      )
                                                                    : `${imagePath}${values.documents[index].file}`
                                                                );
                                                              }}
                                                            >
                                                              {values.documents[
                                                                index
                                                              ].file instanceof
                                                              Blob
                                                                ? values
                                                                    .documents[
                                                                    index
                                                                  ].file.name
                                                                : values
                                                                    .documents[
                                                                    index
                                                                  ].file}
                                                            </Index.Typography>
                                                            <Index.IconButton
                                                              onClick={() => {
                                                                setFieldValue(
                                                                  `documents[${index}].file`,
                                                                  ""
                                                                );
                                                              }}
                                                            >
                                                              <Index.ClearIcon className="muiico-cancel" />
                                                            </Index.IconButton>
                                                          </Index.Box>
                                                        </Index.Box>
                                                      )}
                                                    </>
                                                  )}
                                                </Index.Box>
                                              )
                                            )}
                                          </>
                                        )}
                                      </FieldArray>
                                    )}
                                  </Index.Box>
                                </Index.Box>
                              </Index.Grid>
                            </Index.Grid>
                          </Index.Box> */}

                          <Index.Box
                            sx={{ flexGrow: 1 }}
                            className="al-form-groups fam-in-grp multiple-doc mt-0px-sahay{"
                          >
                            <Index.Grid container spacing={2}>
                              <Index.Grid item xs={12}>
                                <Index.Box className="lable-input-grp">
                                  <Index.Typography
                                    component="p"
                                    className="in-label"
                                  >
                                    Add Multiple Document* (બહુવિધ દસ્તાવેજ
                                    ઉમેરો)*
                                  </Index.Typography>
                                  <Index.Box
                                    display="grid"
                                    className="display-row-add-user"
                                    gridTemplateColumns="repeat(12, 1fr)"
                                    gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                                  >
                                    {values?.documents?.length > 0 && (
                                      <FieldArray name="documentData">
                                        {({ push, remove }) => (
                                          <>
                                            {values?.documents.map(
                                              (document, index) => (
                                                <Index.Box
                                                  gridColumn={{
                                                    xs: "span 3",
                                                    sm: "span 3",
                                                    md: "span 3",
                                                    lg: "span 3",
                                                    xl: "span 3",
                                                  }}
                                                  className="grid-column"
                                                  key={document.documentType}
                                                >
                                                  <Index.Box className="roles-data-box add-check-flex-doc">
                                                    <Index.Box className="add-role-flex">
                                                      <Index.Checkbox
                                                        className="check-box-role"
                                                        checked={
                                                          document?.isUpload
                                                        }
                                                        onChange={() => {
                                                          setFieldValue(
                                                            `documents[${index}].isUpload`,
                                                            !document.isUpload
                                                          );
                                                          setFieldValue(
                                                            `documents[${index}].file`,
                                                            []
                                                          );
                                                        }}

                                                        // name={`${subSahay.subSahayName}-${document.documentType}`}
                                                      />
                                                      <Index.Box className="role-data-title">
                                                        {document.documentType}
                                                      </Index.Box>
                                                    </Index.Box>
                                                  </Index.Box>

                                                  {document?.isUpload && (
                                                    <>
                                                      <Index.Box className="select-position">
                                                        <input
                                                          multiple
                                                          type="file"
                                                          name="documents"
                                                          // multiple
                                                          className="ori-input"
                                                          onChange={(event) => {
                                                            const files =
                                                              event.target
                                                                .files;
                                                            const allDocuments =
                                                              [
                                                                ...values
                                                                  .documents[
                                                                  index
                                                                ].file,
                                                                ...files,
                                                              ]; // Concatenating existing files with newly selected files

                                                            setFieldValue(
                                                              `documents[${index}].file`,
                                                              allDocuments
                                                            );
                                                          }}
                                                          onBlur={(event) => {
                                                            if (
                                                              values.documents[
                                                                index
                                                              ].isUpload
                                                            ) {
                                                              handleBlur(event);
                                                            }
                                                          }}
                                                        />

                                                        <Index.Box className="select-file">
                                                          Upload Your Document
                                                        </Index.Box>

                                                        {errors?.documents?.[
                                                          index
                                                        ]?.file && (
                                                          <p className="error-text">
                                                            {
                                                              errors
                                                                ?.documents?.[
                                                                index
                                                              ]?.file
                                                            }
                                                          </p>
                                                        )}
                                                      </Index.Box>

                                                      {document.file?.length >
                                                        0 &&
                                                        document?.file?.map(
                                                          (item, fileIndex) => {
                                                            return (
                                                              <Index.Box className="select-view-document-name mt-select-doc">
                                                                <Index.Box className="document-name">
                                                                  <Index.Typography
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                    }}
                                                                    onClick={() => {
                                                                      window.open(
                                                                        item instanceof
                                                                          Blob
                                                                          ? URL.createObjectURL(
                                                                              item
                                                                            )
                                                                          : // : `${imagePath}${values.documents[index].filePath}`
                                                                            `${imagePath}${item?.filename}`
                                                                      );
                                                                    }}
                                                                  >
                                                                    {item instanceof
                                                                    Blob
                                                                      ? item.name
                                                                      : item?.document}
                                                                    {/* // : values
                                                                          //     .documents[
                                                                          //     index
                                                                          //   ]
                                                                          //     .file} */}
                                                                  </Index.Typography>
                                                                  <Index.IconButton
                                                                    onClick={() => {
                                                                      const updatedFiles =
                                                                        document.file?.filter(
                                                                          (
                                                                            ele,
                                                                            i
                                                                          ) =>
                                                                            i !==
                                                                            fileIndex
                                                                        );
                                                                      setFieldValue(
                                                                        `documents[${index}].file`,
                                                                        updatedFiles
                                                                      );
                                                                    }}
                                                                  >
                                                                    <Index.ClearIcon className="muiico-cancel" />
                                                                  </Index.IconButton>
                                                                </Index.Box>
                                                              </Index.Box>
                                                            );
                                                          }
                                                        )}
                                                    </>
                                                  )}
                                                </Index.Box>
                                              )
                                            )}
                                          </>
                                        )}
                                      </FieldArray>
                                    )}
                                  </Index.Box>
                                </Index.Box>
                              </Index.Grid>
                            </Index.Grid>
                          </Index.Box>

                          <Index.Box className="field-table">
                            <Index.Grid>
                              <Index.Box className="">
                                <Index.TableContainer
                                  component={Index.Paper}
                                  className="table-container edit-drop-container"
                                >
                                  <Index.Table
                                    aria-label="simple table"
                                    className="table-design-main barge-table edit-drop-table"
                                  >
                                    <Index.TableHead className="edit-drop-head">
                                      <Index.TableRow>
                                        <Index.TableCell align="left">
                                          Name (નામ)
                                        </Index.TableCell>
                                        <Index.TableCell align="left">
                                          Relation (સંબંધ)
                                        </Index.TableCell>
                                        <Index.TableCell align="left">
                                          Age (ઉંમર)
                                        </Index.TableCell>
                                        <Index.TableCell align="left">
                                          Working Type (કામનો પ્રકાર)
                                        </Index.TableCell>
                                        {/* <Index.TableCell align="left">
                                          Monthly Income
                                        </Index.TableCell> */}
                                      </Index.TableRow>
                                    </Index.TableHead>
                                    <Index.TableBody>
                                      <Index.TableRow>
                                        <Index.TableCell
                                          align="left"
                                          className="tablecell"
                                        >
                                          <Index.TextField
                                            placeholder="Name"
                                            small
                                            disabled
                                            inputProps={ariaLabel}
                                            className="tablecell-field"
                                            value={
                                              applicantSelectedMember[0]
                                                ? applicantSelectedMember[0]
                                                    ?.firstName +
                                                  " " +
                                                  applicantSelectedMember[0]
                                                    ?.middleName +
                                                  " " +
                                                  applicantSelectedMember[0]
                                                    ?.lastName
                                                : ""
                                            }
                                          />
                                        </Index.TableCell>
                                        <Index.TableCell
                                          align="left"
                                          className="tablecell"
                                        >
                                          <Index.TextField
                                            placeholder="Relation"
                                            small
                                            inputProps={ariaLabel}
                                            className="tablecell-field"
                                            disabled
                                            value={
                                              applicantSelectedMember[0]
                                                ?.relation
                                                ? "Applicant"
                                                : ""
                                            }
                                          />
                                        </Index.TableCell>

                                        <Index.TableCell
                                          align="left"
                                          className="tablecell"
                                        >
                                          <Index.TextField
                                            placeholder="Age"
                                            small
                                            disabled
                                            inputProps={ariaLabel}
                                            className="tablecell-field"
                                            value={
                                              applicantSelectedMember[0]?.age
                                            }
                                          />
                                        </Index.TableCell>
                                        <Index.TableCell
                                          align="left"
                                          className="tablecell"
                                        >
                                          <Index.TextField
                                            small
                                            inputProps={ariaLabel}
                                            name="workingType"
                                            className="tablecell-field"
                                            onChange={handleChange}
                                            value={
                                              // applicantSelectedMember[0]
                                              //   ?.workingType
                                              values?.workingType
                                            }
                                            disabled
                                            placeholder="Job"
                                          />
                                        </Index.TableCell>
                                        {/* <Index.TableCell
                                          align="left"
                                          className="tablecell"
                                        >
                                          <Index.TextField
                                            small
                                            disabled
                                            name="monthlyIncome"
                                            inputProps={ariaLabel}
                                            className="tablecell-field"
                                            // value={
                                            //   applicantSelectedMember[0]
                                            //     ?.AnnualIncome
                                            // }
                                            value={values?.monthlyIncome}
                                            onChange={handleChange}
                                            placeholder="Monthly Income"
                                          />
                                        </Index.TableCell> */}
                                      </Index.TableRow>

                                      <PagesIndex.FieldArray name="relationRecords">
                                        {({ push, remove }) => (
                                          <>
                                            <>
                                              {values?.relationRecords?.map(
                                                (data, index) => (
                                                  <Index.TableRow>
                                                    <Index.TableCell
                                                      align="left"
                                                      className="tablecell"
                                                    >
                                                      <Index.TextField
                                                        placeholder="Name"
                                                        small
                                                        inputProps={ariaLabel}
                                                        name={`relationRecords.${index}.firstName`}
                                                        className="tablecell-field"
                                                        onChange={handleChange}
                                                        value={
                                                          values
                                                            .relationRecords[
                                                            index
                                                          ].firstName +
                                                          " " +
                                                          values
                                                            .relationRecords[
                                                            index
                                                          ].middleName +
                                                          " " +
                                                          values
                                                            .relationRecords[
                                                            index
                                                          ].lastName
                                                        }
                                                      />
                                                    </Index.TableCell>
                                                    <Index.TableCell
                                                      align="left"
                                                      className="tablecell"
                                                    >
                                                      <Index.Box className="input-design-div with-border max-content-ellipsis">
                                                        <Index.Select
                                                          hiddenLabel
                                                          fullWidth
                                                          id="hgh"
                                                          value={
                                                            values
                                                              .relationRecords[
                                                              index
                                                            ].relation_id
                                                          }
                                                          name={`relationRecords.${index}.relation_id`}
                                                          onBlur={handleBlur}
                                                          onChange={
                                                            handleChange
                                                          }
                                                          // value={
                                                          //   values
                                                          //     .relationRecords[
                                                          //     index
                                                          //   ].relation_id
                                                          // }
                                                        >
                                                          {getRelationList &&
                                                            getRelationList?.map(
                                                              (option) => (
                                                                <Index.MenuItem
                                                                  noWrap
                                                                  key={
                                                                    option.relation_id
                                                                  }
                                                                  value={
                                                                    option.relation_id
                                                                  }
                                                                  name={
                                                                    option.relation_name
                                                                  }
                                                                >
                                                                  {
                                                                    option.relation_name
                                                                  }
                                                                </Index.MenuItem>
                                                              )
                                                            )}
                                                        </Index.Select>
                                                      </Index.Box>
                                                      {/* {errors.relationRecords &&
                                                        errors.relationRecords[
                                                          index
                                                        ] &&
                                                        errors.relationRecords[
                                                          index
                                                        ].relation &&
                                                        touched &&
                                                        touched.relationRecords &&
                                                        touched.relationRecords[
                                                          index
                                                        ] &&
                                                        touched.relationRecords[
                                                          index
                                                        ].relation && (
                                                          <p className="error-text">
                                                            {
                                                              errors
                                                                .relationRecords[
                                                                index
                                                              ].relation
                                                            }
                                                          </p>
                                                        )} */}
                                                    </Index.TableCell>
                                                    {/* <Index.TableCell
                                                      align="left"
                                                      className="tablecell"
                                                    >
                                                      <Index.TextField
                                                        placeholder="Relation"
                                                        small
                                                        inputProps={ariaLabel}
                                                        className="tablecell-field"
                                                        // value={
                                                        //   values
                                                        //     .relationRecords[
                                                        //     index
                                                        //   ].relation
                                                        // }
                                                        name={`relationRecords.${index}.relation`}
                                                        onChange={handleChange}
                                                      />
                                                    </Index.TableCell> */}

                                                    <Index.TableCell
                                                      align="left"
                                                      className="tablecell"
                                                    >
                                                      <Index.TextField
                                                        placeholder="Age"
                                                        small
                                                        inputProps={ariaLabel}
                                                        className="tablecell-field"
                                                        value={
                                                          values
                                                            .relationRecords[
                                                            index
                                                          ].age
                                                        }
                                                        name={`relationRecords.${index}.age`}
                                                        onChange={handleChange}
                                                      />
                                                    </Index.TableCell>
                                                    {/* <Index.TableCell
                                                      align="left"
                                                      className="tablecell"
                                                    >
                                                      <Index.TextField
                                                        placeholder="Job"
                                                        small
                                                        inputProps={ariaLabel}
                                                        className="tablecell-field"
                                                        value={
                                                          values
                                                            .relationRecords[
                                                            index
                                                          ].workingType
                                                        }
                                                        name={`relationRecords.${index}.workingType`}
                                                        onChange={handleChange}
                                                      />
                                                    </Index.TableCell> */}
                                                    <Index.TableCell
                                                      align="left"
                                                      className="tablecell"
                                                    >
                                                      <Index.Box className="input-design-div with-border max-content-ellipsis">
                                                        <Index.Select
                                                          hiddenLabel
                                                          fullWidth
                                                          id="hghasd"
                                                          value={
                                                            values
                                                              .relationRecords[
                                                              index
                                                            ].workingType
                                                          }
                                                          name={`relationRecords.${index}.workingType`}
                                                          onChange={
                                                            handleChange
                                                          }
                                                        >
                                                          {workingTypeList?.map(
                                                            (ele) => (
                                                              <Index.MenuItem
                                                                key={ele?.id}
                                                                value={
                                                                  ele?.name
                                                                }
                                                              >
                                                                {ele?.name}
                                                              </Index.MenuItem>
                                                            )
                                                          )}
                                                        </Index.Select>
                                                      </Index.Box>
                                                    </Index.TableCell>
                                                    {/* <Index.TableCell
                                                      align="left"
                                                      className="tablecell"
                                                    >
                                                      <Index.TextField
                                                        type="number"
                                                        small
                                                        inputProps={ariaLabel}
                                                        className="tablecell-field"
                                                        placeholder="Monthly Income"
                                                        value={
                                                          values
                                                            .relationRecords[
                                                            index
                                                          ].AnnualIncome
                                                        }
                                                        name={`relationRecords.${index}.AnnualIncome`}
                                                        onChange={handleChange}
                                                      />
                                                    </Index.TableCell> */}
                                                  </Index.TableRow>
                                                )
                                              )}
                                            </>
                                          </>
                                        )}
                                      </PagesIndex.FieldArray>
                                    </Index.TableBody>
                                  </Index.Table>
                                </Index.TableContainer>
                              </Index.Box>
                            </Index.Grid>
                          </Index.Box>
                          <Index.Box
                            sx={{ flexGrow: 1 }}
                            className="al-form-groups fam-in-grp"
                          >
                            <Index.Grid container spacing={2}>
                              <Index.Grid item xs={3}>
                                <Index.Box className="lable-input-grp ">
                                  <Index.Box className="select-position">
                                    <Index.Box
                                      className={
                                        values.isSubmit === false
                                          ? "apply-now"
                                          : "disable-now"
                                      }
                                    >
                                      <button
                                        onClick={() => {
                                          setFieldValue("isDraft", 0);
                                        }}
                                        type="submit"
                                        disabled={values.isSubmit}
                                      >
                                        Save
                                      </button>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Grid>
                              <Index.Grid item xs={3}>
                                <Index.Box className="lable-input-grp ">
                                  <Index.Box className="select-position">
                                    <Index.Box
                                      className={
                                        values.isSubmit === false
                                          ? "apply-now"
                                          : "disable-now"
                                      }
                                    >
                                      <button
                                        type="submit"
                                        onClick={() => {
                                          setFieldValue("isDraft", 1);
                                        }}
                                        disabled={values?.isSubmit}
                                      >
                                        Submit
                                      </button>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Grid>
                              <Index.Grid item xs={6}>
                                <Index.Box className="common-button blue-button flex-end save-btn back-btn">
                                  <Index.Button
                                    variant="contained"
                                    onClick={() => handleBack()}
                                  >
                                    Back
                                  </Index.Button>
                                </Index.Box>
                              </Index.Grid>
                            </Index.Grid>
                          </Index.Box>
                        </Index.Box>
                      </form>
                    )}
                  </PagesIndex.Formik>

                  <Index.Box className="title-header-flex "></Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </div>
    </>
  );
};

export default ApplyForSahay;
