import React, { useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import Pagination from "../../../../components/common/Pagination";
import PagesIndex from "../../../PagesIndex";
import {
  getAllFamilyIDs,
  getReportData,
} from "../../../../redux/features/AddService";
import { useSelector } from "react-redux";
import PageLoading from "../../../../components/common/pageLoading/PageLoading";
import { Autocomplete, TableCell } from "@mui/material";
import FileExcel from "../../../../components/common/excelSheet/FileExcel";
import RowPerPageDropdown from "../../../../components/common/paginationRowPerpage/RowPerPageDropdown";
import { Api } from "../../../../config/Api";
import dataService from "../../../../config/DataService";
import { useReactToPrint } from "react-to-print";
import PrintReport from "../../../../components/common/printReport/PrintReport";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/system";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: "#eaac35",
    color: "#ffffff",
    fontSize: "11px",
    fontFamily: "Poppins-Regular",
    padding: "5px 9px",
    lineHeight: "19px",
    borderRadius: "10px",
  },
}));

export default function FamilyReport() {
  const { getReportDataList, getAllRepotsFamilyIds } = PagesIndex.useSelector(
    (state) => state?.admin
  );

  
  const fileName = "Family Report CSV";
  const [excel, setExcel] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [filter, setFilter] = useState("");
  const [filterYear, setFilterYear] = useState("");
  const [subSahayFilter, setSubSahayFilter] = useState("");
  const [rowPerPage, setRowPerPage] = useState("25");
  const dispatch = PagesIndex.useDispatch();
  const [searchedData, setSearchedData] = useState([]);
  const [filterReportData, setFilterReportData] = useState([]);
  const [familyIds, setfamilyIds] = useState([]);
  const [sahaySubTypeList, setSahaySubTypeList] = useState([]);
  const [header, setHeader] = useState();
  const [viewReport, setViewReport] = useState(false);
  const [statusFilter, setStatusFilter] = useState("");
  // Print Reports
  const pageStyle = `{ size: 2.5in 4in }`;
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    pageStyle: { pageStyle },
    content: () => componentRef.current,
  });

  useEffect(() => {
    dispatch(getReportData()).then((res) => {
      if (res?.payload?.status == 200) {
        setLoader(false);
      }
      setLoader(false);
    });
  }, [filterYear, subSahayFilter, statusFilter]);

  //   const sahayAmount = () => {
  //     dataService.get(Api.GET_SAHAY_AMOUNT)
  //         .then((res) => {
  //         })
  //         .catch((error) => { });
  // }
  // useEffect(() => {
  //   sahayAmount()
  // }, [])
  // Copy original data to searched data

  //Filter Data
  useEffect(() => {
    if (filterYear || subSahayFilter || statusFilter) {
      const filterData = getReportDataList?.data?.data?.filter((ele) => {
        if (statusFilter == "All") {
          return ele;
        } else if (filterYear && subSahayFilter && statusFilter) {
          return (
            ele?.SubSahayWiseAmt?.Year == filterYear &&
            ele?.SubSahayWiseAmt?.SubSahayName == subSahayFilter &&
            ele?.committeeStatus?.toLowerCase() == statusFilter?.toLowerCase()
          );
        } else if (filterYear && subSahayFilter) {
          return (
            ele?.SubSahayWiseAmt?.Year == filterYear &&
            ele?.SubSahayWiseAmt?.SubSahayName == subSahayFilter
          );
        } else if (subSahayFilter && statusFilter) {
          return (
            ele?.SubSahayWiseAmt?.SubSahayName == subSahayFilter &&
            ele?.committeeStatus?.toLowerCase() == statusFilter?.toLowerCase()
          );
        } else if (filterYear && statusFilter) {
          return (
            ele?.SubSahayWiseAmt?.Year == filterYear &&
            ele?.committeeStatus?.toLowerCase() == statusFilter?.toLowerCase()
          );
        } else if (filterYear || subSahayFilter || statusFilter) {
          return (
            ele?.SubSahayWiseAmt?.Year == filterYear ||
            ele?.SubSahayWiseAmt?.SubSahayName == subSahayFilter ||
            ele?.committeeStatus?.toLowerCase() == statusFilter?.toLowerCase()
          );
        } else {
          return [];
        }
      });
      setFilterReportData(filterData);
    } else {
      setFilterReportData([]);
    }
  }, [filterYear, subSahayFilter, statusFilter]);

  useEffect(() => {
    setSearchedData(filterReportData);
  }, [filterReportData]);

  // Search on table
  const requestSearch = (searched) => {
    if (!searched) return setSearchedData(filterReportData);
    setSearchedData(
      filterReportData?.filter(
        (item) =>
          item?.ApplicantName?.toString()
            ?.toLowerCase()
            .includes(searched?.toLowerCase()) ||
          item?.Area?.toString()
            ?.toLowerCase()
            .includes(searched?.toLowerCase()) ||
          item?.FID?.toString().includes(searched?.toLowerCase())
      )
    );
  };

  function mergeHeaders(customHeadings, headersData) {
    return customHeadings.map((customHeading, index) => {
      if (headersData && headersData[index]) {
        // Assuming headersData[index] is an object with keys and values
        const headersObject = headersData[index];

        return {
          ...customHeading,
          ...headersObject,
        };
      }
      return customHeading;
    });
  }

  useEffect(() => {
    const headersData = getReportDataList?.data?.headers;
    const allSahayNames = Array.from(
      new Set(headersData?.map((item) => item?.SahayName))
    );
    // const defaultObject = Object.fromEntries(allSahayNames.map((sahayName) => [sahayName, 0]));

    if (filterReportData?.length > 0) {
      const customHeadings = filterReportData?.map((item) => {
        const newItem = {};

        for (const sahayName of allSahayNames) {
          if (sahayName !== item?.SubSahayWiseAmt?.SubSahayName) {
            newItem[sahayName] = "";
          } else {
            newItem[sahayName] = item?.SubSahayWiseAmt?.SubSahayName
              ? `${
                  item?.SubSahayWiseAmt?.approveAmount
                    ? item?.SubSahayWiseAmt?.approveAmount
                    : 0
                } of ${item?.SubSahayWiseAmt?.Amount}`
              : "-";
          }
        }
        return {
          Fid: item?.FID,
          "Applicant name": item?.ApplicantName,
          "Benificiary name": item?.Benificaryname
            ? item?.Benificaryname
            : item?.BenificaryName,
          "Moholla Name": item?.MohollaName,
          Area: item?.Area,
          Amount: item?.SubSahayWiseAmt?.Amount,
          "SubSahay Name": item?.SubSahayWiseAmt?.SubSahayName,
          ...newItem,
        };
      });

      setExcel(customHeadings);
    }
  }, [filterReportData]);

  //get Sub sahay list
  const getSahaySubTypeData = async () => {
    try {
      const res = await dataService.get(Api.GET_ALL_SUB_SAHAY_LIST);
      if (res?.data?.status) {
        setSahaySubTypeList(res?.data?.Data);
      } else {
        setSahaySubTypeList([]);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getSahaySubTypeData();

    dispatch(getAllFamilyIDs());
  }, []);

  const { rollPermissionData } = useSelector((state) => state.admin);

  const rollData = JSON.parse(localStorage?.getItem("RollName"))?.toLowerCase();

  const navigate = PagesIndex.useNavigate();
  const rollPermissionNameData =
    rollPermissionData?.findData?.parentRoleName?.toLowerCase();

  if (
    rollPermissionData?.permissionArray?.includes("Sahay Family Report_view") ||
    rollPermissionNameData === "admin"
  ) {
  } else {
    navigate(`/${rollData}/sahay-application`);
  }

  return (
    <>
      {loader ? (
        <PageLoading />
      ) : (
        <>
          <Index.Box className="page-content-box">
            <Index.Box className="barge-common-box">
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex res-title-header-flex">
                  <Index.Box className="plus-hidden-show-mobile">
                    <Index.Box className="title-main">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="page-title"
                      >
                        Family Report
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="table-list">
                <Index.Box className="">
                  <Index.Box className="grid-row sahay-multiple-main">
                    <Index.Box sx={{ width: 1 }} className="grid-main">
                      <Index.Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                      >
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 4",
                            lg: "span 3",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="sahay-multiple-input-main">
                            <PagesIndex.Search
                              className="search sahay-multiple-search"
                              onChange={(e) => requestSearch(e.target.value)}
                            >
                              <PagesIndex.StyledInputBase placeholder="Search" />
                            </PagesIndex.Search>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 4",
                            lg: "span 3",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="sahay-multiple-input-main">
                            <Autocomplete
                              disablePortal
                              className="multiple-autocomplet-control"
                              id="combo-box-demo"
                              options={getReportDataList?.data?.data || []}
                              getOptionLabel={(option) =>
                                option?.SubSahayWiseAmt?.Year
                              }
                              onChange={(value, newvalue) => {
                                setFilterYear(newvalue?.SubSahayWiseAmt?.Year);
                              }}
                              renderInput={(params) => (
                                <Index.TextField {...params} label="Year" />
                              )}
                              filterOptions={(options, { inputValue }) => {
                                const inputLowerCase = inputValue
                                  ?.toLowerCase()
                                  ?.replace(/\s/g, "");
                                const uniqueYear = new Set();
                                return options?.filter((option) => {
                                  const year =
                                    option?.SubSahayWiseAmt?.Year?.toLowerCase()?.replace(
                                      /\s/g,
                                      ""
                                    );
                                  // Check unique
                                  if (uniqueYear?.has(year)) {
                                    return false;
                                  }
                                  // Check search
                                  if (year?.includes(inputLowerCase)) {
                                    uniqueYear?.add(year);
                                    return true;
                                  }
                                  return false;
                                });
                              }}
                            />
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 3",
                            lg: "span 3",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="sahay-multiple-input-main">
                            <Autocomplete
                              disablePortal
                              className="multiple-autocomplet-control"
                              id="combo-box-demo"
                              options={sahaySubTypeList || []}
                              getOptionLabel={(option) => option?.SahayName}
                              onChange={(value, newvalue) => {
                                setSubSahayFilter(newvalue?.SahayName);
                              }}
                              renderInput={(params) => (
                                <Index.TextField
                                  {...params}
                                  label="Sub Sahay"
                                />
                              )}
                            />
                          </Index.Box>
                        </Index.Box>

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 3",
                            lg: "span 3",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="sahay-multiple-input-main">
                            <Autocomplete
                              disablePortal
                              className="multiple-autocomplet-control"
                              id="combo-box-demo"
                              options={[
                                "All",
                                "Approved",
                                "Rejected",
                                "Pending",
                              ]}
                              getOptionLabel={(option) => option}
                              onChange={(value, newvalue) => {
                                setStatusFilter(newvalue);
                              }}
                              renderInput={(params) => (
                                <Index.TextField {...params} label="All" />
                              )}
                            />
                          </Index.Box>
                        </Index.Box>
                        {/* <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 4",
                            lg: "span 3",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="sahay-multiple-input-main">
                            <Autocomplete
                              disablePortal
                              className="multiple-autocomplet-control"
                              id="combo-box-demo"
                              options={getReportDataList?.data?.data || []}
                              getOptionLabel={(option) =>
                                option?.FID?.toString()
                              }
                              onChange={(value, newvalue) => {
                                setFilter(newvalue?.FID);
                              }}
                              renderInput={(params) => (
                                <Index.TextField {...params} label="Fid" />
                              )}
                              filterOptions={(options, state) => {
                                const uniqueFids = new Set();

                                const filteredOptions = options.filter(
                                  (option) => {
                                    const fid = option?.FID?.toString();
                                    if (fid && !uniqueFids.has(fid)) {
                                      uniqueFids.add(fid);
                                      return true;
                                    }
                                    return false;
                                  }
                                );

                                return filteredOptions;
                              }}
                            />
                          </Index.Box>
                        </Index.Box> */}
                        {filterReportData?.length > 0 && (
                          <>
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 4",
                                lg: "span 3",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="sahay-multiple-input-main">
                                <Index.Box
                                  Box
                                  className="res-auto-left common-button blue-button multiple-download-btn res-blue-button"
                                >
                                  <FileExcel
                                    apiData={excel}
                                    fileName={fileName}
                                  />
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 4",
                                lg: "span 3",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="sahay-multiple-input-main">
                                <Index.Box
                                  Box
                                  className="res-auto-left common-button blue-button multiple-download-btn res-blue-button"
                                >
                                  <Index.Button onClick={handlePrint}>
                                    Print
                                  </Index.Button>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </>
                        )}
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.TableContainer
                  sx={{ maxHeight: 280 }}
                  component={Index.Paper}
                  className="table-container user-master-container  scroll-table"
                >
                  <Index.Table
                    stickyHeader
                    aria-label="sticky table"
                    className="table-design-main barge-table family-report-table"
                  >
                    <Index.TableHead className="user-master-head">
                      <Index.TableRow className="user-master-tr">
                        <Index.TableCell className="user-master-th">
                          Fid
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Applicant Name
                        </Index.TableCell>

                        <Index.TableCell className="user-master-th">
                          Benificiary name
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Moholla Name
                        </Index.TableCell>

                        <Index.TableCell className="user-master-th">
                          Area
                        </Index.TableCell>
                        <Index.TableCell
                          className="user-master-th"
                          align="center"
                        >
                          Sub Sahay Wise Amount
                          <Index.Table
                            aria-label="simple table"
                            className="table-design-main barge-table user-master-table-inner family-wise-table-inner"
                          >
                            <Index.TableHead>
                              <Index.TableRow>
                                {getReportDataList?.data?.headers?.map(
                                  (ele, index) => {
                                    return (
                                      <>
                                        {/* {index <
                                          getReportDataList?.data?.headers
                                            ?.length && (
                                          <Index.TableCell>
                                            {ele?.SahayName}
                                          </Index.TableCell>
                                        )}
                                       */}
                                        {(!viewReport ? (
                                          index < 10
                                        ) : (
                                          <Index.TableCell
                                            sx={{ cursor: "pointer" }}
                                          >
                                            <LightTooltip
                                              title={ele?.SahayName}
                                            >
                                              {ele?.SahayName
                                                ? ele?.SahayName.length >= 14
                                                  ? ele?.SahayName?.slice(
                                                      0,
                                                      15
                                                    ) + "..."
                                                  : ele?.SahayName
                                                : ""}
                                            </LightTooltip>
                                          </Index.TableCell>
                                        )) && (
                                          <Index.TableCell
                                            sx={{ cursor: "pointer" }}
                                          >
                                            <LightTooltip
                                              title={ele?.SahayName}
                                            >
                                              {ele?.SahayName
                                                ? ele?.SahayName.length >= 14
                                                  ? ele?.SahayName?.slice(
                                                      0,
                                                      15
                                                    ) + "..."
                                                  : ele?.SahayName
                                                : ""}
                                            </LightTooltip>
                                          </Index.TableCell>
                                        )}
                                      </>
                                    );
                                  }
                                )}
                              </Index.TableRow>
                              <Index.Button
                                className="table-view-more-btn"
                                onClick={() =>
                                  setViewReport(!viewReport ? true : false)
                                }
                              >
                                view more
                              </Index.Button>
                            </Index.TableHead>
                          </Index.Table>
                        </Index.TableCell>
                      </Index.TableRow>
                    </Index.TableHead>
                    <Index.TableBody className="user-master-tbody">
                      {pageData?.length &&
                      (filterYear || subSahayFilter || statusFilter) ? (
                        pageData?.map((data, index) => (
                          <Index.TableRow
                            className="user-master-tr"
                            
                          >
                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-role-name">
                                {data?.FID || "-"}
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-details-name">
                                {data?.ApplicantName || "-"}
                              </Index.Box>
                            </Index.TableCell>

                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-email-name">
                                {data?.Benificaryname
                                  ? data?.Benificaryname
                                  : data?.BenificaryName || "-"}
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-number-name">
                                {data?.MohollaName || "-"}
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-number-name">
                                {data?.Area || "-"}
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className="user-master-td">
                              {/* <Index.Box className="user-number-name"> */}
                              <Index.Table
                                aria-label="simple table"
                                className="table-design-main barge-table user-master-table-inner"
                              >
                                <Index.TableBody>
                                  <Index.TableRow>
                                    {getReportDataList?.data?.headers?.map(
                                      (ele, index) => {
                                        return (
                                          <>
                                            {(!viewReport ? (
                                              index < 10
                                            ) : (
                                              <Index.TableCell>
                                                {ele?.SahayName ==
                                                data?.SubSahayWiseAmt
                                                  ?.SubSahayName
                                                  ? `${
                                                      data?.SubSahayWiseAmt
                                                        ?.approveAmount
                                                        ? data?.SubSahayWiseAmt
                                                            ?.approveAmount
                                                        : 0
                                                    } of ${
                                                      data?.SubSahayWiseAmt
                                                        ?.Amount
                                                    }`
                                                  : "-"}
                                              </Index.TableCell>
                                            )) && (
                                              <Index.TableCell>
                                                {ele?.SahayName ==
                                                data?.SubSahayWiseAmt
                                                  ?.SubSahayName
                                                  ? `${
                                                      data?.SubSahayWiseAmt
                                                        ?.approveAmount
                                                        ? data?.SubSahayWiseAmt
                                                            ?.approveAmount
                                                        : 0
                                                    } of ${
                                                      data?.SubSahayWiseAmt
                                                        ?.Amount
                                                    }`
                                                  : "-"}
                                              </Index.TableCell>
                                            )}
                                            {/* <Index.TableCell>
                                                  {ele?.SahayName ==
                                                  data?.SubSahayWiseAmt
                                                    ?.SubSahayName
                                                    ? data?.SubSahayWiseAmt
                                                        ?.Amount
                                                    : "-"}
                                                </Index.TableCell> */}
                                          </>
                                        );
                                      }
                                    )}
                                  </Index.TableRow>
                                </Index.TableBody>
                              </Index.Table>
                              {/* </Index.Box> */}
                            </Index.TableCell>
                          </Index.TableRow>
                        ))
                      ) : (
                        <PagesIndex.NoRecordFound colSpan={12} />
                      )}
                    </Index.TableBody>
                  </Index.Table>
                </Index.TableContainer>
              </Index.Box>
              <Index.Box className="pagination-design flex-end">
                <RowPerPageDropdown
                  rowPerPage={rowPerPage}
                  setRowPerPage={setRowPerPage}
                />
                <Index.Stack spacing={2}>
                  <Pagination
                    fetchData={searchedData}
                    setPageData={setPageData}
                    pageData={pageData}
                    rowPerPage={rowPerPage}
                  />
                </Index.Stack>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box sx={{ display: "none" }}>
            <PrintReport
              componentRef={componentRef}
              tableData={excel}
              reportName={[{ type: "FamilyReport" }]}
            />
          </Index.Box>
        </>
      )}
    </>
  );
}
