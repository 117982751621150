import React, { useEffect, useState } from "react";

import Index from "../../../../container/Index";
import { useTheme } from "@mui/material/styles";

import PagesIndex from "../../../PagesIndex";
import { Autocomplete, Tooltip, tooltipClasses } from "@mui/material";

import moment from "moment";
import dayjs from "dayjs";

import SwapVertIcon from "@mui/icons-material/SwapVert";
import {
  listYearOfSahay,
  bankAccountsList,
  getListOfFamilyId,
  addSingleDisbursementData,
  editSingleDisbursementData,
  getAllBankList,
  getDisbursementDataList,
  addMassDisbursementData,
} from "../../../../redux/features/AddService";
import {
  massDisbursementSchema,

} from "../../../../validation/Validation";

import { Api } from "../../../../config/Api";
import dataService, { imagePath } from "../../../../config/DataService";
import { toast } from "react-toastify";
import PageLoading from "../../../../components/common/pageLoading/PageLoading";
import { useSelector } from "react-redux";
import { MobileDatePicker } from "@mui/x-date-pickers";
import styled from "styled-components";

import RowPerPageDropdown from "../../../../components/common/paginationRowPerpage/RowPerPageDropdown";
import Pagination from "../../../../components/common/Pagination";
import { FieldArray } from "formik";
import { string } from "yup";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: "#eaac35",
    color: "#ffffff",
    fontSize: "11px",
    fontFamily: "Poppins-Regular",
    padding: "5px 9px",
    lineHeight: "19px",
    borderRadius: "10px",
  },
}));

const ariaLabel = { "aria-label": "description" };

const MassDisbursement = () => {
  const theme = useTheme();
  const dispatch = PagesIndex.useDispatch();
  const navigate = PagesIndex.useNavigate();
  const location = PagesIndex.useLocation();
  const rollData = JSON.parse(localStorage?.getItem("RollName"))?.toLowerCase();

  //all state

  const [pageLoader, setPageLoader] = useState(false);

  const [bankList, setBankList] = useState([]);

  const [pageData, setPageData] = useState([]);
  const [searchedData, setSearchedData] = React.useState([]);
  const [sortColumn, setSortColumn] = useState("userName");
  const [sortDirection, setSortDirection] = useState("asc");
  const [rowPerPage, setRowPerPage] = useState("25");
 

  let initialValues;

  initialValues = {
    VoucherNo: "",
    checkIssueDate: "",
    RepayDetail: "",
    BankIssueName: "",
    ChequeNo: "",
    chequeDate: "",
    StartDate: "",
    documents: [],
    MassDisbursedAmount: "",
    disbursed: pageData?.map((row) => {
      return {
        id: row?.id,
        isChecked: false,
        familyId: row?.familyId,
        yearFrom: row?.yearFrom,
        toYear: row?.toYear,
        mem_fname: row?.mem_fname,
        mem_mname: row?.mem_mname,
        mem_lname: row?.mem_lname,
        SahayTypeName: row?.SahayTypeName,
        subSahayTypeName: row?.subSahayTypeName,
        ApproveAmount: row?.ApproveAmount,
        newStatusColumn: row?.newStatusColumn,
        disbursedStatus: row?.disbursedStatus,
        committeeStatus: row?.committeeStatus,
        committeeRemark: row?.committeeRemark,
        isDisbursed: row?.isDisbursed,
        disbursedInstallment:
          row?.disbursedInstallment &&
          row?.disbursedInstallment !== "undefined" &&
          row?.disbursedInstallment !== null
            ? [
                ...JSON.parse(row?.disbursedInstallment),
                {
                  disbursedAmount: "",
                  pendingAmount: JSON.parse(row?.disbursedInstallment)[
                    JSON.parse(row?.disbursedInstallment)?.length - 1
                  ].pendingAmount,
                  DisbursedDate: new Date(),
                },
              ]
            : [
                {
                  disbursedAmount: "",
                  pendingAmount: row?.ApproveAmount,
                  DisbursedDate: new Date(),
                },
              ],
      };
    }),
  };

  const handleBack = () => {
    navigate(`/${rollData}/disbursement-application`);
  };

  const handleFormSubmit = (values, { setFieldValue }) => {
    const data = new FormData();

    // Append main fields to FormData
    data.append("BankIssueName", values?.BankIssueName);
    data.append("ChequeNo", values?.ChequeNo);
    data.append("ChequeDate", values?.chequeDate);
    data.append("RepayDetail", values?.RepayDetail);
    data.append("StartDate", values?.StartDate);
    data.append("VoucherNo", values?.VoucherNo);
    data.append("CheckIssueDate", values?.checkIssueDate);
    data.append("MassDisbursedAmount", values?.MassDisbursedAmount);

    // Append SahayIds array
    values?.disbursed
      ?.filter?.((row) => row?.isChecked == true)
      ?.forEach((sahay, index) => {
        data.append(`SahayIds[${index}][SahayId]`, sahay?.id);
      });

    // Handle the disbursed array (loop through each item and append necessary details)
    values?.disbursed
      ?.filter?.((row) => row?.isChecked == true)
      ?.forEach((amount, index) => {
        data.append(`disbursed[${index}][SahayId]`, amount?.id);
        data.append(
          `disbursed[${index}][approvedAmount]`,
          amount?.ApproveAmount
        );

        // Handle disbursedInstallment for each disbursed item
        amount?.disbursedInstallment?.forEach((installment, disbursedIndex) => {
          data.append(
            `disbursed[${index}][disbursedInstallment][${disbursedIndex}][disbursedAmount]`,
            installment?.disbursedAmount
          );
          data.append(
            `disbursed[${index}][disbursedInstallment][${disbursedIndex}][pendingAmount]`,
            installment?.pendingAmount
          );
          data.append(
            `disbursed[${index}][disbursedInstallment][${disbursedIndex}][DisbursedDate]`,
            moment(installment?.DisbursedDate).format(
              "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
            )
          );
        });

        // Append disbursed item-related fields
        data.append(
          `disbursed[${index}][BankIssueName]`,
          values?.BankIssueName
        );
        data.append(`disbursed[${index}][ChequeNo]`, values?.ChequeNo);
        data.append(`disbursed[${index}][ChequeDate]`, values?.chequeDate);
        data.append(`disbursed[${index}][RepayDetail]`, values?.RepayDetail);
        data.append(`disbursed[${index}][StartDate]`, values?.StartDate);
        data.append(`disbursed[${index}][VoucherNo]`, values?.VoucherNo);
        data.append(
          `disbursed[${index}][CheckIssueDate]`,
          values?.checkIssueDate
        );
      });

    // Handle documents if present
    if (values?.documents?.length > 0) {
      values?.documents?.forEach((ele) => {
        if (ele instanceof Blob) {
          data.append("documents", ele);
        } else {
          data.append("oldDocuments", ele?.Documents);
        }
      });
    }

    // Dispatch the form data if required (assuming a Redux dispatch)
    dispatch(addMassDisbursementData(data)).then((res) => {
     
      
      if (res?.payload?.status == 201) {
        navigate(`/${rollData}/disbursement-application`);
      } 
    });
  };

  const getAllBanksList = () => {
    dispatch(getAllBankList()).then((res) => {
      if (res?.payload) {
        setBankList(res?.payload?.data);
      } else {
        setBankList([]);
      }
    });
  };

  useEffect(() => {
    getAllBanksList();
    getDisbursementData();
  }, []);

  const getDisbursementData = () => {
    dispatch(getDisbursementDataList()).then((data) => {
      if (data?.payload) {
        const filterData = data?.payload?.filter((ele) => {
          return (
            (ele?.isDisbursed === 0 && ele?.disbursedStatus === null) ||
            (ele?.isDisbursed === 0 &&
              ele?.disbursedStatus === "PartialyPending")
          );
        });
        setSearchedData(filterData);
      }
    });
  };

  const handleSort = (column) => {
    const isAsc = sortColumn === column && sortDirection === "asc";
    setSortDirection(isAsc ? "desc" : "asc");
    setSortColumn(column);

    const sortedData = [...searchedData].sort((a, b) => {
      if (column === "userName") {
        const nameA = `${a.mem_fname} ${a.mem_lname}`.toLowerCase();
        const nameB = `${b.mem_fname} ${b.mem_lname}`.toLowerCase();
        return isAsc ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
      }
      return 0;
    });

    setSearchedData(sortedData);
  };


  const { rollPermissionData } = useSelector((state) => state.admin);

  if (
    rollPermissionData?.permissionArray?.includes("Disbursement_add") ||
    rollPermissionData?.permissionArray?.includes("Disbursement_edit")
  ) {
  } else {
    navigate(`/${rollData}/sahay-application`);
  }

  return (
    <>
      <div>
        {pageLoader ? (
          <PageLoading />
        ) : (
          <>
            <Index.Box className="apply-sahay-main">
              <Index.Box className="head-content">
                <Index.Box
                  className={`user-right ${
                    location.pathname == "/admin/add-disbursement-application"
                      ? "admin-border-radius"
                      : ""
                  }`}
                >
                  <Index.Box
                    className={`bottom-user-right ${
                      location.pathname == "/admin/add-disbursement-application"
                        ? "admin-height-remove"
                        : ""
                    }`}
                  >
                    <Index.Box className="user-apply-content">
                      <PagesIndex.Formik
                        enableReinitialize
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={massDisbursementSchema}
                      >
                        {({
                          values,
                          handleChange,
                          handleSubmit,
                          touched,
                          setFieldValue,
                          handleBlur,
                          errors,
                          isValid,
                          isSubmitting,
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <Index.Box className="main-in-row">
                              <Index.Box className="user-basic user-heading">
                                <Index.Typography
                                  component="h1"
                                  className="head-apply"
                                >
                                  Apply for mass disbursement
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className="main-in-row">
                              <Index.Box
                                className="user-basic"
                                sx={{ display: "flex" }}
                              ></Index.Box>
                              <Index.Box
                                sx={{ flexGrow: 1 }}
                                className="al-form-groups"
                              >
                                <Index.Grid container spacing={2}>
                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        Voucher No (વાઉચર નં.)
                                      </Index.Typography>
                                      <Index.TextField
                                        type="number"
                                        inputProps={ariaLabel}
                                        className="basic-input input-width"
                                        name="VoucherNo"
                                        value={values?.VoucherNo}
                                        onChange={(e) => {
                                          if (e.target.value.length <= 12) {
                                            // Only update the value if it's not more than 40 characters
                                            handleChange(e);
                                          }
                                        }}
                                        onBlur={handleBlur}
                                      />
                                    </Index.Box>
                                    {errors?.VoucherNo &&
                                      touched?.VoucherNo && (
                                        <p className="error-text">
                                          {errors?.VoucherNo}
                                        </p>
                                      )}
                                  </Index.Grid>
                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        Cheque No. (ચેક નં.)
                                      </Index.Typography>
                                      <Index.TextField
                                        inputProps={ariaLabel}
                                        className="basic-input input-width"
                                        name="ChequeNo"
                                        value={values?.ChequeNo}
                                        onChange={(e) => {
                                          if (e.target.value.length <= 10) {
                                            // Only update the value if it's not more than 40 characters
                                            handleChange(e);
                                          }
                                        }}
                                        onBlur={handleBlur}
                                      />
                                    </Index.Box>
                                    {errors?.ChequeNo && touched?.ChequeNo && (
                                      <p className="error-text">
                                        {errors?.ChequeNo}
                                      </p>
                                    )}
                                  </Index.Grid>

                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        Bank Issue Name (બેંક ઇશ્યુનું નામ)
                                      </Index.Typography>
                                      <Index.Box className="sahay-multiple-input-main">
                                        <Autocomplete
                                          disablePortal
                                          className="multiple-autocomplet-control"
                                          id="combo-box-bank-issue"
                                          options={bankList?.map(
                                            (row) => row?.BankIssueName
                                          )}
                                          getOptionLabel={(option) => option}
                                          value={values?.BankIssueName}
                                          onChange={(event, newValue) => {
                                            setFieldValue(
                                              "BankIssueName",
                                              newValue
                                            );
                                          }}
                                          onBlur={handleBlur}
                                          renderInput={(params) => (
                                            <Index.TextField
                                              {...params}
                                              placeholder="Select Bank Issue Name"
                                            />
                                          )}
                                        />
                                      </Index.Box>
                                      {errors?.BankIssueName &&
                                        touched?.BankIssueName && (
                                          <p className="error-text">
                                            {errors?.BankIssueName}
                                          </p>
                                        )}
                                    </Index.Box>
                                  </Index.Grid>

                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Typography
                                      component="p"
                                      className="in-label"
                                    >
                                      Cheque Date (તારીખ તપાસો)
                                    </Index.Typography>
                                    <Index.Box className="input-design-div with-border ">
                                      <Index.LocalizationProvider
                                        dateAdapter={Index.AdapterDayjs}
                                        sx={{ position: "absolute" }}
                                      >
                                        <Index.DemoContainer
                                          components={["DatePicker"]}
                                        >
                                          <MobileDatePicker
                                            name="chequeDate"
                                            onChange={(value) => {
                                              setFieldValue(
                                                "chequeDate",
                                                moment(value.$d).format(
                                                  "YYYY-MM-DD"
                                                )
                                              );
                                            }}
                                            value={dayjs(values?.chequeDate)}
                                            format="YYYY-MM-DD"
                                            sx={{
                                              overflowY: "hidden",
                                              position: "relative",
                                              bottom: "8px",
                                              width: "100%",
                                            }}
                                            slotProps={{
                                              textField: {
                                                size: "small",
                                                error: false,
                                              },
                                            }}
                                          />
                                        </Index.DemoContainer>
                                      </Index.LocalizationProvider>
                                    </Index.Box>
                                    {errors?.chequeDate &&
                                      touched.chequeDate && (
                                        <p className="error-text">
                                          {errors?.chequeDate}
                                        </p>
                                      )}
                                  </Index.Grid>
                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        Repay Details (ચુકવણી વિગતો)
                                      </Index.Typography>
                                      <Index.Textarea
                                        style={{
                                          fontSize: "15px",
                                          color: "black",
                                          padding: "4px",
                                        }}
                                        fullWidth
                                        minRows={2}
                                        inputProps={ariaLabel}
                                        className="basic-input input-width"
                                        name="RepayDetail"
                                        value={values?.RepayDetail}
                                        onChange={(e) => {
                                          if (e.target.value.length <= 200) {
                                            // Only update the value if it's not more than 40 characters
                                            handleChange(e);
                                          }
                                        }}
                                        onBlur={handleBlur}
                                      />
                                    </Index.Box>
                                    {errors?.RepayDetail &&
                                      touched?.RepayDetail && (
                                        <p className="error-text">
                                          {errors?.RepayDetail}
                                        </p>
                                      )}
                                  </Index.Grid>
                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Typography
                                      component="p"
                                      className="in-label"
                                    >
                                      Start Date (પ્રારંભ તારીખ)
                                    </Index.Typography>
                                    <Index.Box className="input-design-div with-border ">
                                      <Index.LocalizationProvider
                                        dateAdapter={Index.AdapterDayjs}
                                        sx={{ position: "absolute" }}
                                      >
                                        <Index.DemoContainer
                                          components={["DatePicker"]}
                                        >
                                          <MobileDatePicker
                                            name="StartDate"
                                            onChange={(value) => {
                                              setFieldValue(
                                                "StartDate",
                                                moment(value.$d).format(
                                                  "YYYY-MM-DD"
                                                )
                                              );
                                            }}
                                            value={dayjs(values?.StartDate)}
                                            format="YYYY-MM-DD"
                                            sx={{
                                              overflowY: "hidden",
                                              position: "relative",
                                              bottom: "8px",
                                              width: "100%",
                                            }}
                                            slotProps={{
                                              textField: {
                                                size: "small",
                                                error: false,
                                              },
                                            }}
                                          />
                                        </Index.DemoContainer>
                                      </Index.LocalizationProvider>
                                    </Index.Box>
                                    {errors?.StartDate &&
                                      touched?.StartDate && (
                                        <p className="error-text">
                                          {errors?.StartDate}
                                        </p>
                                      )}
                                  </Index.Grid>
                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Typography
                                      component="p"
                                      className="in-label"
                                    >
                                      Check Issue date (ઇશ્યૂ તારીખ તપાસો)
                                    </Index.Typography>
                                    <Index.Box className="input-design-div with-border ">
                                      <Index.LocalizationProvider
                                        dateAdapter={Index.AdapterDayjs}
                                        sx={{ position: "absolute" }}
                                      >
                                        <Index.DemoContainer
                                          components={["DatePicker"]}
                                        >
                                          <MobileDatePicker
                                            name="checkIssueDate"
                                            onChange={(value) => {
                                              setFieldValue(
                                                "checkIssueDate",
                                                moment(value.$d).format(
                                                  "YYYY-MM-DD"
                                                )
                                              );
                                            }}
                                            value={dayjs(
                                              values?.checkIssueDate
                                            )}
                                            format="YYYY-MM-DD"
                                            sx={{
                                              overflowY: "hidden",
                                              position: "relative",
                                              bottom: "8px",
                                              width: "100%",
                                            }}
                                            slotProps={{
                                              textField: {
                                                size: "small",
                                                error: false,
                                              },
                                            }}
                                          />
                                        </Index.DemoContainer>
                                      </Index.LocalizationProvider>
                                    </Index.Box>
                                    {errors?.checkIssueDate &&
                                      touched.checkIssueDate && (
                                        <p className="error-text">
                                          {errors?.checkIssueDate}
                                        </p>
                                      )}
                                  </Index.Grid>

                                  <Index.Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    lg={3}
                                    mb={2}
                                  >
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        Total Disbursement Amount (કુલ વિતરણ રકમ)
                                      </Index.Typography>
                                      <Index.TextField
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        type="number"
                                        inputProps={ariaLabel}
                                        className="basic-input input-width"
                                        name="MassDisbursedAmount"
                                        value={values?.MassDisbursedAmount}
                                      />
                                    </Index.Box>
                                    {errors?.MassDisbursedAmount &&
                                      touched?.MassDisbursedAmount && (
                                        <p className="error-text">
                                          {errors?.MassDisbursedAmount}
                                        </p>
                                      )}
                                  </Index.Grid>
                                </Index.Grid>
                              </Index.Box>
                            </Index.Box>

                            <Index.Box
                              sx={{ flexGrow: 1, marginTop: "15px" }}
                              className="al-form-groups fam-in-grp multiple-doc mt-0px-sahay{"
                            >
                              <Index.Grid container spacing={2}>
                                <Index.Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  md={4}
                                  lg={4}
                                  mb={2}
                                >
                                  <Index.Box className="lable-input-grp">
                                    <Index.Typography
                                      component="p"
                                      className="in-label"
                                    >
                                      Add Multiple Document (બહુવિધ દસ્તાવેજ
                                      ઉમેરો)
                                    </Index.Typography>
                                    <Index.Box className="select-position">
                                      <input
                                        type="file"
                                        name="documents"
                                        multiple
                                        className="ori-input"
                                        onChange={(event) => {
                                          const files = event.target.files;
                                          setFieldValue("documents", [
                                            ...values?.documents,
                                            ...files,
                                          ]);
                                        }}
                                      />

                                      <Index.Box className="select-file">
                                        Upload Your Document
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Grid>

                                <Index.Grid item xs={8}>
                                  <Index.Box className="select-view-document-name">
                                    {values?.documents?.map((file, index) => (
                                      <Index.Box
                                        className="document-name"
                                        key={index}
                                      >
                                        <Index.Typography
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            // Implement the logic to view the document here
                                            // You can use a modal or a new browser tab/window to show the document
                                            window.open(
                                              file instanceof Blob
                                                ? URL.createObjectURL(file)
                                                : `${imagePath}${file?.Documents}`
                                            );
                                          }}
                                        >
                                          {file?.name
                                            ? file?.name
                                            : file?.filename}
                                        </Index.Typography>
                                        <Index.IconButton
                                          onClick={() => {
                                            const updatedDocuments =
                                              values.documents.filter(
                                                (_, i) => i !== index
                                              );
                                            setFieldValue(
                                              "documents",
                                              updatedDocuments
                                            );
                                          }}
                                        >
                                          <Index.ClearIcon className="muiico-cancel" />
                                        </Index.IconButton>
                                      </Index.Box>
                                    ))}
                                  </Index.Box>
                                </Index.Grid>
                              </Index.Grid>
                            </Index.Box>

                            <Index.Box className="">
                              <Index.TableContainer
                                sx={{ maxHeight: 380 }}
                                component={Index.Paper}
                                className="table-container sahay-application-container scroll-table"
                              >
                                <Index.Table
                                  stickyHeader
                                  aria-label="sticky table"
                                  className="table-design-main barge-table sahay-appli-table"
                                >
                                  <Index.TableHead className="sahay-appli-head">
                                    <Index.TableRow className="sahay-appli-tr">
                                      <Index.TableCell className="sahay-appli-tr"></Index.TableCell>

                                      <Index.TableCell className="sahay-appli-tr">
                                        Family Id
                                      </Index.TableCell>
                                      <Index.TableCell
                                        className="sahay-appli-tr"
                                        align="left"
                                      >
                                        Year
                                      </Index.TableCell>
                                      <Index.TableCell
                                        className="user-sahay-td"
                                        onClick={() => handleSort("userName")}
                                        style={{ cursor: "pointer" }}
                                      >
                                        User Name
                                        {sortColumn === "userName" &&
                                          (sortDirection === "asc" ? (
                                            <SwapVertIcon
                                              className="swap-icon"
                                              fontSize="small"
                                            />
                                          ) : (
                                            <SwapVertIcon
                                              className="swap-icon"
                                              fontSize="small"
                                            />
                                          ))}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        className="sahay-appli-tr"
                                        align="left"
                                      >
                                        Sahay Category
                                      </Index.TableCell>
                                      <Index.TableCell
                                        className="sahay-appli-tr"
                                        align="left"
                                      >
                                        Sub Sahay Category
                                      </Index.TableCell>
                                      <Index.TableCell
                                        className="sahay-appli-tr"
                                        align="left"
                                      >
                                        Approved Amount
                                      </Index.TableCell>
                                      <Index.TableCell
                                        className="sahay-appli-tr"
                                        align="left"
                                      >
                                        Pending Amount
                                      </Index.TableCell>
                                      <Index.TableCell
                                        className="sahay-appli-tr"
                                        align="left"
                                        width="30%"
                                      >
                                        Disbursed Amount
                                      </Index.TableCell>

                                      <Index.TableCell
                                        className="sahay-appli-tr"
                                        align="left"
                                      >
                                        Staff Status
                                      </Index.TableCell>
                                      <Index.TableCell
                                        className="sahay-appli-tr"
                                        align="left"
                                      >
                                        Disbursement Status
                                      </Index.TableCell>
                                      <Index.TableCell
                                        className="sahay-appli-tr"
                                        align="left"
                                      >
                                        Committee Status
                                      </Index.TableCell>

                                      <Index.TableCell
                                        className="sahay-appli-tr"
                                        align="left"
                                      >
                                        Committee Remark
                                      </Index.TableCell>
                                    </Index.TableRow>
                                  </Index.TableHead>
                                  <Index.TableBody>
                                    <FieldArray name="disbursed">
                                      <>
                                        {values.disbursed?.length ? (
                                          values.disbursed?.map(
                                            (row, index) => (
                                              <Index.TableRow
                                                key={index}
                                                className={`sahay-appli-tr`}
                                              >
                                                <Index.TableCell
                                                  component="td"
                                                  variant="td"
                                                  scope="row"
                                                  className="sahay-appli-td"
                                                  align="left"
                                                >
                                                  <Index.Checkbox
                                                    component="td"
                                                    variant="td"
                                                    scope="row"
                                                    className="check-box-role"
                                                    align="left"
                                                    checked={row?.isChecked}
                                                  
                                                    onChange={(e) =>
                                                      setFieldValue(
                                                        `disbursed[${index}].isChecked`,
                                                        !row?.isChecked
                                                      )
                                                    }
                                                  />
                                                </Index.TableCell>
                                                <Index.TableCell
                                                  align="left"
                                                  className="sahay-appli-td"
                                                >
                                                  {row?.familyId}
                                                </Index.TableCell>

                                                <Index.TableCell
                                                  align="left"
                                                  className="sahay-appli-td"
                                                >
                                                  {row?.yearFrom} -{" "}
                                                  {row?.toYear}
                                                </Index.TableCell>
                                                <Index.TableCell
                                                  align="left"
                                                  className="sahay-appli-td"
                                                >
                                                  {row?.mem_fname}{" "}
                                                  {row?.mem_mname}{" "}
                                                  {row?.mem_lname}
                                                </Index.TableCell>

                                                <Index.TableCell
                                                  align="left"
                                                  className="sahay-appli-td"
                                                >
                                                  {row?.SahayTypeName}
                                                </Index.TableCell>
                                                <Index.TableCell
                                                  align="left"
                                                  className="sahay-appli-td"
                                                >
                                                  {row?.subSahayTypeName}
                                                </Index.TableCell>
                                                <Index.TableCell
                                                  align="left"
                                                  className="sahay-appli-td"
                                                >
                                                  {row?.ApproveAmount}
                                                </Index.TableCell>
                                                {console.log(errors, 91777)}

                                                <Index.TableCell
                                                  align="left"
                                                  className="sahay-appli-td"
                                                >
                                                  {
                                                    row?.disbursedInstallment[
                                                      row?.disbursedInstallment
                                                        ?.length - 1
                                                    ].pendingAmount
                                                  }
                                                </Index.TableCell>
                                                <Index.TableCell
                                                  align="left"
                                                  className="sahay-appli-td"
                                                >
                                                  {row?.disbursedInstallment?.map(
                                                    (
                                                      installment,
                                                      installmentIndex
                                                    ) => {
                                                      if (
                                                        installmentIndex ===
                                                        row
                                                          ?.disbursedInstallment
                                                          ?.length -
                                                          1
                                                      ) {
                                                        return (
                                                          <Index.Grid
                                                            item
                                                            xs={12}
                                                            sm={4}
                                                            md={4}
                                                            lg={4}
                                                            key={
                                                              installmentIndex
                                                            }
                                                          >
                                                            <Index.Box className="input-design-div with-border">
                                                              <Index.TextField
                                                                inputProps={{
                                                                  ...ariaLabel,
                                                                  disabled:
                                                                    row?.isChecked ==
                                                                    false,
                                                                }}
                                                                className="basic-input input-width"
                                                                name={`disbursed[${index}].disbursedInstallment[${installmentIndex}].disbursedAmount`}
                                                                type="number"
                                                                value={
                                                                  installment?.disbursedAmount ||
                                                                  ""
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  const newValue =
                                                                    parseFloat(
                                                                      e.target
                                                                        .value
                                                                    ) || 0;

                                                                    

                                                                  const previousPendingAmount =
                                                                    installmentIndex >
                                                                    0
                                                                      ? parseFloat(
                                                                          row
                                                                            ?.disbursedInstallment[
                                                                            installmentIndex -
                                                                              1
                                                                          ]
                                                                            .pendingAmount
                                                                        ) || 0
                                                                      : parseFloat(
                                                                          row?.ApproveAmount ||
                                                                            0
                                                                        );

                                                                  const newPendingAmount =
                                                                    previousPendingAmount -
                                                                    newValue;

                                                                  if (
                                                                    newPendingAmount <
                                                                    0
                                                                  ) {
                                                                    return;
                                                                  }

                                                                  setFieldValue(
                                                                    `disbursed[${index}].disbursedInstallment[${installmentIndex}].disbursedAmount`,
                                                                    newValue
                                                                  );
                                                                  setFieldValue(
                                                                    `disbursed[${index}].disbursedInstallment[${installmentIndex}].pendingAmount`,
                                                                    newPendingAmount
                                                                  );
                                                                }}
                                                              />
                                                            </Index.Box>

                                                            {errors?.disbursed
                                                              ?.length !== 0 ? (
                                                              errors
                                                                ?.disbursed?.[
                                                                index
                                                              ]
                                                                ?.disbursedInstallment &&
                                                              touched
                                                                ?.disbursed?.[
                                                                index
                                                              ]
                                                                ?.disbursedInstallment && (
                                                                <p className="error-text">
                                                                  {
                                                                    errors
                                                                      ?.disbursed[
                                                                      index
                                                                    ]
                                                                      ?.disbursedInstallment
                                                                  }
                                                                </p>
                                                              )
                                                            ) : (
                                                              <></>
                                                            )}
                                                          </Index.Grid>
                                                        );
                                                      }

                                                      // If it's not the last installment, don't render anything
                                                      return null;
                                                    }
                                                  )}
                                                </Index.TableCell>

                                                <Index.TableCell
                                                  align="left"
                                                  className="sahay-appli-td"
                                                >
                                                  <Index.Box
                                                    className={`status-details ${
                                                      row?.newStatusColumn ==
                                                      "Pending"
                                                        ? "pending - status"
                                                        : row?.newStatusColumn ==
                                                          "Approved"
                                                        ? "approved-status"
                                                        : row?.newStatusColumn ==
                                                          "Rejected"
                                                        ? "reject-status"
                                                        : ""
                                                    }`}
                                                  >
                                                    {row?.newStatusColumn}
                                                  </Index.Box>
                                                </Index.TableCell>

                                                <Index.TableCell
                                                  align="left"
                                                  className="sahay-appli-td"
                                                  sx={{ textAlign: "center" }}
                                                >
                                                  <Index.Box
                                                    className={`status-details ${
                                                      row?.isDisbursed === 0
                                                        ? "pending - status"
                                                        : row?.isDisbursed === 1
                                                        ? "approved-status"
                                                        : ""
                                                    }`}
                                                  >
                                                    {row?.isDisbursed === 0 &&
                                                    row?.disbursedStatus == null
                                                      ? "Pending"
                                                      : row?.isDisbursed ===
                                                          1 &&
                                                        (row?.disbursedStatus ==
                                                          "Complete" ||
                                                          row?.disbursedStatus ==
                                                            null)
                                                      ? "Completed"
                                                      : row?.isDisbursed ===
                                                          0 &&
                                                        row?.disbursedStatus ==
                                                          "PartialyPending"
                                                      ? "Partially Pending"
                                                      : "-"}
                                                  </Index.Box>
                                                </Index.TableCell>
                                                <Index.TableCell
                                                  align="left"
                                                  className="sahay-appli-td"
                                                  sx={{ textAlign: "center" }}
                                                >
                                                  <Index.Box
                                                    className={`status-details ${
                                                      row?.committeeStatus ==
                                                      "Pending"
                                                        ? "pending - status"
                                                        : row?.committeeStatus ==
                                                          "Approved"
                                                        ? "approved-status"
                                                        : row?.committeeStatus ==
                                                          "Rejected"
                                                        ? "reject-status"
                                                        : ""
                                                    }`}
                                                  >
                                                    {row?.committeeStatus ===
                                                    null
                                                      ? "-"
                                                      : row?.committeeStatus}
                                                  </Index.Box>
                                                </Index.TableCell>

                                                <Index.TableCell
                                                  align="left"
                                                  className="sahay-appli-td"
                                                  sx={{ textAlign: "center" }}
                                                >
                                                  {row?.committeeRemark ===
                                                  "null" ? (
                                                    "-"
                                                  ) : row?.committeeRemark ===
                                                    "undefined" ? (
                                                    "-"
                                                  ) : row?.committeeRemark ===
                                                    null ? (
                                                    "-"
                                                  ) : (
                                                    <LightTooltip
                                                      title={
                                                        row?.committeeRemark
                                                      }
                                                    >
                                                      {row.committeeRemark
                                                        ?.split(" ")
                                                        ?.slice(0, 3)
                                                        ?.join(" ")}
                                                    </LightTooltip>
                                                  )}
                                                </Index.TableCell>
                                              </Index.TableRow>
                                            )
                                          )
                                        ) : (
                                          <PagesIndex.NoRecordFound
                                            colSpan={12}
                                          />
                                        )}
                                      </>
                                    </FieldArray>
                                  </Index.TableBody>
                                </Index.Table>
                             
                              
                              </Index.TableContainer>
                              {typeof errors?.disbursed == "string" && (
                                  <p className="error-text">
                                    {
                                     touched?.disbursed && errors?.disbursed}
                                  </p>
                                )}
                            </Index.Box>

                            <Index.Box className="pagination-design flex-end">
                              <RowPerPageDropdown
                                rowPerPage={rowPerPage}
                                setRowPerPage={setRowPerPage}
                              />
                              <Index.Stack spacing={2}>
                                <Pagination
                                  fetchData={searchedData}
                                  setPageData={setPageData}
                                  pageData={pageData}
                                  rowPerPage={rowPerPage}
                                />
                              </Index.Stack>
                            </Index.Box>

                            <Index.Box
                              sx={{ flexGrow: 1 }}
                              className="al-form-groups fam-in-grp"
                            >
                              <Index.Grid item xs={6}>
                                <Index.Box className="common-button blue-button flex-end save-btn back-btn">
                                  <Index.Button
                                    variant="contained"
                                    onClick={() => handleBack()}
                                  >
                                    Back
                                  </Index.Button>
                                </Index.Box>
                              </Index.Grid>
                              <Index.Grid container spacing={2}>
                                <Index.Grid item xs={6}>
                                  <Index.Box className="lable-input-grp ">
                                    <Index.Box className="select-position">
                                      <Index.Box
                                        className=
                                           "apply-now"
                                            
                                        
                                      >
                                        <button type="submit">Submit</button>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Grid>
                              </Index.Grid>
                            </Index.Box>
                          </form>
                        )}
                      </PagesIndex.Formik>

                      <Index.Box className="title-header-flex "></Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </>
        )}
      </div>
    </>
  );
};

export default MassDisbursement;
