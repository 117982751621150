import React, { useEffect, useState } from "react";
import PagesIndex from "../../../PagesIndex";
import Index from "../../../Index";

import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import "./usermaster.css";
import PageLoading from "../../../../components/common/pageLoading/PageLoading";
import { useSelector } from "react-redux";
const ViewUserMaster = () => {
  //useselector
  const { getSubSahaydata } = PagesIndex.useSelector((state) => state.admin);
  //all state

  const [subSahayId, setSubSahayId] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(true);
  const navigate = PagesIndex.useNavigate();
  const { state } = PagesIndex.useLocation();

  let initialValues = {
    email: state ? state?.email : "",
    name: state ? state?.name : "",
    mobileno: state ? state?.mobileno : "",
    role: state?.UserRole ? state?.UserRole : "",
    sahayId: subSahayId ? subSahayId : [],
    isSubmit: false,
  };

  const getSingleUserMaster = async () => {
    setLoading(true);
    try {
      const res = await DataService.post(
        `${Api.ADMIN_GET_SINGLE_USER_MASTER}/${state?.id}`
      );

      if (res?.data?.status == 200) {
        setLoader(false);
        // setLoading(false);
        setSubSahayId(res?.data?.data?.subsahayid);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getSingleUserMaster();
  }, []);
  const { rollPermissionData } = useSelector((state) => state.admin);
  const rollData = JSON.parse(localStorage?.getItem("RollName"))?.toLowerCase();
  const rollPermissionNameData =
  rollPermissionData?.findData?.parentRoleName?.toLowerCase();

  if (rollPermissionData?.permissionArray?.includes("User Master_view")|| rollPermissionNameData === "admin") {
  } else {
    navigate(`/${rollData}/sahay-application`);
  }

  return (
    <>
      {loader ? (
        <PageLoading />
      ) : (
        <>
          <Index.Box className="page-content-box">
            <Index.Box className="barge-common-box">
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex">
                  <Index.Box className="title-main mb-10">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="page-title"
                    >
                      View User Master
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="common-button blue-button flex-start save-btn res-set-search text-end-details">
                    <Index.Button
                      variant="contained"
                      type="submit"
                      onClick={() => {
                        navigate("/admin/user-master", {
                          state: { rowID: state?.id },
                        });
                      }}
                    >
                      Back
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              {!loader ? (
                <>
                  {" "}
                  <PagesIndex.Formik
                    enableReinitialize
                    initialValues={initialValues}
                  >
                    {({ values, handleSubmit, setFieldValue }) => (
                      <form onSubmit={handleSubmit}>
                        <Index.Box className="user-master-add-page-list">
                          <Index.Grid container spacing={3}>
                            <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                              <Index.Typography
                                variant="label"
                                component="label"
                                className="input-label"
                              >
                                Email(ઈમેલ)
                              </Index.Typography>
                              <Index.Box className="input-design-div with-border">
                                <Index.TextField
                                  id="filled-hidden-label-normal"
                                  placeholder="Email"
                                  variant="filled"
                                  disabled
                                  className="admin-input-design input-placeholder"
                                  autoComplete="off"
                                  name="email"
                                  value={values.email}
                                />
                              </Index.Box>
                            </Index.Grid>

                            <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                              <Index.Typography
                                variant="label"
                                component="label"
                                className="input-label"
                              >
                                User Name(વપરાશકર્તા નામ)
                              </Index.Typography>
                              <Index.Box className="input-design-div with-border">
                                <Index.TextField
                                  disabled
                                  value={values.name}
                                  id="filled-hidden-label-normal"
                                  placeholder="User Name"
                                  variant="filled"
                                  type="text"
                                  className="admin-input-design input-placeholder"
                                  autoComplete="off"
                                  name="name"
                                />
                              </Index.Box>
                            </Index.Grid>

                            <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                              <Index.Typography
                                variant="label"
                                component="label"
                                className="input-label"
                              >
                                Mobile No(મોબાઈલ નં)
                              </Index.Typography>
                              <Index.Box className="input-design-div with-border">
                                <Index.TextField
                                  disabled
                                  value={values.mobileno}
                                  id="filled-hidden-label-normal"
                                  placeholder="Mobile No"
                                  variant="filled"
                                  type="number"
                                  className="admin-input-design input-placeholder"
                                  autoComplete="off"
                                  name="mobileno"
                                />
                              </Index.Box>
                            </Index.Grid>

                            <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                              <Index.Typography
                                variant="label"
                                component="label"
                                className="input-label"
                              >
                                User Role(વપરાશકર્તા ભૂમિકા)
                              </Index.Typography>

                              <Index.Box className="input-design-div with-border">
                                <Index.TextField
                                  disabled
                                  value={values?.role}
                                  id="filled-hidden-label-normal"
                                  placeholder="Role"
                                  variant="filled"
                                  className="admin-input-design input-placeholder"
                                  autoComplete="off"
                                  name="role"
                                />
                              </Index.Box>
                            </Index.Grid>
                            {state?.UserRole == "Committee" && (
                              <Index.Grid item xs={12} sm={6} md={4} lg={3}>
                                <Index.Typography
                                  variant="label"
                                  component="label"
                                  className="input-label"
                                >
                                  Sub Sahay(સબ સપ્લાય)
                                </Index.Typography>
                                <Index.Box className="input-design-div with-border">
                                  <Index.FormControl className="form-multiple-dropdowns">
                                    <Index.Select
                                      name="sahayId"
                                      id="demo-multiple-checkbox"
                                      multiple
                                      value={values?.sahayId || []}
                                      className="select-multiple-dropdowns"
                                      onChange={(e) => {
                                        setFieldValue(
                                          "sahayId",
                                          e.target.value
                                        );
                                      }}
                                      renderValue={(selected) =>
                                        selected
                                          .map(
                                            (id) =>
                                              getSubSahaydata?.find(
                                                (item) => item.Id === id
                                              )?.sahayName
                                          )
                                          .join(", ")
                                      }
                                    >
                                      {getSubSahaydata?.map((name) => (
                                        <Index.MenuItem
                                          disabled
                                          className="select-menu-dropdowns"
                                          key={name?.Id}
                                          value={name?.Id}
                                        >
                                          <Index.Checkbox
                                            checked={
                                              values?.sahayId?.indexOf(
                                                name?.Id
                                              ) > -1
                                            }
                                            className="select-menu-checkbox"
                                          />
                                          <Index.ListItemText
                                            primary={name?.sahayName}
                                            className="select-menu-text-details"
                                          />
                                        </Index.MenuItem>
                                      ))}
                                    </Index.Select>
                                  </Index.FormControl>
                                </Index.Box>
                              </Index.Grid>
                            )}
                          </Index.Grid>
                          <Index.Box className="line" mt={3} mb={3}></Index.Box>
                        </Index.Box>
                      </form>
                    )}
                  </PagesIndex.Formik>
                </>
              ) : (
                <>
                  <Index.Box className="circular-loading-user-master">
                    <PagesIndex.PageLoading colSpan={12} />
                  </Index.Box>
                </>
              )}
            </Index.Box>
          </Index.Box>
        </>
      )}
    </>
  );
};
export default ViewUserMaster;
