import React, { useEffect, useState } from "react";

import Index from "../../../../container/Index";

import OutlinedInput from "@mui/material/OutlinedInput";
import PagesIndex from "../../../PagesIndex";
import { Autocomplete, Tooltip, tooltipClasses } from "@mui/material";

import moment from "moment";
import dayjs from "dayjs";

import {
  addUpdateBill,
  getAllFamilyWithMohollaNameAndAreaList,
  getAllProgramList,
  getSingleDisbursedBill,
} from "../../../../redux/features/AddService";
import { billSchema } from "../../../../validation/Validation";

import { imagePath } from "../../../../config/DataService";

import PageLoading from "../../../../components/common/pageLoading/PageLoading";
import { useSelector } from "react-redux";
import { MobileDatePicker } from "@mui/x-date-pickers";
import styled from "styled-components";
import { FieldArray } from "formik";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: "#eaac35",
    color: "#ffffff",
    fontSize: "11px",
    fontFamily: "Poppins-Regular",
    padding: "5px 9px",
    lineHeight: "19px",
    borderRadius: "10px",
  },
}));

const ariaLabel = { "aria-label": "description" };

const ViewBill = () => {
  const { getUserLoginDetails } = PagesIndex.useSelector(
    (state) => state.admin
  );

  const dispatch = PagesIndex.useDispatch();
  const navigate = PagesIndex.useNavigate();
  const location = PagesIndex.useLocation();

  const viewBillData = location?.state?.viewBillData;
  const rollData = JSON.parse(localStorage?.getItem("RollName"))?.toLowerCase();

  //all state

  const [pageLoader, setPageLoader] = useState(false);

  const [loader, setLoader] = useState(false);

  const handleBack = () => {
    navigate(`/${rollData}/bill-list`, {
      state: {
        rowID: location?.state?.viewBillData?.Id,
        prevState: location?.state?.viewBillData?.lastStatus,
      },
    });
  };

  const { rollPermissionData } = useSelector((state) => state.admin);

  // if (
  //   rollPermissionData?.permissionArray?.includes("Disbursement_add") ||
  //   rollPermissionData?.permissionArray?.includes("Disbursement_edit")
  // ) {
  // } else {
  //   navigate(`/${rollData}/bill-list`);
  // }

  return (
    <>
      <div>
        {pageLoader ? (
          <PageLoading />
        ) : (
          <>
            <Index.Box className="apply-sahay-main">
              <Index.Box className="head-content">
                <Index.Box
                  className={`user-right ${
                    location.pathname == "/admin/add-disbursement-application"
                      ? "admin-border-radius"
                      : ""
                  }`}
                >
                  <Index.Box
                    className={`bottom-user-right ${
                      location.pathname == "/admin/add-disbursement-application"
                        ? "admin-height-remove"
                        : ""
                    }`}
                  >
                    <Index.Box className="user-apply-content">
                      <Index.Box className="main-in-row">
                        <Index.Box className="user-basic user-heading">
                          <Index.Typography
                            component="h1"
                            className="head-apply"
                          >
                            View Bill
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="main-in-row">
                        <Index.Box
                          className="user-basic"
                          sx={{ display: "flex" }}
                        ></Index.Box>
                        <Index.Box
                          sx={{ flexGrow: 1 }}
                          className="al-form-groups"
                        >
                          <Index.Grid container spacing={2}>
                            {viewBillData?.BillDocuments?.length > 0 && (
                              <Index.Grid item xs={12} sm={12} md={12}>
                                <Index.Box className="title-header">
                                  <Index.Box className="title-header-flex">
                                    <Index.Box className="title-main ">
                                      <Index.Typography
                                        variant="p"
                                        component="p"
                                        className="page-title"
                                      >
                                        Documents
                                      </Index.Typography>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                                <Index.Grid item xs={8}>
                                  <Index.Box className="select-view-document-name">
                                    {viewBillData?.BillDocuments?.map(
                                      (file, index) => (
                                        <Index.Box
                                          className="document-name"
                                          key={index}
                                        >
                                          <Index.Typography
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                              // Implement the logic to view the document here
                                              // You can use a modal or a new browser tab/window to show the document
                                              window.open(
                                                `${imagePath}${file.Documents}`
                                              );
                                            }}
                                          >
                                            {file.Filename}
                                          </Index.Typography>
                                        </Index.Box>
                                      )
                                    )}
                                  </Index.Box>
                                </Index.Grid>
                              </Index.Grid>
                            )}
                            <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                              <Index.Box className="lable-input-grp">
                                <Index.Typography
                                  component="p"
                                  className="in-label"
                                >
                                  Program Name (પ્રોગ્રામનું નામ)
                                </Index.Typography>
                                <Index.TextField
                                  type="text"
                                  inputProps={ariaLabel}
                                  className="basic-input input-width"
                                  disabled
                                  value={
                                    viewBillData?.ProgramData?.[0]?.programName
                                  }
                                />
                              </Index.Box>
                            </Index.Grid>
                            <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                              <Index.Box className="lable-input-grp">
                                <Index.Typography
                                  component="p"
                                  className="in-label"
                                >
                                  Vendor Name (વિક્રેતાનું નામ)
                                </Index.Typography>
                                <Index.TextField
                                  type="text"
                                  inputProps={ariaLabel}
                                  className="basic-input input-width"
                                  name="vendorName"
                                  value={viewBillData?.vendorName}
                                />
                              </Index.Box>
                            </Index.Grid>

                            <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                              <Index.Box className="lable-input-grp">
                                <Index.Typography
                                  component="p"
                                  className="in-label"
                                >
                                  Bill Number (બિલ નંબર)
                                </Index.Typography>
                                <Index.TextField
                                  inputProps={ariaLabel}
                                  className="basic-input input-width"
                                  name="billNumber"
                                  value={viewBillData?.billNumber}
                                />
                              </Index.Box>
                            </Index.Grid>

                            <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                              <Index.Typography
                                component="p"
                                className="in-label"
                              >
                                Bill Date (બિલ તારીખ)
                              </Index.Typography>
                              <Index.Box className="input-design-div with-border ">
                                <Index.LocalizationProvider
                                  dateAdapter={Index.AdapterDayjs}
                                  sx={{ position: "absolute" }}
                                >
                                  <Index.DemoContainer
                                    components={["DatePicker"]}
                                  >
                                    <MobileDatePicker
                                      name="billDate"
                                      value={dayjs(viewBillData?.billDate)}
                                      format="YYYY-MM-DD"
                                      sx={{
                                        overflowY: "hidden",
                                        position: "relative",
                                        bottom: "8px",
                                        width: "100%",
                                      }}
                                      slotProps={{
                                        textField: {
                                          size: "small",
                                          error: false,
                                        },
                                      }}
                                    />
                                  </Index.DemoContainer>
                                </Index.LocalizationProvider>
                              </Index.Box>
                            </Index.Grid>

                            <>
                              <Index.Grid
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                lg={3}
                                mb={2}
                              >
                                <Index.Box className="lable-input-grp">
                                  <Index.Typography
                                    component="p"
                                    className="in-label"
                                  >
                                    Total Amount (કુલ રકમ)
                                  </Index.Typography>
                                  <Index.TextField
                                    type="number"
                                    inputProps={ariaLabel}
                                    className="basic-input input-width"
                                    name="totalAmount"
                                    value={viewBillData?.totalAmount}
                                  />
                                </Index.Box>
                              </Index.Grid>
                              <Index.Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                mb={12}
                              >
                                <Index.Box className="">
                                  <Index.TableContainer
                                    sx={{ maxHeight: 380 }}
                                    component={Index.Paper}
                                    className="table-container sahay-application-container scroll-table"
                                  >
                                    <Index.Table
                                      stickyHeader
                                      aria-label="sticky table"
                                      className="table-design-main barge-table sahay-appli-table add-bill-table-custom"
                                    >
                                      <Index.TableHead className="sahay-appli-head">
                                        <Index.TableRow className="sahay-appli-tr">
                                          <Index.TableCell
                                            className="sahay-appli-tr"
                                            align="left"
                                          >
                                            Member (સભ્ય)
                                          </Index.TableCell>

                                          <Index.TableCell
                                            className="sahay-appli-tr"
                                            align="left"
                                          >
                                            Amount (રકમ)
                                          </Index.TableCell>
                                        </Index.TableRow>
                                      </Index.TableHead>

                                      <Index.TableBody>
                                        <>
                                          {viewBillData?.memberAmountMain
                                            ?.length ? (
                                            viewBillData?.memberAmountMain?.map(
                                              (row, index) => (
                                                <Index.TableRow
                                                  key={index}
                                                  className={`sahay-appli-tr`}
                                                >
                                                  <Index.TableCell
                                                    align="left"
                                                    className="sahay-appli-td"
                                                  >
                                                    {row?.memberName}
                                                  </Index.TableCell>

                                                  <Index.TableCell
                                                    align="left"
                                                    className="sahay-appli-td"
                                                  >
                                                    {row?.amount}
                                                  </Index.TableCell>

                                           
                                                </Index.TableRow>
                                              )
                                            )
                                          ) : (
                                            <PagesIndex.NoRecordFound
                                              colSpan={12}
                                            />
                                          )}
                                        </>
                                      </Index.TableBody>
                                    </Index.Table>
                                  </Index.TableContainer>
                                </Index.Box>
                              </Index.Grid>
                            </>
                          </Index.Grid>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box
                        sx={{ flexGrow: 1 }}
                        className="al-form-groups fam-in-grp"
                      >
                        <Index.Grid item xs={6}>
                          <Index.Box className="common-button blue-button flex-end save-btn back-btn">
                            <Index.Button
                              variant="contained"
                              onClick={() => handleBack()}
                            >
                              Back
                            </Index.Button>
                          </Index.Box>
                        </Index.Grid>
                      </Index.Box>

                      <Index.Box className="title-header-flex "></Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </>
        )}
      </div>
    </>
  );
};

export default ViewBill;
