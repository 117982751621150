import PagesIndex from "../../../container/PagesIndex";
import Index from "../../Index";

const ButtonPageLoading = ({ loading, ariaLabel, dataTestId }) => (
  // <Index.TableRow>
  //   <Index.TableCell colSpan={props.colSpan} className="border-bottom-loader">
  <Index.Box className="loading-spinner">
    {/* <PagesIndex.PulseLoader color="#003d90" loading="loading" size={10} /> */}

    {/* <PagesIndex.PulseLoader
      color="#003d90"
      loading={loading}
      size={12}
      aria-label={ariaLabel}
      data-testid={dataTestId}
      // style={{ transform: "none", margin: "auto" }}
    /> */}
  </Index.Box>
  //   </Index.TableCell>
  // </Index.TableRow>
);

export default ButtonPageLoading;
