import React, { useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import Pagination from "../../../../components/common/Pagination";
import PagesIndex from "../../../PagesIndex";
import { getDisbursementData } from "../../../../redux/features/AddService";
import { useSelector } from "react-redux";
import PageLoading from "../../../../components/common/pageLoading/PageLoading";
import FileExcel from "../../../../components/common/excelSheet/FileExcel";
import { Autocomplete } from "@mui/material";
import RowPerPageDropdown from "../../../../components/common/paginationRowPerpage/RowPerPageDropdown";
import dataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import PrintReport from "../../../../components/common/printReport/PrintReport";
import { useReactToPrint } from "react-to-print";
import moment from "moment";

export default function DisbursementReport() {
const location = PagesIndex.useLocation()
 
  const { getDisbursementDataList } = PagesIndex.useSelector(
    (state) => state?.admin
  );

  const fileName = "Disbursement Report CSV";
  const [excel, setExcel] = useState([]);
  const [printData, setPrintData] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [disbursementDataList, setDisbursementDataList] = useState([]);
  const [sahaySubTypeList, setSahaySubTypeList] = useState([]);
  const [rowPerPage, setRowPerPage] = useState("25");
  const [loader, setLoader] = useState(true);
  const dispatch = PagesIndex.useDispatch();
  const [filterSubSahayName, setFilterSubSahayName] = useState("");
  const [filter, setFilter] = useState("");
  const [filterYear, setFilterYear] = useState("");
  const [searchedData, setSearchedData] = useState([]);
  const [filterReportData, setFilterReportData] = useState([]);
  const [selectDateData, setSelectDateData] = useState("");
  // Print Reports
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });



  
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  


  

  useEffect(() => {
    
    dispatch(getDisbursementData()).then((res) => {
      if (res?.payload?.status == 200) {
        setDisbursementDataList(res?.payload?.data);
        setLoader(false);
      }
      setLoader(false);
    });
  }, [filter, filterSubSahayName, filterYear, selectDateData]);

  //Filter Data
  // useEffect(() => {
  //   if (filter || filterSubSahayName || filterYear) {
  //     const filterData = getDisbursementDataList?.data?.filter((ele) => {
  //       if (filter && filterSubSahayName && filterYear) {
  //         return (
  //           ele?.FID === filter &&
  //           ele?.SubSahayName === filterSubSahayName &&
  //           ele?.Year === filterYear
  //         );
  //       } else if (filter && filterSubSahayName) {
  //         return (
  //           ele?.FID === filter && ele?.SubSahayName === filterSubSahayName
  //         );
  //       } else if (filterYear && filterSubSahayName) {
  //         return (
  //           ele?.Year === filterYear && ele?.SubSahayName === filterSubSahayName
  //         );
  //       } else if (filter && filterYear) {
  //         return ele?.FID === filter && ele?.Year === filterYear;
  //       } else if (filter || filterSubSahayName || filterYear) {
  //         return (
  //           ele?.FID === filter ||
  //           ele?.SubSahayName === filterSubSahayName ||
  //           ele?.Year === filterYear
  //         );
  //       } else {
  //         return [];
  //       }
  //     });
  //     setFilterReportData(filterData);
  //   } else {
  //     setFilterReportData([]);
  //   }
  // }, [filter, filterSubSahayName, filterYear]);

  useEffect(() => {
    const filters = {
      FID: filter,
      SubSahayName: filterSubSahayName,
      Year: filterYear,
      Date: selectDateData 
      // Assuming checkDateFilter is a Date object
      // Date: checkDateFilter ? moment(checkDateFilter).format("YYYY-MM-DD") : null,
    };

    const filterData = getDisbursementDataList?.data?.filter((ele) => {
      if (Object.values(filters).some(Boolean)) {
        return Object.entries(filters).every(([key, value]) => {
          if (!value) return true;
          return ele[key] == value;
        });
      } else {
        return true;
      }
    });

    setFilterReportData(filterData);
  }, [filter, filterSubSahayName, filterYear, selectDateData]);

  // Copy original data to searched data
  useEffect(() => {
    setSearchedData(filterReportData);
  }, [filterReportData]);

  // Search on table
  const requestSearch = (searched) => {
    if (!searched) return setSearchedData(filterReportData);
    setSearchedData(
      filterReportData?.filter((item) =>
        item?.ApplicantName?.toString()
          ?.toLowerCase()
          .includes(searched?.toLowerCase())
      )
    );
  };

  useEffect(() => {
    if (filterReportData?.length > 0) {
      const customHeadings = filterReportData?.map((item) => ({
        Fid: item?.FID,
        "Applicant name": item?.ApplicantName,
        "Beneficiary Name": item?.BeneficiaryName,
        Area: item?.Area,
        "Bank Name": item?.BankDetails?.BankName,
        Branch: item?.BankDetails?.BranchName,
        "A/c No": item?.BankDetails?.AccountNo,
        "IFSC code": item?.BankDetails?.IFSCCode,
        "Cheque No": item?.IssueChqDetail?.ChqNo,
        // "Bank Name": item?.IssueChqDetail?.BkName,
        "Sub Sahay Name": item?.SubSahayName,
      }));
      const customPrintHeadings = filterReportData?.map((item) => ({
        Fid: item?.FID,
        "Applicant name": item?.ApplicantName,
        "Beneficiary Name": item?.BeneficiaryName,
        Area: item?.Area,
        "Sub Sahay Name": item?.SubSahayName,
        "Bank Name": item?.BankDetails?.BankName,
        Branch: item?.BankDetails?.BranchName,
        "A/c No": item?.BankDetails?.AccountNo,
        "IFSC code": item?.BankDetails?.IFSCCode,
        "Account Holder Name": item?.HolderName,
        "Cheque No": item?.IssueChqDetail?.ChqNo,
        "Bank Name ": item?.IssueChqDetail?.BkName,
        "Disbursed Amount": item?.DisbursedAmount,
        "Disbursed Year": item?.Year,
      }));

      // setExcel(customHeadings);
      setExcel(customPrintHeadings);
      setPrintData(customPrintHeadings);
    }
  }, [filterReportData]);

  //get Sub sahay list
  const getSahaySubTypeData = async () => {
    try {
      const res = await dataService.get(Api.GET_ALL_SUB_SAHAY_LIST);
      if (res?.data?.status) {
        setSahaySubTypeList(res?.data?.Data);
      } else {
        setSahaySubTypeList([]);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getSahaySubTypeData();
  }, []);

    //#region start view sahay application

    const handleSahayApplicationView = (viewdata) => {
      const updatedViewData = {
        ...viewdata,
        familyId: viewdata?.FID,   
        prevRoute: location?.pathname,
      };
      navigate(`/${rollData}/sahay-report-view`, { state: updatedViewData });
    };
  
  
    //#region end view sahay application

  const { rollPermissionData } = useSelector((state) => state.admin);

  const rollData = JSON.parse(localStorage?.getItem("RollName"))?.toLowerCase();

  const navigate = PagesIndex.useNavigate();
  const rollPermissionNameData =
    rollPermissionData?.findData?.parentRoleName?.toLowerCase();

  if (
    rollPermissionData?.permissionArray?.includes("Disbursement Report_view") ||
    rollPermissionNameData === "admin"
  ) {
  } else {
    navigate(`/${rollData}/sahay-application`);
  }
  return (
    <>
      {loader ? (
        <PageLoading />
      ) : (
        <>
          <Index.Box className="page-content-box">
            <Index.Box className="barge-common-box">
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex res-title-header-flex">
                  <Index.Box className="title-main">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="page-title"
                    >
                      Disbursement Report
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="grid-row  sahay-multiple-main">
                <Index.Box sx={{ width: 1 }} className="grid-main">
                  <Index.Box
                    display="grid"
                    className="app-report-row"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 3",
                        lg: "span 3",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="sahay-multiple-input-main">
                        <PagesIndex.Search
                          className="search sahay-multiple-search"
                          onChange={(e) => requestSearch(e.target.value)}
                        >
                          <PagesIndex.StyledInputBase placeholder="Search" />
                        </PagesIndex.Search>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 3",
                        lg: "span 3",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="sahay-multiple-input-main">
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          className="multiple-autocomplet-control"
                          options={getDisbursementDataList?.data || []}
                          getOptionLabel={(option) => option?.FID}
                          onChange={(value, newvalue) => {
                            setFilter(newvalue?.FID);
                          }}
                          filterOptions={(options, { inputValue }) => {
                            const inputLowerCase = inputValue?.toLowerCase();
                            const uniqueFamilyId = new Set();
                            return options?.filter((option) => {
                              const familyId =
                                option?.FID?.toString()?.toLowerCase();
                              // Check unique
                              if (uniqueFamilyId?.has(familyId)) {
                                return false;
                              }
                              // Check search
                              if (familyId?.includes(inputLowerCase)) {
                                uniqueFamilyId?.add(familyId);
                                return true;
                              }
                              return false;
                            });
                          }}
                          renderInput={(params) => (
                            <Index.TextField {...params} label="Fid" />
                          )}
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 3",
                        lg: "span 3",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="sahay-multiple-input-main">
                        <Autocomplete
                          disablePortal
                          className="multiple-autocomplet-control"
                          id="combo-box-demo"
                          options={sahaySubTypeList || []}
                          getOptionLabel={(option) => option?.SahayName}
                          onChange={(value, newvalue) => {
                            setFilterSubSahayName(newvalue?.SahayName);
                          }}
                          renderInput={(params) => (
                            <Index.TextField {...params} label="Sub Sahay" />
                          )}
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 4",
                        lg: "span 3",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="sahay-multiple-input-main">
                        <Autocomplete
                          disablePortal
                          className="multiple-autocomplet-control"
                          id="combo-box-demo"
                          options={getDisbursementDataList?.data || []}
                          getOptionLabel={(option) => option?.Year}
                          onChange={(value, newvalue) => {
                            setFilterYear(newvalue?.Year);
                          }}
                          renderInput={(params) => (
                            <Index.TextField {...params} label="Year" />
                          )}
                          filterOptions={(options, { inputValue }) => {
                            const inputLowerCase = inputValue
                              ?.toLowerCase()
                              ?.replace(/\s/g, "");
                            const uniqueYear = new Set();
                            return options?.filter((option) => {
                              const year = option?.Year?.toLowerCase()?.replace(
                                /\s/g,
                                ""
                              );
                              // Check unique
                              if (uniqueYear?.has(year)) {
                                return false;
                              }
                              // Check search
                              if (year?.includes(inputLowerCase)) {
                                uniqueYear?.add(year);
                                return true;
                              }
                              return false;
                            });
                          }}
                        />
                      </Index.Box>
                      
                    </Index.Box>
                    <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 3",
                          lg: "span 3",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="sahay-multiple-input-main">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                            sx={{ position: "absolute" }}
                          >
                            <Index.DemoContainer components={["DatePicker"]}>
                              <Index.DatePicker
                                label="Application Receive Date"
                                name="applicationReceiveDate"
                                onChange={(value) => {
                              
                                  
                                  setSelectDateData(
                                   moment(value?.$d).format(
                                      "YYYY-MM-DD"
                                    )
                                  );
                                }}
                                // renderInput={(params) => (
                                //   <Index.TextField
                                //       {...params}
                                //       label="Application Receive Date"
                                //   />
                                // )}
                                format="YYYY-MM-DD"
                                sx={{
                                  // overflowY: "hidden",
                                  // position: "relative",
                                  bottom: "4px",
                                  width: "100%",
                                }}
                                slotProps={{
                                  textField: {
                                    size: "small",
                                    error: false,
                                  },
                                }}
                              />
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    {filterReportData?.length > 0 && (
                      <>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 3",
                            lg: "span 3",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="sahay-multiple-input-main">
                            <Index.Box
                              Box
                              className="res-auto-left common-button blue-button multiple-download-btn res-blue-button"
                            >
                              <FileExcel apiData={excel} fileName={fileName} />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 4",
                            lg: "span 3",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="sahay-multiple-input-main">
                            <Index.Box
                              Box
                              className="res-auto-left common-button blue-button multiple-download-btn res-blue-button"
                            >
                              <Index.Button onClick={handlePrint}>
                                Print
                              </Index.Button>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </>
                    )}
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="table-list">
                <Index.TableContainer
                 sx={{ maxHeight: 220 }}
                  component={Index.Paper}
                  className="table-container user-master-container scroll-table"
                >
                  <Index.Table
                   stickyHeader aria-label="sticky table"
                    className="table-design-main barge-table disbursement-report-table"
                  >
                    <Index.TableHead className="user-master-head">
                      <Index.TableRow className="user-master-tr">
                        <Index.TableCell className="user-master-th">
                          Fid
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Applicant Name
                        </Index.TableCell>

                        <Index.TableCell className="user-master-th">
                          Beneficiary name
                        </Index.TableCell>

                        <Index.TableCell className="user-master-th">
                          Area
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Sub Sahay Name
                        </Index.TableCell>
                        <Index.TableCell
                          className="user-master-th"
                          align="center"
                        >
                          Bank Detail of Applicant
                          <Index.Table
                            aria-label="simple table"
                            className="table-design-main barge-table user-master-table-inner"
                          >
                            <Index.TableHead>
                              <Index.TableRow>
                                <Index.TableCell>Bank Name</Index.TableCell>
                                <Index.TableCell>Branch</Index.TableCell>
                                <Index.TableCell>A/c No</Index.TableCell>
                                <Index.TableCell>IFSC code</Index.TableCell>
                                <Index.TableCell>
                                  Account Holder Name
                                </Index.TableCell>
                              </Index.TableRow>
                            </Index.TableHead>
                          </Index.Table>
                        </Index.TableCell>

                        <Index.TableCell
                          className="user-master-th"
                          align="center"
                        >
                          Issue Cheque Detail
                          <Index.Table
                            aria-label="simple table"
                            className="table-design-main barge-table user-master-table-inner"
                          >
                            <Index.TableHead>
                              <Index.TableRow>
                                <Index.TableCell>Cheque No</Index.TableCell>
                                <Index.TableCell>Bank Name</Index.TableCell>
                              </Index.TableRow>
                            </Index.TableHead>
                          </Index.Table>
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Disbursed Date
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Disbursed Amount
                        </Index.TableCell>
                        <Index.TableCell className="user-master-th">
                          Disbursed Year
                        </Index.TableCell>
                      </Index.TableRow>
                    </Index.TableHead>
                    <Index.TableBody className="user-master-tbody">
                      
                      {pageData?.length &&
                      (filter || filterSubSahayName || filterYear || selectDateData) ? (
                        pageData?.map((data, index) => (
                          <Index.TableRow className="user-master-tr"     onClick={() => handleSahayApplicationView(data)}
                          sx={{ cursor: "pointer" }}>
                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-role-name">
                                {data?.FID || "-"}
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-details-name">
                                {data?.ApplicantName || "-"}
                              </Index.Box>
                            </Index.TableCell>

                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-email-name">
                                {data?.BeneficiaryName || "-"}
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-number-name">
                                {data?.Area || "-"}
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-number-name">
                                {data?.SubSahayName || "-"}
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-number-name">
                                <Index.Table
                                  aria-label="simple table"
                                  className="table-design-main barge-table user-master-table-inner"
                                >
                                  <Index.TableBody>
                                    <Index.TableRow>
                                      <Index.TableCell>
                                        {data?.BankDetails?.BankName || "-"}
                                      </Index.TableCell>
                                      <Index.TableCell>
                                        {data?.BankDetails?.BranchName || "-"}
                                      </Index.TableCell>
                                      <Index.TableCell>
                                        {data?.BankDetails?.AccountNo || "-"}
                                      </Index.TableCell>
                                      <Index.TableCell>
                                        {data?.BankDetails?.IFSCCode || "-"}
                                      </Index.TableCell>
                                      <Index.TableCell>
                                        {data?.HolderName || "-"}
                                      </Index.TableCell>
                                    </Index.TableRow>
                                  </Index.TableBody>
                                </Index.Table>
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-number-name">
                                <Index.Table
                                  aria-label="simple table"
                                  className="table-design-main barge-table user-master-table-inner"
                                >
                                  <Index.TableBody>
                                    <Index.TableRow>
                                      <Index.TableCell>
                                        {data?.IssueChqDetail?.ChqNo || "-"}
                                      </Index.TableCell>
                                      <Index.TableCell>
                                        {data?.IssueChqDetail?.BkName || "-"}
                                      </Index.TableCell>
                                    </Index.TableRow>
                                  </Index.TableBody>
                                </Index.Table>
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-email-name">
                              {data?.CreatedDateDisburse
                                  ? data?.Date
                                  : "-"}
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-email-name">
                                {data?.DisbursedAmount || "-"}
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className="user-master-td">
                              <Index.Box className="user-email-name">
                                {data?.Year || "-"}
                              </Index.Box>
                            </Index.TableCell>
                          </Index.TableRow>
                        ))
                      ) : (
                        <PagesIndex.NoRecordFound colSpan={12} />
                      )}
                    </Index.TableBody>
                  </Index.Table>
                </Index.TableContainer>
                {filterReportData?.length > 0 && searchedData?.length > 0 &&  (filter || filterSubSahayName || filterYear || selectDateData) ? (
                  <Index.Box className="multiple-total-text-main">
                    <Index.Typography className="multiple-total-text">
                      {/* Total Amount : {tableData?.totalAmount} */}
                      Total Amount :
                      {searchedData.length > 0 &&
                        searchedData?.reduce((acc, curr) => {
                      

                          let total;
                          total = acc + curr?.DisbursedAmount;
                          return total;
                        }, 0)}
                    </Index.Typography>
                  </Index.Box>
                ) :  ""}
              </Index.Box>
              <Index.Box className="pagination-design flex-end">
                <RowPerPageDropdown
                  rowPerPage={rowPerPage}
                  setRowPerPage={setRowPerPage}
                />
                <Index.Stack spacing={2}>
                  <Pagination
                    fetchData={searchedData}
                    setPageData={setPageData}
                    pageData={pageData}
                    rowPerPage={rowPerPage}
                  />
                </Index.Stack>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box sx={{ display: "none" }}>
            <PrintReport
              componentRef={componentRef}
              tableData={printData}
              reportName={[
                { name: "", type: "DisbursementReport" },
                {
                  name: "Bank Detail of Applicant",
                  type: "DisbursementReport",
                },
                { name: "Issue Cheque Detail", type: "DisbursementReport" },
                { name: "", type: "DisbursementReport" },
              ]}
            />
          </Index.Box>
        </>
      )}
    </>
  );
}
