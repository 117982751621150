import React, { useState, useEffect } from "react";
import Index from "../../container/Index";

const Pagination = ({ fetchData, setPageData, pageData, search ,rowPerPage}) => {



  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  useEffect(() => {
    const recordsPerPage = rowPerPage == "All"? fetchData?.length  : parseInt(rowPerPage);
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const records = fetchData && fetchData?.slice(firstIndex, lastIndex);
    const numbersOfPage = Math.ceil(fetchData?.length / recordsPerPage);
    setPageCount(numbersOfPage);
    setPageData(records);
    if (records?.length === 0 && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }, [currentPage, fetchData, search, setPageData,rowPerPage]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <>
      {pageData?.length !== 0 ? (
        <Index.Box className="pagination-main">
          <Index.Pagination
            count={pageCount}
            page={currentPage}
            onChange={handlePageChange}
            shape="rounded"
            className="pagination"
          />
        </Index.Box>
      ) : (
        ""
      )}
    </>
  );
};

export default Pagination;
