import React, { useEffect, useState } from "react";
import Sidebar from "../../../../components/user/defaultLayout/Sidebar";
import Index from "../../../../container/Index";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import PagesIndex from "../../../PagesIndex";
import RemoveIcon from "@mui/icons-material/Remove";
import moment from "moment";
import dayjs from "dayjs";
import * as yup from "yup";
import {
  getFamilyIds,
  getSahayTypeList,
  getSahaySubTypeList,
  getFamilyMemberById,
  applyForSahayAPI,
  listYearOfSahay,
  bankAccountsList,
  getRelationMasterList,
  addPreviousSahayData,
  getListOfFamilyId,
  updatePreviousData,
  getVastiPatrakListOfFamilyId,
} from "../../../../redux/features/AddService";
import {
  validationSchemaPreviousSahay,
  validationSchemaApplyForSahayBankMember,
  validationSchemaPreviousSahayRepayDetail,
} from "../../../../validation/Validation";
import { Autocomplete, CircularProgress, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import PageLoading from "../../../../components/common/pageLoading/PageLoading";
import { imagePath } from "../../../../config/DataService";
import ButtonPageLoading from "../../../../components/common/pageLoading/ButtonPageLoading";
import { useSelector } from "react-redux";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const applySahayValidation = yup.object().shape({
  AadharNo: yup
    .number()
    .typeError("Only Number allowed")
    .required("Aadhar No Required"),
});

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const ariaLabel = { "aria-label": "description" };

const PreviousApplyForSahay = () => {
  const { editPreviousSahayData, getUpdatedPriviousData } =
    PagesIndex.useSelector((state) => state.admin);

  const {
    getUserLoginDetails,
    sahayApplicationAdminSingleGet,
    getRelationList,
  } = PagesIndex.useSelector((state) => state.admin);

  const theme = useTheme();
  const dispatch = PagesIndex.useDispatch();
  const navigate = PagesIndex.useNavigate();
  const location = PagesIndex.useLocation();
  const editPreviousData = location?.state;
  //all state
  const [personName, setPersonName] = React.useState([]);
  const [radioButtonFamily, setRadioButtonFamily] = React.useState(
    editPreviousData?.vastipatrak == 0 ? "NonVastrikpatrak" : "Vastrikpatrak"
  );



  const [validationMore, setValidationMoreTrue] = React.useState(false);
  const [documentError, setDocumentError] = React.useState(false);
  const [listYearOfSahayData, setListYearOfSahayData] = useState([]);
  const [bankAccountsListData, setBankAccountsListData] = useState([]);
  const [applicantId, setApplicantId] = useState();
  const [familyIdsList, setFamilyIdsList] = useState();
  const [sahayTypeList, setSahayTypeList] = useState();
  const [sahaySubTypeList, setSahaySubTypeList] = useState();
  const [familyMembers, setFamilyMembers] = useState();
  const [selectedImage, setSelectedImage] = useState();
  const [sahayTypecondition, setSahayTypeCondition] = useState();
  const [sahayCondition, setSahayCondition] = useState();
  const [singlaFamilyId, setSingleFamilyId] = useState();
  const [getDocument, setGetDocument] = useState({});
  const [selectApplicant, setSelectApplicant] = useState();
  const [getSingleSubSahay, setGetSingleSubSahay] = useState();
  const [applicantSelectedMember, setApplicantSelectedMember] = useState([]);
  const [applicantAllMember, setApplicantAllMember] = useState([]);
  const [isLoadingFamilyId, setIsLoadingFamilyId] = useState(false);
  const [loader, setLoader] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [loadingTimeout, setLoadingTimeout] = useState(null);

  const rollData = JSON.parse(localStorage?.getItem("RollName"))?.toLowerCase();

  ///// Previous Sahay Data

  const [familyListData, setFamilyListData] = useState([]);
  const [updatePreviousSahayData, setUpdatePreviousSahayData] = useState([]);
  const [familyIdForApplicant, setFamilyIdForApplicant] = useState("");

  const EducationSubsahayValue = [
    1015, 1017, 1010, 1007, 1006, 1008, 1009, 1010, 1011, 1012, 1013, 1019,
    1018, 1020, 1021, 1022, 1023,
  ];

  let familyMemberName =
    getUserLoginDetails &&
    getUserLoginDetails?.data?.mem_fname +
      " " +
      getUserLoginDetails?.data?.mem_mname +
      " " +
      getUserLoginDetails?.data?.mem_lname;

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const [alignment, setAlignment] = React.useState("web");



  useEffect(() => {
    if (editPreviousData?.applicationId) {
      setPageLoader(true);
      dispatch(updatePreviousData(editPreviousData?.applicationId)).then(
        (data) => {
          if (data?.payload?.status == 200 || 201) {
            setUpdatePreviousSahayData(data?.payload?.data);
            setRadioButtonFamily(
              data?.payload?.data?.sahayApplication?.vastipatrak == 1
                ? "Vastrikpatrak"
                : "NonVastrikpatrak"
            );
            setTimeout(() => {
              setPageLoader(false);
            }, 1000);
          } else {
            setTimeout(() => {
              setPageLoader(false);
            }, 1000);
          }
        }
      );
    }
  }, [editPreviousData?.applicationId]);

  const getFamilyMemberIds = (status) => {

    let newState = radioButtonFamily == "Vastrikpatrak" ? true : false;
    dispatch(getFamilyIds(newState)).then((res) => {
      setFamilyIdsList(
        res?.payload?.map((ele, i) => {
          return {
            familyId: ele?.familyId,
            firstName: ele?.firstName,
            lastName: ele?.lastName,
            middleName: ele?.middleName,
            fullName:
              ele?.firstName + " " + ele?.middleName + " " + ele?.lastName,
          };
        })
      );
    });
  };
  const handleMenuOpen = () => {
    setLoader(true);
    setLoadingTimeout(
      familyListData &&
        setTimeout(() => {
          setLoader(false);
        }, 2000)
    );
  };
  const handleMenuClose = () => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  };
  /// Add Previous Sahay Data ///////
  const handlePreviousSahayData = () => {
    dispatch(addPreviousSahayData());
  };

  const getSahayTypeData = () => {
    dispatch(getSahayTypeList()).then((res) => {
      setSahayTypeList(res?.payload);
    });
  };

  const getSahaySubTypeData = (sahayTypeId) => {
    dispatch(getSahaySubTypeList(sahayTypeId)).then((res) => {
      setSahaySubTypeList(res?.payload);
    });
  };
  useEffect(() => {
    getSahaySubTypeData(+editPreviousData?.SahayType);
  }, [+editPreviousData?.SahayType]);

  const setMemberDeatils = (applicantId, setFieldValue) => {
    const member =
      familyMembers &&
      familyMembers?.filter((member) => member?.id == applicantId);
    if (member?.[0]) {
      setFieldValue(
        "memberName",
        member?.[0]?.firstName +
          " " +
          member?.[0]?.middleName +
          " " +
          member?.[0]?.lastName
      );
      setFieldValue("age", member?.[0]?.age);
      setFieldValue("workingType", member?.[0]?.workingType);
      setFieldValue("relation", member?.[0]?.relation);
      setFieldValue("relation_id", member?.[0]?.relation_id);
      setFieldValue("monthlyIncome", member?.[0]?.annualIncome);
      setFieldValue(
        "firstNameApplicant",
        member?.[0]?.firstName + " " + member?.[0]?.lastName
      );
    }
  };

  useEffect(() => {
    getFamilyMemberIds("Vastrikpatrak");
    getSahayTypeData();
    dispatch(getRelationMasterList());
  }, []);

  const resetImage = (setFieldValue) => {
    setFieldValue("passportPic", "");
    setSelectedImage("");
  };

  let initialValues = {
    SahayType: editPreviousData?.applicationId
      ? +editPreviousData?.SahayType
      : "",
    subSahayType: editPreviousData?.applicationId
      ? +editPreviousData?.subSahayType
      : "",
    SahayAmmount: editPreviousData?.applicationId
      ? editPreviousData?.SahayAmmount
      : 0,
    yearOfSahay: editPreviousData?.applicationId
      ? editPreviousData?.yearOfSahay
      : "",
    familyId: editPreviousData?.applicationId ? editPreviousData?.familyId : "",
    repayDetails: editPreviousData?.applicationId
      ? editPreviousData?.repayDetails
      : "",
    totalMonth: editPreviousData?.applicationId
      ? editPreviousData?.totalMonth
      : 1,
    startDate: editPreviousData?.applicationId
      ? editPreviousData?.startDate
      : "",
    applicantId: editPreviousData?.applicationId
      ? +editPreviousData?.applicantId
      : "",
    documents: editPreviousData?.applicationId
      ? updatePreviousSahayData?.documents
      : [],
    yearType: "previousYear",
    isRepayDetails: editPreviousData?.applicationId
      ? editPreviousData?.repayDetails
      : "",
    // isRepayDetails:
    //   editPreviousData?.applicationId && editPreviousData?.repayDetails ? true : false,

    // rentSahayAmmount: "",
    // claimedAmmount: 0,

    // noOfMonth: 1,
    // // trustName: "",
    // // ammount: "",

    // bankMasterId: "",
    // typeOfDisease: "",

    // passportPic: "",
    // remark: "",
    // isMediclaim: "No",
    // mediclaimPremium: 0,
    isSubmit: false,
    // dataArray: [
    //   {
    //     trustName: "",
    //     ammount: 0,
    //   },
    // ],
    // relationRecords: [
    //   {
    //     firstName: "",
    //     lastName: "",
    //     middleName: "",
    //     age: "",
    //     relation_id: "",
    //     workingType: "",
    //     AnnualIncome: 0,
    //   },
    // ],
  };

  useEffect(() => {
    dispatch(listYearOfSahay()).then((data) => {
      if (data?.payload) {
        // const currentYear = new Date().getFullYear();
        // const filteredData = data?.payload?.filter((item) => {
        //   return (
        //     (item?.fromYear < currentYear &&
        //       item?.toYear !== currentYear + 1) ||
        //     (item?.fromYear < currentYear && item?.toYear === currentYear)
        //   );
        // });
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth(); // 0-indexed, January is 0
        const filteredData = data?.payload?.filter((item) => {
          return (
            (item?.fromYear < currentYear && item?.toYear !== currentYear) ||
            (item?.fromYear === currentYear - 1 &&
              item?.toYear === currentYear &&
              currentMonth >= 3) || 
            item?.fromYear > currentYear
          );
        });
        if (filteredData?.length) {
          setListYearOfSahayData(filteredData);
        } else {
          setListYearOfSahayData([]);
        }
      } else {
        setListYearOfSahayData([]);
      }
    });
  }, []);

  useEffect(() => {
    if (applicantId) {
      dispatch(bankAccountsList(applicantId)).then((data) => {
        if (data?.payload) {
          setBankAccountsListData(data?.payload);
        } else {
          setBankAccountsListData([]);
        }
      });
    }
  }, [applicantId]);

  ///////////////// Previous sahay ///////////////

  useEffect(() => {
    let newState = radioButtonFamily == "Vastrikpatrak" ? true : false;

    dispatch(getFamilyIds(newState)).then((res) => {
      if (res?.payload) {

        setFamilyListData(res?.payload);
      } else {
        setFamilyListData([]);
      }
    });
  }, [radioButtonFamily]);


  useEffect(() => {
    // let familyId = localStorage.getItem("familyId")
    //   ? localStorage.getItem("familyId")
    //   : 0;

    let familyId = editPreviousData?.familyId
      ? editPreviousData?.familyId
      : familyIdForApplicant;
    if (editPreviousData?.applicationId) {
      setRadioButtonFamily(
        editPreviousData?.vastipatrak == 1
          ? "Vastrikpatrak"
          : "NonVastrikpatrak"
      );
    }
    let data;
    if (radioButtonFamily === "Vastrikpatrak") {
      data = {
        familyId: familyId,
        // VastiPatra: getUserLoginDetails?.vastipatrak,
        VastiPatra: true,
      };
    } else {
      data = {
        familyId: familyId,
        // VastiPatra: getUserLoginDetails?.vastipatrak,
        VastiPatra: false,
      };
    }
    setSingleFamilyId(familyId);
    dispatch(getFamilyMemberById(data)).then((res) => {
      setFamilyMembers(res?.payload);
    });
  }, [familyIdForApplicant, editPreviousData?.familyId, radioButtonFamily]);

  const handleBack = () => {
    navigate(`/${rollData}/sahay-application`, {
      state: { tabType: "previous" },
    });
  };

  const handleFormSubmit = (values, { setFieldValue }) => {
    setFieldValue("isSubmit", true);
    const data = new FormData();

    if (editPreviousData?.applicationId) {
      data.append("id", editPreviousData?.applicationId);
    }
    data.append("familyId", +values?.familyId);
    data.append("startDate", values?.startDate);
    data.append("repayDetails", values?.repayDetails);
    data.append("totalMonth", +values?.totalMonth);

    data.append("SahayType", +values?.SahayType);
    data.append("subSahayType", +values?.subSahayType);

    data.append("applicantId", +values?.applicantId);

    data.append("SahayAmmount", +values?.SahayAmmount);

    data.append("yearOfSahay", +values?.yearOfSahay);
    data.append("vastiPatrak", radioButtonFamily == "Vastrikpatrak" ? 1 : 0);
    data.append("yearType", "previousYear");

    // if (values?.documents?.length == 0) {
    //   setDocumentError(true);
    //   return 0;
    // }

    values?.documents?.length > 0 &&
      values?.documents?.forEach((ele) => {
        if (ele instanceof Blob) {
          data.append("newDocuments", ele);
        } else {
          data.append("documents", ele?.document);
        }
      });

    dispatch(addPreviousSahayData(data)).then((res) => {
      if (res?.payload?.status == 201 || 200) {
        navigate(`/${rollData}/sahay-application`, {
          state: { tabType: "previous" },
        });
        // navigate("/admin/sahay-application");
        setTimeout(() => {
          setFieldValue("isSubmit", false);
        }, 1000);
      } else {
        setTimeout(() => {
          setFieldValue("isSubmit", false);
        }, 1000);
      }
    });
  };

 

  const { rollPermissionData } = useSelector((state) => state.admin);

  const rollPermissionNameData =
    rollPermissionData?.findData?.parentRoleName?.toLowerCase();

  if (
    rollPermissionData?.permissionArray?.includes(
      "Previous Sahay Application_add"
    ) ||
    rollPermissionData?.permissionArray?.includes(
      "Previous Sahay Application_edit"
    )
    //  || rollPermissionNameData === "admin"
  ) {
  } else {
    navigate(`/${rollData}/sahay-application`);
  }

  return (
    <>
      <div>
        {pageLoader ? (
          <PageLoading />
        ) : (
          <>
            <Index.Box className="apply-sahay-main">
              {location.pathname !== "/admin/apply-for-previous-sahay"}

              <Index.Box className="head-content">
                <Index.Box
                  className={`user-right ${
                    location.pathname == "/admin/apply-for-previous-sahay"
                      ? "admin-border-radius"
                      : ""
                  }`}
                >
                  <Index.Box
                    // className={location.pathname == "/admin/apply-for-previous-sahay" ? "" :"bottom-user-right"}
                    className={`bottom-user-right ${
                      location.pathname == "/admin/apply-for-previous-sahay"
                        ? "admin-height-remove"
                        : ""
                    }`}
                  >
                    <Index.Box className="user-apply-content">
                      <PagesIndex.Formik
                        enableReinitialize
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={
                          validationMore == false
                            ? validationSchemaPreviousSahay
                            : validationSchemaPreviousSahayRepayDetail
                        }
                      >
                        {({
                          values,
                          handleChange,
                          handleSubmit,
                          touched,
                          setFieldValue,
                          handleBlur,
                          errors,
                          isValid,
                          isSubmitting,
                        }) => (
                          <form onSubmit={handleSubmit}>
                       
                            <Index.Box className="main-in-row">
                              <Index.Box className="user-basic user-heading">
                                <Index.Typography
                                  component="h1"
                                  className="head-apply"
                                >
                                  Apply for previous sahay
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className="main-in-row">
                              <Index.Box
                                className="user-basic"
                                sx={{ display: "flex" }}
                              >
                                <Index.Typography
                                  component="p"
                                  className="head-apply"
                                >
                                  Basic Details
                                </Index.Typography>
                                <Index.Typography
                                  component="h1"
                                  className="head-apply"
                                >
                                  ({" "}
                                  {getUserLoginDetails?.vastipatrak
                                    ? "Member"
                                    : "Non Member"}{" "}
                                  )
                                </Index.Typography>
                              </Index.Box>
                              <Index.FormControl sx={{ marginBottom: "15px" }}>
                                <Index.RadioGroup
                                  row
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="family_radio_button"
                                  // value={values?.family_radio_button}
                                  value={radioButtonFamily}
                                  defaultValue="Vastrikpatrak"
                                  onChange={(e) => {
                                    handleChange(e);
                               
                                    setRadioButtonFamily(e.target.value);
                                  }}
                                >
                                  <Index.FormControlLabel
                                    value="Vastrikpatrak"
                                    control={<Index.Radio />}
                                    label="Vastrikpatrak Member"
                                    disabled={editPreviousData?.applicationId}
                                  />
                                  <Index.FormControlLabel
                                    value="NonVastrikpatrak"
                                    control={<Index.Radio />}
                                    label="Non Vastrikpatrak Member"
                                    disabled={editPreviousData?.applicationId}
                                  />
                                </Index.RadioGroup>
                              </Index.FormControl>
                              <Index.Box
                                sx={{ flexGrow: 1 }}
                                className="al-form-groups"
                              >
                                <Index.Grid container spacing={2}>
                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    {
                                      // location.pathname ==
                                      // "/admin/apply-for-previous-sahay" ?
                                      !editPreviousData?.applicationId ? (
                                        <Index.Box className="lable-input-grp">
                                          <Index.Typography
                                            component="p"
                                            className="in-label"
                                          >
                                            FamilyId* (ફેમિલી આઈડી)
                                          </Index.Typography>

                                          <Index.Box className="sahay-multiple-input-main">
                                            <Autocomplete
                                              disablePortal
                                              className="multiple-autocomplet-control"
                                              id="combo-box-demo"
                                              disabled={
                                                editPreviousData?.applicationId
                                              }
                                              options={familyListData || []}
                                              onOpen={handleMenuOpen}
                                              onClose={handleMenuClose}
                                              loading={loader}
                                              // getOptionLabel={(option) =>
                                              //   option?.family_id?.toString()
                                              // }
                                              getOptionLabel={(option) =>
                                                `${option?.familyId} ${option?.firstName} ${option?.middleName} ${option?.lastName}`
                                              }
                                              onChange={(value, newvalue) => {
                                                setFieldValue(
                                                  "familyId",
                                                  newvalue?.familyId
                                                );
                                                setFamilyIdForApplicant(
                                                  newvalue?.familyId
                                                );
                                                getSahaySubTypeData(
                                                  newvalue?.familyId
                                                );
                                                // setFilter({year:newvalue?.SubSahayWiseAmt?.Year})
                                              }}
                                              renderInput={(params) => (
                                                <Index.TextField
                                                  {...params}
                                                  placeholder="Select FamilyId"
                                                  InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                      <React.Fragment>
                                                        {/* {loader ? <CircularProgress color="inherit" size={20} /> : null} */}
                                                        {
                                                          params.InputProps
                                                            .endAdornment
                                                        }
                                                      </React.Fragment>
                                                    ),
                                                  }}
                                                />
                                              )}
                                            />
                                          </Index.Box>
                                          {/* <Index.FormControl
                                          sx={{ width: "100%" }}
                                          className="main-select"
                                        >
                                          <Index.Select
                                            className="select-drop"
                                            displayEmpty
                                            disabled={ editPreviousData?.applicationId}
                                            // value={values?.SahayType}
                                            value={values?.familyId}
                                            onOpen={handleMenuOpen}
                                            onClose={handleMenuClose}
                                            onChange={(e) => {
                                              setFieldValue(
                                                "familyId",
                                                e.target.value
                                              );
                                              setFamilyIdForApplicant(
                                                e.target.value
                                              );
                                              getSahaySubTypeData(
                                                e.target.value
                                              );
                                            }}
                                            onBlur={handleBlur}
                                            input={<OutlinedInput />}
                                            MenuProps={MenuProps}
                                            inputProps={{
                                              "aria-label": "Without label",
                                            }}
                                          >
                                            {loader ? (
                                              <Index.Box className="loader-container">
                                                <CircularProgress className="small-circular-progress" />
                                              </Index.Box>
                                            ) : (
                                              familyListData?.map((ele) => (
                                                <Index.MenuItem
                                                  key={ele?.family_id}
                                                  value={ele?.family_id}
                                                >
                                                  {ele?.family_id}
                                                </Index.MenuItem>
                                              ))
                                            )}
                                          </Index.Select>
                                        </Index.FormControl> */}
                                          {errors?.familyId &&
                                            touched?.familyId && (
                                              <p className="error-text">
                                                {errors?.familyId}
                                              </p>
                                            )}
                                        </Index.Box>
                                      ) : (
                                        <>
                                          <Index.Box className="lable-input-grp">
                                            <Index.Typography
                                              component="p"
                                              className="in-label"
                                            >
                                              FamilyId* (ફેમિલી આઈડી)
                                            </Index.Typography>
                                            <Index.TextField
                                              inputProps={ariaLabel}
                                              className="basic-input input-width"
                                              value={
                                                editPreviousData?.familyId &&
                                                editPreviousData?.familyId
                                              }
                                            />
                                          </Index.Box>{" "}
                                        </>
                                      )
                                    }
                                  </Index.Grid>
                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        Name Of Applicant* (અરજદારનું નામ)
                                      </Index.Typography>
                                      <Index.FormControl
                                        sx={{ width: "100%" }}
                                        className="main-select"
                                      >
                                        <Index.Select
                                          className="select-drop"
                                          displayEmpty
                                          value={values?.applicantId}
                                          onChange={(e) => {
                                            setFieldValue(
                                              "applicantId",
                                              e.target.value
                                            );

                                            setMemberDeatils(
                                              e.target.value,
                                              setFieldValue
                                            );

                                            setApplicantId(e.target.value);
                                            setApplicantSelectedMember(
                                              familyMembers?.filter((item) => {
                                                return (
                                                  item?.id == e.target.value
                                                );
                                              })
                                            );
                                            setApplicantAllMember(
                                              familyMembers?.filter((item) => {
                                                return (
                                                  item?.id != e.target.value
                                                );
                                              })
                                            );
                                            setFieldValue(
                                              "relationRecords",
                                              familyMembers?.filter((item) => {
                                                return (
                                                  item?.id !== e.target.value
                                                );
                                              })
                                            );
                                          }}
                                          onBlur={handleBlur}
                                          input={<OutlinedInput />}
                                          MenuProps={MenuProps}
                                          inputProps={{
                                            "aria-label": "Without label",
                                          }}
                                        >
                                          {familyMembers?.map((ele) => (
                                            <Index.MenuItem
                                              key={ele?.id}
                                              value={ele?.id}
                                            >
                                              {ele?.firstName +
                                                " " +
                                                ele?.middleName +
                                                " " +
                                                ele?.lastName}
                                            </Index.MenuItem>
                                          ))}
                                        </Index.Select>
                                      </Index.FormControl>
                                      {errors?.applicantId &&
                                        touched?.applicantId && (
                                          <p className="error-text">
                                            {errors?.applicantId}
                                          </p>
                                        )}
                                    </Index.Box>
                                  </Index.Grid>
                                  {/* <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                <Index.Box className="lable-input-grp">
                                  <Index.Typography
                                    component="p"
                                    className="in-label"
                                  >
                                    Aadhar Card Number
                                  </Index.Typography>
                                  <Index.TextField
                                    type="string"
                                    inputProps={ariaLabel}
                                    className="basic-input input-width"
                                    name="AadharNo"
                                    value={values?.AadharNo}
                                    onChange={(e) => {
                                      const inputValue = e.target.value.replace(
                                        /\D/g,
                                        ""
                                      ); // Remove non-numeric characters
                                      setFieldValue(
                                        "AadharNo",
                                        inputValue.slice(0, 12)
                                      );
                                    }}
                                    onBlur={handleBlur}
                                  />
                                </Index.Box>
                                {errors?.AadharNo && touched?.AadharNo && (
                                  <p className="error-text">
                                    {errors?.AadharNo}
                                  </p>
                                )}
                              </Index.Grid> */}
                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        Sahay Type* (શાખાનો પ્રકાર)
                                      </Index.Typography>
                                      <Index.FormControl
                                        sx={{ width: "100%" }}
                                        className="main-select"
                                      >
                                        <Index.Select
                                          className="select-drop"
                                          displayEmpty
                                          value={values?.SahayType}
                                          onChange={(e) => {
                                            setSahayTypeCondition(
                                              e.target.value
                                            );
                                            setFieldValue(
                                              "SahayType",
                                              e.target.value
                                            );
                                            getSahaySubTypeData(e.target.value);
                                          }}
                                          onBlur={handleBlur}
                                          input={<OutlinedInput />}
                                          MenuProps={MenuProps}
                                          inputProps={{
                                            "aria-label": "Without label",
                                          }}
                                        >
                                          {sahayTypeList?.map((ele) => (
                                            <Index.MenuItem
                                              key={ele?.Id}
                                              value={ele?.Id}
                                            >
                                              {ele?.Sahay_category}
                                            </Index.MenuItem>
                                          ))}
                                        </Index.Select>
                                      </Index.FormControl>
                                      {errors?.SahayType &&
                                        touched?.SahayType && (
                                          <p className="error-text">
                                            {errors?.SahayType}
                                          </p>
                                        )}
                                    </Index.Box>
                                  </Index.Grid>
                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        Sub Sahay Type* (સબ સહાય પ્રકાર)
                                      </Index.Typography>
                                      <Index.FormControl
                                        sx={{ width: "100%" }}
                                        className="main-select"
                                      >
                                        <Index.Select
                                          className="select-drop"
                                          displayEmpty
                                          value={values?.subSahayType}
                                          onChange={(e) => {
                                            setGetSingleSubSahay(
                                              e.target.value
                                            );
                                            setFieldValue(
                                              "subSahayType",
                                              e.target.value
                                            );
                                          }}
                                          onBlur={handleBlur}
                                          input={<OutlinedInput />}
                                          MenuProps={MenuProps}
                                          inputProps={{
                                            "aria-label": "Without label",
                                          }}
                                        >
                                          {sahaySubTypeList?.map((ele) => (
                                            <Index.MenuItem
                                              key={ele?.Id}
                                              value={ele?.Id}
                                            >
                                              {ele?.SahayName}
                                            </Index.MenuItem>
                                          ))}
                                        </Index.Select>
                                      </Index.FormControl>
                                      {errors?.subSahayType &&
                                        touched?.subSahayType && (
                                          <p className="error-text">
                                            {errors?.subSahayType}
                                          </p>
                                        )}
                                    </Index.Box>
                                  </Index.Grid>
                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        Year Of Sahay* (સહાયનું વર્ષ)
                                      </Index.Typography>
                                      <Index.FormControl
                                        sx={{ width: "100%" }}
                                        className="main-select"
                                      >
                                        <Index.Select
                                          className="select-drop"
                                          displayEmpty
                                          value={values?.yearOfSahay}
                                          onChange={(e) => {
                                            setFieldValue(
                                              "yearOfSahay",
                                              e.target.value
                                            );
                                          }}
                                          onBlur={handleBlur}
                                          input={<OutlinedInput />}
                                          MenuProps={MenuProps}
                                          inputProps={{
                                            "aria-label": "Without label",
                                          }}
                                        >
                                          {listYearOfSahayData &&
                                            listYearOfSahayData
                                              ?.sort(
                                                (a, b) => a.toYear - b.toYear
                                              )
                                              ?.map((ele) => (
                                                <Index.MenuItem
                                                  key={ele?.id}
                                                  value={ele?.id}
                                                >
                                                  {ele?.fromYear}-{ele?.toYear}
                                                </Index.MenuItem>
                                              ))}
                                        </Index.Select>
                                      </Index.FormControl>
                                      {errors?.yearOfSahay &&
                                        touched?.yearOfSahay && (
                                          <p className="error-text">
                                            {errors?.yearOfSahay}
                                          </p>
                                        )}
                                    </Index.Box>
                                  </Index.Grid>
                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        {/* Total Claimed Sahay Amount* */}
                                        Total Requested Sahay Amount* (કુલ વિનંતી કરેલ સહાય રકમ)
                                      </Index.Typography>
                                      <Index.TextField
                                        type="number"
                                        inputProps={ariaLabel}
                                        className="basic-input input-width"
                                        name="SahayAmmount"
                                        value={values?.SahayAmmount}
                                        onChange={(e) => {
                                          if (e.target.value.length <= 7) {
                                            // Only update the value if it's not more than 40 characters
                                            handleChange(e);
                                          }
                                        }}
                                        onBlur={handleBlur}
                                      />
                                    </Index.Box>
                                    {errors?.SahayAmmount &&
                                      touched?.SahayAmmount && (
                                        <p className="error-text">
                                          {errors?.SahayAmmount}
                                        </p>
                                      )}
                                  </Index.Grid>
                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        Repay Details (ચુકવણી વિગતો)
                                      </Index.Typography>
                                      <Index.Textarea
                                        style={{
                                          fontSize: "15px",
                                          color: "black",
                                          padding: "4px",
                                        }}
                                        minRows={2}
                                        inputProps={ariaLabel}
                                        className="basic-input input-width"
                                        name="repayDetails"
                                        value={values?.repayDetails}
                                        onChange={(e) => {
                                          if (e.target.value.length == 0) {
                                            setValidationMoreTrue(false);
                                          } else {
                                            setValidationMoreTrue(true);
                                          }

                                          if (e.target.value.length <= 200) {
                                            // Only update the value if it's not more than 40 characters
                                            handleChange(e);
                                          }
                                          if (e.target.value.length > 0) {
                                            setFieldValue(
                                              "isRepayDetails",
                                              e.target.value
                                            );
                                          }
                                          // else {
                                          //   setFieldValue(
                                          //     "isRepayDetails",
                                          //     false
                                          //   );
                                          // }
                                        }}
                                        onBlur={handleBlur}
                                      />
                                    </Index.Box>
                                    {errors?.repayDetails &&
                                      touched?.repayDetails && (
                                        <p className="error-text">
                                          {errors?.repayDetails}
                                        </p>
                                      )}
                                  </Index.Grid>
                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Typography
                                      component="p"
                                      className="in-label"
                                    >
                                      Start Date (પ્રારંભ તારીખ)
                                    </Index.Typography>
                                    <Index.Box className="input-design-div with-border ">
                                      <Index.LocalizationProvider
                                        dateAdapter={Index.AdapterDayjs}
                                        sx={{ position: "absolute" }}
                                      >
                                        <Index.DemoContainer
                                          components={["DatePicker"]}
                                        >
                                          <Index.DatePicker
                                            name="startDate"
                                            onChange={(value) => {
                                              setFieldValue(
                                                "startDate",
                                                moment(value.$d).format(
                                                  "YYYY-MM-DD"
                                                )
                                              );
                                            }}
                                            value={dayjs(values?.startDate)}
                                            format="YYYY-MM-DD"
                                            sx={{
                                              overflowY: "hidden",
                                              position: "relative",
                                              bottom: "8px",
                                              width: "100%",
                                            }}
                                            slotProps={{
                                              textField: {
                                                size: "small",
                                                error: false,
                                              },
                                            }}
                                          />
                                        </Index.DemoContainer>
                                      </Index.LocalizationProvider>
                                    </Index.Box>
                                    {errors?.startDate && touched.startDate && (
                                      <p className="error-text">
                                        {errors?.startDate}
                                      </p>
                                    )}
                                  </Index.Grid>
                                  <Index.Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Index.Box className="lable-input-grp">
                                      <Index.Typography
                                        component="p"
                                        className="in-label"
                                      >
                                        No. Of Month (મહિનાની સંખ્યા)
                                      </Index.Typography>
                                      <Index.TextField
                                        type="number"
                                        inputProps={ariaLabel}
                                        className="basic-input input-width"
                                        name="totalMonth"
                                        value={values?.totalMonth}
                                        onChange={(e) => {
                                          if (e.target.value.length <= 2) {
                                            // Only update the value if it's not more than 40 characters
                                            handleChange(e);
                                          }
                                        }}
                                        onBlur={handleBlur}
                                      />
                                    </Index.Box>
                                    {errors?.totalMonth &&
                                      touched?.totalMonth && (
                                        <p className="error-text">
                                          {errors?.totalMonth}
                                        </p>
                                      )}
                                  </Index.Grid>
                                </Index.Grid>
                              </Index.Box>
                            </Index.Box>

                            <Index.Box
                              sx={{ flexGrow: 1, marginTop: "15px" }}
                              className="al-form-groups fam-in-grp multiple-doc mt-0px-sahay{"
                            >
                              <Index.Grid container spacing={2}>
                                <Index.Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  md={4}
                                  lg={4}
                                  mb={2}
                                >
                                  <Index.Box className="lable-input-grp">
                                    <Index.Typography
                                      component="p"
                                      className="in-label"
                                    >
                                      Add Multiple Document (બહુવિધ દસ્તાવેજ ઉમેરો)
                                    </Index.Typography>
                                    <Index.Box className="select-position">
                                      <input
                                        type="file"
                                        name="documents"
                                        multiple
                                        className="ori-input"
                                        onChange={(event) => {
                                          const files = event.target.files;
                                          setFieldValue("documents", [
                                            ...values?.documents,
                                            ...files,
                                          ]);
                                        }}
                                      />

                                      <Index.Box className="select-file">
                                        Upload Your Document
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Grid>
                                <Index.Grid item xs={8}>
                                  <Index.Box className="select-view-document-name">
                                    {values?.documents?.map((file, index) => (
                                      <Index.Box
                                        className="document-name"
                                        key={index}
                                      >
                                        <Index.Typography
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            // Implement the logic to view the document here
                                            // You can use a modal or a new browser tab/window to show the document
                                            window.open(
                                              file instanceof Blob
                                                ? URL.createObjectURL(file)
                                                : `${imagePath}${file?.filename}`
                                            );
                                          }}
                                        >
                                          {file?.name
                                            ? file?.name
                                            : file?.document}
                                        </Index.Typography>
                                        <Index.IconButton
                                          onClick={() => {
                                            const updatedDocuments =
                                              values.documents.filter(
                                                (_, i) => i !== index
                                              );
                                            setFieldValue(
                                              "documents",
                                              updatedDocuments
                                            );
                                          }}
                                        >
                                          <Index.ClearIcon className="muiico-cancel" />
                                        </Index.IconButton>
                                      </Index.Box>
                                    ))}
                                  </Index.Box>
                                </Index.Grid>
                              </Index.Grid>
                            </Index.Box>

                            {/* {errors?.documents && touched?.documents && (
                            <p className="error-text">{errors?.documents}</p>
                          )} */}

                            <Index.Box
                              sx={{ flexGrow: 1 }}
                              className="al-form-groups fam-in-grp"
                            >
                              <Index.Grid container spacing={2}>
                                <Index.Grid item xs={6}>
                                  <Index.Box className="lable-input-grp ">
                                    <Index.Box className="select-position">
                                      <Index.Box
                                        className={
                                          values.isSubmit === false
                                            ? "apply-now"
                                            : "disable-now"
                                        }
                                      >
                                        <button
                                          type="submit"
                                          disabled={values.isSubmit}
                                        >
                                          {editPreviousData?.applicationId
                                            ? "Update application"
                                            : "Apply now"}
                                        </button>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Grid>
                                <Index.Grid item xs={6}>
                                  <Index.Box className="common-button blue-button flex-end save-btn back-btn">
                                    <Index.Button
                                      variant="contained"
                                      onClick={() => handleBack()}
                                    >
                                      Back
                                    </Index.Button>
                                  </Index.Box>
                                </Index.Grid>
                              </Index.Grid>
                            </Index.Box>
                          </form>
                        )}
                      </PagesIndex.Formik>

                      <Index.Box className="title-header-flex "></Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </>
        )}
      </div>
    </>
  );
};

export default PreviousApplyForSahay;
